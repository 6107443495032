import Box from "@mui/material/Box";
import * as React from "react";

import { EventPopover } from "@/shared/components/visualization/Timeline/EventPopover";
import { EventRecord } from "@/shared/domain/events";

import { colorForAnnotation } from "../colorScale";
import { SidebarViewType, useWorkspaceSidebar } from "../sidebarContext";

export interface RenderableEvent {
  startMs: number;
  endMs: number;
  eventRecord: EventRecord;
}

interface EventTimelineProps {
  events: RenderableEvent[];
  startMs: number;
  endMs: number;
}

export function EventTimeline({ events, startMs, endMs }: EventTimelineProps) {
  const durationMs = endMs - startMs;
  const timelineRef = React.useRef<HTMLDivElement | null>(null);

  const sidebar = useWorkspaceSidebar();

  const [anchorPosition, setAnchorPosition] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<EventRecord | null>(
    null,
  );

  const handleMouseEnter = (eventRecord: EventRecord) => {
    setSelectedEvent(eventRecord);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const timelineRectTop =
      timelineRef.current?.getBoundingClientRect()?.top || 0;
    setAnchorPosition({ x: event.clientX, y: timelineRectTop - 4 });
  };

  const handleMouseLeave = () => {
    setSelectedEvent(null);
  };

  return (
    <div ref={timelineRef}>
      {events.map((event) => {
        const startPct = ((event.startMs - startMs) * 100) / durationMs;
        const widthPct = ((event.endMs - event.startMs) * 100) / durationMs;

        return (
          // Top box is the hover hitbox, but it's invisible. Inner box is colored
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: `${startPct}%`,
              width: `${widthPct}%`,
              height: "100%",
              cursor: "pointer",
            }}
            key={event.eventRecord.event_id}
            onMouseEnter={() => handleMouseEnter(event.eventRecord)}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onClick={function openEventDetails() {
              sidebar.openView({
                type: SidebarViewType.EventDetails,
                data: {
                  eventId: event.eventRecord.event_id,
                },
              });
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                left: "0px",
                height: "7px",
                width: "100%",
                backgroundColor: colorForAnnotation(
                  event.eventRecord.name,
                  event.eventRecord.event_id === selectedEvent?.event_id
                    ? 0.5
                    : 0.25,
                  event.eventRecord.display_options?.color,
                ),
              }}
            />
          </Box>
        );
      })}
      {selectedEvent && anchorPosition && (
        <EventPopover
          event={selectedEvent}
          anchorPosition={{
            x: anchorPosition.x - 70,
            y: anchorPosition.y - 90,
          }}
        />
      )}
    </div>
  );
}
