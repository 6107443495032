import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Box, IconButton, useTheme, Popper } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "@/shared/components";
import { HighlightedSpan } from "@/shared/components/ai/HighlightedSpan";

import { useWorkspaceTimer } from "../WorkspaceCtx";

import {
  useOpenPanelForMessagePath,
  useOpenTopicSearchForTopicId,
} from "./hooks";
import styles from "./WorkspaceActionLink.module.css";

interface WorkspaceActionLinkProps {
  text: string;
  topicId?: string;
  messagePathId?: string;
  time?: bigint;
  className?: string;
}

export const WorkspaceActionLink: React.FC<WorkspaceActionLinkProps> = ({
  text,
  topicId,
  messagePathId,
  time,
}) => {
  const theme = useTheme();
  const timer = useWorkspaceTimer();
  const boxRef = React.useRef<HTMLSpanElement>(null);
  const [open, setOpen] = React.useState(false);

  const {
    openPanel,
    available: openPanelAvailable,
    fullyQualifiedMessagePath,
  } = useOpenPanelForMessagePath(messagePathId, time);
  const {
    openSearch,
    available: openSearchAvailable,
    topicName,
  } = useOpenTopicSearchForTopicId(topicId, time);

  const timerIconOnClick = () => {
    if (!time) {
      return;
    }
    timer.seekTo(time);
    setOpen(false);
  };

  const handleActionClick = (action: () => void) => {
    action();
    setOpen(false);
  };

  const tooltipTitle = React.useMemo(() => {
    if (openPanelAvailable) {
      return `Visualize: ${fullyQualifiedMessagePath}`;
    }
    if (openSearchAvailable) {
      return `See topic: ${topicName}`;
    }
    return "Visualization not available";
  }, [
    openPanelAvailable,
    openSearchAvailable,
    fullyQualifiedMessagePath,
    topicName,
  ]);

  const [mouseX, setMouseX] = React.useState(0);

  const handleMouseEnter = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!boxRef.current) {
      return;
    }

    const rect = boxRef.current.getBoundingClientRect();
    setMouseX(event.clientX - rect.left); // Get relative X position
    setOpen(true);
  };

  return (
    <>
      <HighlightedSpan
        ref={boxRef}
        className={styles.container}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setOpen(false)}
      >
        {text}
      </HighlightedSpan>
      {boxRef.current && (
        <Popper
          open={open}
          anchorEl={boxRef.current}
          placement="top"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [mouseX - boxRef.current.offsetWidth / 2, 4], // Center above cursor
              },
            },
            {
              name: "preventOverflow",
              options: {
                boundary: "window",
              },
            },
          ]}
          style={{
            pointerEvents: "auto",
          }}
        >
          <Box
            className={styles.popperBox}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <Box
              className={styles.popperContent}
              sx={{ backgroundColor: theme.palette.paper.main }}
            >
              <RobotoTooltip title={tooltipTitle} placement="top">
                <span>
                  <IconButton
                    size="small"
                    className={styles.iconButton}
                    onClick={() => {
                      if (openPanelAvailable) {
                        handleActionClick(openPanel);
                      } else if (openSearchAvailable) {
                        handleActionClick(openSearch);
                      }
                    }}
                    disabled={!openPanelAvailable && !openSearchAvailable}
                  >
                    <QueryStatsIcon className={styles.icon} />
                  </IconButton>
                </span>
              </RobotoTooltip>
              <RobotoTooltip
                title={
                  time !== undefined
                    ? "Jump to timestamp"
                    : "No timestamp available"
                }
                placement="top"
              >
                <span>
                  <IconButton
                    size="small"
                    className={styles.iconButton}
                    onClick={timerIconOnClick}
                    disabled={time === undefined}
                  >
                    <TimerOutlinedIcon className={styles.icon} />
                  </IconButton>
                </span>
              </RobotoTooltip>
            </Box>
          </Box>
        </Popper>
      )}
    </>
  );
};
