import { ListItem, ListItemButton, ListItemText } from "@mui/material";

import { VisibilityToggle } from "@/shared/components/VisibilityToggle";
import { EventRecord } from "@/shared/domain/events";
import { actions, EventConfig } from "@/shared/state/visualization";
import {
  useEventConfig,
  useVizDispatch,
} from "@/shared/state/visualization/hooks";

import styles from "./Event.module.css";

interface EventProps {
  event: EventRecord;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const fallBackEventConfig: EventConfig = {
  isVisible: true,
};

export function Event({ event, onClick }: EventProps) {
  const eventConfig = useEventConfig(event.event_id) ?? fallBackEventConfig;

  const dispatch = useVizDispatch();

  return (
    <ListItem
      key={event.event_id}
      secondaryAction={
        <VisibilityToggle
          on={eventConfig.isVisible}
          className={styles.visibilityButton}
          onToggle={() => {
            dispatch(
              actions.setEventVisibility(
                event.event_id,
                !eventConfig.isVisible,
              ),
            );
          }}
        />
      }
      sx={{
        "& .MuiListItemSecondaryAction-root": {
          right: "12px",
        },
      }}
      disablePadding
    >
      <ListItemButton onClick={onClick}>
        <ListItemText
          primary={event.name}
          title={event.name}
          style={{
            opacity: eventConfig.isVisible ? 1 : 0.5,
          }}
          primaryTypographyProps={{
            className: styles.eventName,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
