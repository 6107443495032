import { type AssociationRecord } from "../association";

export enum RepresentationStorageFormat {
  MCAP = "mcap",
}

export interface RepresentationRecord {
  association: AssociationRecord;
  context: Record<string, string>;
  representation_id: string;
  storage_format: RepresentationStorageFormat;
}
