import {
  imageFileTypes,
  roboticsFileTypes,
  videoFileTypes,
} from "@/shared/domain/files/fileTypes";

export const computeExtensionsArray = (
  serverExtensions: string[] | undefined,
) => {
  const fileExtensions = new Set<string>();

  if (serverExtensions) {
    serverExtensions.forEach(function (value) {
      fileExtensions.add(value);
    });
  }

  // Add useful image defaults
  if (imageFileTypes) {
    imageFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });
  }

  // Add useful video defaults
  if (videoFileTypes) {
    videoFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });
  }

  // Add useful robotics defaults
  if (roboticsFileTypes) {
    roboticsFileTypes.forEach(function (value) {
      fileExtensions.add(value);
    });
  }

  return Array.from(fileExtensions.values()).sort();
};
