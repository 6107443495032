import { Box, useTheme } from "@mui/material";

import { CommentsBox } from "@/shared/components/CommentsBox/CommentsBox";
import { EphemeralWorkspaceState } from "@/shared/components/visualization/WorkspaceCtx/EphemeralWorkspaceState";
import { File } from "@/shared/state/visualization";

interface WorkspaceCommentProps {
  fileIds: string[];
  workspaceContext: EphemeralWorkspaceState;
  files: File[];
}

export const WorkspaceComment: React.FC<WorkspaceCommentProps> = ({
  fileIds,
  files,
  workspaceContext,
}) => {
  const theme = useTheme();

  let commentEntityId = undefined;
  const entityType = fileIds.length > 1 ? "dataset" : "file";

  if (fileIds.length === 1) {
    commentEntityId = fileIds[0];
  } else if (
    workspaceContext.files.length > 0 &&
    fileIds.length > 1 &&
    workspaceContext.files.every(
      (file) =>
        file.association_id === workspaceContext.files[0].association_id,
    )
  ) {
    commentEntityId = workspaceContext.files[0].association_id;
  }

  // Comments should be visible when all files are from the same dataset
  const isCommentsVisible =
    workspaceContext.files.every(
      (file) =>
        file.association_id === workspaceContext.files[0].association_id,
    ) && files.length > 0;

  return isCommentsVisible ? (
    <Box
      sx={{
        marginTop: theme.spacing(2),
        pl: theme.spacing(1),
        pr: theme.spacing(1),
      }}
    >
      <CommentsBox
        entityType={entityType}
        entityId={commentEntityId}
        enableTimestamps={true}
      />
    </Box>
  ) : null;
};
