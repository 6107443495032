import type { TimeBounds } from "@/shared/domain/topics/determineTimeBounds";
import type { PlotPanelState } from "@/shared/state/visualization";
import { type MessagePayload, WorkerManager } from "@/shared/webworker";

import {
  PanPayload,
  PlotCommand,
  PlotEvent,
  SOURCE,
  ZoomPayload,
  isErrorEvent,
  isInitializedEvent,
  isLoadingStateChangeEvent,
  isRenderedEvent,
  type PlotEventPayloadMap,
  type PlotInit,
  TimeSpanAnnotation,
} from "./messaging";
import type { HoverDatum, Style } from "./PlotRenderer";
import { Viewport } from "./Viewport";

export type OnInitializedCallback = () => void;
export type OnErrorCallback = (
  error: PlotEventPayloadMap[PlotEvent.Error],
) => void;
export type OnLoadingCallback = (
  arg0: PlotEventPayloadMap[PlotEvent.LoadingStateChange],
) => void;
export type OnRenderCallback = (
  arg0: PlotEventPayloadMap[PlotEvent.Rendered],
) => void;

const noop = () => {};

/**
 * Coordinates the rendering of a plot from the UI thread.
 * Instantiates its counterpart in a web worker, forwards commands to it, and listens for events emit by it.
 */
export class PlotManager {
  #abortController: AbortController;
  #onErrorCallback: OnErrorCallback = noop;
  #onInitialized: OnInitializedCallback = noop;
  #onLoading: OnLoadingCallback = noop;
  #onRender: OnRenderCallback = noop;
  #workerManager: WorkerManager;

  constructor({ canvas, devicePixelRatio }: PlotInit) {
    this.#abortController = new AbortController();
    const worker = new Worker(
      new URL("./PlotManager.worker", import.meta.url),
      {
        type: "module",
      },
    );
    this.#workerManager = new WorkerManager({
      worker,
      onError: this.#onError,
      onMessage: this.#onMessage,
      signal: this.#abortController.signal,
    });

    this.#workerManager.sendCommand({
      data: {
        canvas,
        devicePixelRatio,
      },
      source: SOURCE,
      signal: this.#abortController.signal,
      transferables: [canvas],
      type: PlotCommand.Init,
    });
  }

  public getDataUnderCursor({
    x,
    y,
    signal,
  }: {
    x: number;
    y: number;
    signal: AbortSignal;
  }): Promise<HoverDatum[]> {
    return this.#workerManager.sendCommandAwaitResponse({
      type: PlotCommand.GetDataUnderCursor,
      data: { x, y },
      signal,
      source: SOURCE,
    });
  }

  public setEventListener<K extends keyof PlotEventPayloadMap>(
    type: K,
    listener: (arg0: PlotEventPayloadMap[K]) => void,
  ): void {
    switch (type) {
      case PlotEvent.Initialized:
        this.#onInitialized = listener as OnInitializedCallback;
        break;
      case PlotEvent.LoadingStateChange:
        this.#onLoading = listener as OnLoadingCallback;
        break;
      case PlotEvent.Rendered:
        this.#onRender = listener as OnRenderCallback;
        break;
      case PlotEvent.Error:
        this.#onErrorCallback = listener as OnErrorCallback;
        break;
      default:
        throw new Error(`Unknown event type: ${type}`);
    }
  }

  public dispose() {
    // Remove event listeners registered on worker
    this.#abortController.abort();

    // Allow worker to shut itself down gracefully
    this.#workerManager.sendCommand({
      data: undefined,
      source: SOURCE,
      type: PlotCommand.Dispose,
    });

    // Free worker
    this.#workerManager.dispose();
  }

  public pan(payload: PanPayload): void {
    this.#workerManager.sendCommand({
      data: payload,
      source: SOURCE,
      type: PlotCommand.Pan,
    });
  }

  public applyViewport(viewport: Viewport): void {
    this.#workerManager.sendCommand({
      data: viewport.getParams(),
      source: SOURCE,
      type: PlotCommand.ApplyViewport,
    });
  }

  public resetView(): void {
    this.#workerManager.sendCommand({
      data: undefined,
      source: SOURCE,
      type: PlotCommand.ResetView,
    });
  }

  public resize({ width, height }: { width: number; height: number }): void {
    this.#workerManager.sendCommand({
      data: { width, height },
      source: SOURCE,
      type: PlotCommand.Resize,
    });
  }

  public setState(
    state: PlotPanelState["data"],
    annotations: TimeSpanAnnotation[],
    timeBounds: TimeBounds,
    signal: AbortSignal,
  ): void {
    this.#workerManager.sendCommand({
      data: { state, annotations, timeBounds },
      signal,
      source: SOURCE,
      type: PlotCommand.SetState,
    });
  }

  public setStyle(style: Style): void {
    this.#workerManager.sendCommand({
      data: style,
      source: SOURCE,
      type: PlotCommand.SetStyle,
    });
  }

  public zoom(payload: ZoomPayload): void {
    this.#workerManager.sendCommand({
      data: payload,
      source: SOURCE,
      type: PlotCommand.Zoom,
    });
  }

  #onMessage = (event: MessageEvent<MessagePayload>) => {
    if (isErrorEvent(event) && event.data.error !== undefined) {
      this.#onErrorCallback(event.data.error);
      return;
    }

    if (isInitializedEvent(event)) {
      this.#onInitialized();
      return;
    }

    if (isLoadingStateChangeEvent(event)) {
      this.#onLoading(event.data.data);
      return;
    }

    if (isRenderedEvent(event)) {
      this.#onRender(event.data.data);
      return;
    }
  };

  #onError = (error: Error) => {
    this.#onErrorCallback(error);
  };
}
