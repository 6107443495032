import { XMLParser } from "fast-xml-parser";

import { FileRecord, FileService } from "@/shared/domain/files";
import { HttpClient, HttpResponse } from "@/shared/http";
import { AsyncEvent } from "@/shared/synchronization";

import { LocalFile } from "./LocalFile";
import { type Readable } from "./Readable";
import { StorageManager } from "./StorageManager";

/**
 * Determined experimentally
 */
interface S3Error {
  Error: {
    Code: string;
    Message: string;
    Expires: string;
    ServerTime: string;
    RequestId: string;
    HostId: string;
  };
}

export class RemoteFile implements Readable {
  #abortSignal: AbortSignal | undefined;
  #fileRecord: FileRecord;
  #fileService: FileService;
  #httpClient: HttpClient;
  #isPresignedUrlLoading = false;
  #presignedUrl: URL | undefined;
  #presignedUrlLoadedEvent = new AsyncEvent();

  constructor(
    fileRecord: FileRecord,
    fileService: FileService,
    httpClient?: HttpClient,
  ) {
    this.#fileRecord = fileRecord;
    this.#fileService = fileService;
    this.#httpClient = httpClient ?? new HttpClient();
  }

  public get id() {
    return this.#fileRecord.file_id;
  }

  async read(offset: bigint, size: bigint): Promise<Uint8Array> {
    const url = await this.getPresignedUrl();
    const response = await this.#httpClient.get(url, {
      ranges: [
        {
          unit: "bytes",
          start: Number(offset),
          end: Number(offset + size) - 1, // Range requests are inclusive of bounds
        },
      ],
      signal: this.#abortSignal,
    });

    if (await this.presignedUrlExpired(response)) {
      if (this.#presignedUrl === undefined || this.#isPresignedUrlLoading) {
        // If a concurrent request has already determined that the presigned URL has expired,
        // avoid stepping on its toes.
        // This AsyncEvent is set when the presigned URL has been successfully refreshed.
        await this.#presignedUrlLoadedEvent.wait(this.#abortSignal);
      } else {
        // When RemoteFile::getPresignedUrl is called on the next call to RemoteFile::read,
        // it will refresh the presigned URL and set the AsyncEvent.
        this.#presignedUrl = undefined;
        this.#presignedUrlLoadedEvent.clear();
      }
      return this.read(offset, size);
    }
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
  }

  public setAbortSignal(abortSignal?: AbortSignal) {
    this.#abortSignal = abortSignal;
  }

  public size(): Promise<bigint> {
    return Promise.resolve(BigInt(this.#fileRecord.size));
  }

  /**
   * Request all bytes of the file in batches no larger than 1MB,
   * and write them into a file stored in the Origin Private Filesystem.
   * Return a LocalFile to provide a reader interface for those bytes.
   */
  public async toLocalFile(storageManager: StorageManager): Promise<LocalFile> {
    const file = new LocalFile(this.#fileRecord, storageManager);
    file.setAbortSignal(this.#abortSignal);

    if (await file.isDownloaded()) {
      return file;
    }

    try {
      const fileHandle = await file.path.getWriteableSyncFileHandle(
        this.#abortSignal,
      );
      try {
        // Memorialize last access time before downloading bytes.
        // If there is an issue downloading the data,
        // this will ensure the last access time file exists,
        // making it visible for GC.
        await storageManager.memorializeLastAccessTime(file.path);

        const MAX_CHUNK_SIZE = 1024 * 1024; // 1MiB chunks
        const numParts = Math.max(
          Math.ceil(this.#fileRecord.size / MAX_CHUNK_SIZE),
          1,
        );

        // Create array of chunk ranges
        const ranges: { start: number; end: number }[] = [];
        for (let i = 0; i < numParts; i++) {
          ranges.push({
            start: i * MAX_CHUNK_SIZE,
            end: Math.min((i + 1) * MAX_CHUNK_SIZE, this.#fileRecord.size),
          });
        }

        // Fetch all chunks concurrently and write them to the file
        await Promise.all(
          ranges.map(async (range) => {
            const chunk = await this.read(
              BigInt(range.start),
              BigInt(range.end - range.start),
            );
            const numBytesWritten = fileHandle.write(chunk, {
              at: range.start,
            });
            if (numBytesWritten !== chunk.byteLength) {
              throw new Error("Failed to download data, please retry.", {
                cause: `Expected to write ${chunk.byteLength} bytes to ${file.path.toString()}, wrote ${numBytesWritten}`,
              });
            }
          }),
        );
        fileHandle.flush();
        return file;
      } finally {
        fileHandle.close();
      }
    } catch (err: unknown) {
      // Assume any exception downloading the data makes the file unusable
      try {
        await file.path.delete();
      } catch {
        // swallow, may happen if the file failed to create in the first place
      }

      const isDOMException = err instanceof DOMException;
      if (!isDOMException) {
        // unhandled, rethrow
        throw err;
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemSyncAccessHandle/write#exceptions
      if (err.name === "QuotaExceededError") {
        await storageManager.purgeStorage();
        throw new Error(
          [
            "Failed to download data.",
            "Roboto uses temporary storage on your file system, which is now full.",
            "We've cleared our stored data, but you may need to free additional space.",
            "Check your available storage then reload this panel to retry.",
          ].join(" "),
          {
            cause: err,
          },
        );
      }

      // in any other case, just ask the user to retry
      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/createSyncAccessHandle#exceptions
      throw new Error(
        [
          "Failed to download data.",
          "Roboto uses temporary storage on your file system.",
          "Check your available storage then reload this panel to retry.",
        ].join(" "),
        {
          cause: err,
        },
      );
    }
  }

  private async getPresignedUrl(): Promise<URL> {
    if (this.#isPresignedUrlLoading) {
      await this.#presignedUrlLoadedEvent.wait(this.#abortSignal);
    }
    if (this.#presignedUrl === undefined) {
      this.#isPresignedUrlLoading = true;
      try {
        const url = await this.#fileService.getPresignedDownloadUrl(
          this.#fileRecord.file_id,
          {
            abortSignal: this.#abortSignal,
          },
        );
        this.#presignedUrl = url;
      } finally {
        this.#isPresignedUrlLoading = false;
        this.#presignedUrlLoadedEvent.set();
      }
    }

    return this.#presignedUrl;
  }

  /**
   * Reading of remote files relies on having a presigned url that has not expired.
   * When the URLs expire, AWS returns a 403 with an XML document.
   */
  private async presignedUrlExpired(response: HttpResponse): Promise<boolean> {
    if (response.status !== 403) {
      return false;
    }

    // The signed URL might be expired.
    // AWS returns an XML document that looks like:
    // <Error>
    //    <Code>AccessDenied</Code>
    //    <Message>Request has expired</Message>
    //    <X-Amz-Expires>60</X-Amz-Expires>
    //    <Expires>2024-02-29T18:46:34Z</Expires>
    //    <ServerTime>2024-02-29T18:46:38Z</ServerTime>
    //    <RequestId>BNZ7FV4AWSPFW125</RequestId>
    //    <HostId>...</HostId>
    // </Error>
    const responseText = await response.text();
    const xmlParser = new XMLParser();
    const responseXML = xmlParser.parse(responseText) as S3Error;
    const error = responseXML["Error"];
    if (error === undefined) {
      return false;
    }

    const serverTime = Date.parse(error["ServerTime"] ?? "");
    const expirationTime = Date.parse(error["Expires"] ?? "");
    if (Number.isNaN(serverTime) || Number.isNaN(expirationTime)) {
      throw new Error("Failed to read file content", { cause: responseText });
    }
    return serverTime > expirationTime;
  }
}
