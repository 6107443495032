import { Alert, Box } from "@mui/material";

import { useEphemeralWorkspaceState } from "@/shared/components/visualization/WorkspaceCtx";

import styles from "./AISummary.module.css";
import { AISummaryFileContent } from "./AISummaryFileContent";

export function AISummary() {
  const workspace = useEphemeralWorkspaceState();

  let fileId: string | undefined;

  if (!workspace.loading && workspace.files.length === 1) {
    fileId = workspace.files[0]?.file_id;
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        {!workspace.loading && !fileId && (
          <Alert severity="info">
            AI Summaries are currently only available with single files in the
            visualizer.
          </Alert>
        )}
        {fileId && <AISummaryFileContent fileId={fileId} />}
      </Box>
    </Box>
  );
}
