import * as React from "react";

import {
  EventDataEntityType,
  EventSourceType,
} from "@/shared/components/events";
import { RobotoButton } from "@/shared/components/RobotoButton";
import {
  CreateEventSidebarProps,
  SidebarViewType,
  useWorkspaceSidebar,
} from "@/shared/components/visualization/sidebarContext";
import { EphemeralWorkspaceStateContext } from "@/shared/components/visualization/WorkspaceCtx";
import { Dimensions } from "@/shared/hooks";

import { Extents } from "../../PlotRenderer";
import { pixelRegionToTimeRange } from "../chartRegionToTimeRange";

interface CreateEventFromSelectionButtonProps {
  onClose: () => void;
  panelId: string;
  selectedRegion: Dimensions;
  chartExtents: Extents;
}

export function CreateEventFromSelectionButton({
  chartExtents,
  onClose,
  panelId,
  selectedRegion,
}: CreateEventFromSelectionButtonProps) {
  const sidebar = useWorkspaceSidebar();

  const timeRange = pixelRegionToTimeRange(selectedRegion, chartExtents);

  const { addEventToWorkspace } = React.useContext(
    EphemeralWorkspaceStateContext,
  );

  return (
    <RobotoButton
      eventName={"CreateEventFromSelectionClicked"}
      variant="text"
      onClick={function openCreateEventForm() {
        sidebar.toggleDrawer<CreateEventSidebarProps>({
          type: SidebarViewType.CreateEvent,
          data: {
            defaultFields: {
              start_time: timeRange.startTime,
              end_time: timeRange.endTime,
              scope: {
                dataEntity: { type: EventDataEntityType.AllMessagePaths },
                source: {
                  type: EventSourceType.ThisPanel,
                  panelId: panelId,
                },
              },
            },
            onSuccess: (event) => {
              addEventToWorkspace(event);
              onClose();
            },
            onCancel: onClose,
            sourceOptions: [
              { type: EventSourceType.ThisPanel, panelId },
              { type: EventSourceType.AllPanels },
            ],
          },
        });
      }}
    >
      Create Event
    </RobotoButton>
  );
}
