import * as React from "react";

import { PlotTool } from "../plotTools/PlotTool";

import { PlotPanelContext, Region } from "./PlotPanelContext";

export function PlotPanelProvider({ children }: React.PropsWithChildren) {
  const [activeTool, setActiveTool] = React.useState<PlotTool>(PlotTool.Move);
  const [isViewportSynced, setViewportSynced] = React.useState(true);
  const [isLegendShown, setIsLegendShown] = React.useState(true);

  const [selectedRegion, setSelectedRegion] = React.useState<Region | null>(
    null,
  );

  React.useEffect(
    function resetSelectedRegionWhenToolChanges() {
      setSelectedRegion(null);
    },
    [activeTool, setSelectedRegion],
  );

  return (
    <PlotPanelContext.Provider
      value={{
        activeTool,
        setActiveTool,
        isViewportSynced,
        setViewportSynced,
        isLegendShown,
        setIsLegendShown,
        selectedRegion,
        setSelectedRegion,
      }}
    >
      {children}
    </PlotPanelContext.Provider>
  );
}
