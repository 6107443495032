import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import IconButton from "@mui/material/IconButton";

import { usePlotPanelContext } from "../panelContext";

import { PlotTool } from "./PlotTool";

export function SelectRegionTool() {
  const { activeTool, setActiveTool } = usePlotPanelContext();
  return (
    <IconButton
      aria-label="select-region"
      onClick={() => setActiveTool(PlotTool.SelectRegion)}
      size="small"
      title="Select a region"
    >
      <HighlightAltIcon
        color={activeTool === PlotTool.SelectRegion ? "primary" : "inherit"}
        fontSize="small"
      />
    </IconButton>
  );
}
