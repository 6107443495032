import { RepresentationRecord } from "./RepresentationRecord";

export enum CanonicalDataType {
  Array = "array",
  Boolean = "boolean",
  Byte = "byte",
  Image = "image",
  Number = "number",
  NumberArray = "number_array",
  Object = "object",
  String = "string",
  Unknown = "unknown",

  // Special purpose types for data that represents geographic points
  LatDegFloat = "latdegfloat",
  LonDegFloat = "londegfloat",
  LatDegInt = "latdegint",
  LonDegInt = "londegint",
}

export interface MessagePathRecord {
  canonical_data_type: CanonicalDataType;
  created: string; // timestamp
  created_by: string;
  data_type: string;
  message_path: string;
  message_path_id: string;
  metadata: Record<string, unknown>;
  modified: string; // timestamp
  modified_by: string;
  representations: RepresentationRecord[];
  topic_id: string;
}

export enum MessagePathPartType {
  Attr = "Attr",
  Slice = "Slice",
}

export interface MessagePathAttr {
  type: MessagePathPartType.Attr;
  attribute: string;
  dataType: CanonicalDataType;
}

export interface MessagePathSlice {
  type: MessagePathPartType.Slice;
  start: number;
  end?: number | null;
}

export type MessagePathPart = MessagePathAttr | MessagePathSlice;
