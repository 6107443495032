import { HttpClient } from "@/shared/http";

import { HealthCheckResponse } from "./HealthRecord";

export class HealthService {
  constructor(private readonly httpClient: HttpClient) {}

  async deepCheck(options?: {
    abortSignal?: AbortSignal;
  }): Promise<HealthCheckResponse> {
    const response = await this.httpClient.get(
      this.httpClient.constructUrl("v1/health/ping/deep"),
      {
        signal: options?.abortSignal,
      },
    );

    return response.json<HealthCheckResponse>();
  }
}
