import SettingsIcon from "@mui/icons-material/Settings";
import { Box, TableCell, useTheme } from "@mui/material";
import * as React from "react";
import { Resizable, ResizeCallbackData } from "react-resizable";

import { formatFieldName } from "@/shared/text";
import { DataGridSortingOrder } from "@/types";

import { RobotoIconButton } from "../RobotoIconButton";

import { AdjustmentHandle } from "./AdjustmentHandle";
import { SorterIcon } from "./SorterIcon";

interface ResizableHeaderCellProps {
  title: string;
  width: number;
  constantHeight: number;
  columnIndex: number;
  maxCellWidth: number;
  minCellWidth: number;
  onResize: (columnIndex: number, data: ResizeCallbackData) => void;
  onSortStateChange: (
    sortingState: DataGridSortingOrder,
    columnIndex: number,
  ) => void;
  sortingState: DataGridSortingOrder;
  finalColumn: boolean;
  onAddColumn: () => void;
  isSortable: boolean;
}

export const ResizableHeaderCell: React.FC<ResizableHeaderCellProps> = ({
  title,
  width,
  constantHeight,
  maxCellWidth,
  minCellWidth,
  columnIndex,
  onResize,
  sortingState,
  onSortStateChange,
  finalColumn,
  onAddColumn,
  isSortable,
}) => {
  const theme = useTheme();
  return (
    <TableCell
      sx={{
        backgroundColor: theme.palette.paper.main,
        padding: "0px",
        width: finalColumn ? "100%" : "auto",
      }}
    >
      <Resizable
        className="box"
        height={constantHeight}
        width={width}
        onResize={(_event, data) => {
          onResize(columnIndex, data);
        }}
        resizeHandles={["e"]}
        minConstraints={[minCellWidth, constantHeight]}
        maxConstraints={[maxCellWidth, constantHeight]}
        handle={<AdjustmentHandle />}
      >
        <div
          style={{
            width: width + "px",
            height: constantHeight + "px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px",
          }}
        >
          {/* NOTE: This div above contains anything you put here AND the AdjustmentHandle. Act accordingly */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              paddingLeft: "8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Box
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "var(--gray-blue-grey-blue-70, rgb(107, 114, 128))",
                fontSize: "0.9rem",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              {formatFieldName(title)}
            </Box>
            {isSortable && (
              <SorterIcon
                onSortStateChange={(sortingState) => {
                  onSortStateChange(sortingState, columnIndex);
                }}
                sortingState={sortingState}
              />
            )}
            {finalColumn && (
              <RobotoIconButton
                eventName={"AddColumnToDatagridClicked"}
                onClick={onAddColumn}
              >
                <SettingsIcon fontSize="small" />
              </RobotoIconButton>
            )}
          </Box>
        </div>
      </Resizable>
    </TableCell>
  );
};
