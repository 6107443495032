import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoLinkHoverUnderline } from "@/shared/components";
import { TriggerLink } from "@/shared/components/links";
import { UserLink } from "@/shared/components/UserLink";
import {
  InvocationRecord,
  InvocationSource,
  InvocationStatus,
  isDataSourceSpecified,
} from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import { ActionLink } from "../action";
import { ActionComputeReqsTable } from "../action/ActionComputeReqsTable";
import { ActionContainerParamsTable } from "../action/ActionContainerParamsTable";
import { stringifyCommand } from "../action/actionUtils";

import CancelInvocationButton from "./CancelInvocationButton";
import CloneInvocationButton from "./CloneInvocationButton";
import { InvocationRuntimeText } from "./InvocationRuntimeText/InvocationRuntimeText";
import InvocationStatusChip from "./InvocationStatusChip";
import { ParamValuesTable } from "./ParamValuesTable";

interface InvocationDetailsProps {
  currentOrg: OrgRecord | null;
  invocation: InvocationRecord;
  refetchRecord: () => void;
}

const invocationStatusToAlertSeverity = (status?: InvocationStatus) => {
  switch (status) {
    case InvocationStatus.Queued:
    case InvocationStatus.Scheduled:
    case InvocationStatus.Downloading:
    case InvocationStatus.Processing:
    case InvocationStatus.Uploading:
    case InvocationStatus.Cancelled: // Fall through
      return "info";
    case InvocationStatus.Completed:
      return "success";
    case InvocationStatus.Failed:
    case InvocationStatus.Deadly: // Fall through
      return "error";
  }

  return "info";
};

export const InvocationDetails: React.FC<InvocationDetailsProps> = ({
  currentOrg,
  invocation,
  refetchRecord,
}) => {
  const theme = useTheme();

  const statusRecord = invocation.status[invocation.status.length - 1];
  const statusDetail = statusRecord.detail;

  const [infoAnchorEl, setInfoAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  let linkContent;

  if (invocation.provenance.source.source_type === InvocationSource.Trigger) {
    linkContent = (
      <TriggerLink triggerId={invocation.provenance.source.source_id} />
    );
  } else if (
    invocation.provenance.source.source_type === InvocationSource.Manual
  ) {
    linkContent = <UserLink userId={invocation.provenance.source.source_id} />;
  } else {
    linkContent = invocation.provenance.source.source_id;
  }

  return (
    <Box>
      <Alert
        severity={invocationStatusToAlertSeverity(statusRecord?.status)}
        sx={{ mt: theme.spacing(1.5) }}
      >
        <AlertTitle>Invocation Status</AlertTitle>
        {statusDetail}
      </Alert>
      <List sx={{ width: "100%" }}>
        <ListItem>
          <ListItemText
            primary={"Invocation ID"}
            secondary={invocation.invocation_id}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Status"}
            secondary={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                }}
              >
                <InvocationStatusChip
                  onPollForStatus={refetchRecord}
                  status={statusRecord?.status}
                />
                <CancelInvocationButton
                  invocation={invocation}
                  onCancel={refetchRecord}
                  org={currentOrg}
                />
                <CloneInvocationButton
                  invocation={invocation}
                  org={currentOrg}
                />
              </Box>
            }
            secondaryTypographyProps={{
              component: "span",
              sx: {
                mt: 0.5,
              },
            }}
          />
        </ListItem>
        <InvocationRuntimeText
          duration={invocation.duration}
          lastStatus={invocation.last_status}
        />
        <ListItem>
          <ListItemText
            primary={"Run By"}
            secondary={
              <>
                {linkContent} on{" "}
                {new Date(invocation.created || "").toLocaleString()}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Action"}
            secondaryTypographyProps={{
              component: "span",
            }}
            secondary={
              invocation.provenance?.action && (
                <>
                  <ActionLink
                    action={{
                      name: invocation.provenance.action.name,
                      digest: invocation.provenance.action.digest,
                      owner: invocation.provenance.action.org_id,
                    }}
                  />
                  <IconButton
                    size="small"
                    color="info"
                    sx={{ ml: theme.spacing(0.25) }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      setInfoAnchorEl(event.currentTarget)
                    }
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </>
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Dataset"}
            secondaryTypographyProps={{
              component: "span",
            }}
            secondary={
              isDataSourceSpecified(invocation.data_source) ? (
                <RobotoLinkHoverUnderline
                  to={`/datasets/${invocation.data_source.data_source_id}`}
                >
                  {invocation.data_source.data_source_id}
                </RobotoLinkHoverUnderline>
              ) : (
                "None"
              )
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Input Data"}
            secondaryTypographyProps={{
              component: "span",
              sx: {
                fontSize: "0.8rem",
              },
            }}
            secondary={
              <pre>{JSON.stringify(invocation.input_data, null, 2)}</pre> ||
              "None"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Timeout"}
            secondaryTypographyProps={{
              component: "span",
            }}
            secondary={`${invocation.timeout} minutes`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Parameters"}
            secondaryTypographyProps={{
              component: "span",
              sx: {
                fontSize: "0.8rem",
              },
            }}
            secondary={
              <Box
                sx={{
                  mt: theme.spacing(1),
                  maxWidth: "70ch",
                }}
              >
                <ParamValuesTable paramValues={invocation.parameter_values} />
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Container Parameters"}
            secondaryTypographyProps={{
              component: "span",
              sx: {
                fontSize: "0.8rem",
              },
            }}
            secondary={
              <Box
                sx={{
                  mt: theme.spacing(1),
                }}
              >
                <ActionContainerParamsTable
                  workdir={JSON.stringify(
                    invocation.container_parameters.workdir,
                  )}
                  command={stringifyCommand(
                    invocation.container_parameters.command || null,
                  )}
                  entrypoint={JSON.stringify(
                    invocation.container_parameters.entry_point,
                  )}
                  envVars={invocation.container_parameters.env_vars || {}}
                />
              </Box>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={"Compute Requirements"}
            secondaryTypographyProps={{
              component: "span",
              sx: {
                fontSize: "0.8rem",
              },
            }}
            secondary={
              <Box
                sx={{
                  mt: theme.spacing(1),
                }}
              >
                <ActionComputeReqsTable
                  cpu={invocation.compute_requirements.vCPU.toString()}
                  memory={invocation.compute_requirements.memory.toString()}
                  gpu={invocation.compute_requirements.gpu.toString()}
                  storage={invocation.compute_requirements.storage}
                />
              </Box>
            }
          />
        </ListItem>
      </List>
      <Popover
        open={Boolean(infoAnchorEl)}
        anchorEl={infoAnchorEl}
        onClose={() => setInfoAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          maxWidth: "600px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            variant="caption"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {[
              invocation.provenance.executable.container_image_uri,
              invocation.provenance.executable.container_image_digest,
            ]
              .filter(Boolean)
              .join("@")}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
