import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

import { type MessagePathNode } from "@/shared/domain/topics";
import {
  LayoutItem,
  VizStateDispatchContext,
} from "@/shared/state/visualization";

import { DraggableItem, DragItem, DropZone, type DroppableData } from "../DnD";

import { MessagePathDragOverlay, PanelDragOverlay } from "./DragOverlay";
import { dropMessagePath, dropPanel } from "./dropHandlers";
import { PointerSensor } from "./PointerSensor";

const closestCenterWhenIntersection: CollisionDetection = (args) => {
  const active = args.active.data.current as DraggableItem<unknown>;
  const nextArgs = {
    ...args,
    droppableContainers: args.droppableContainers.filter(({ data, id }) => {
      const { dropZone } = data.current as DroppableData<unknown>;

      if (active.type === DragItem.MessagePath) {
        return [DropZone.Layout, DropZone.Panel, DropZone.PanelBoard].includes(
          dropZone,
        );
      }

      if (active.type === DragItem.Panel) {
        return (
          dropZone === DropZone.Layout &&
          !id.toString().includes(args.active.id.toString())
        );
      }
    }),
  };

  const rectIntersectionCollisions = rectIntersection(nextArgs);

  if (rectIntersectionCollisions.length === 0) {
    return [];
  }

  return closestCenter(nextArgs);
};

function isMessagePathDragEvent(
  eventData: DraggableItem<unknown>,
): eventData is DraggableItem<MessagePathNode> {
  return eventData.type === DragItem.MessagePath;
}

function isPanelDragEvent(
  eventData: DraggableItem<unknown>,
): eventData is DraggableItem<LayoutItem> {
  return eventData.type === DragItem.Panel;
}

/**
 * A context provider for handling the drag of MessagePaths and Panels into their drop zones.
 */
export function DndContainer({ children }: React.PropsWithChildren) {
  const dispatch = React.useContext(VizStateDispatchContext);
  const [active, setActive] = React.useState<DraggableItem<unknown> | null>(
    null,
  );
  const [alert, setAlert] = React.useState<string | null>(null);

  const sensors = useSensors(useSensor(PointerSensor));

  const onDragStart = React.useCallback((event: DragStartEvent) => {
    const eventData = event.active.data.current as
      | DraggableItem<unknown>
      | undefined;

    setActive(eventData ?? null);
  }, []);

  const onDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      if (event.over === null) {
        setActive(null);
        return;
      }

      const draggableItem = event.active.data.current as DraggableItem<unknown>;
      const droppable = event.over.data.current as DroppableData<unknown>;

      if (isMessagePathDragEvent(draggableItem)) {
        const draggedMessagePath = draggableItem.data;
        dropMessagePath(
          dispatch,
          draggedMessagePath,
          droppable,
          (errorMsg: string) => {
            setAlert(errorMsg);
          },
        );
      }

      if (isPanelDragEvent(draggableItem)) {
        const draggedPanel = draggableItem.data;
        dropPanel(dispatch, draggedPanel, droppable, (errorMsg: string) => {
          setAlert(errorMsg);
        });
      }

      setActive(null);
    },
    [dispatch],
  );

  const dismissAlert = () => setAlert(null);

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      collisionDetection={closestCenterWhenIntersection}
      sensors={sensors}
    >
      {children}
      <Snackbar
        key={alert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000} // ms
        onClose={dismissAlert}
        open={alert !== null}
      >
        <Alert severity="error" onClose={dismissAlert}>
          {alert}
        </Alert>
      </Snackbar>
      <MessagePathDragOverlay active={active} />
      <PanelDragOverlay active={active} />
    </DndContext>
  );
}
