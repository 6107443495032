import { HttpClient, PaginatedResponse, robotoHeaders } from "@/shared/http";

import {
  LayoutRecord,
  CreateLayoutRequest,
  UpdateLayoutRequest,
} from "./LayoutRecord";

export class LayoutService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async createLayout(
    request: CreateLayoutRequest,
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<LayoutRecord> {
    const requestUrl = this.#httpClient.constructUrl(`v1/layouts/create`);
    const response = await this.#httpClient.post(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ orgId }),
      body: JSON.stringify(request),
    });
    return response.json<LayoutRecord>();
  }

  public async getLayoutById(
    layoutId: string,
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<LayoutRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/layouts/id/${layoutId}`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ orgId }),
    });
    return response.json<LayoutRecord>();
  }

  public async getLayoutsForOrg(
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      pageToken: string;
      limit: number;
    }>,
  ): Promise<PaginatedResponse<LayoutRecord[]>> {
    const searchParams = new URLSearchParams();

    if (options?.pageToken !== undefined) {
      searchParams.append("page_token", options.pageToken);
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/layouts`,
      searchParams,
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ orgId }),
    });
    return response.json<PaginatedResponse<LayoutRecord[]>>();
  }

  public async updateLayout(
    layoutId: string,
    request: UpdateLayoutRequest,
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<LayoutRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/layouts/id/${layoutId}`,
    );
    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ orgId }),
      body: JSON.stringify(request),
    });
    return response.json<LayoutRecord>();
  }

  public async deleteLayout(
    layoutId: string,
    orgId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/layouts/id/${layoutId}`,
    );
    const response = await this.#httpClient.delete(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ orgId }),
    });
    await response.throwIfError();
  }
}
