import { Alert, Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";

import {
  type BaseTableProps,
  DatasetsTable,
  TopicsTable,
  TopicMessagePathsTable,
  EventResultsTable,
} from "@/shared/components/SearchResultViews";
import { QueryTarget } from "@/shared/domain/query";
import { useQueryRecord, useQueryResults } from "@/shared/domain/query/hooks";
import { RobotoDomainException } from "@/shared/http";
import { ErrorMonitoringService } from "@/shared/services";
import { Dataset } from "@/types";

import { FileResults } from "./FileResults";
import { OnResultInteraction, SearchResultInteraction } from "./interaction";

const noop = () => {};

export interface SearchResultsProps {
  queryId?: string;
  lastError?: RobotoDomainException | Error;
  onError?: (error: RobotoDomainException | Error) => void;
  onLoaded?: () => void;
  onResultInteraction?: OnResultInteraction;
  selectedDatasets?: Set<string>;
  setSelectedDatasets?: (arg: Set<string>) => void;
  extraHeight?: number;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  queryId,
  lastError,
  onError = noop,
  onLoaded = noop,
  onResultInteraction,
  selectedDatasets,
  setSelectedDatasets,
  extraHeight = 0,
}) => {
  const theme = useTheme();
  const queryRecordQuery = useQueryRecord(queryId);
  const queryRecord = queryRecordQuery.data;

  const { resultsQuery, countQuery } = useQueryResults(queryRecord?.query_id);

  const results = React.useMemo(() => resultsQuery.data ?? [], [resultsQuery]);
  const count = countQuery.data;

  const isLoading = queryRecordQuery.isLoading || resultsQuery.isLoading;
  const queryError = queryRecordQuery.error || resultsQuery.error;

  // 100vh - navTopBarHeight - searchBarHeight - any margins/padding - pagination
  const maxTableHeight = `calc(100vh - ${
    theme.navTopBarHeight
  } - 56px - 35px - ${parseInt(theme.spacing(3))}px) - 49px - ${extraHeight}px`;

  const fetchMoreData = React.useCallback(() => {
    if (!resultsQuery.isFetching) {
      resultsQuery
        .fetchNextPage()
        .catch((err) => ErrorMonitoringService.captureError(err));
    }
  }, [resultsQuery]);

  const makeBaseProps = React.useCallback(
    function makeBaseProps<T>(): BaseTableProps<T> {
      return {
        data: (results as T[]) || [],
        dataCount: count,
        loading: isLoading,
        fetchMoreData,
        containerStyle: {
          transition: "max-width 0.3s ease-in-out",
          maxHeight: maxTableHeight,
        },
      };
    },
    [results, count, isLoading, fetchMoreData, maxTableHeight],
  );

  React.useEffect(() => {
    if (queryRecordQuery.error || resultsQuery.error) {
      if (queryRecordQuery.error) {
        onError(queryRecordQuery.error);
      } else if (resultsQuery.error) {
        onError(resultsQuery.error);
      }
    }
  }, [onError, queryRecordQuery, resultsQuery]);

  React.useEffect(() => {
    if (!isLoading) {
      onLoaded();
    }
  }, [onLoaded, isLoading]);

  if (
    !isLoading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Datasets
  ) {
    const onDatasetSingleClick = (dataset: Dataset) => {
      if (onResultInteraction) {
        onResultInteraction(
          dataset,
          QueryTarget.Datasets,
          SearchResultInteraction.SingleClick,
        );
      }
    };

    return (
      <DatasetsTable
        {...makeBaseProps()}
        selectedDatasets={selectedDatasets}
        setSelectedDatasets={setSelectedDatasets}
        onDatasetSingleClick={onDatasetSingleClick}
      />
    );
  } else if (
    !isLoading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Files
  ) {
    {
      return <FileResults {...makeBaseProps()} extraHeight={extraHeight} />;
    }
  } else if (
    !isLoading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Topics
  ) {
    {
      return (
        <TopicsTable
          {...makeBaseProps()}
          selectedRows={undefined}
          setSelectedRows={noop}
          onRowSingleClick={noop}
        />
      );
    }
  } else if (
    !isLoading &&
    queryRecord &&
    queryRecord.target === QueryTarget.TopicMessagePaths
  ) {
    return (
      <TopicMessagePathsTable
        {...makeBaseProps()}
        selectedRows={undefined}
        setSelectedRows={noop}
        onRowSingleClick={noop}
      />
    );
  } else if (
    !isLoading &&
    queryRecord &&
    queryRecord.target === QueryTarget.Events
  ) {
    return <EventResultsTable {...makeBaseProps()} />;
  } else if (!isLoading && queryRecord && queryRecord.target) {
    return (
      <Box mt={theme.spacing(2)}>
        <Alert severity="error">
          Search results cannot be displayed. Please contact support@roboto.ai
          if this problem persists.
        </Alert>
      </Box>
    );
  } else if (!queryError && !lastError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(3),
        }}
      >
        <CircularProgress size="2rem" />
      </Box>
    );
  }
};
