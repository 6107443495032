import StartIcon from "@mui/icons-material/StartOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Divider, useTheme, Box } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { useSystemUser } from "@/providers/auth/hooks.ts";
import { AIBadge } from "@/shared/components/ai";
import { RobotoIconButton } from "@/shared/components/RobotoIconButton";
import { RobotoStyledTab } from "@/shared/components/RobotoStyledTabs";
import { RobotoTooltip } from "@/shared/components/RobotoTooltip";

import { SidebarTab, useWorkspaceSidebar } from "../sidebarContext";

import { AISummary } from "./AISummary";
import { EventList } from "./EventList";
import { FileAndTopicList } from "./FileAndTopicList";
import styles from "./TabbedSidebar.module.css";

interface TabbedSidebarProps {
  containerClassName?: classNames.Argument;
  containerStyle?: React.CSSProperties;
}

export function TabbedSidebar(props: TabbedSidebarProps) {
  const { containerClassName, containerStyle = {} } = props;
  const theme = useTheme();
  const sidebar = useWorkspaceSidebar();
  const { isSystemUser } = useSystemUser();

  const onTabChange = React.useCallback(
    (_event: React.SyntheticEvent, value: string) => {
      const tab = SidebarTab[value as keyof typeof SidebarTab];
      sidebar.setActiveTab(tab);
    },
    [sidebar],
  );

  return (
    <TabContext value={sidebar.activeTab}>
      <section
        className={classNames(styles.container, containerClassName)}
        style={{ backgroundColor: theme.palette.code.main, ...containerStyle }}
      >
        <header className={styles.header}>
          <TabList onChange={onTabChange} className={styles.tabList}>
            <RobotoStyledTab value={SidebarTab.Topics} label="Topics" />
            <RobotoStyledTab value={SidebarTab.Events} label="Events" />
            {isSystemUser && (
              <RobotoStyledTab
                value={SidebarTab.AISummary}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(0.75),
                    }}
                  >
                    <AIBadge />
                    AI
                  </Box>
                }
              />
            )}
          </TabList>
          <RobotoTooltip title="Close Sidebar">
            <span>
              <RobotoIconButton
                onClick={() => sidebar.close()}
                eventName="WorkspaceSidebarClosed"
                size="small"
              >
                <StartIcon
                  fontSize="small"
                  sx={{
                    transform: "rotate(180deg)",
                    color: theme.palette.text.secondary,
                  }}
                />
              </RobotoIconButton>
            </span>
          </RobotoTooltip>
        </header>
        <Divider sx={{ borderColor: theme.border.color }} />
        <div className={styles.tabPanels}>
          <TabPanel className={styles.tabPanel} value={SidebarTab.Topics}>
            <FileAndTopicList />
          </TabPanel>
          <TabPanel className={styles.tabPanel} value={SidebarTab.Events}>
            <EventList />
          </TabPanel>
          <TabPanel className={styles.tabPanel} value={SidebarTab.AISummary}>
            <AISummary />
          </TabPanel>
        </div>
      </section>
    </TabContext>
  );
}
