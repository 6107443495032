import { Pagination as MUIPagination } from "@mui/material";

import styles from "./Pagination.module.css";

interface Pagination {
  /**
   * Expect currentPage to be 0 indexed
   */
  currentPage: number;
  onPageChange: (page: number) => void;
  pageCount: number;
}

export function Pagination({
  currentPage,
  onPageChange,
  pageCount,
}: Pagination) {
  if (pageCount < 2) {
    return null;
  }

  return (
    <div className={styles.container}>
      <MUIPagination
        count={pageCount}
        // Convert 0-based to 1-based
        page={currentPage + 1}
        // Convert 1-based to 0-based
        onChange={(_e, page: number) => onPageChange(page - 1)}
        color="primary"
        size="small"
        showFirstButton
        showLastButton
      />
    </div>
  );
}
