import { Alert, Box, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import { RobotoLinkHoverUnderline } from "@/shared/components/RobotoLinkHoverUnderline";
import { Accessibility, ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { Goto } from "@/shared/navigation";

import ActionCard from "./ActionCard";

interface ActionCardGridProps {
  actions: ActionRecord[];
  isLoading: boolean;
  onDelete?: (action: ActionRecord) => void;
  org: OrgRecord | null;
  accessibility: Accessibility;
}

/**
 * A responsive grid of ActionCards.
 */
export default function ActionCardGrid({
  actions,
  isLoading,
  onDelete,
  org,
  accessibility,
}: ActionCardGridProps) {
  let content: JSX.Element[] | null = null;
  if (isLoading) {
    content = Array(3)
      .fill(0)
      .map((_, index) => {
        return (
          <Skeleton
            key={index}
            variant="rectangular"
            width={368}
            height={210}
            sx={{
              borderRadius: 1,
            }}
          />
        );
      });
  } else {
    content = actions.map((action) => {
      return (
        <ActionCard
          key={action.digest}
          action={action}
          onDelete={onDelete}
          org={org}
          accessibility={accessibility}
        />
      );
    });
  }

  const theme = useTheme();

  return (
    <>
      {isLoading || actions.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, 1fr)", // applies to xtra small (mobile) screens
              sm: "repeat(auto-fit, 368px)", // applies to small screens
            },
            gridTemplateRows: "repeat(auto-fit, 1fr)",
            columnGap: theme.spacing(2),
            rowGap: theme.spacing(2),
          }}
        >
          {content}
        </Box>
      ) : (
        <>
          {!isLoading && (
            <Alert severity="warning">
              No actions found.{" "}
              {accessibility === Accessibility.Organization && (
                <>
                  Create one above, or check out the{" "}
                  <RobotoLinkHoverUnderline to={Goto.route.ActionHub}>
                    Action Hub
                  </RobotoLinkHoverUnderline>
                  .
                </>
              )}
            </Alert>
          )}
        </>
      )}
    </>
  );
}
