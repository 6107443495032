import { parseIDL } from "@foxglove/omgidl-parser";
import { MessageReader as OmgidlMessageReader } from "@foxglove/omgidl-serialization";
import { parseRos2idl } from "@foxglove/ros2idl-parser";
import { parse as parseMessageDefinition } from "@foxglove/rosmsg";
import { MessageReader as ROS1MessageReader } from "@foxglove/rosmsg-serialization";
import { MessageReader as ROS2MessageReader } from "@foxglove/rosmsg2-serialization";
import { McapTypes } from "@mcap/core";

export type Deserializer = (data: ArrayBufferView) => unknown;

const textDecoder = new TextDecoder();

/**
 * https://mcap.dev/spec/registry#schema-encodings
 */
enum SchemaEncoding {
  Empty = "",
  Flatbuffer = "flatbuffer",
  Json = "jsonschema",
  Omgidl = "omgidl",
  Protobuf = "protobuf",
  Ros1msg = "ros1msg",
  Ros2idl = "ros2idl",
  Ros2msg = "ros2msg",
}

/**
 * https://mcap.dev/spec/registry#message-encodings
 */
enum MessageEncoding {
  Cdr = "cdr",
  Flatbuffer = "flatbuffer",
  Json = "json",
  Protobuf = "protobuf",
  Ros1 = "ros1",
}

const SCHEMA_ENCODING_TO_DESERIALIZER: {
  [P in SchemaEncoding]: (schema: McapTypes.Schema) => Deserializer;
} = {
  [SchemaEncoding.Empty]: function deserializeUnspecifiedMsgType() {
    return (data: ArrayBufferView) =>
      JSON.parse(textDecoder.decode(data)) as JsonValue;
  },
  [SchemaEncoding.Flatbuffer]: function deserializeFlatbufferMsgType() {
    throw new Error("Flatbuffer deserialization not yet implemented");
  },
  [SchemaEncoding.Json]: function deserializeJsonMsgType() {
    return (data: ArrayBufferView) =>
      JSON.parse(textDecoder.decode(data)) as JsonValue;
  },
  [SchemaEncoding.Omgidl]: function deserializeOmgIdlMsgType(
    schema: McapTypes.Schema,
  ) {
    const reader = new OmgidlMessageReader(
      schema.name,
      parseIDL(textDecoder.decode(schema.data)),
    );
    return (data: ArrayBufferView) => reader.readMessage(data);
  },
  [SchemaEncoding.Protobuf]: function deserializeProtobufMsgType() {
    throw new Error("Protobuf deserialization not yet implemented");
  },
  [SchemaEncoding.Ros1msg]: function deserializeRos1MsgType(
    schema: McapTypes.Schema,
  ) {
    const reader = new ROS1MessageReader(
      parseMessageDefinition(textDecoder.decode(schema.data)),
    );
    return (data: ArrayBufferView) => reader.readMessage(data);
  },
  [SchemaEncoding.Ros2idl]: function deserializeRos2IdlMsgType(
    schema: McapTypes.Schema,
  ) {
    const reader = new ROS2MessageReader(
      parseRos2idl(textDecoder.decode(schema.data)),
    );
    return (data: ArrayBufferView) => reader.readMessage(data);
  },
  [SchemaEncoding.Ros2msg]: function deserializeRos2MsgType(
    schema: McapTypes.Schema,
  ) {
    const reader = new ROS2MessageReader(
      parseMessageDefinition(textDecoder.decode(schema.data), { ros2: true }),
    );
    return (data: ArrayBufferView) => reader.readMessage(data);
  },
};

const MESSAGE_TO_ALLOWED_SCHEMA_ENCODING: {
  [P in MessageEncoding]: SchemaEncoding[];
} = {
  [MessageEncoding.Cdr]: [
    SchemaEncoding.Omgidl,
    SchemaEncoding.Ros2idl,
    SchemaEncoding.Ros2msg,
  ],
  [MessageEncoding.Flatbuffer]: [SchemaEncoding.Flatbuffer],
  [MessageEncoding.Json]: [SchemaEncoding.Json, SchemaEncoding.Empty],
  [MessageEncoding.Protobuf]: [SchemaEncoding.Protobuf],
  [MessageEncoding.Ros1]: [SchemaEncoding.Ros1msg],
};

function messageEncodingIsKnown(
  messageEncoding: string,
): messageEncoding is MessageEncoding {
  const known: string[] = Object.values(MessageEncoding);
  return known.includes(messageEncoding);
}

function schemaEncodingIsKnown(
  schemaEncoding: string,
): schemaEncoding is SchemaEncoding {
  const known: string[] = Object.values(SchemaEncoding);
  return known.includes(schemaEncoding);
}

export function makeDeserializer(
  channel: McapTypes.Channel,
  schema: McapTypes.Schema,
): Deserializer {
  const messageEncoding = channel.messageEncoding;
  if (!messageEncodingIsKnown(messageEncoding)) {
    throw new Error(`Unknown message encoding ${channel.messageEncoding}`);
  }

  const schemaEncoding = schema.encoding;
  if (!schemaEncodingIsKnown(schemaEncoding)) {
    throw new Error(`Unknown schema encoding ${schema.encoding}`);
  }

  const allowedSchemaEncodings =
    MESSAGE_TO_ALLOWED_SCHEMA_ENCODING[messageEncoding];
  if (!allowedSchemaEncodings.includes(schemaEncoding)) {
    throw new Error(
      `Schema encoding ${schemaEncoding} not allowed for message encoding ${channel.messageEncoding}`,
    );
  }
  return SCHEMA_ENCODING_TO_DESERIALIZER[schemaEncoding](schema);
}
