import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import InvocationStatusChip from "@/Actions/components/invocation/InvocationStatusChip";
import { useAuth } from "@/providers";
import {
  InvocationRecord,
  isDataSourceSpecified,
} from "@/shared/domain/actions";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { searchInvocationsEndpoint, RobotoAPICall } from "@/types";

interface RecentInvocationsProps {
  count: number;
}

export const RecentInvocations: React.FC<RecentInvocationsProps> = ({
  count,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const { getFirstPage, error, loading, pageData } =
    usePaginatedAPICall<InvocationRecord>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!currentOrganization?.org_id) {
        return;
      }

      const apiCall: RobotoAPICall = {
        endpoint: searchInvocationsEndpoint,
        method: "POST",
        requestBody: JSON.stringify({
          sort_direction: "DESC",
          sort_by: "created",
          limit: Math.max(count, 10),
        }),
        orgId: currentOrganization?.org_id,
      };

      await getFirstPage(apiCall, count);
    };

    void fetchData();
  }, [currentOrganization?.org_id, getFirstPage, count]);

  let listOrAlertContent;

  if (loading) {
    listOrAlertContent = (
      <Box
        sx={{
          height: "90%",
          pt: theme.spacing(1),
          pb: theme.spacing(2),
          minHeight: "200px",
        }}
      >
        <Skeleton animation="wave" variant="rounded" height={"100%"} />
      </Box>
    );
  } else if (error) {
    listOrAlertContent = (
      <Alert
        severity="error"
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(2),
        }}
      >
        <AlertTitle>Error</AlertTitle>
        {error?.message}
      </Alert>
    );
  } else if (pageData.length === 0) {
    listOrAlertContent = (
      <Alert
        severity="warning"
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(2),
        }}
      >
        <AlertTitle>Invocations</AlertTitle>
        No actions have been invoked yet. Use the Invoke Action button above to
        process a dataset.
      </Alert>
    );
  } else {
    listOrAlertContent = (
      <List>
        {pageData.map((invocation, idx) => {
          const statusRecord = invocation.status[invocation.status.length - 1];
          return (
            <ListItem
              key={idx}
              sx={{
                pl: 0,
                pr: 0,
                pt: theme.spacing(0.25),
              }}
            >
              <ListItemButton
                sx={{
                  pt: 0,
                  pb: 0,
                }}
                component={RouterLink}
                to={`/invocations/${invocation.invocation_id}`}
                disableRipple
                disableTouchRipple
              >
                <ListItemIcon
                  sx={{
                    mr: theme.spacing(4),
                    width: "85px",
                    justifyContent: "center",
                  }}
                >
                  <InvocationStatusChip
                    size="small"
                    status={statusRecord.status}
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  />
                </ListItemIcon>
                {invocation.invocation_id !== undefined && (
                  <ListItemText
                    primary={
                      <>
                        {invocation.provenance.action.name}
                        {isDataSourceSpecified(invocation.data_source)
                          ? ` on ${invocation.data_source.data_source_id}`
                          : ""}
                      </>
                    }
                    primaryTypographyProps={{
                      color: theme.palette.primary.main,
                      variant: "body2",
                      component: "span",
                    }}
                    secondaryTypographyProps={{
                      component: "span",
                      variant: "caption",
                    }}
                    secondary={
                      <>
                        {invocation.created &&
                          new Date(invocation.created).toLocaleString()}{" "}
                        by {invocation.provenance.source.source_id}
                      </>
                    }
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return <Box sx={{ height: "100%" }}>{listOrAlertContent}</Box>;
};
