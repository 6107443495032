import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import Slide from "@mui/material/Slide";

import { RobotoIconButton } from "@/shared/components/RobotoIconButton";

import { usePlotPanelContext } from "../panelContext";
import { PlotManager } from "../PlotManager";
import { Extents } from "../PlotRenderer";

import {
  CreateEventFromSelectionButton,
  ZoomFromSelectionButton,
} from "./controls";
import styles from "./SelectedRegionActionsMenu.module.css";

interface SelectedRegionActionsMenuProps {
  chartExtents: Extents | null;
  panelId: string;
  plotManager: PlotManager | null;
}

export function SelectedRegionActionsMenu({
  chartExtents,
  panelId,
  plotManager,
}: SelectedRegionActionsMenuProps) {
  const theme = useTheme();

  const { selectedRegion, setSelectedRegion } = usePlotPanelContext();

  const closeMenu = () => {
    setSelectedRegion(null);
  };

  // We open this menu when a region is selected
  if (selectedRegion === null || chartExtents === null) {
    return null;
  }

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <div
        className={styles.menu}
        style={{
          borderColor: theme.palette.action.active,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <div className={styles.buttonGroup}>
          <CreateEventFromSelectionButton
            chartExtents={chartExtents}
            onClose={closeMenu}
            panelId={panelId}
            selectedRegion={selectedRegion}
          />
          <hr
            className={styles.divider}
            style={{ borderColor: theme.palette.action.active }}
          />
          <ZoomFromSelectionButton
            chartExtents={chartExtents}
            onClose={closeMenu}
            plotManager={plotManager}
            selectedRegion={selectedRegion}
          />
        </div>
        <RobotoIconButton
          eventName="PlotPanelSelectedRegionMenuClosed"
          onClick={closeMenu}
        >
          <CloseIcon
            sx={{ color: theme.palette.action.active }}
            fontSize="small"
          />
        </RobotoIconButton>
      </div>
    </Slide>
  );
}
