import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";
import {
  NavigationPage,
  RobotoButton,
  RobotoLinkHoverUnderline,
} from "@/shared/components";
import {
  ActionParameter,
  ActionRecord,
  InvocationRecord,
} from "@/shared/domain/actions";
import { creditsPerHour } from "@/shared/domain/actions/computeCredits";
import { OrganizationTier } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import {
  APIResponse,
  APIServiceError,
  LimitError,
  actionEndpoint,
  invocationEndpoint,
  invokeActionEndpoint,
} from "@/types";
import { Resource } from "@/types/resources";

import {
  ActionComputeReqs,
  ActionContainerParams,
  ActionInputData,
  ActionSelector,
  DatasetSelector,
} from "../components/action";
import { ActionFilesPreview } from "../components/action/ActionFilesPreview";
import { ActionParamsTable } from "../components/action/ActionParamsTable";
import { ActionTimeoutField } from "../components/action/ActionTimeoutField";
import {
  actionNameError,
  createInitialTimeoutState,
  isActionTimeoutValid,
  parseComputeRequirements,
  parseContainerParameters,
  stringifyCommand,
} from "../components/action/actionUtils";

const formErrors = (
  hasComputeFormErrors: boolean,
  name?: string,
  actionTimeout?: number | string,
) => {
  return (
    actionNameError(name) ||
    hasComputeFormErrors ||
    !isActionTimeoutValid(actionTimeout)
  );
};

export const InvokeAction: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { currentOrganization, currentUser } = useAuth();

  const [searchParams] = useSearchParams();
  const actionName = searchParams.get("action_name");
  const datasetId = searchParams.get("dataset_id");
  const actionOwner = searchParams.get("action_owner") ?? undefined;
  const actionDigest = searchParams.get("action_digest") ?? undefined;
  const baseInvocation = searchParams.get("base_invocation");
  const inputFiles = searchParams.get("input_files");

  const decodedInputFiles = React.useMemo(() => {
    if (inputFiles) {
      return inputFiles.split(",").map((item) => decodeURIComponent(item));
    }
  }, [inputFiles]);

  const [inputData, setInputData] = React.useState<string[] | undefined>(
    decodedInputFiles,
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>("");

  const [parameters, setParameters] = React.useState<
    ActionParameter[] | undefined
  >(undefined);

  const [parameterValues, setParameterValues] = React.useState<
    { [key: string]: unknown } | undefined
  >(undefined);

  const [cpu, setCpu] = React.useState<string | undefined>(undefined);
  const [memory, setMemory] = React.useState<string | undefined>(undefined);
  const [gpu, setGpu] = React.useState<string | undefined>(undefined);
  const [storage, setStorage] = React.useState<number | undefined>(undefined);
  const [workdir, setWorkdir] = React.useState<string | undefined>(undefined);
  const [command, setCommand] = React.useState<string | undefined>(undefined);
  const [entrypoint, setEntrypoint] = React.useState<string | undefined>(
    undefined,
  );
  const [envVars, setEnvVars] = React.useState<Record<string, string>>({});
  const [openContainerParams, setOpenContainerParams] =
    React.useState<boolean>(false);
  const [openResourceParams, setOpenResourceParams] =
    React.useState<boolean>(false);

  const [hasComputeFormErrors, setHasComputeFormErrors] = React.useState(false);

  const isGated =
    currentOrganization?.tier === OrganizationTier.Free ? true : false;
  const [actionTimeout, setActionTimeout] = React.useState<
    number | string | undefined
  >(createInitialTimeoutState(isGated));

  const action = React.useMemo(() => {
    if (actionName) {
      return {
        name: actionName,
        owner: actionOwner,
        digest: actionDigest,
      };
    }

    return null;
  }, [actionName, actionOwner, actionDigest]);

  const { initiateRequest: getInvocation } =
    useLazyAPICall<APIResponse<InvocationRecord>>();

  React.useEffect(() => {
    async function fetchBaseInvocation() {
      if (!baseInvocation) {
        return;
      }

      const { data, error } = await getInvocation({
        endpoint: invocationEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id,
        pathParams: {
          invocationId: baseInvocation,
        },
      });

      if (!error && data) {
        setCpu(data.data.compute_requirements.vCPU?.toString());
        setMemory(data.data.compute_requirements.memory?.toString());
        setGpu(data.data.compute_requirements.gpu?.toString());
        setStorage(data.data.compute_requirements.storage);
        setWorkdir(data.data.container_parameters.workdir?.toString());
        setCommand(stringifyCommand(data.data.container_parameters.command));

        if (data.data.container_parameters.entry_point) {
          setEntrypoint(data.data.container_parameters.entry_point[0]);
        }

        setParameterValues(data.data.parameter_values);
        setInputData(data.data.input_data);
        setEnvVars(data.data.container_parameters.env_vars ?? {});
        setActionTimeout(data.data.timeout?.toString());
      }
    }
    void fetchBaseInvocation();
  }, [baseInvocation, currentOrganization?.org_id, getInvocation]);

  const cleanActionState = () => {
    setCpu(undefined);
    setMemory(undefined);
    setGpu(undefined);
    setStorage(undefined);
    setWorkdir(undefined);
    setCommand(undefined);
    setEntrypoint(undefined);
    setParameters(undefined);
    setParameterValues(undefined);
    setEnvVars({});
    setActionTimeout(undefined);
  };

  const getErrorText = (error: APIServiceError | null) => {
    if (!error) {
      return "Got an unknown error with no message, please contact support@roboto.ai with details.";
    }

    if (error instanceof LimitError) {
      if (error.resourceName === Resource.FreeTierComputeSpec) {
        return (
          "Free tier orgs can use a max of 4vCPU, 8GB of RAM, 50 GiB of storage space, and no GPUs. " +
          "To upgrade your account, please contact sales@roboto.ai."
        );
      } else if (error.resourceName === Resource.StorageGiBs) {
        return (
          "Free tier orgs can only invoke actions with 50 GiB storage. Lower the storage in the Compute Requirements section. " +
          "To upgrade your account, please contact sales@roboto.ai."
        );
      } else {
        return (
          "You can't invoke any more actions as you've reached your monthly compute quota. " +
          "To upgrade your account, please contact sales@roboto.ai."
        );
      }
    } else {
      return error.message;
    }
  };

  const invokeAction = async () => {
    if (!action) {
      return;
    }

    setErrorText("");
    setLoading(true);

    const computeRequirements = parseComputeRequirements(
      cpu,
      memory,
      gpu,
      storage,
    );
    const containerParameters = parseContainerParameters(
      command,
      entrypoint,
      workdir,
      envVars,
    );

    // Invoke the Action
    const queryParams = action.digest
      ? new URLSearchParams({ digest: action.digest })
      : undefined;

    const headers = action.owner
      ? { "X-Roboto-Resource-Owner-Id": action.owner }
      : undefined;

    const { error, data } = await invokeActionReq({
      method: "POST",
      endpoint: invokeActionEndpoint,
      pathParams: { actionName: action.name },
      queryParams,
      headers,
      requestBody: JSON.stringify({
        data_source_type: "Dataset",
        data_source_id: datasetId,
        invocation_source: "Manual",
        invocation_source_id: currentUser?.user_id,
        input_data: inputData,
        compute_requirement_overrides: computeRequirements,
        container_parameter_overrides: containerParameters,
        parameter_values: parameterValues,
        timeout: actionTimeout,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setErrorText(getErrorText(error));
    }

    if (!error && data) {
      goto.invocation({
        invocationId: data.data.invocation_id,
      });
    }
    setLoading(false);
  };

  // Replace URL when the action is updated to reflect new query params.
  const onActionUpdate = React.useCallback(
    (record: ActionRecord | null) => {
      const queryParams = new URLSearchParams();
      if (record) {
        queryParams.set("action_name", record.name);
        if (currentOrganization?.org_id !== record.org_id) {
          queryParams.set("action_owner", record.org_id);
        }
      }

      if (datasetId) {
        queryParams.set("dataset_id", datasetId);
      }
      navigate(`${pathname}?${queryParams.toString()}`);
    },
    [datasetId, pathname, currentOrganization?.org_id, navigate],
  );

  // Replace URL when the dataset is updated to reflect new query params.
  const onDatasetUpdate = React.useCallback(
    (datasetId: string | null) => {
      const queryParams = new URLSearchParams();
      if (action) {
        queryParams.set("action_name", action.name);
        if (action.owner) {
          queryParams.set("action_owner", action.owner);
        }
        if (action.digest) {
          queryParams.set("action_digest", action.digest);
        }
      }
      if (datasetId) {
        queryParams.set("dataset_id", datasetId);
      }
      navigate(`${pathname}?${queryParams.toString()}`);
    },
    [action, pathname, navigate],
  );

  const { initiateRequest: invokeActionReq } =
    useLazyAPICall<APIResponse<InvocationRecord>>();

  const {
    data: getActionResponse,
    error,
    initiateRequest: getAction,
    updateCache,
  } = useLazyAPICall<APIResponse<ActionRecord>>();

  React.useEffect(() => {
    async function fetchAction() {
      if (!action) {
        updateCache(null);
        return;
      }

      const queryParams = action.digest
        ? new URLSearchParams({ digest: action.digest })
        : undefined;

      const headers = action.owner
        ? { "X-Roboto-Resource-Owner-Id": action.owner }
        : undefined;

      const { data, error } = await getAction({
        endpoint: actionEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id,
        pathParams: { name: action.name },
        queryParams,
        headers,
      });

      if (!error) {
        const computeReqs = data?.data.compute_requirements;
        const containerParams = data?.data.container_parameters;

        setParameters(data?.data.parameters);

        setCpu(computeReqs?.vCPU.toString());
        setMemory(computeReqs?.memory.toString());
        setGpu(computeReqs?.gpu.toString());
        setStorage(computeReqs?.storage);
        setWorkdir(containerParams?.workdir ?? undefined);
        setCommand(stringifyCommand(containerParams?.command ?? null));
        setEnvVars(containerParams?.env_vars ?? {});
        if (
          containerParams?.entry_point &&
          containerParams?.entry_point.length > 0
        ) {
          setEntrypoint(containerParams?.entry_point[0]);
        }
      }
    }
    cleanActionState();
    void fetchAction();
  }, [action, currentOrganization?.org_id, getAction, updateCache]);

  let computeRequirementsTitle = "Compute Requirements (optional)";

  if (currentUser?.is_system_user) {
    computeRequirementsTitle +=
      " (" +
      creditsPerHour(
        parseInt(cpu || "512"),
        parseInt(memory || "1024"),
        storage || 21,
      ).toFixed(2) +
      " credits per hour)";
  }

  const { goto } = useNavigation();

  const actionRecord = getActionResponse?.data ?? null;

  let errorDisplay = null;
  if (error || errorText) {
    errorDisplay = (
      <Alert severity="error">{error ? error.message : errorText}</Alert>
    );
  }

  let header = null;
  if (errorDisplay) {
    header = errorDisplay;
  } else {
    header = (
      <Alert severity="info">
        Complete the form below to invoke an action. Check out the{" "}
        <RobotoLinkHoverUnderline
          to="https://docs.roboto.ai/user-guides/index.html"
          target="_blank"
        >
          user guide
        </RobotoLinkHoverUnderline>{" "}
        if it&apos;s your first time.
      </Alert>
    );
  }

  return (
    <NavigationPage title={"Roboto - Invoke Action"}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/actions?tab=0">Actions</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
        >
          Invoke Action
        </Typography>
      </Breadcrumbs>

      <Box>
        <Box
          component={Paper}
          variant="outlined"
          sx={{ p: theme.spacing(2), mt: theme.spacing(3), background: "none" }}
        >
          <Box
            sx={{
              mb: theme.spacing(2),
            }}
          >
            {header}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: theme.spacing(10),
            }}
          >
            <Stack
              component="form"
              sx={{
                maxWidth: "75ch",
                width: {
                  xs: "100%",
                  lg: "50%",
                },
              }}
              spacing={3}
              noValidate
              autoComplete="off"
            >
              <Box>
                <Box sx={{ mb: theme.spacing(1.25) }}>
                  <Typography variant="subtitle2">Action</Typography>
                  <Typography variant="caption">
                    Select the action to invoke from your org or the Action Hub.
                  </Typography>
                </Box>
                <ActionSelector
                  action={actionRecord}
                  setAction={onActionUpdate}
                  currentOrg={currentOrganization}
                />
              </Box>

              <Box>
                <Box sx={{ mb: theme.spacing(1.25) }}>
                  <Typography variant="subtitle2">Dataset</Typography>
                  <Typography variant="caption">
                    Specify which dataset the action should run on.
                  </Typography>
                </Box>
                <DatasetSelector
                  datasetId={datasetId}
                  setDatasetId={onDatasetUpdate}
                  currentOrg={currentOrganization}
                />
              </Box>
              <Box>
                <Box sx={{ mb: theme.spacing(1.25) }}>
                  <Typography variant="subtitle2">Input Data</Typography>
                  <Typography variant="caption">
                    Specify file patterns to include from the dataset, e.g.{" "}
                    <pre style={{ display: "inline-block", fontWeight: 600 }}>
                      **/cam_front/*.jpg
                    </pre>
                    <br />
                    Data will be mounted to{" "}
                    <pre style={{ display: "inline-block", fontWeight: 600 }}>
                      $ROBOTO_INPUT_DIR
                    </pre>{" "}
                    inside the action container.
                  </Typography>
                </Box>

                <ActionInputData
                  inputData={inputData || [""]}
                  setInputData={setInputData}
                  minRequired={1}
                />
              </Box>

              {parameters && parameters.length > 0 && (
                <Box>
                  <Box sx={{ mb: theme.spacing(2) }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle2">Parameters</Typography>
                    </Box>
                    <Typography variant="caption">
                      Provide values for this action&apos;s parameters.
                      <br />
                      These will be accessible as env vars inside the container
                      as <strong>$ROBOTO_PARAM_*</strong>
                    </Typography>
                  </Box>
                  <ActionParamsTable
                    editable={true}
                    mode="values"
                    params={parameters || []}
                    setParams={setParameters}
                    paramValues={parameterValues}
                    setParamValues={setParameterValues}
                  />
                </Box>
              )}
              <ActionTimeoutField
                isGated={isGated}
                showAlert={true}
                actionTimeout={actionTimeout}
                setActionTimeout={setActionTimeout}
              />
              <Box>
                <Box sx={{ mb: theme.spacing(2) }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2">
                      Container Parameters (optional)
                    </Typography>
                    <IconButton
                      aria-label="container-params"
                      size="small"
                      sx={{
                        ml: theme.spacing(0.25),
                        transform: openContainerParams
                          ? "rotate(180deg)"
                          : "none",
                      }}
                      onClick={() =>
                        setOpenContainerParams(!openContainerParams)
                      }
                    >
                      <ExpandCircleDownOutlinedIcon
                        color="primary"
                        fontSize="small"
                      />
                    </IconButton>
                  </Box>
                  <Typography variant="caption">
                    Specify parameters to pass to the action&apos;s container at
                    runtime. Pass <i>null</i> to use image defaults.
                  </Typography>
                </Box>
                <Collapse in={openContainerParams} timeout="auto" unmountOnExit>
                  <ActionContainerParams
                    workdir={workdir}
                    setWorkdir={setWorkdir}
                    command={command}
                    setCommand={setCommand}
                    entrypoint={entrypoint}
                    setEntrypoint={setEntrypoint}
                    envVars={envVars}
                    setEnvVars={setEnvVars}
                  />
                </Collapse>
              </Box>
              <Box>
                <Box sx={{ mb: theme.spacing(2) }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="subtitle2">
                      {computeRequirementsTitle}
                    </Typography>
                    <IconButton
                      aria-label="compute-requirements"
                      size="small"
                      sx={{
                        ml: theme.spacing(0.25),
                        transform: openResourceParams
                          ? "rotate(180deg)"
                          : "none",
                      }}
                      onClick={() => setOpenResourceParams(!openResourceParams)}
                    >
                      <ExpandCircleDownOutlinedIcon
                        color="primary"
                        fontSize="small"
                      />
                    </IconButton>
                  </Box>
                  <Typography variant="caption">
                    Specify compute resources required to run the action.
                  </Typography>
                </Box>
                <Collapse in={openResourceParams} timeout="auto" unmountOnExit>
                  <ActionComputeReqs
                    cpu={cpu}
                    setCpu={setCpu}
                    memory={memory}
                    setMemory={setMemory}
                    gpu={gpu}
                    setGpu={setGpu}
                    storage={storage}
                    setStorage={setStorage}
                    setHasComputeFormErrors={setHasComputeFormErrors}
                  />
                </Collapse>
              </Box>
            </Stack>
            <ActionFilesPreview inputData={inputData} datasetId={datasetId} />
          </Box>
          <Box sx={{ mt: theme.spacing(3) }}>
            <Box
              sx={{
                mt: theme.spacing(1),
                display: "flex",
                alignItems: "center",
                gap: theme.spacing(2),
              }}
            >
              <RobotoButton
                eventName={"ActionInvoked"}
                eventProperties={{ name: action?.name ?? "" }}
                variant={"contained"}
                color="secondary"
                disabled={
                  !action ||
                  !datasetId ||
                  !inputData ||
                  formErrors(hasComputeFormErrors, action.name, actionTimeout)
                }
                onClick={() => void invokeAction()}
              >
                Invoke Action
              </RobotoButton>
              {loading && (
                <CircularProgress sx={{ ml: theme.spacing(1.5) }} size="1rem" />
              )}
              {errorText && (
                <Typography color="error" variant="caption">
                  {errorText}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </NavigationPage>
  );
};
