import { MoreVert } from "@mui/icons-material";
import { IconButton, Popover } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import {
  Dimensions,
  useDebouncedCallback,
  useMeasure,
  usePopupState,
} from "@/shared/hooks";
import { type PanelState } from "@/shared/state/visualization";

import { OpenPanelSettings, RemovePanel } from "../controls";

import styles from "./PanelHeader.module.css";
import { PanelIcon } from "./PanelIcon";
import { PanelTitle } from "./PanelTitle";

interface PanelHeaderProps {
  additionalTools?: React.ReactNode;
  className?: classNames.Argument;
  state: PanelState;
}

/**
 * A header for a panel that displays the panel title (if any) and controls for managing
 * the panel's layers.
 */
export function PanelHeader(props: PanelHeaderProps) {
  const { additionalTools, className, state } = props;

  const { shouldCollapse, measuredRow, measuredTools } = useCollapseTools({
    collapseRatio: 0.4,
  });

  const tools = [
    additionalTools,
    <OpenPanelSettings key="panel-header-settings" state={state} />,
  ];

  // Only collapse the toolbar into a popup if there are extra toolbar items that make collapsing worthwhile.
  const isCollapsed = shouldCollapse && additionalTools;

  return (
    <div className={classNames(styles.panelHeader, className)}>
      <div
        className={styles.panelRow}
        ref={(node) => node !== null && measuredRow(node)}
      >
        <div className={styles.titleGroup}>
          <PanelIcon className={styles.panelIcon} panelType={state.type} />
          <PanelTitle className={styles.title} title={state.title} />
        </div>
        <div className={styles.panelControls} data-not-draggable="true">
          {isCollapsed ? (
            <HeaderOverflow>
              {/* Don't measure the collapsed toolbar's size to avoid jitter. */}
              {...tools}
            </HeaderOverflow>
          ) : (
            <div
              className={styles.panelControls}
              data-not-draggable="true"
              ref={(node) => node !== null && measuredTools(node)}
            >
              {...tools}
            </div>
          )}
          <RemovePanel panelId={state.id} data-not-draggable="true" />
        </div>
      </div>
    </div>
  );
}

function HeaderOverflow({ children }: React.PropsWithChildren) {
  const { isOpen, anchor, open, close } = usePopupState();

  return (
    <>
      <IconButton
        aria-label="show-panel-options"
        onClick={(event) => open(event.currentTarget)}
        size="small"
        title="Show all options."
      >
        <MoreVert fontSize="small"></MoreVert>
      </IconButton>
      <Popover
        className={styles.popover}
        anchorEl={anchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          root: {
            // @ts-expect-error ts(2353): data-not-draggable is a custom prop to disable drag interactions.
            "data-not-draggable": true,
          },
        }}
        open={isOpen}
        onClose={close}
      >
        {children}
      </Popover>
    </>
  );
}

/**
 * Determines whether the toolbar is too wide and should collapse into a popover for legibility.
 *
 * This approach uses two measures, one to attach to the header's outer row, and the other to
 * attach to the uncollapsed toolbar.
 *
 * This approach allows us to keep the toolbar smaller than props.collapseRatio,
 * relative to the panel's width.
 *
 * @param props.collapseRatio is the ratio where the toolbar is too large for the panel to display
 * right and should collapse.
 *
 * @returns collapse state for the toolbar, and the measurement functions for the panel's row and toolbar
 */
function useCollapseTools(props: { collapseRatio: number }) {
  const { rowWidth, measuredRow } = hookHelpers.useMeasureRow();
  const { toolsWidth, measuredTools } = hookHelpers.useMeasureTools();

  const nextShouldCollapse = React.useMemo(() => {
    let nextShouldCollapse: boolean;
    if (toolsWidth > rowWidth * props.collapseRatio) {
      // Collapse the tools after toolsWidth is a larger fraction of rowWidth than collapseRatio.
      nextShouldCollapse = true;
    } else {
      // Expand the toolbar once the tools width is below the expandRatio again.
      nextShouldCollapse = false;
    }
    return nextShouldCollapse;
  }, [rowWidth, toolsWidth, props]);
  return { shouldCollapse: nextShouldCollapse, measuredRow, measuredTools };
}

const hookHelpers = {
  useMeasureRow: () => {
    const [rowWidth, setRowWidth] = React.useState(0);
    const undebounced = React.useCallback(
      ({ width }: Dimensions) => {
        setRowWidth(width);
      },
      [setRowWidth],
    );
    const onDimensionsChange = useDebouncedCallback(undebounced, 10);
    const [measuredRow] = useMeasure({ onDimensionsChange });
    return { rowWidth, measuredRow };
  },

  useMeasureTools: () => {
    const [toolsWidth, setToolsWidth] = React.useState(0);
    const undebounced = React.useCallback(
      ({ width }: Dimensions) => {
        if (width > 0) {
          setToolsWidth(width);
        }
      },
      [setToolsWidth],
    );
    const onDimensionsChange = useDebouncedCallback(undebounced, 10);
    const [measuredTools] = useMeasure({ onDimensionsChange });
    return { toolsWidth, measuredTools };
  },
};
