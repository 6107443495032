import { Autocomplete, TextField } from "@mui/material";
import { SetStateAction } from "react";
import * as React from "react";

import { TriggerForEachPrimitive } from "@/shared/domain/actions/TriggerRecord";

interface TriggerForEachSelectorProps {
  forEach: TriggerForEachPrimitive;
  setForEach: React.Dispatch<SetStateAction<TriggerForEachPrimitive>>;
}

export const TriggerForEachSelector: React.FC<TriggerForEachSelectorProps> = ({
  forEach,
  setForEach,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="available-for-each"
      options={Object.values(TriggerForEachPrimitive)}
      sx={{ width: 400 }}
      value={forEach}
      size={"small"}
      renderInput={(params) => <TextField {...params} label="For Each" />}
      onChange={(_event, newValue) => {
        if (newValue !== null) {
          setForEach(newValue);
        }
      }}
      componentsProps={{
        paper: {
          sx: {
            fontSize: "0.875rem",
          },
        },
      }}
    />
  );
};
