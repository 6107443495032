import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { TriggerRecord, UpdateTriggerRequest } from "../TriggerRecord";

export interface UpdateTrigger {
  name: string;
  resourceOwnerId: string;
  updates: UpdateTriggerRequest;
}

export function useUpdateTrigger() {
  const { triggerService } = React.useContext(DomainServicesContext);

  const queryClient = useQueryClient();

  return useMutation<TriggerRecord, Error, UpdateTrigger>({
    mutationFn: async ({ name, resourceOwnerId, updates }) => {
      return await triggerService.updateTrigger(name, updates, {
        resourceOwnerId,
      });
    },

    onSuccess: (triggerRecord) => {
      queryClient.setQueryData(
        ["triggerRecord", triggerRecord.name],
        triggerRecord,
      );
    },
  });
}
