import * as React from "react";
import { Outlet, useParams } from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";

import { LoadingScreen } from "../../shared/components/LoadingScreen";

interface AuthenticationLayoutProps {
  isNotOrgProtected?: boolean;
}

export function AuthenticationLayout({
  isNotOrgProtected,
}: AuthenticationLayoutProps) {
  const {
    initialAuthCheckComplete,
    isAuthenticated,
    isLoading,
    getCurrentOrganization,
  } = useAuth();
  const { goto } = useNavigation();
  const currentOrg = getCurrentOrganization();
  const { inviteId } = useParams();

  React.useEffect(() => {
    if (isLoading || !initialAuthCheckComplete) {
      return;
    }

    if (!isAuthenticated && isNotOrgProtected) {
      // Redirect to Sign In with inviteId in the URL
      goto.signIn({ inviteId });
    } else if (isAuthenticated && currentOrg === null && !isNotOrgProtected) {
      goto.signIn({ chooseOrg: true });
    } else if (!isAuthenticated && !isNotOrgProtected) {
      goto.signIn();
    }
  }, [
    initialAuthCheckComplete,
    isAuthenticated,
    isLoading,
    currentOrg,
    isNotOrgProtected,
    goto,
    inviteId,
  ]);

  const orgValid = currentOrg !== null || isNotOrgProtected;

  if (isAuthenticated && orgValid) {
    return <Outlet />;
  } else {
    return <LoadingScreen />;
  }
}
