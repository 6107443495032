import { zodResolver } from "@hookform/resolvers/zod";
import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { z } from "zod";

import { useCurrentOrgId } from "@/providers/auth/hooks";
import { useDomainServices } from "@/providers/DomainServices";
import {
  EventScopeForm,
  EventDurationForm,
  EventDataEntityType,
  EventSourceType,
  EventSource,
  extendCreateEventFormSchema,
  EventNameInput,
} from "@/shared/components/events";
import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { EventRecord } from "@/shared/domain/events";
import { useVizState } from "@/shared/state/visualization";
import { nanoSecToSecStr } from "@/shared/time";

import { Unit } from "../../events/schema";
import { MetadataForm, MetadataView } from "../../Metadata";
import { RobotoButton } from "../../RobotoButton";
import { AutocompleteType } from "../../TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "../../tags";
import { useEphemeralWorkspaceState } from "../WorkspaceCtx";

import {
  CreateWorkspaceEventFormDefaults,
  makeCreateEventRequest,
  type CreateWorkspaceEventForm as CreateEventForm,
} from "./makeCreateEventRequest";

interface CreateEventFormProps {
  defaultFields?: CreateWorkspaceEventFormDefaults;
  onCancel: () => void;
  onSuccess: (event: EventRecord) => void;
  sourceOptions: Array<EventSource>;
}

const workspaceEventFormSchema = extendCreateEventFormSchema({
  scope: z.object({
    dataEntity: z.object({
      type: z.string(),
    }),
    source: z.object({
      type: z.string(),
      panelId: z.string().optional(),
    }),
  }),
});

function mergeDefaultFormValues(
  defaultFields: CreateWorkspaceEventFormDefaults,
): CreateEventForm {
  const defaults = {
    name: "",
    description: "",
    metadata: {},
    tags: [],
    scope: {
      dataEntity: { type: EventDataEntityType.AllFiles },
      source: { type: EventSourceType.AllPanels },
    },
    unit: Unit.Sec,
  };

  // Convert start_time and end_time bigints to strings because
  // the form requires them as strings.
  const overrideDefaultsWithStartEndTimes = {
    ...defaultFields,
    start_time: nanoSecToSecStr(defaultFields?.start_time ?? 0n),
    end_time: nanoSecToSecStr(defaultFields?.end_time ?? 0n),
  };

  return {
    ...defaults,
    ...overrideDefaultsWithStartEndTimes,
  };
}

export const CreateWorkspaceEventForm = ({
  defaultFields,
  onSuccess,
  onCancel,
  sourceOptions,
}: CreateEventFormProps) => {
  const { events } = useDomainServices();
  const orgId = useCurrentOrgId();
  const workspaceState = useEphemeralWorkspaceState();
  const vizState = useVizState();
  const form = useForm<CreateEventForm>({
    defaultValues: mergeDefaultFormValues(defaultFields ?? {}),
    resolver: zodResolver(workspaceEventFormSchema),
  });

  const onSubmit: SubmitHandler<CreateEventForm> = (data) => {
    const request = makeCreateEventRequest(data, vizState, workspaceState);
    return events.createEvent(orgId, request).then(onSuccess);
  };

  const submissionErrors = form.formState.errors.root?.serverErrors;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(event) => {
          form
            .handleSubmit(onSubmit)(event)
            .catch((error: Error) => {
              form.setError("root.serverErrors", {
                message: error.message,
              });
            });
        }}
        style={{ margin: "1rem 0" }}
      >
        <Grid container direction="column" justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <EventScopeForm sourceOptions={sourceOptions} />
          </Grid>
          <Grid item xs={12}>
            <EventDurationForm />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              component={"h3"}
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Event name
            </RobotoTypography>
            <Controller
              control={form.control}
              name="name"
              render={({ field }) => {
                return (
                  <EventNameInput
                    currentName={field.value}
                    onNameChanged={field.onChange}
                    error={form.formState.errors.name !== undefined}
                    helperText={form.formState.errors.name?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              component={"h3"}
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Description (optional)
            </RobotoTypography>
            <TextField
              {...form.register("description")}
              placeholder="Description"
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
              error={form.formState.errors.description !== undefined}
              helperText={form.formState.errors.description?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              component={"h3"}
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Tags (optional)
            </RobotoTypography>
            <Controller
              control={form.control}
              name="tags"
              render={({ field }) => {
                return (
                  <TagsGroup>
                    <Tags
                      tags={field.value}
                      onDeleteTag={(tag) => {
                        field.onChange(field.value.filter((t) => t !== tag));
                      }}
                    />
                    <TagInput
                      tags={field.value}
                      autocompleteType={AutocompleteType.EventTags}
                      onAddTag={(tag) => {
                        field.onChange([...field.value, tag]);
                      }}
                    />
                  </TagsGroup>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              component={"h3"}
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0 0" }}
            >
              Metadata (optional)
            </RobotoTypography>
            <Controller
              control={form.control}
              name="metadata"
              render={({ field }) => {
                return (
                  <>
                    <MetadataView
                      metadata={field.value}
                      onChange={(metadata) => {
                        field.onChange(metadata);
                      }}
                    />
                    <MetadataForm
                      metadata={field.value}
                      autocompleteType={AutocompleteType.EventMetadataKeys}
                      onAddMetadata={(key, value) => {
                        field.onChange({
                          ...field.value,
                          [key]: value,
                        });
                      }}
                    />
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <RobotoButton
                eventName={"WorkspaceEventCreated"}
                eventProperties={{ eventName: form.getValues("name") }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={form.formState.isSubmitting}
                endIcon={
                  form.formState.isSubmitting ? (
                    <CircularProgress size={"1rem"} />
                  ) : null
                }
              >
                Create Event
              </RobotoButton>
              <RobotoButton
                eventName={"CreateWorkspaceEventCanceled"}
                type="button"
                variant="text"
                onClick={onCancel}
              >
                Cancel
              </RobotoButton>
            </div>
            {submissionErrors && (
              <Box component="p" sx={{ color: "red", margin: "0.5rem 0" }}>
                {submissionErrors.message}
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
