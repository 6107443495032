import * as React from "react";

import { useSystemUser } from "@/providers/auth/hooks";

type SystemUserOnlyProps = {
  fallback?: React.ReactNode;
  children: React.ReactNode;
  enforceOverride?: boolean;
};

/**
 * Renders children only if the current user is a system user.
 * @param enforceOverride When true (default), respects the system user view override setting.
 * When false, always shows content to system users regardless of override.
 */
export const SystemUserOnly: React.FC<SystemUserOnlyProps> = ({
  children,
  fallback = null,
  enforceOverride = true,
}) => {
  const { isSystemUser, isActualSystemUser } = useSystemUser();

  if (enforceOverride ? !isSystemUser : !isActualSystemUser) {
    return fallback;
  }

  return children;
};
