import {
  type MessagePathAttr,
  type MessagePathPart,
  CanonicalDataType,
  MessagePathPartType,
} from "@/shared/domain/topics";

import { DataExtractionError } from "./DataExtractionError";
import { isIndexedCollection } from "./isIndexedCollection";

function getNextAttrPathPartOrDieTrying(
  path: MessagePathPart[],
): MessagePathAttr {
  const part = path.shift();
  if (!part) {
    throw new DataExtractionError(
      "Expected to have more path segments by which to extract data, but found none.",
    );
  }

  if (part.type !== MessagePathPartType.Attr) {
    throw new DataExtractionError(
      "This feature is not yet supported for data attributes that are descendants--at any depth--of arrays.",
    );
  }

  return part;
}

/**
 * Extract a single value from a message at the given path.
 * Does not support message paths that traverse through arrays.
 */
export function valueAtMessagePath(
  messageData: Record<string, unknown>,
  path: MessagePathPart[],
): unknown {
  const messagePath = [...path];
  let obj = messageData;
  let part = messagePath.shift();
  if (!part) {
    return messageData;
  }
  if (part.type !== MessagePathPartType.Attr) {
    throw new DataExtractionError(
      "This feature is not yet supported for data attributes that are descendants--at any depth--of arrays.",
    );
  }
  while (messagePath.length > 0) {
    if (
      // The current object is not record-like
      typeof obj !== "object" ||
      obj === null ||
      isIndexedCollection(obj) ||
      // The current object cannot be expected to be accessed by the current attribute
      part.dataType !== CanonicalDataType.Object ||
      !(part.attribute in obj)
    ) {
      throw DataExtractionError.fromMessagePath(path);
    }

    obj = obj[part.attribute] as Record<string, unknown>;

    if (messagePath.length > 0) {
      part = getNextAttrPathPartOrDieTrying(messagePath);
    } else {
      break;
    }
  }
  return obj[part.attribute];
}
