import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useTheme } from "@mui/material";
import * as React from "react";

import { actions, useVizDispatch } from "@/shared/state/visualization";

import panelStyles from "../LogPanel.module.css";

import { ColumnDefinition } from "./columnDefinition";
import headerStyles from "./TableHeader.module.css";

interface LogTableHeader {
  columnDefinitions: ColumnDefinition[];
  panelId: string;
}

export function TableHeader({ columnDefinitions, panelId }: LogTableHeader) {
  const dispatch = useVizDispatch();

  const theme = useTheme();
  return (
    <div
      className={panelStyles.rowHeader}
      style={
        {
          "--background-color": theme.palette.background.default,
          borderBottom: theme.border.thin,
          fontWeight: theme.typography.fontWeightMedium,
        } as React.CSSProperties
      }
    >
      <h6 className={headerStyles.header}>Time (s)</h6>
      {columnDefinitions.map((column) => {
        return (
          <h6 key={column.messagePathId} className={headerStyles.header}>
            <span className={headerStyles.headerText}>{column.name}</span>
            <IconButton
              aria-label="remove column"
              onClick={() => {
                dispatch(
                  actions.removeMessagePathFromLogPanel(
                    panelId,
                    column.messagePathId,
                  ),
                );
              }}
              size="small"
              sx={{ ml: "0.25rem" }}
              title="Remove column"
            >
              <CloseIcon
                fontSize="small"
                sx={{
                  width: "0.9em",
                  height: "0.9em",
                }}
              />
            </IconButton>
          </h6>
        );
      })}
    </div>
  );
}
