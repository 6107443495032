/**
 * Converts hello_world to Hello World
 */
function snakeToSpacedCamelCase(snakeStr: string) {
  return snakeStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

/**
 * Formats a snake case field name, e.g. hello_world to Hello World
 * unless it is a metadata field, in which case it returns Metadata.key.
 *
 * If it's not a snake case field name, it returns the field name as is.
 */
export const formatFieldName = (name: string) => {
  if (name.includes("metadata.")) {
    // Simply capitalize first letter, don't transform the field name
    return name.charAt(0).toUpperCase() + name.slice(1);
  } else {
    // Fully transform the field name to improve readability
    return snakeToSpacedCamelCase(name);
  }
};
