import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Link, useTheme } from "@mui/material";
import React from "react";

import { useAuth } from "@/providers";
import { RobotoTypography } from "@/shared/components";
import { useHealthCheck } from "@/shared/domain/health/hooks";
import { Env } from "@/shared/environment";

interface HealthCheckDetail {
  is_healthy: boolean;
  detail?: Record<string, unknown>;
}

const AdminOverview: React.FC = () => {
  const theme = useTheme();
  const { currentOrganization, currentUser } = useAuth();
  const { data: healthData, error } = useHealthCheck();

  const renderHealthStatus = () => {
    if (error) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ErrorIcon sx={{ color: "#b60707", width: "16px" }} />
          <span>Error checking platform health</span>
        </Box>
      );
    }

    if (!healthData) {
      return null;
    }

    return Object.entries(healthData).map((entry) => {
      const key: string = entry[0];
      const value: HealthCheckDetail = entry[1];

      const formatDetail = (detail: unknown): string => {
        if (typeof detail === "string") {
          try {
            // If we have serialized JSON, parse and pretty print it
            return JSON.stringify(JSON.parse(detail), null, 2);
          } catch {
            // If we just have a string, return a string
            return detail;
          }
        }

        // If it's already an object, stringify it with pretty printing
        return JSON.stringify(detail, null, 2);
      };

      return (
        <Box key={key} sx={{ mb: 1 }}>
          <RobotoTypography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
          >
            {value.is_healthy ? (
              <CheckCircleIcon sx={{ color: "#2aa909", width: "15px" }} />
            ) : (
              <ErrorIcon sx={{ color: "#b60707", width: "15px" }} />
            )}{" "}
            {key}
          </RobotoTypography>
          {value.detail && (
            <RobotoTypography
              variant="caption"
              component="pre"
              sx={{
                whiteSpace: "pre-wrap",
                backgroundColor: theme.palette.code.main,
                padding: 1,
                borderRadius: 1,
                mt: 0.5,
              }}
            >
              {formatDetail(value.detail)}
            </RobotoTypography>
          )}
        </Box>
      );
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Box>
          <RobotoTypography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
            }}
          >
            Admin Debugging Information
          </RobotoTypography>

          <RobotoTypography variant="body2">
            Build Version:{" "}
            <Link
              href={`https://github.com/roboto-ai/roboto-hosted-app/commit/${Env.gitCommitId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Env.gitCommitId.substring(0, 7)}
            </Link>
            <br />
            Committed: {new Date(Env.gitCommitDate).toLocaleString()}
            <br />
            Current Org ID: <span>{currentOrganization?.org_id || "none"}</span>
            <br />
            Current User ID: <span>{currentUser?.user_id || "none"}</span>
          </RobotoTypography>
        </Box>

        <Box>
          <RobotoTypography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
            }}
          >
            Deep Health Check Results
          </RobotoTypography>
          {renderHealthStatus()}
        </Box>
      </Box>
    </>
  );
};

export default AdminOverview;
