import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem, Select, useTheme } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";

import { RobotoIconButton, RobotoTypography } from "@/shared/components";
import { formatFieldName } from "@/shared/text";
import { ConditionsOperator } from "@/types";

import { UISimpleCondition } from "./conditions";
import { SearchFieldType } from "./fields";

interface FiltersBoxProps {
  conditions: UISimpleCondition[];
  conditionsOperator: ConditionsOperator;
  onGroupOperatorChange: (newOperator: ConditionsOperator) => void;
  onDeleteCondition: (condition: UISimpleCondition) => void;
  onFilterClick: (
    event: React.MouseEvent<HTMLDivElement>,
    filterToUpdate: UISimpleCondition,
  ) => void;
  editable: boolean;
}

export const FiltersBox: React.FC<FiltersBoxProps> = ({
  conditions,
  conditionsOperator,
  onGroupOperatorChange,
  onDeleteCondition,
  onFilterClick,
  editable,
}) => {
  //
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.foreground.main,
        border: 0,
        borderBottomLeftRadius: theme.border.radius,
        borderBottomRightRadius: theme.border.radius,
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        borderTop: editable ? `1px solid ${theme.border.color}` : "0px",
        boxShadow: editable
          ? "0px 2px 1px -1px rgba(0,0,0,0.005), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 1px 3px 0px rgba(0,0,0,0.05)"
          : "none",
        "& fieldset": { border: "none" },
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: editable ? theme.spacing(1) : 0,
        paddingRight: editable ? theme.spacing(1) : 0,
      }}
    >
      {conditions.map((cond, index) => {
        const condition = cond;
        return (
          <FilterBox
            key={index}
            groupOperator={index > 0 ? conditionsOperator : undefined}
            condition={condition}
            onGroupOperatorChange={onGroupOperatorChange}
            onDeleteCondition={onDeleteCondition}
            onFilterClick={onFilterClick}
            searchFieldType={condition.searchFieldType}
            editable={editable}
          />
        );
      })}
    </Box>
  );
};

interface FilterBoxProps {
  condition: UISimpleCondition;
  groupOperator?: ConditionsOperator;
  onGroupOperatorChange: (newOperator: ConditionsOperator) => void;
  onDeleteCondition: (condition: UISimpleCondition) => void;
  onFilterClick: (
    event: React.MouseEvent<HTMLDivElement>,
    filterToUpdate: UISimpleCondition,
  ) => void;
  searchFieldType: SearchFieldType;
  editable: boolean;
}

const FilterBox: React.FC<FilterBoxProps> = ({
  condition,
  groupOperator,
  onGroupOperatorChange,
  onDeleteCondition,
  onFilterClick,
  searchFieldType,
  editable,
}) => {
  const theme = useTheme();

  let value = condition.value;

  if (searchFieldType === "date") {
    value = dayjs(`${condition.value}`).format("YYYY-MM-DD");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.border.radius,
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: editable ? "pointer" : "auto",
      }}
      onClick={(e) => editable && onFilterClick(e, condition)}
    >
      {groupOperator && (
        <Select
          SelectDisplayProps={{
            style: {
              padding: 0,
              paddingRight: theme.spacing(4),
            },
          }}
          value={groupOperator}
          disabled={!editable}
          onChange={(event) => {
            event?.preventDefault();
            event.stopPropagation();
            onGroupOperatorChange(event.target.value as "AND" | "OR");
          }}
          data-cy={"groupOperatorSelect"}
        >
          <MenuItem
            value={"AND"}
            onClick={(event) => {
              event?.preventDefault();
              event.stopPropagation();
            }}
          >
            AND
          </MenuItem>
          <MenuItem
            value={"OR"}
            onClick={(event) => {
              event?.preventDefault();
              event.stopPropagation();
            }}
          >
            OR
          </MenuItem>
        </Select>
      )}
      <RobotoTypography
        variant={"body2"}
        sx={{ marginRight: theme.spacing(1) }}
      >
        {formatFieldName(condition.field)}
      </RobotoTypography>
      <RobotoTypography
        variant={"body2"}
        sx={{ marginRight: theme.spacing(1) }}
      >
        {condition.comparator}
      </RobotoTypography>
      <RobotoTypography variant={"body2"}>{value}</RobotoTypography>
      <Box sx={{ margin: theme.spacing(0.5) }}>
        {editable && (
          <RobotoIconButton
            eventName={"DatasetFilterDeleted"}
            data-cy={"deleteFilterButton"}
            onClick={(event) => {
              event?.preventDefault();
              event.stopPropagation();
              onDeleteCondition(condition);
            }}
            size="small"
            sx={{
              p: 0.25,
            }}
          >
            <CloseIcon fontSize="small" />
          </RobotoIconButton>
        )}
      </Box>
    </Box>
  );
};
