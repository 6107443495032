import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import * as React from "react";

export interface AlertDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  dialogTitle: string;
  dialogText?: string;
  dialogContent?: React.ReactNode;
  dialogAction?: () => Promise<void>;
  suppressAutoClose?: boolean;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  dialogOpen,
  handleClose,
  dialogTitle,
  dialogText,
  dialogContent,
  dialogAction = () => Promise.resolve(),
  suppressAutoClose,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // This absolutely needs to be here so that the dialog can be used in the viz workspace
      // because of https://github.com/roboto-ai/roboto-hosted-app/blob/main/web-ui/src/shared/components/visualization/DndContainer/PointerSensor.ts#L7
      data-not-draggable="true"
      maxWidth="md"
      sx={{ zIndex: theme.zLayer(10) }}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogText && (
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        )}
        {dialogContent}
      </DialogContent>
      {
        <DialogActions>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginBottom: theme.spacing(2),
              }}
            >
              <CircularProgress size={"1.5rem"} />
            </Box>
          ) : (
            <Box sx={{ marginBottom: theme.spacing(2) }}>
              <Button onClick={handleClose}>No</Button>

              <Button
                data-cy="confirm-action"
                onClick={() => {
                  setLoading(true);
                  void dialogAction().finally(() => {
                    if (!suppressAutoClose) {
                      setLoading(false);
                      handleClose();
                    }
                  });
                }}
                autoFocus
              >
                Yes
              </Button>
            </Box>
          )}
        </DialogActions>
      }
    </Dialog>
  );
};
