import type { JSONSchemaType } from "ajv";

import {
  type AssociationRecord,
  AssociationType,
} from "@/shared/domain/association";
import {
  type MessagePathAttr,
  type MessagePathPart,
  type MessagePathSlice,
  CanonicalDataType,
  MessagePathPartType,
  RepresentationStorageFormat,
} from "@/shared/domain/topics";

import { type BaseSchema } from "./base";

export interface File {
  fileId: string;
  relativePath?: string;
}
const fileSchema: JSONSchemaType<File> = {
  type: "object",
  properties: {
    fileId: { type: "string" },
    relativePath: { type: "string", nullable: true },
  },
  required: ["fileId"],
  additionalProperties: false,
};

export enum PanelType {
  Image = "Image",
  Log = "Log",
  Map = "Map",
  Plot = "Plot",
  RawMessage = "RawMessage",
  ThreeD = "ThreeD",
}

export enum LayoutType {
  Panel = "Panel",
}

const messagePathAttrSchema: JSONSchemaType<MessagePathAttr> = {
  type: "object",
  properties: {
    type: {
      type: "string",
      enum: [MessagePathPartType.Attr],
    },
    attribute: { type: "string" },
    dataType: {
      type: "string",
      enum: [
        CanonicalDataType.Array,
        CanonicalDataType.Boolean,
        CanonicalDataType.Byte,
        CanonicalDataType.Image,
        CanonicalDataType.Number,
        CanonicalDataType.Object,
        CanonicalDataType.String,
        CanonicalDataType.Unknown,
        CanonicalDataType.LatDegFloat,
        CanonicalDataType.LonDegFloat,
        CanonicalDataType.LatDegInt,
        CanonicalDataType.LonDegInt,
      ],
    },
  },
  required: ["type", "attribute", "dataType"],
  additionalProperties: false,
};

const messagePathSliceSchema: JSONSchemaType<MessagePathSlice> = {
  type: "object",
  properties: {
    type: {
      type: "string",
      enum: [MessagePathPartType.Slice],
    },
    start: { type: "number" },
    end: { type: "number", nullable: true },
  },
  required: ["type", "start"],
  additionalProperties: false,
};

const messagePathPartSchema: JSONSchemaType<MessagePathPart> = {
  anyOf: [messagePathAttrSchema, messagePathSliceSchema],
};

export interface TopicData {
  topic: {
    id: string; // TopicRecord::topic_id
    name: string; // TopicRecord::topic_name
    association: AssociationRecord; // TopicRecord::association
    startTime?: string; // TopicRecord::start_time
    endTime?: string; // TopicRecord::end_time
    messageCount?: number; // TopicRecord::message_count
  };
  messagePath: {
    id: string; // MessagePathRecord::topic_message_path_id
    dotPath: string; // MessagePathRecord::message_path, e.g., "transforms.transform.translation.x"
    metadata: Record<string, unknown>; // MessagePathRecord::metadata
    // Ordered list of parts that make up the message path.
    // E.g., for a message path like "transforms.transform.translation.x"
    // this would be:
    // [
    //   { type: "Attr", attribute: "transforms", dataType: <CanonicalDataType.Array> },
    //   { type: "Slice", start: 0, end: 10, step: 1 },
    //   { type: "Attr", attribute: "transform", dataType: <CanonicalDataType.Object> },
    //   { type: "Attr", attribute: "translation", dataType: <CanonicalDataType.Object> },
    //   { type: "Attr", attribute: "x", dataType: <CanonicalDataType.Number> },
    // ]
    parts: MessagePathPart[];
  };
  representation: {
    association: AssociationRecord;
    id: string;
    format: RepresentationStorageFormat;
  };
}

const associationSchema: JSONSchemaType<AssociationRecord> = {
  type: "object",
  properties: {
    association_id: { type: "string" },
    association_type: {
      type: "string",
      enum: [AssociationType.Dataset, AssociationType.File],
    },
    association_version: { type: "number", nullable: true },
  },
  required: ["association_id", "association_type"],
  additionalProperties: true,
};

const representationSchema: JSONSchemaType<TopicData["representation"]> = {
  type: "object",
  properties: {
    association: associationSchema,
    id: { type: "string" },
    format: {
      type: "string",
      enum: [RepresentationStorageFormat.MCAP],
    },
  },
  required: ["association", "id", "format"],
  additionalProperties: true,
};

const topicDataSchema: JSONSchemaType<TopicData> = {
  type: "object",
  properties: {
    topic: {
      type: "object",
      properties: {
        id: { type: "string" },
        name: { type: "string" },
        association: associationSchema,
        startTime: { type: "string", nullable: true },
        endTime: { type: "string", nullable: true },
        messageCount: { type: "number", nullable: true },
      },
      required: ["name", "association"],
      additionalProperties: true,
    },
    messagePath: {
      type: "object",
      properties: {
        id: { type: "string" },
        dotPath: { type: "string" },
        metadata: {
          type: "object",
          additionalProperties: true,
        },
        parts: {
          type: "array",
          items: messagePathPartSchema,
        },
      },
      required: ["dotPath", "metadata", "parts"],
      additionalProperties: true,
    },
    representation: representationSchema,
  },
  required: ["topic", "messagePath", "representation"],
  additionalProperties: true,
};

export interface PanelState<Data = unknown, Config = unknown> {
  config?: Config;
  data: Data;
  id: string;
  title: string;
  type: PanelType;
}

//// IMAGE PANEL
export interface ImagePanelClip {
  data: TopicData;
  id: string;
}

const imageClipSchema: JSONSchemaType<ImagePanelClip> = {
  type: "object",
  properties: {
    data: topicDataSchema,
    id: { type: "string" },
  },
  required: ["data", "id"],
  additionalProperties: true,
};

export interface ImagePanelConfig {
  rotation: number; // in degrees
  stretchToFitCanvas: boolean;
}
const imagePanelConfigSchema: JSONSchemaType<ImagePanelConfig> = {
  type: "object",
  properties: {
    rotation: { type: "number" },
    stretchToFitCanvas: { type: "boolean" },
  },
  required: ["rotation", "stretchToFitCanvas"],
  additionalProperties: true,
};

export interface ImagePanelState
  extends PanelState<ImagePanelClip[], ImagePanelConfig> {}
export function isImagePanelState(state: PanelState): state is ImagePanelState {
  return state.type === PanelType.Image;
}

//// MAP PANEL
export interface MapPath {
  data: TopicData[];
  id: string;
  // This is a prop bag to store any style-related state.
  style: Record<string, unknown>;
  visible: boolean;
}
const mapPathSchema: JSONSchemaType<MapPath> = {
  type: "object",
  properties: {
    data: { type: "array", items: topicDataSchema },
    id: { type: "string" },
    style: {
      type: "object",
      additionalProperties: true,
    },
    visible: { type: "boolean" },
  },
  required: ["data", "id", "style", "visible"],
  additionalProperties: true,
};
export interface MapPanelState extends PanelState<MapPath[]> {}
export function isMapPanelState(state: PanelState): state is MapPanelState {
  return state.type === PanelType.Map;
}

//// PLOT PANEL
export interface PlotSeries {
  data: TopicData;
  id: string;
  // This is a prop bag to store configurable state (such as style)
  config?: Record<string, unknown>;
  visible: boolean;
}
const plotSeriesSchema: JSONSchemaType<PlotSeries> = {
  type: "object",
  properties: {
    config: {
      type: "object",
      additionalProperties: true,
      nullable: true,
    },
    data: topicDataSchema,
    id: { type: "string" },
    visible: { type: "boolean" },
  },
  required: ["data", "id", "visible"],
  additionalProperties: true,
};
export interface PlotPanelState extends PanelState<PlotSeries[]> {}
export function isPlotPanelState(state: PanelState): state is PlotPanelState {
  return state.type === PanelType.Plot;
}

//// 3D PANEL
export interface ThreeDData {
  data: {};
  id: string;
  // This is a prop bag to store configurable state (such as style)
  config?: Record<string, unknown>;
  visible: boolean;
}
const threeDSchema: JSONSchemaType<ThreeDData> = {
  type: "object",
  properties: {
    config: {
      type: "object",
      additionalProperties: true,
      nullable: true,
    },
    // TODO(#1179): introduce data types for 3D topic data (x,y,z?)
    // TODO(#1180): introduce data types for 3D file data (.dae, .urdf, .splat)
    data: { type: "object", properties: {} },
    id: { type: "string" },
    visible: { type: "boolean" },
  },
  required: ["data", "id", "visible"],
  additionalProperties: true,
};
export interface ThreeDPanelState extends PanelState<ThreeDData[]> {}
export function isThreeDPanelState(
  state: PanelState,
): state is ThreeDPanelState {
  return state.type === PanelType.ThreeD;
}

//// RAW MESSAGE PANEL
export interface RawMessagePanelState extends PanelState<TopicData | null> {}
export function isRawMessagePanelState(
  state: PanelState,
): state is RawMessagePanelState {
  return state.type === PanelType.RawMessage;
}

export interface LogPanelState extends PanelState<TopicData[]> {}
export function isLogPanelState(state: PanelState): state is LogPanelState {
  return state.type === PanelType.Log;
}

export type PanelStates =
  | ImagePanelState
  | LogPanelState
  | MapPanelState
  | PlotPanelState
  | RawMessagePanelState;

const panelSchema: JSONSchemaType<PanelStates> = {
  type: "object",
  anyOf: [
    {
      properties: {
        type: { const: PanelType.Plot },
        data: {
          type: "array",
          items: plotSeriesSchema,
        },
        id: { type: "string" },
        title: { type: "string" },
      },
    },
    {
      properties: {
        type: { const: PanelType.ThreeD },
        data: {
          type: "array",
          items: threeDSchema,
        },
        id: { type: "string" },
        title: { type: "string" },
      },
    },
    {
      properties: {
        type: { const: PanelType.Map },
        data: {
          type: "array",
          items: mapPathSchema,
        },
        id: { type: "string" },
        title: { type: "string" },
      },
    },
    {
      properties: {
        type: { const: PanelType.RawMessage },
        data: topicDataSchema,
        id: { type: "string" },
        title: { type: "string" },
      },
    },
    {
      properties: {
        type: { const: PanelType.Image },
        config: imagePanelConfigSchema,
        data: { type: "array", items: imageClipSchema },
        id: { type: "string" },
        title: { type: "string" },
      },
    },
    {
      properties: {
        type: { const: PanelType.Log },
        data: {
          type: "array",
          items: topicDataSchema,
        },
        id: { type: "string" },
        title: { type: "string" },
      },
    },
  ],
  required: ["data", "type", "id"],
  additionalProperties: true,
};

interface BaseLayout {
  id: string;
  relativeSize: number;
}

export interface LayoutItem extends BaseLayout {
  type: LayoutType;
  isResizing: boolean;
}

export interface Layout extends BaseLayout {
  axis: "x" | "y";
  children: (Layout | LayoutItem)[];
}

export function isLayoutItem(
  layout: Layout | LayoutItem,
): layout is LayoutItem {
  return "children" in layout === false;
}

const LayoutItemSchema: JSONSchemaType<LayoutItem> = {
  $id: "LayoutItemSchema",
  type: "object",
  properties: {
    id: {
      type: "string",
    },
    relativeSize: {
      type: "number",
    },
    isResizing: {
      type: "boolean",
    },
    type: {
      type: "string",
      enum: [LayoutType.Panel],
    },
  },
  required: ["id", "relativeSize", "isResizing", "type"],
};

const LayoutSchema: JSONSchemaType<Layout> = {
  $id: "LayoutSchema",
  type: "object",
  properties: {
    id: {
      type: "string",
    },
    axis: {
      type: "string",
      enum: ["x", "y"],
    },
    relativeSize: {
      type: "number",
    },
    children: {
      type: "array",
      items: {
        anyOf: [
          {
            type: "object",
            $ref: "LayoutSchema",
            required: ["id", "axis", "relativeSize", "children"],
          },
          LayoutItemSchema,
        ],
      },
    },
  },
  required: ["id", "axis", "relativeSize", "children"],
};

export interface EventConfig {
  isVisible: boolean;
}

const eventConfigSchema: JSONSchemaType<EventConfig> = {
  type: "object",
  properties: {
    isVisible: { type: "boolean" },
  },
  required: ["isVisible"],
  additionalProperties: true,
};

export interface State extends BaseSchema {
  files: File[];

  // panel id -> panel state
  panels?: {
    [id: string]: PanelStates;
  };

  layout: Layout;

  // event id -> event config
  events?: {
    [id: string]: EventConfig;
  };
}

export const VERSION = "visualization_v1";

export const schema: JSONSchemaType<State> = {
  $id: VERSION,
  type: "object",
  properties: {
    version: { type: "string", const: VERSION },
    files: {
      type: "array",
      items: fileSchema,
    },
    panels: {
      type: "object",
      nullable: true,
      required: [],
      patternProperties: {
        ".*": panelSchema,
      },
    },
    layout: LayoutSchema,
    events: {
      type: "object",
      nullable: true,
      required: [],
      patternProperties: {
        ".*": eventConfigSchema,
      },
    },
  },
  required: ["files", "layout"],
  additionalProperties: true,
};

type EnumCases<T extends string | number | symbol, R> = {
  [K in T]: () => R;
};
function exhaustiveSwitch<T extends string | number | symbol, R>(
  enumValue: T,
  cases: EnumCases<T, R>,
): R {
  return cases[enumValue]();
}

/**
 * Return the list of topic data for the given panel.
 */
export function getTopicDataForPanel(panel: PanelState): TopicData[] {
  return exhaustiveSwitch<PanelType, TopicData[]>(panel.type, {
    [PanelType.Image]: () => {
      const imagePanel = panel as ImagePanelState;
      return imagePanel.data.map((clip) => clip.data);
    },
    [PanelType.Log]: () => {
      const logPanel = panel as LogPanelState;
      return logPanel.data;
    },
    [PanelType.Map]: () => {
      const mapPanel = panel as MapPanelState;
      return mapPanel.data.flatMap((path) => path.data);
    },
    [PanelType.Plot]: () => {
      const plotPanel = panel as PlotPanelState;
      return plotPanel.data.map((series) => series.data);
    },
    [PanelType.ThreeD]: () => {
      // TODO(#1179): support topic data in the 3d panel.
      return [];
    },
    [PanelType.RawMessage]: () => {
      const rawMessagePanel = panel as RawMessagePanelState;
      return rawMessagePanel.data ? [rawMessagePanel.data] : [];
    },
  });
}
