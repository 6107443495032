import * as React from "react";
import { useSearchParams } from "react-router-dom";

import {
  useWorkspaceMessagePathById,
  useWorkspaceTopicById,
  useWorkspaceTopicByMessagePathId,
} from "@/shared/components/visualization/WorkspaceCtx/hooks.tsx";
import {
  getMessagePathNodeFromRecord,
  MessagePathRecord,
  TopicRecord,
} from "@/shared/domain/topics";
import { SearchParams } from "@/shared/environment";
import { useVizDispatch } from "@/shared/state/visualization";
import { nanoSecToSecStr } from "@/shared/time";

import {
  constructPanelForMessagePath,
  determineDefaultPanelType,
} from "../DndContainer/dropHandlers";
import {
  SidebarTab,
  SidebarViewType,
  useWorkspaceSidebar,
} from "../sidebarContext";

export function useOpenTopicSearch() {
  const [_, setSearchParams] = useSearchParams();
  const sidebar = useWorkspaceSidebar();

  return React.useCallback(
    function viewTopic(topic: TopicRecord, timestamp: bigint | undefined) {
      setSearchParams(
        (oldParams) => {
          const params = new URLSearchParams(oldParams);
          params.set(SearchParams.FILTER_TERM, topic.topic_name);
          if (timestamp) {
            params.set(SearchParams.TIMESTAMP, nanoSecToSecStr(timestamp));
          }
          return params;
        },
        { replace: true },
      );

      sidebar.openView({
        type: SidebarViewType.TabbedSidebar,
        data: undefined,
      });
      sidebar.setActiveTab(SidebarTab.Topics);
    },
    [setSearchParams, sidebar],
  );
}

export function useOpenPanel() {
  const [_, setSearchParams] = useSearchParams();
  const vizDispatch = useVizDispatch();

  return React.useCallback(
    function viewMessagePath(
      messagePath: MessagePathRecord,
      topic: TopicRecord,
      timestamp: bigint | undefined,
    ) {
      setSearchParams(
        (oldParams) => {
          const params = new URLSearchParams(oldParams);
          if (timestamp) {
            params.set(SearchParams.TIMESTAMP, nanoSecToSecStr(timestamp));
          }
          return params;
        },
        { replace: true },
      );

      const messagePathNode = getMessagePathNodeFromRecord(topic, messagePath);
      if (!messagePathNode) {
        throw new Error(
          `Failed to find message path node for ${messagePath.message_path} when constructing tree from topic ${topic.topic_name} to visualize.`,
        );
      }

      constructPanelForMessagePath(
        vizDispatch,
        messagePathNode,
        determineDefaultPanelType(messagePathNode),
      );
    },
    [setSearchParams, vizDispatch],
  );
}

export function useOpenPanelForMessagePath(
  messagePathId?: string,
  timestamp?: bigint,
): {
  openPanel: () => void;
  available: boolean;
  fullyQualifiedMessagePath?: string;
} {
  const messagePath = useWorkspaceMessagePathById(messagePathId);
  const topic = useWorkspaceTopicByMessagePathId(messagePathId);
  const viewMessagePath = useOpenPanel();
  const available = !!messagePath && !!topic;

  const fullyQualifiedMessagePath = React.useMemo(() => {
    if (!messagePath || !topic) {
      return undefined;
    }
    return `${topic.topic_name}.${messagePath.message_path}`;
  }, [topic, messagePath]);

  const openPanel = React.useCallback(() => {
    if (!available) {
      return;
    }
    viewMessagePath(messagePath, topic, timestamp);
  }, [available, messagePath, topic, viewMessagePath, timestamp]);

  return {
    openPanel,
    available,
    fullyQualifiedMessagePath,
  };
}

export function useOpenTopicSearchForTopicId(
  topicId?: string,
  timestamp?: bigint,
): {
  openSearch: () => void;
  available: boolean;
  topicName?: string;
} {
  const topic = useWorkspaceTopicById(topicId);
  const viewTopic = useOpenTopicSearch();

  const openSearch = React.useCallback(() => {
    if (!topic) {
      return;
    }
    viewTopic(topic, timestamp);
  }, [topic, viewTopic, timestamp]);

  return {
    openSearch,
    available: !!topic,
    topicName: topic?.topic_name,
  };
}
