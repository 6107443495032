import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { AISummary } from "@/shared/domain/ai_summary";

export function useFileSummary(fileId: string) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<AISummary, Error>({
    queryKey: ["file-summary", fileId],
    queryFn: ({ signal }) => {
      return files.getSummary(fileId, {
        abortSignal: signal,
      });
    },
    staleTime: 300 * 1000, // Cache this for 5 minutes, since it's expensive to compute
  });
}
