import { Extents } from "../PlotRenderer";

interface Dimensions {
  left: number;
  top: number;
  width: number;
  height: number;
}

function calculateRegionPercentages(region: Dimensions, width: number) {
  const right = region.left + region.width;
  return {
    startPct: region.left / width,
    endPct: right / width,
  };
}

/**
 * Maps the selected region's dimensions to a chart's time range.
 *
 * @param region Dimensions are in pixels relative to the chart
 * @param chartExtents The chart's boundaries
 */
export function pixelRegionToTimeRange(
  region: Dimensions,
  chartExtents: Extents,
) {
  const { logTime } = chartExtents.data;

  const { startPct, endPct } = calculateRegionPercentages(
    region,
    chartExtents.pixel.width,
  );
  const duration = Number(logTime.max - logTime.min);

  const start = BigInt(Math.round(duration * startPct));
  const end = BigInt(Math.round(duration * endPct));

  return {
    startTime: logTime.min + start,
    endTime: logTime.min + end,
  };
}

/**
 * Maps the selected region's dimensions to a chart's current viewport domain.
 *
 * @param region Dimensions are in pixels relative to the chart
 * @param chartExtents The chart's boundaries
 */
export function pixelRegionToViewport(
  region: Dimensions,
  chartExtents: Extents,
) {
  const { viewportDomain } = chartExtents.data;

  const { startPct, endPct } = calculateRegionPercentages(
    region,
    chartExtents.pixel.width,
  );
  const duration = viewportDomain.max - viewportDomain.min;

  return {
    min: viewportDomain.min + duration * startPct,
    max: viewportDomain.min + duration * endPct,
  };
}
