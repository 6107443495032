import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { VisibilityToggle } from "@/shared/components/VisibilityToggle";
import {
  type PlotSeries,
  actions,
  VizStateDispatchContext,
} from "@/shared/state/visualization";

import styles from "./PlotSeriesHeader.module.css";

interface PlotSeriesHeaderProps {
  order: number;
  panelId: string;
  plotSeries: PlotSeries;
}

export function PlotSeriesHeader({
  order,
  panelId,
  plotSeries,
}: PlotSeriesHeaderProps) {
  const vizStateDispatch = React.useContext(VizStateDispatchContext);

  const removeSeries = () => {
    vizStateDispatch(actions.removeSeriesFromPlotPanel(panelId, plotSeries.id));
  };

  const setSeriesVisibility = (visible: boolean) => {
    vizStateDispatch(
      actions.setSeriesVisibility(panelId, plotSeries.id, visible),
    );
  };

  return (
    <div className={styles.container}>
      <RobotoTypography component={"h3"} className={styles.title}>
        Series {order}
      </RobotoTypography>
      <VisibilityToggle
        className={styles.iconButton}
        on={plotSeries.visible}
        onToggle={setSeriesVisibility}
      />
      <IconButton className={styles.iconButton} onClick={removeSeries}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
}
