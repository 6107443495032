import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { MetadataView } from "@/shared/components/Metadata";
import { constructTree, TopicRecord } from "@/shared/domain/topics";
import { determineTimeBounds } from "@/shared/domain/topics/determineTimeBounds";
import { timeToSeconds } from "@/shared/mcap";

import { RobotoTypography } from "../RobotoTypography";

import { TopicsTableRow } from "./TopicsTableRow";

export const MetadataTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  fontSize: "0.75rem",
  lineHeight: 1.66,
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const tableHeaderStyle = {
  fontWeight: 500,
  fontSize: "0.8rem",
  padding: "3px 0",
};

interface TopicsTableProps {
  topics: TopicRecord[];
}

export const TopicsTable: React.FC<TopicsTableProps> = ({ topics }) => {
  const theme = useTheme();

  const timeBounds = React.useMemo(() => {
    return determineTimeBounds(topics);
  }, [topics]);

  const topicTree = React.useMemo(() => {
    return constructTree(topics);
  }, [topics]);

  return (
    <>
      {/* It would be better for these to be populated during ingestion (ROBO-845) */}
      <MetadataView
        metadata={{
          duration:
            timeBounds.difference !== undefined
              ? `${timeToSeconds(timeBounds.difference)} sec`
              : "-",
          earliest_time:
            timeBounds.earliest !== undefined
              ? `${timeToSeconds(timeBounds.earliest)} sec`
              : "-",
          latest_time:
            timeBounds.latest !== undefined
              ? `${timeToSeconds(timeBounds.latest)} sec`
              : "-",
          topic_count: topics.length || "-",
        }}
        disabled={true}
      />

      <Box sx={{ mt: theme.spacing(2) }}>
        <TableContainer>
          <Table size="small" sx={{ width: "auto" }}>
            <TableHead>
              <TableRow>
                <MetadataTableCell>
                  <RobotoTypography variant="body2" sx={tableHeaderStyle}>
                    Topic
                  </RobotoTypography>
                </MetadataTableCell>
                <MetadataTableCell>
                  <RobotoTypography variant="body2" sx={tableHeaderStyle}>
                    Messages
                  </RobotoTypography>
                </MetadataTableCell>
                <MetadataTableCell>
                  <RobotoTypography variant="body2" sx={tableHeaderStyle}>
                    Schema
                  </RobotoTypography>
                </MetadataTableCell>
                <MetadataTableCell>
                  <RobotoTypography variant="body2" sx={tableHeaderStyle}>
                    Start Time (sec)
                  </RobotoTypography>
                </MetadataTableCell>
                <MetadataTableCell>
                  <RobotoTypography variant="body2" sx={tableHeaderStyle}>
                    End Time (sec)
                  </RobotoTypography>
                </MetadataTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topicTree.map((topicNode) => {
                return (
                  <TopicsTableRow
                    key={topicNode.data.topic_id}
                    topicNode={topicNode}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export const MemoizedTopicsTable = React.memo(TopicsTable);
