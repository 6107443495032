import * as React from "react";

import { isAbortError } from "@/shared/errors";
import { StorageManager } from "@/shared/fs";
import { ErrorMonitoringService } from "@/shared/services";

/**
 * Some Panels pull topic data representations into the origin private file system.
 * While browsers provide lenient quotas,
 * the space available on a users harddrive is not infinite.
 * On mount of the visualization workspace,
 * make an effort to clear old data we've downloaded,
 * and otherwise make sure we're well under quota for our origin.
 */
export function useEnforceStorageQuota() {
  React.useEffect(function enforceStorageQuota() {
    const controller = new AbortController();

    const storageManager = new StorageManager({
      abortSignal: controller.signal,
    });
    storageManager.enforceStorageQuota().catch((err) => {
      if (isAbortError(err)) {
        return;
      }
      ErrorMonitoringService.captureError(err);
    });

    return function cleanup() {
      controller.abort();
    };
  }, []);
}
