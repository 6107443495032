import {
  type MessagePathNode,
  CanonicalDataType,
} from "@/shared/domain/topics";
import { type PanelState, PanelType } from "@/shared/state/visualization";

import { isLatitudeType, isLongitudeType } from "./MapPanel";

export function isViewableAsImage(messagePath: MessagePathNode) {
  return messagePath.data.canonical_data_type === CanonicalDataType.Image;
}

export function isViewableAsPlot(messagePath: MessagePathNode) {
  return (
    messagePath.isLeafNode() &&
    [
      CanonicalDataType.Number,
      CanonicalDataType.Boolean,
      CanonicalDataType.LatDegFloat,
      CanonicalDataType.LatDegInt,
      // The PlotPanel will attempt pick values out of arrays and coerce them to numbers.
      // If it is unable to coerce a value to a number, it will raise an exception.
      CanonicalDataType.Array,
      CanonicalDataType.NumberArray,
    ].includes(messagePath.data.canonical_data_type)
  );
}

export function isViewableAsThreeD() {
  // TODO(#1179): Support topic data in the ThreeDPanel.
  return false;
}

export function isViewableAsMap(messagePath: MessagePathNode) {
  return (
    isLatitudeType(messagePath.data.canonical_data_type) ||
    isLongitudeType(messagePath.data.canonical_data_type)
  );
}

export function isViewableAsRawMessage(messagePath: MessagePathNode) {
  return !isViewableAsImage(messagePath);
}

export function isViewableAsConsoleLog(messagePath: MessagePathNode) {
  return !isViewableAsImage(messagePath);
}

export function panelAcceptsMessagePath(
  panel: PanelState,
  messagePath: MessagePathNode,
): boolean {
  const panelType = panel.type;
  switch (panelType) {
    case PanelType.Plot:
      return isViewableAsPlot(messagePath);
    case PanelType.ThreeD:
      return isViewableAsThreeD();
    case PanelType.RawMessage:
      return isViewableAsRawMessage(messagePath);
    case PanelType.Image:
      return isViewableAsImage(messagePath);
    case PanelType.Map:
      return isViewableAsMap(messagePath);
    case PanelType.Log:
      return isViewableAsConsoleLog(messagePath);
    default:
      return false;
  }
}
