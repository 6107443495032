import DataObjectIcon from "@mui/icons-material/DataObject";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MapIcon from "@mui/icons-material/Map";
import TimelineIcon from "@mui/icons-material/Timeline";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

import { type MessagePathNode } from "@/shared/domain/topics";
import { PanelType, useVizDispatch } from "@/shared/state/visualization";

import { RobotoTypography } from "../../RobotoTypography";
import { constructPanelForMessagePath } from "../DndContainer/dropHandlers";
import {
  isViewableAsMap,
  isViewableAsPlot,
  isViewableAsImage,
} from "../Panel/predicates";

interface ContextMenuProps {
  node: MessagePathNode;
  anchorElement: HTMLElement | null;
  closeMenu: () => void;
}

export function ContextMenu({
  node,
  anchorElement,
  closeMenu,
}: ContextMenuProps) {
  const dispatch = useVizDispatch();

  const handleImagePanelOption = () => {
    closeMenu();
    constructPanelForMessagePath(dispatch, node, PanelType.Image);
  };

  const handleMapPanelOption = () => {
    closeMenu();
    constructPanelForMessagePath(dispatch, node, PanelType.Map);
  };

  const handlePlotPanelOption = () => {
    closeMenu();
    constructPanelForMessagePath(dispatch, node, PanelType.Plot);
  };

  const handleRawMessagePanelOption = () => {
    closeMenu();
    constructPanelForMessagePath(dispatch, node, PanelType.RawMessage);
  };

  const handleLogPanelOption = () => {
    closeMenu();
    constructPanelForMessagePath(dispatch, node, PanelType.Log);
  };

  const hasPossiblePanelOption =
    !node.sharesRepresentationWithBinaryField() ||
    isViewableAsPlot(node) ||
    isViewableAsMap(node) ||
    isViewableAsImage(node);

  return (
    <Menu
      id="context-menu"
      anchorEl={anchorElement}
      open={hasPossiblePanelOption && anchorElement !== null}
      onClose={closeMenu}
    >
      {isViewableAsImage(node) && (
        <MenuItem onClick={handleImagePanelOption}>
          <ListItemIcon>
            <ImageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <RobotoTypography
              sx={{
                fontSize: "0.85rem",
              }}
            >
              View in Image panel
            </RobotoTypography>
          </ListItemText>
        </MenuItem>
      )}

      {!node.sharesRepresentationWithBinaryField() && (
        <MenuItem onClick={handleLogPanelOption}>
          <ListItemIcon>
            <ListAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <RobotoTypography
              sx={{
                fontSize: "0.85rem",
              }}
            >
              View in Log panel
            </RobotoTypography>
          </ListItemText>
        </MenuItem>
      )}

      {isViewableAsMap(node) && (
        <MenuItem onClick={handleMapPanelOption}>
          <ListItemIcon>
            <MapIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <RobotoTypography
              sx={{
                fontSize: "0.85rem",
              }}
            >
              View in Map panel
            </RobotoTypography>
          </ListItemText>
        </MenuItem>
      )}

      {isViewableAsPlot(node) && (
        <MenuItem onClick={handlePlotPanelOption}>
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <RobotoTypography
              sx={{
                fontSize: "0.85rem",
              }}
            >
              View in Plot panel
            </RobotoTypography>
          </ListItemText>
        </MenuItem>
      )}

      {!node.sharesRepresentationWithBinaryField() && (
        <MenuItem onClick={handleRawMessagePanelOption}>
          <ListItemIcon>
            <DataObjectIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <RobotoTypography
              sx={{
                fontSize: "0.85rem",
              }}
            >
              View in Raw panel
            </RobotoTypography>
          </ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}
