import { useQuery, UseQueryResult } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

const IGNORED_PAGE_SIZE = 10;

export function useDirectoryItemCount(
  directoryPath: string,
  datasetId: string,
  resourceOwnerId?: string,
  searchTerm?: string,
  extensions?: string[],
  showHiddenFiles: boolean = false,
): UseQueryResult<number, Error> {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery({
    queryKey: [
      "directoryItemCount",
      directoryPath,
      datasetId,
      searchTerm,
      extensions,
      showHiddenFiles,
    ],
    queryFn: async ({ signal }) => {
      let itemCount = -1;

      if (searchTerm) {
        itemCount = await files.getFileCountForDirectory({
          path: directoryPath,
          datasetId,
          fileNameSearchTerm: searchTerm,
          extensions,
          showHiddenFiles,
          pageSize: IGNORED_PAGE_SIZE,
          options: {
            abortSignal: signal,
            resourceOwnerId,
          },
        });
      } else {
        itemCount = await files.getItemCountForDirectory({
          directoryPath,
          datasetId,
          extensions,
          showHiddenFiles,
          pageSize: IGNORED_PAGE_SIZE,
          options: {
            abortSignal: signal,
            resourceOwnerId,
          },
        });
      }

      return itemCount;
    },
    retry: false,
  });
}
