import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { nanoSecToSecStr, strSecToBigIntNanosec } from "@/shared/time";

import { RobotoTypography } from "../RobotoTypography";

import { EventDuration } from "./EventDuration";
import { Unit } from "./schema";

/**
 * Assumes only two unit possibilities (s and ns)
 */
function convertTimeToUnit(
  time: string,
  params: {
    fromUnit: Unit;
    toUnit: Unit;
  },
): string {
  const { fromUnit, toUnit } = params;
  if (!time || fromUnit === toUnit) {
    return time;
  }

  switch (toUnit) {
    case Unit.Sec:
      // FromUnit = ns
      return nanoSecToSecStr(BigInt(time));

    case Unit.NanoSec:
      // FromUnit = s
      return strSecToBigIntNanosec(time).toString();
  }
}

function UnitToggle() {
  const form = useFormContext<{
    start_time: string;
    end_time: string;
    unit: Unit;
  }>();

  const startTime = form.watch("start_time");
  const endTime = form.watch("end_time");
  const unit = form.watch("unit");

  return (
    <Controller
      name="unit"
      control={form.control}
      render={({ field }) => {
        return (
          <ToggleButtonGroup
            {...field}
            aria-label="Select time unit"
            color="primary"
            exclusive
            onChange={(_, value: Unit) => {
              if (value !== null) {
                field.onChange(value);
                form.setValue(
                  "start_time",
                  convertTimeToUnit(startTime, {
                    fromUnit: unit,
                    toUnit: value,
                  }),
                );
                form.setValue(
                  "end_time",
                  convertTimeToUnit(endTime, {
                    fromUnit: unit,
                    toUnit: value,
                  }),
                );
              }
            }}
            size="small"
            sx={{
              /* This is a hack to make the toggle button group
               * the same height as the text fields when they render
               * an error message */
              height: "40px",
            }}
          >
            <ToggleButton sx={{ textTransform: "none" }} value={Unit.Sec}>
              sec
            </ToggleButton>
            <ToggleButton sx={{ textTransform: "none" }} value={Unit.NanoSec}>
              nsec
            </ToggleButton>
          </ToggleButtonGroup>
        );
      }}
    />
  );
}

export function EventDurationForm() {
  const form = useFormContext<{
    start_time: string;
    end_time: string;
    unit: Unit;
  }>();

  const startTime = form.watch("start_time");
  const endTime = form.watch("end_time");
  const unit = form.watch("unit");

  return (
    <section>
      <RobotoTypography
        component={"h3"}
        sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
      >
        Duration
      </RobotoTypography>
      <EventDuration startTime={startTime} endTime={endTime} unit={unit} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <TextField
            id="startTime"
            label="Start Time"
            size="small"
            {...form.register("start_time")}
            error={form.formState.errors.start_time !== undefined}
            helperText={form.formState.errors.start_time?.message}
            sx={{
              width: "25ch",
            }}
          />
          <UnitToggle />
        </div>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <TextField
            id="endTime"
            label="End Time"
            size="small"
            {...form.register("end_time")}
            error={form.formState.errors.end_time !== undefined}
            helperText={form.formState.errors.end_time?.message}
            sx={{
              width: "25ch",
            }}
          />
          <UnitToggle />
        </div>
      </div>
    </section>
  );
}
