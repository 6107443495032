import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { ErrorMonitoringService, LoggerService } from "@/shared/services";

import { OPFSPath } from "./OPFSPath";

dayjs.extend(duration);

interface StorageManagerInit {
  abortSignal: AbortSignal;
  /**
   * Storage usage percentage (0...1) that triggers cleanup (default: 0.3).
   * This is a percent of our domain's allocated quota, which varies browser by browser.
   * See:
   *  - https://docs.google.com/document/d/19QemRTdIxYaJ4gkHYf2WWBNPbpuZQDNMpUVf8dQxj4U/edit?tab=t.0
   *  - https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria
   */
  storageThresholdPercentage: number;
  /** Maximum age in days before files are eligible for cleanup (default: 14) */
  maxAgeDays: number;
}

export class StorageManager {
  static readonly DEFAULT_MAX_AGE_DAYS = 14;
  static readonly DEFAULT_STORAGE_THRESHOLD_PERCENTAGE = 0.3;
  static readonly GC_LOCK_NAME = "opfs_garbage_collection";
  static readonly LAST_ACCESS_TIME_SUFFIX = ".last_access_time";

  #abortSignal?: AbortSignal;
  #maxAgeDays: number;
  #storageThresholdPercentage: number;

  constructor(params?: Partial<StorageManagerInit>) {
    this.#abortSignal = params?.abortSignal;
    this.#maxAgeDays =
      params?.maxAgeDays ?? StorageManager.DEFAULT_MAX_AGE_DAYS;
    this.#storageThresholdPercentage =
      params?.storageThresholdPercentage ??
      StorageManager.DEFAULT_STORAGE_THRESHOLD_PERCENTAGE;
  }

  /**
   * Removes files older than `maxAgeDays`,
   * then continue removing oldest files until usage is below threshold.
   *
   * Quotas:
   * https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria
   */
  async enforceStorageQuota() {
    await navigator.locks.request(
      StorageManager.GC_LOCK_NAME,
      { signal: this.#abortSignal },
      async () => {
        if (this.#abortSignal?.aborted) {
          return;
        }

        const start = performance.now();
        try {
          LoggerService.log(
            `Considering files for removal from OPFS that were last accessed more than ${this.#maxAgeDays} days ago`,
          );
          const root = await navigator.storage.getDirectory();
          await this.#removeStaleFiles(root);

          const estimate = await navigator.storage.estimate();
          if (estimate.quota === undefined || estimate.usage === undefined) {
            LoggerService.warn("Storage estimates unavailable");
            return;
          }

          const usagePercentage = estimate.usage / estimate.quota;
          if (usagePercentage > this.#storageThresholdPercentage) {
            LoggerService.log(
              "OPFS utilization is over configured threshold, removing least recently used files",
            );
            await this.#removeUntilUnderThreshold(root, estimate);
          }
        } catch (error) {
          ErrorMonitoringService.captureError(error);
        } finally {
          LoggerService.log(
            `OPFS cleanup took ${performance.now() - start}ms (total time)`,
          );
        }
      },
    );
  }

  /**
   * Record the time this file was last accessed in a sibling file
   * named `${filename}.last_access_time`.
   *
   * The time is only written to the file if the date portion of the timestamp changes.
   *
   * The last access time is used to determine which files are candidates for GC,
   * implemented as an LRU eviction policy in StorageManager::enforceStorageQuota.
   *
   * N.b. (2025-03-07): this method is only expected to work in a web worker.
   * Once Safari implements https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/createWritable
   * we can use that method instead and stop using FileSystemSyncAccessHandler.
   * Safari support appears to be landing soon: https://github.com/WebKit/WebKit/commit/92cb5665c378bef8d73a61a782ad57291520f77d
   */
  async memorializeLastAccessTime(opfsPath: OPFSPath) {
    const now = new Date();

    // Store file in OPFS as
    // <file_id>/<vN>/<filename>.last_access_time
    const lastAccessTimePath = new OPFSPath({
      name: `${opfsPath.name}${StorageManager.LAST_ACCESS_TIME_SUFFIX}`,
      parents: opfsPath.parents,
    });

    const readLastAccessTime = async (): Promise<Date | null> => {
      try {
        const handle = await lastAccessTimePath.getReadOnlySyncFileHandle();
        try {
          const fileSize = handle.getSize();
          if (fileSize === 0) {
            return null;
          }
          const contents = new Uint8Array(
            fileSize / Uint8Array.BYTES_PER_ELEMENT,
          );
          handle.read(contents, { at: 0 });
          const timestamp = new TextDecoder().decode(contents);
          const lastAccessTime = new Date(timestamp);
          if (Number.isNaN(lastAccessTime.valueOf())) {
            return null;
          }
          return lastAccessTime;
        } catch {
          return null;
        } finally {
          handle.close();
        }
      } catch {
        // swallow
        return null;
      }
    };

    const writeLastAccessTime = async (lastAccessTime: Date) => {
      try {
        const handle = await lastAccessTimePath.getWriteableSyncFileHandle();
        try {
          const encoder = new TextEncoder();
          const timestamp = encoder.encode(lastAccessTime.toISOString());
          handle.truncate(0);
          handle.write(timestamp);
          handle.flush();
          opfsPath.lastAccessTime = now;
        } finally {
          handle.close();
        }
      } catch {
        // swallow
      }
    };

    const isSameDay = (date1: Date, date2: Date): boolean => {
      return date1.toDateString() === date2.toDateString();
    };

    if (opfsPath.lastAccessTime === null) {
      const storedTime = await readLastAccessTime();
      if (!storedTime) {
        await writeLastAccessTime(now);
      } else {
        opfsPath.lastAccessTime = storedTime;
      }
      return;
    }

    if (!isSameDay(opfsPath.lastAccessTime, now)) {
      await writeLastAccessTime(now);
    }
  }

  async purgeStorage() {
    await navigator.locks.request(
      StorageManager.GC_LOCK_NAME,
      { signal: this.#abortSignal },
      async () => {
        if (this.#abortSignal?.aborted) {
          return;
        }

        const start = performance.now();
        try {
          LoggerService.log("Purging OPFS storage");
          const opfsRoot = await navigator.storage.getDirectory();
          for await (const name of opfsRoot.keys()) {
            await opfsRoot.removeEntry(name, { recursive: true });
          }
        } catch (error) {
          ErrorMonitoringService.captureError(error);
        } finally {
          LoggerService.log(
            `OPFS purge took ${performance.now() - start}ms (total time)`,
          );
        }
      },
    );
  }

  #getFileSystemDirectoryHandleFromPath = async (
    root: FileSystemDirectoryHandle,
    path: string[],
  ) => {
    let container = root;
    for (const dir of path) {
      const child = await container.getDirectoryHandle(dir, { create: false });
      container = child;
    }
    return container;
  };

  #getParent = async (
    root: FileSystemDirectoryHandle,
    entry: FileSystemHandle,
  ) => {
    if (await root.isSameEntry(entry)) {
      return null;
    }
    const path = await root.resolve(entry);
    if (path === null) {
      return null;
    }
    return this.#getFileSystemDirectoryHandleFromPath(root, path.slice(0, -1));
  };

  #removeContainingDirectory = async (
    root: FileSystemDirectoryHandle,
    file: FileSystemHandle,
  ) => {
    const path = await root.resolve(file); // full path to the file
    if (path === null) {
      return;
    }
    const parentPath = path.slice(0, -1); // parent dirs of file
    const parent = await this.#getFileSystemDirectoryHandleFromPath(
      root,
      parentPath,
    );
    const grandparent = await this.#getParent(root, parent);
    if (grandparent === null) {
      return;
    }
    // Unconditionally remove immediate parent dir of the file and everything in it
    LoggerService.log(`Deleting ${parentPath.join("/")} recursively`);
    await grandparent.removeEntry(parent.name, { recursive: true });

    // Walk back upward, deleting any parent dirs that are now empty
    let dir = grandparent;
    let container = await this.#getParent(root, grandparent);
    while (container !== null) {
      const dirIsEmpty = (await dir.entries().next()).done === true;
      if (dirIsEmpty) {
        LoggerService.log(`${dir.name} is now empty, deleting`);
        await container.removeEntry(dir.name, { recursive: true });
      }
      dir = container;
      container = await this.#getParent(root, container);
    }
  };

  #removeStaleFiles = async (root: FileSystemDirectoryHandle) => {
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - this.#maxAgeDays);
    const shouldRemove = (lastAccessTime: Date) => {
      const diff = dayjs
        .duration(dayjs(cutoffDate).diff(dayjs(lastAccessTime)))
        .asDays();
      return diff >= 1;
    };

    const processedDirs = new Set<string>();

    await this.#traverseDirectory(root, async ({ lastAccessTime, file }) => {
      const path = await root.resolve(file);
      if (path === null) {
        return;
      }
      const container = path[0];
      if (shouldRemove(lastAccessTime) && !processedDirs.has(container)) {
        processedDirs.add(container);
        try {
          await this.#removeContainingDirectory(root, file);
        } catch (err) {
          // Report error and hope it doesn't happen during next GC sweep
          ErrorMonitoringService.captureError(err);
        }
      }
    });
  };

  #removeUntilUnderThreshold = async (
    root: FileSystemDirectoryHandle,
    estimate: StorageEstimate,
  ) => {
    if (!estimate.quota) {
      return;
    }

    const targetUsage = estimate.quota * this.#storageThresholdPercentage;

    const processedDirs = new Set<string>();
    const dirInfos: {
      file: FileSystemFileHandle;
      lastAccess: Date;
    }[] = [];
    await this.#traverseDirectory(root, async ({ lastAccessTime, file }) => {
      const path = await root.resolve(file);
      if (path === null) {
        return;
      }
      const container = path[0];
      if (!processedDirs.has(container)) {
        processedDirs.add(container);
        dirInfos.push({
          lastAccess: lastAccessTime,
          file,
        });
      }
    });

    dirInfos.sort((a, b) => a.lastAccess.getTime() - b.lastAccess.getTime());

    for (const { file } of dirInfos) {
      if (this.#abortSignal?.aborted) {
        return;
      }
      try {
        await this.#removeContainingDirectory(root, file);
      } catch (error) {
        // Report error and hope it doesn't happen during next GC sweep
        ErrorMonitoringService.captureError(error);
        continue;
      }

      const newEstimate = await navigator.storage.estimate();
      if (newEstimate.usage === undefined || newEstimate.usage < targetUsage) {
        break;
      }
    }
  };

  #traverseDirectory = async (
    directory: FileSystemDirectoryHandle,
    handleTimestampFile: (params: {
      lastAccessTime: Date;
      file: FileSystemFileHandle;
    }) => Promise<void>,
  ) => {
    const FALLBACK_FOR_UNPARSEABLE_TIMESTAMPS = new Date(0);

    for await (const [name, entry] of directory.entries()) {
      if (this.#abortSignal?.aborted) {
        return;
      }

      try {
        if (entry.kind === "directory") {
          await this.#traverseDirectory(
            entry as FileSystemDirectoryHandle,
            handleTimestampFile,
          );
        } else if (
          entry.kind === "file" &&
          name.endsWith(StorageManager.LAST_ACCESS_TIME_SUFFIX)
        ) {
          const fileHandle = entry as FileSystemFileHandle;
          const file = await fileHandle.getFile();
          const lastAccessTimeStr = await file.text();
          const lastAccessTime = new Date(lastAccessTimeStr);
          const lastAccessTimeIsValid = !isNaN(lastAccessTime.getTime());

          if (!lastAccessTimeIsValid) {
            ErrorMonitoringService.captureError(
              "Failed to parse last access time while cleaning up OPFS",
              {
                lastAccessTime: lastAccessTimeStr,
              },
            );
          }

          await handleTimestampFile({
            lastAccessTime: lastAccessTimeIsValid
              ? lastAccessTime
              : FALLBACK_FOR_UNPARSEABLE_TIMESTAMPS,
            file: fileHandle,
          });
        }
      } catch (error) {
        LoggerService.error(
          "Error iterating through OPFS during clean up",
          error,
        );
      }
    }
  };
}
