import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FileRecord } from "@/shared/domain/files";
import { useFile } from "@/shared/domain/files/hooks/useFile.ts";
import { LoggerService } from "@/shared/services";
import { nanoSecToSecStr } from "@/shared/time";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

function defaultLinkName(fileRecord: FileRecord) {
  return fileRecord.relative_path;
}

interface FileVizLinkProps {
  fileId: string;
  getLinkName?: (fileRecord: FileRecord) => string;

  /**
   * If provided, the file will be opened at the given time.
   * Otherwise, the file will be opened at the start of the file.
   *
   * `startTime` is in nanoseconds since the UNIX epoch.
   **/
  startTime?: bigint;
  topicName?: string;

  /**
   * Optional text content to display below the link
   **/
  subtitle?: React.ReactNode;
}

function buildFileVizUrl(
  fileId: string,
  params: Partial<{
    filterTerm: string;
    t: string;
  }>,
) {
  // Filter out undefined values from the params object so we don't
  // include undefined values in the URL.
  const searchParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== undefined),
    ),
  );
  const query = searchParams.toString();
  return `/files/${fileId}${query ? `?${query}` : ""}`;
}

export const FileVizLink: React.FC<FileVizLinkProps> = ({
  fileId,
  getLinkName = defaultLinkName,
  startTime,
  topicName,
  subtitle,
}) => {
  const fileQuery = useFile(fileId);

  if (fileQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (fileQuery.isError || fileQuery.data === undefined) {
    LoggerService.error(fileQuery.error?.message, fileQuery.error);
    return (
      <Typography variant="body2" color="error">
        Failed to load event scope
      </Typography>
    );
  }

  const route = buildFileVizUrl(fileId, {
    filterTerm: topicName,
    t: startTime ? nanoSecToSecStr(startTime) : undefined,
  });

  return (
    <>
      <RobotoLinkHoverUnderline to={route} variant="body2">
        {getLinkName(fileQuery.data)}
      </RobotoLinkHoverUnderline>
      {subtitle}
    </>
  );
};
