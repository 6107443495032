import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

export function useDatasetSummary(datasetId: string) {
  const { datasetService } = useDomainServices();

  return useQuery({
    queryKey: ["dataset-summary", datasetId],
    queryFn: ({ signal }) => {
      return datasetService.getSummary(datasetId, {
        abortSignal: signal,
      });
    },
    staleTime: 300 * 1000, // Cache this for 5 minutes, since it's expensive to compute
  });
}
