import { Autocomplete, TextField } from "@mui/material";
import { SetStateAction } from "react";
import * as React from "react";

import {
  causeToFormattedString,
  TriggerEvaluationCause,
} from "@/shared/domain/actions";

interface TriggerCauseSelectorProps {
  causes: TriggerEvaluationCause[];
  setCauses: React.Dispatch<SetStateAction<TriggerEvaluationCause[]>>;
}

export const TriggerCauseSelector: React.FC<TriggerCauseSelectorProps> = ({
  causes,
  setCauses,
}) => {
  return (
    <Autocomplete
      multiple
      disablePortal
      id="trigger-causes"
      options={Object.values(TriggerEvaluationCause)}
      getOptionLabel={(option) => causeToFormattedString(option)}
      sx={{ width: 400 }}
      value={causes}
      size="small"
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder="Select events" />
      )}
      onChange={(_event, newValue) => {
        setCauses(newValue);
      }}
      componentsProps={{
        paper: {
          sx: {
            fontSize: "0.875rem",
          },
        },
      }}
    />
  );
};
