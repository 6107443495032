import { Route, Routes } from "react-router-dom";

import { Invocations } from "@/Actions/components";
import { useAuth } from "@/providers";
import { SystemUserOnly } from "@/shared/components/SystemUserOnly.tsx";
import { UnderConstruction } from "@/shared/components/UnderConstruction.tsx";

import { DatasetDetails } from "./DatasetDetails";
import { DatasetEvents } from "./DatasetEvents";
import { DatasetFileBrowser } from "./DatasetFileBrowser";
import { DatasetPermissions } from "./DatasetPermissions";
import { DatasetAISummary } from "./DatasetSummary";
import { DatasetTopics } from "./DatasetTopics";

interface DatasetTabRoutesProps {
  datasetId: string;
}

export function DatasetTabRoutes({ datasetId }: DatasetTabRoutesProps) {
  const { currentOrganization } = useAuth();

  return (
    <Routes>
      <Route
        path="files/*"
        element={
          <DatasetFileBrowser
            datasetId={datasetId}
            currentOrg={currentOrganization}
          />
        }
      />
      <Route path="topics" element={<DatasetTopics datasetId={datasetId} />} />
      <Route path="events" element={<DatasetEvents datasetId={datasetId} />} />
      <Route
        path="actions"
        element={
          <Invocations datasetId={datasetId} currentOrg={currentOrganization} />
        }
      />
      <Route
        path="details"
        element={
          <DatasetDetails
            datasetId={datasetId}
            currentOrg={currentOrganization}
          />
        }
      />
      <Route
        path="permissions"
        element={<DatasetPermissions datasetId={datasetId} />}
      />
      <Route
        path="summary"
        element={
          <SystemUserOnly fallback={<UnderConstruction />}>
            <DatasetAISummary datasetId={datasetId} />
          </SystemUserOnly>
        }
      />
      <Route
        path="*"
        element={
          <DatasetFileBrowser
            datasetId={datasetId}
            currentOrg={currentOrganization}
          />
        }
      />
    </Routes>
  );
}
