import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

import { AISummary } from "../../ai_summary";

interface RegenerateSummaryOptions {
  systemPrompt?: string;
}

export function useRegenerateDatasetSummary() {
  const { datasetService } = useDomainServices();
  const queryClient = useQueryClient();

  return useMutation<
    AISummary,
    Error,
    { datasetId: string; options?: RegenerateSummaryOptions }
  >({
    mutationFn: ({ datasetId, options }) => {
      return datasetService.generateSummary(datasetId, {
        systemPrompt: options?.systemPrompt,
      });
    },
    onSuccess: (newSummary, { datasetId }) => {
      queryClient.setQueryData(["dataset-summary", datasetId], newSummary);
    },
  });
}
