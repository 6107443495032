import { Alert, AlertTitle, Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { type SelectableFile } from "../FileSelector";
import {
  useEphemeralWorkspaceStateLoading,
  useTopicTreeForFile,
} from "../WorkspaceCtx";

import { Filter } from "./Filter";
import { Topic } from "./Topic";
import styles from "./TopicTree.module.css";

interface TopicTreeProps {
  className?: classNames.Argument;
  file?: SelectableFile;
  renderHeader?: () => React.ReactNode;
}

/**
 * List of Topics associated with a file selected in the FileSelector.
 */
export function TopicTree(props: TopicTreeProps) {
  const { className, file, renderHeader } = props;

  const ephemeralStateLoading = useEphemeralWorkspaceStateLoading();
  const topicTree = useTopicTreeForFile(file?.fileId);

  const [searchParams, setSearchParams] = useSearchParams();
  const sp_filterTerm = searchParams.get("filterTerm");
  const initialFilterTerm = sp_filterTerm ?? "";

  const [filterTerm, setFilterTerm] = React.useState<string>(initialFilterTerm);

  React.useEffect(() => {
    // If the file changes, reset the topic name filter
    setFilterTerm(initialFilterTerm);
  }, [initialFilterTerm, file]);

  // Needed to ensure the no topics alert below doesn't flash on initial load
  // as ephemeralStateLoading is initially set to false
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);

  React.useEffect(() => {
    if (!ephemeralStateLoading) {
      setIsInitialLoading(false);
    }
  }, [ephemeralStateLoading]);

  const filteredTopicTree = topicTree.filter((topic) => {
    if (filterTerm) {
      const filterTermLower = filterTerm.toLowerCase();
      const topicLabelMatches = topic.label
        .toLowerCase()
        .includes(filterTermLower);
      const messagePathMatches = topic.data.message_paths.some((pathObj) =>
        pathObj.message_path.toLowerCase().includes(filterTermLower),
      );
      return topicLabelMatches || messagePathMatches;
    } else {
      return true;
    }
  });

  return (
    <div className={classNames(className, styles.container)}>
      {renderHeader && renderHeader()}

      <Filter
        className={styles.filter}
        handleFilterChange={(event) => {
          const newFilterTerm = event.target.value;
          setFilterTerm(newFilterTerm);
          setSearchParams(
            (params) => {
              if (newFilterTerm) {
                params.set("filterTerm", newFilterTerm);
              } else {
                params.delete("filterTerm");
              }
              return params;
            },
            { replace: true },
          );
        }}
        initialValue={filterTerm}
      />
      <div className={classNames(styles.topicRoot)}>
        <div>
          {ephemeralStateLoading
            ? // Render skeletons when loading
              Array.from({ length: 9 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  height={45}
                  animation="wave"
                  className={styles.skeleton}
                />
              ))
            : // Render topics when not loading
              filteredTopicTree.map((item) => {
                return <Topic key={item.data.topic_id} node={item} />;
              })}
        </div>
        <Alert
          severity="info"
          className={styles.alert}
          sx={{
            display:
              file !== undefined &&
              topicTree.length !== 0 &&
              filteredTopicTree.length === 0 &&
              !ephemeralStateLoading &&
              !isInitialLoading
                ? "flex"
                : "none",
          }}
        >
          No matching topics to show
        </Alert>
        <Alert
          severity="warning"
          className={styles.alert}
          sx={{
            display:
              file !== undefined &&
              topicTree.length === 0 &&
              !ephemeralStateLoading &&
              !isInitialLoading
                ? "flex"
                : "none",
          }}
        >
          <AlertTitle>No topics</AlertTitle>
          There were no topics found for this file, or they haven&apos;t been
          ingested yet.
        </Alert>
      </div>
    </div>
  );
}
