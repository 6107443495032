import { useWorkspaceTopicById } from "@/shared/components/visualization/WorkspaceCtx/hooks.tsx";

import { WorkspaceActionLink } from "./WorkspaceActionLink";

interface WorkspaceTopicLinkProps {
  topicId: string;
  text: string;
  time?: bigint;
}

export const WorkspaceTopicLink: React.FC<WorkspaceTopicLinkProps> = ({
  topicId,
  text,
  time,
}) => {
  const topic = useWorkspaceTopicById(topicId);

  if (!topic) {
    if (time) {
      return <WorkspaceActionLink text={text} time={time} />;
    } else {
      return <span>{text}</span>;
    }
  } else {
    return <WorkspaceActionLink topicId={topicId} text={text} time={time} />;
  }
};
