import * as React from "react";

import { AnalyticsProvider } from "./analytics";
import { AuthProvider } from "./auth";
import { DomainServicesProvider } from "./DomainServices";
import { FileUploadProvider } from "./FileUpload";
import { HttpClientProvider } from "./HttpClient";
import { Localization } from "./LocalizationProvider";
import { NavigationProvider } from "./navigation";
import { QueryProvider } from "./QueryClient";
import { MUIThemeProvider } from "./theme/MUIThemeProvider";
import { VisualizationStateProvider } from "./VisualizationState";

const Providers = ({ children }: React.PropsWithChildren) => {
  return (
    <HttpClientProvider>
      <MUIThemeProvider>
        <QueryProvider>
          <Localization>
            <AuthProvider>
              <DomainServicesProvider>
                <VisualizationStateProvider>
                  <FileUploadProvider>
                    <NavigationProvider>
                      <AnalyticsProvider>{children}</AnalyticsProvider>
                    </NavigationProvider>
                  </FileUploadProvider>
                </VisualizationStateProvider>
              </DomainServicesProvider>
            </AuthProvider>
          </Localization>
        </QueryProvider>
      </MUIThemeProvider>
    </HttpClientProvider>
  );
};

export default Providers;
