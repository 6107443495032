type TypedArray =
  | Int8Array
  | Uint8Array
  | Uint8ClampedArray
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array;
export type IndexedCollection = unknown[] | TypedArray;

export function isTypedArray(obj: unknown): obj is TypedArray {
  return obj instanceof Object.getPrototypeOf(Int8Array);
}

export function isIndexedCollection(obj: unknown): obj is IndexedCollection {
  return Array.isArray(obj) || isTypedArray(obj);
}
