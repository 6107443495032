import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Box, Modal, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

import { useHttpClient } from "@/providers/HttpClient";

import { RobotoButton } from "../RobotoButton";
import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoTooltip } from "../RobotoTooltip";
import { RobotoTypography } from "../RobotoTypography";

import styles from "./AISummaryFeedback.module.css";

interface AISummaryFeedbackProps {
  context?: Record<string, unknown>;
}

export const AISummaryFeedback: React.FC<AISummaryFeedbackProps> = ({
  context,
}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [feedbackType, setFeedbackType] = React.useState<
    "positive" | "negative" | null
  >(null);
  const [message, setMessage] = React.useState("");
  const httpClient = useHttpClient();

  const handleFeedback = (type: "positive" | "negative") => {
    setFeedbackType(type);
    setModalOpen(true);
  };

  const sendFeedback = async () => {
    await httpClient.post("/v1/feedback", {
      body: JSON.stringify({
        message: JSON.stringify({
          feature: "ai_summary",
          context,
          feedbackType,
          message,
        }),
      }),
    });

    setModalOpen(false);
    setMessage("");
    setFeedbackType(null);
  };

  return (
    <>
      <Box className={styles.feedbackContainer}>
        <RobotoTooltip title="Good summary">
          <span>
            <RobotoIconButton
              eventName="SummaryFeedbackPositiveClicked"
              eventProperties={{ context }}
              onClick={() => void handleFeedback("positive")}
              className={styles.iconButton}
              sx={{
                "&:hover": {
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.success.main,
                  },
                },
              }}
            >
              <ThumbUpAltOutlinedIcon className={styles.thumbIcon} />
            </RobotoIconButton>
          </span>
        </RobotoTooltip>

        <RobotoTooltip title="Bad summary">
          <span>
            <RobotoIconButton
              eventName="SummaryFeedbackNegativeClicked"
              eventProperties={{ context }}
              onClick={() => void handleFeedback("negative")}
              className={styles.iconButton}
              sx={{
                "&:hover": {
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.error.main,
                  },
                },
              }}
            >
              <ThumbDownAltOutlinedIcon className={styles.thumbIcon} />
            </RobotoIconButton>
          </span>
        </RobotoTooltip>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          className={styles.modalContent}
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[24],
          }}
        >
          <RobotoTypography
            variant="h6"
            className={styles.modalTitle}
            sx={{ color: theme.palette.text.primary }}
          >
            {feedbackType === "positive"
              ? "What did you like?"
              : "What could be improved?"}
          </RobotoTypography>
          <TextField
            placeholder="Optional feedback message"
            multiline
            rows={4}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles.feedbackInput}
          />
          <Box className={styles.buttonContainer}>
            <RobotoButton
              eventName="SummaryFeedbackCancelClicked"
              eventProperties={{ context }}
              variant="text"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </RobotoButton>
            <RobotoButton
              eventName="SummaryFeedbackSubmitClicked"
              eventProperties={{ context, message, feedbackType }}
              variant="contained"
              onClick={() => void sendFeedback()}
            >
              Submit
            </RobotoButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
