import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { RobotoTypography } from "@/shared/components";
import { EventRecord } from "@/shared/domain/events";
import { TopicRecord } from "@/shared/domain/topics";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";
import { SearchParams } from "@/shared/environment";
import { nanoSecToSecStr } from "@/shared/time";

import {
  SidebarTab,
  SidebarViewType,
  useWorkspaceSidebar,
} from "../../sidebarContext";

import styles from "./Scopes.module.css";

interface AssociatedTopicProps {
  topicId: string;
  event: EventRecord;
}

export function AssociatedTopic({ topicId, event }: AssociatedTopicProps) {
  const topicQuery = useTopicById(topicId);
  const viewTopic = useViewTopic();

  if (topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (topicQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load topic
        </RobotoTypography>
      </span>
    );
  }

  return (
    <span className={styles.container}>
      <AccountTreeOutlinedIcon className={styles.icon} />
      <RobotoTypography
        component={"a"}
        className={classNames(styles.text, styles.link)}
        onClick={() => viewTopic(topicQuery.data, event)}
        title={topicQuery.data.topic_name}
      >
        {topicQuery.data.topic_name}
      </RobotoTypography>
    </span>
  );
}

function useViewTopic() {
  const [_, setSearchParams] = useSearchParams();
  const sidebar = useWorkspaceSidebar();

  return React.useCallback(
    function viewTopic(topic: TopicRecord, event: EventRecord) {
      setSearchParams((oldParams) => {
        const params = new URLSearchParams(oldParams);
        params.set(SearchParams.FILTER_TERM, topic.topic_name);
        params.set(SearchParams.TIMESTAMP, nanoSecToSecStr(event.start_time));
        return params;
      });

      sidebar.openView({
        type: SidebarViewType.TabbedSidebar,
        data: undefined,
      });
      sidebar.setActiveTab(SidebarTab.Topics);
    },
    [setSearchParams, sidebar],
  );
}
