import { Virtualizer } from "@tanstack/react-virtual";
import * as React from "react";

import { isAbortError } from "@/shared/errors";
import { ErrorMonitoringService } from "@/shared/services";

import { Timer } from "../../timer";

import { ScrollDirection } from "./LogCache";
import { LogManager } from "./LogManager";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function* interval(ms: number, signal: AbortSignal) {
  while (!signal.aborted) {
    // Skip polling if tab is not visible
    if (document.visibilityState === "hidden") {
      await new Promise((resolve) =>
        document.addEventListener("visibilitychange", resolve, {
          once: true,
          signal,
        }),
      );
      continue;
    }

    const now = performance.now();
    yield now;
    // best effort attempt at running within deadline
    // takes into account duration of whatever happened during yield
    await delay(ms - (performance.now() - now));
  }
}

export function useLogs(
  logManager: LogManager | null,
  virtualizer: Virtualizer<HTMLDivElement, Element>,
  timer: Timer,
  currentPage: number,
  logsPerPage: number,
) {
  const [_, setLastCacheUpdate] = React.useState(performance.now());

  const loadLogsIfNeeded = React.useCallback(
    function loadLogsIfNeeded(abortSignal?: AbortSignal) {
      if (logManager === null) {
        return Promise.resolve(false);
      }

      const scrollDirection =
        virtualizer.scrollDirection === "backward"
          ? ScrollDirection.Backward
          : ScrollDirection.Forward;

      const { startIndex: virtualStart = 0, endIndex: virtualEnd = 0 } =
        virtualizer.range ?? {};

      // Adjust indices for pagination applied ahead of virtualization
      const start = currentPage * logsPerPage + virtualStart;
      const end = currentPage * logsPerPage + virtualEnd;

      return logManager.loadLogs(
        { start, end },
        scrollDirection,
        timer.playbackRate,
        abortSignal,
      );
    },
    [logManager, virtualizer, timer, currentPage, logsPerPage],
  );

  React.useEffect(() => {
    const abortController = new AbortController();

    void (async function pollForLogs() {
      for await (const ts of interval(50, abortController.signal)) {
        if (abortController.signal.aborted) {
          break;
        }
        try {
          const didRun = await loadLogsIfNeeded(abortController.signal);
          if (didRun && !abortController.signal.aborted) {
            // set state to enqueue a component re-render
            setLastCacheUpdate(ts);
          }
        } catch (err) {
          if (isAbortError(err)) {
            return;
          }
          ErrorMonitoringService.captureError(err);
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [loadLogsIfNeeded]);
}
