import { HttpClient, PaginatedResponse, robotoHeaders } from "../../http";

import { OrgRecord, OrganizationTier, RobotoRegion } from "./OrgRecord";

interface Options {
  abortSignal: AbortSignal;
  searchParams: URLSearchParams;
}

export class OrgService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getAvailableDataRegions(
    options?: Partial<Options>,
  ): Promise<RobotoRegion[]> {
    const requestUrl = this.#httpClient.constructUrl(`v1/integrations/regions`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });
    return await response.json<RobotoRegion[]>();
  }

  public async getOrg(
    orgId: string,
    options?: Partial<Options>,
  ): Promise<OrgRecord> {
    const requestUrl = this.#httpClient.constructUrl(`v1/orgs`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({
        orgId: orgId,
      }),
    });
    return await response.json<OrgRecord>();
  }

  public async getAllOrgs(options?: Partial<Options>): Promise<OrgRecord[]> {
    const orgs = [];
    let pageToken = null;

    while (true) {
      const searchParams = new URLSearchParams();
      if (pageToken) {
        searchParams.append("page_token", pageToken);
      }

      const url = this.#httpClient.constructUrl("v1/orgs/all", searchParams);
      const response = await this.#httpClient.get(url, {
        signal: options?.abortSignal,
      });

      const paginatedResponse =
        await response.json<PaginatedResponse<OrgRecord>>();
      orgs.push(...paginatedResponse.items);
      pageToken = paginatedResponse.next_token;
      if (!pageToken) {
        break;
      }
    }

    return orgs;
  }

  public async updateOrgTier(
    orgId: string,
    tier: OrganizationTier,
    options?: Partial<Options>,
  ): Promise<OrgRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/orgs/id/${orgId}/tier`,
    );
    const response = await this.#httpClient.put(requestUrl, {
      idempotent: true,
      signal: options?.abortSignal,
      body: JSON.stringify({ tier: tier }),
    });
    return await response.json<OrgRecord>();
  }
}
