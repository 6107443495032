import { z, ZodRawShape } from "zod";

import { strSecToBigIntNanosec } from "@/shared/time";

export enum Unit {
  Sec = "sec",
  NanoSec = "nsec",
}

export interface CreateEventForm {
  description?: string;
  end_time: string;
  metadata: Record<string, unknown>;
  name: string;
  start_time: string;
  tags: string[];
  unit: Unit;
}

export const createEventFormSchema = z.object({
  description: z.string(),
  end_time: z
    .string()
    .min(1, "End time is required.")
    .regex(new RegExp("^\\d*\\.?\\d+$"), "End time must be a valid number."),
  metadata: z.record(z.string(), z.string()),
  name: z.string().min(1, "Name is required."),
  start_time: z
    .string()
    .min(1, "Start time is required.")
    .regex(new RegExp("^\\d*\\.?\\d+$"), "Start time must be a valid number."),
  tags: z.array(z.string()),
  unit: z.enum([Unit.Sec, Unit.NanoSec]),
});

type EventFormData = z.infer<typeof createEventFormSchema>;

export function withValidation<T extends ZodRawShape>(schema: z.ZodObject<T>) {
  return schema
    .refine(
      (data) => {
        const formData = data as EventFormData;
        try {
          const start = strSecToBigIntNanosec(formData.start_time);
          const end = strSecToBigIntNanosec(formData.end_time);

          return start < end;
        } catch {
          // If parsing fails, regex should have already caught it
          return false;
        }
      },
      {
        message: "End time must be greater than start time",
        path: ["end_time"],
      },
    )
    .refine(
      (data) => {
        const formData = data as EventFormData;
        if (formData.unit === Unit.NanoSec) {
          if (formData.start_time.includes(".")) {
            return false;
          }
        }
        return true;
      },
      {
        message: "Nanosecond timestamps should not contain decimals",
        path: ["start_time"],
      },
    )
    .refine(
      (data) => {
        const formData = data as EventFormData;
        if (formData.unit === Unit.NanoSec) {
          if (formData.end_time.includes(".")) {
            return false;
          }
        }
        return true;
      },
      {
        message: "Nanosecond timestamps should not contain decimals",
        path: ["end_time"],
      },
    );
}

export function extendCreateEventFormSchema(schema: ZodRawShape) {
  return withValidation(createEventFormSchema.extend(schema));
}
