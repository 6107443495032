import { Box, useTheme } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { UIUploadItem } from "@/providers/FileUpload/types";

interface LinkableRowProps {
  children: React.ReactNode;
  isHovering: boolean;
  item: UIUploadItem;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const LinkableRow: React.FC<LinkableRowProps> = ({
  children,
  isHovering,
  item,
  onMouseEnter,
  onMouseLeave,
}) => {
  const theme = useTheme();

  const relativePath = item.relative_path.split("/").slice(0, -1).join("/");

  let linkAddr = `/datasets/${item.dataset_id}/${relativePath}`;

  if (item.prefix !== undefined) {
    linkAddr = `/datasets/${item.dataset_id}/${item.prefix}/${relativePath}`;
  }

  // remove any trailing slashes
  linkAddr = linkAddr.replace(/\/+$/, "");

  const isLinkDisplayed = isHovering && item.isUploadCompleted;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1.5),
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
        width: "100%",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLinkDisplayed ? (
        <Link
          to={linkAddr}
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </Box>
  );
};
