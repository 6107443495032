import { Autocomplete, TextField, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { type FileRecord } from "@/shared/domain/files";

import { useWorkspaceFiles } from "../../WorkspaceCtx";

import styles from "./FileSelector.module.css";

interface FileSelectorProps {
  className?: classNames.Argument;
  onSelect: (selection: string) => void;
  selection?: string; // file_id
}

export function FileSelector({
  className,
  onSelect,
  selection,
}: FileSelectorProps) {
  const theme = useTheme();

  const files = useWorkspaceFiles();
  const fileIdToFileMap = React.useMemo(() => {
    const map = new Map<string, FileRecord>();
    for (const file of files) {
      map.set(file.file_id, file);
    }
    return map;
  }, [files]);

  const onChange = React.useCallback(
    (_: React.SyntheticEvent, selection: FileRecord | null) => {
      if (selection === null) {
        return;
      }
      onSelect(selection.file_id);
    },
    [onSelect],
  );

  if (files.length < 2) {
    return null;
  }

  const value =
    selection !== undefined ? fileIdToFileMap.get(selection) : undefined;

  return (
    <Autocomplete
      className={classNames(className)}
      disableClearable
      fullWidth
      getOptionKey={(file) => file.file_id}
      getOptionLabel={(file) => file.relative_path ?? file.file_id}
      onChange={onChange}
      options={files}
      renderInput={(params) => <TextField {...params} fullWidth label="File" />}
      renderOption={(props, option) => {
        return (
          <li {...props} className={classNames(props.className, styles.option)}>
            <div className={styles.primary}>{option.relative_path}</div>
            <div
              className={styles.secondary}
              style={{ color: theme.palette.text.secondary }}
            >
              <span className={styles.label}>Dataset: </span>
              <span>{option.association_id}</span>
            </div>
          </li>
        );
      }}
      size="small"
      value={value}
    />
  );
}
