import { fetchAuthSession } from "aws-amplify/auth";
import * as React from "react";

import { Env } from "@/shared/environment";
import { HttpClient, robotoHeaders } from "@/shared/http";

import { HttpClientContext } from "./HttpClientContext";

export function HttpClientProvider({ children }: React.PropsWithChildren) {
  const [resourceOwnerId, setResourceOwnerId] = React.useState<
    string | undefined
  >(undefined);

  const httpClientContext = React.useMemo(() => {
    return {
      httpClient: new HttpClient({
        baseHeaders: robotoHeaders({
          resourceOwnerId,
        }),
        baseUrl: new URL(Env.robotoServiceBaseUrl),
        bearerTokenVendor: async () => {
          const session = await fetchAuthSession();
          return session.tokens?.idToken?.toString();
        },
      }),
      setResourceOwnerId,
    };
  }, [resourceOwnerId]);

  return (
    <HttpClientContext.Provider value={httpClientContext}>
      {children}
    </HttpClientContext.Provider>
  );
}
