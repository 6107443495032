import * as React from "react";
import { createSelector, weakMapMemoize } from "reselect";

import {
  EphemeralWorkspaceState,
  EphemeralWorkspaceStateContext,
} from "@/shared/components/visualization/WorkspaceCtx/EphemeralWorkspaceState";
import { AssociationType } from "@/shared/domain/association.ts";
import { EventRecord } from "@/shared/domain/events";
import { FileRecord } from "@/shared/domain/files";
import { constructTree, TopicNode, TopicRecord } from "@/shared/domain/topics";
import {
  TimeBounds,
  determineTimeBounds,
} from "@/shared/domain/topics/determineTimeBounds";

interface WorkspaceLoadingResponse {
  isLoading: true;
  isError: false;
  error: null;
  data: null;
}

interface WorkspaceErrorResponse {
  isLoading: false;
  isError: true;
  error: Error;
  data: null;
}

interface WorkspaceSuccessResponse<T> {
  isLoading: false;
  isError: false;
  error: null;
  data: T;
}

type WorkspaceResponse<T> =
  | WorkspaceLoadingResponse
  | WorkspaceErrorResponse
  | WorkspaceSuccessResponse<T>;

export const useEphemeralWorkspaceState = (): EphemeralWorkspaceState => {
  return React.useContext(EphemeralWorkspaceStateContext);
};

const selectDatasetIdsForFiles =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [(state) => state, (_, fileIds: string[]) => fileIds],
    (state, fileIds) => {
      return state.files
        .filter((file) => fileIds.includes(file.file_id))
        .map((file) => file.association_id);
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: string[], b: string[]) {
          if (a.length !== b.length) {
            return false;
          }
          for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
              return false;
            }
          }

          return true;
        },
      },
    },
  );

const selectFilesForTopics =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [(state) => state, (_, topicIds: string[]) => topicIds],
    (state, topicIds) => {
      const fileIds: string[] = state.topics
        .filter(
          (topic) =>
            topic.association.association_type === AssociationType.File &&
            topicIds.includes(topic.topic_id),
        )
        .map((topic) => topic.association.association_id);

      return state.files.filter((file) => fileIds.includes(file.file_id));
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: FileRecord[], b: FileRecord[]) {
          if (a.length !== b.length) {
            return false;
          }
          for (let i = 0; i < a.length; i++) {
            if (a[i].file_id !== b[i].file_id) {
              return false;
            }
          }

          return true;
        },
      },
    },
  );

const selectTopicsForFile = createSelector.withTypes<EphemeralWorkspaceState>()(
  [(state) => state, (_, fileId: string) => fileId],
  (state, fileId) => {
    return state.topics.filter(
      (topic) => fileId === topic.association.association_id,
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck(a: TopicRecord[], b: TopicRecord[]) {
        if (a.length !== b.length) {
          return false;
        }
        for (let i = 0; i < a.length; i++) {
          if (a[i].topic_id !== b[i].topic_id) {
            return false;
          }
        }

        return true;
      },
    },
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize,
  },
);

const constructTreeForTopics =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [selectTopicsForFile],
    (topics: TopicRecord[]) => {
      return constructTree(topics);
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: TopicNode[], b: TopicNode[]) {
          if (a.length !== b.length) {
            return false;
          }
          for (let i = 0; i < a.length; i++) {
            if (a[i].id !== b[i].id) {
              return false;
            }
          }

          return true;
        },
      },
      memoize: weakMapMemoize,
      argsMemoize: weakMapMemoize,
    },
  );

const selectEventsForTopics =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [(state) => state, (_, topicIds: string[]) => topicIds],
    (state, topicIds) => {
      const files = selectFilesForTopics(state, topicIds);
      const fileIds = files.map((file) => file.file_id);
      const datasetIds = selectDatasetIdsForFiles(state, fileIds);

      return state.events.filter((event) => {
        return event.associations.some((association) => {
          return (
            datasetIds.includes(association.association_id) ||
            fileIds.includes(association.association_id) ||
            topicIds.includes(association.association_id)
          );
        });
      });
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: EventRecord[], b: EventRecord[]) {
          if (a.length !== b.length) {
            return false;
          }
          for (let i = 0; i < a.length; i++) {
            if (a[i].event_id !== b[i].event_id) {
              return false;
            }
          }

          return true;
        },
      },
      memoize: weakMapMemoize,
      argsMemoize: weakMapMemoize,
    },
  );

const selectEventsForMessagePaths =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [(state) => state.events, (_, messagePathIds: string[]) => messagePathIds],
    (events, messagePathIds) => {
      return events.filter((event) => {
        return event.associations.some((association) => {
          return messagePathIds.includes(association.association_id);
        });
      });
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: EventRecord[], b: EventRecord[]) {
          if (a.length !== b.length) {
            return false;
          }
          for (let i = 0; i < a.length; i++) {
            if (a[i].event_id !== b[i].event_id) {
              return false;
            }
          }

          return true;
        },
      },
      memoize: weakMapMemoize,
      argsMemoize: weakMapMemoize,
    },
  );

const selectTimeBoundsForTopics =
  createSelector.withTypes<EphemeralWorkspaceState>()(
    [
      (state) => state.topics,
      (_, fileIds?: string[]) => {
        return fileIds;
      },
    ],
    (topics, fileIds) => {
      let filteredTopics = topics;
      if (fileIds && fileIds.length > 0) {
        const fileIdsSet = new Set(fileIds);
        filteredTopics = topics.filter((topic) =>
          fileIdsSet.has(topic.association.association_id),
        );
      }
      return determineTimeBounds(filteredTopics);
    },
    {
      memoizeOptions: {
        resultEqualityCheck(a: TimeBounds, b: TimeBounds) {
          return a.earliest === b.earliest && a.latest === b.latest;
        },
      },
      memoize: weakMapMemoize,
      argsMemoize: weakMapMemoize,
    },
  );

export const useTopicTreeForFile = (fileId?: string): TopicNode[] => {
  const state = useEphemeralWorkspaceState();

  if (fileId === undefined) {
    return [];
  }

  return constructTreeForTopics(state, fileId);
};

export const useWorkspaceTimeBounds = (fileIds?: string[]): TimeBounds => {
  const state = useEphemeralWorkspaceState();
  return selectTimeBoundsForTopics(state, fileIds);
};

export const useWorkspaceTopicsForFile = (fileId?: string): TopicRecord[] => {
  const state = useEphemeralWorkspaceState();

  if (fileId === undefined) {
    return [];
  }

  return selectTopicsForFile(state, fileId);
};

export const useWorkspaceEventsForTopics = (
  topicIds: string[],
): EventRecord[] => {
  const state = useEphemeralWorkspaceState();
  return selectEventsForTopics(state, topicIds);
};

export const useWorkspaceFiles = (): FileRecord[] => {
  const state = useEphemeralWorkspaceState();
  return state.files;
};

export const useWorkspaceFilesForTopics = (
  topicIds: string[],
): FileRecord[] => {
  const state = useEphemeralWorkspaceState();
  return selectFilesForTopics(state, topicIds);
};

export const useWorkspaceEventsForTopicsAndMessagePaths = (
  topicIds: string[],
  messagePathIds: string[],
): EventRecord[] => {
  const state = useEphemeralWorkspaceState();
  const topicEvents = selectEventsForTopics(state, topicIds);
  const messagePathEvents = selectEventsForMessagePaths(state, messagePathIds);

  return React.useMemo(() => {
    const uniqueEvents = new Map<string, EventRecord>();
    [...topicEvents, ...messagePathEvents].forEach((event) => {
      uniqueEvents.set(event.event_id, event);
    });

    return Array.from(uniqueEvents.values());
  }, [topicEvents, messagePathEvents]);
};

export const useAllWorkspaceEvents = () => {
  const state = useEphemeralWorkspaceState();
  return state.events;
};

export const useWorkspaceMessagePathById = (messagePathId?: string) => {
  const state = useEphemeralWorkspaceState();

  if (messagePathId === undefined) {
    return undefined;
  }

  return state.messagePaths.find(
    (messagePath) => messagePath.message_path_id === messagePathId,
  );
};

export const useWorkspaceTopicById = (topicId?: string) => {
  const state = useEphemeralWorkspaceState();

  if (topicId === undefined) {
    return undefined;
  }

  return state.topics.find((topic) => topic.topic_id === topicId);
};

export const useWorkspaceTopicByMessagePathId = (messagePathId?: string) => {
  const state = useEphemeralWorkspaceState();
  const messagePath = useWorkspaceMessagePathById(messagePathId);

  if (messagePath === undefined) {
    return undefined;
  }

  return state.topics.find((topic) => topic.topic_id === messagePath.topic_id);
};

export const useEphemeralWorkspaceStateLoading = () => {
  const state = useEphemeralWorkspaceState();
  return state.loading;
};

export const useWorkspaceError = () => {
  const state = useEphemeralWorkspaceState();
  return state.error;
};

export const useWorkspaceEvent = (
  eventId: string,
): WorkspaceResponse<EventRecord> => {
  const state = useEphemeralWorkspaceState();

  if (state.loading) {
    return {
      isLoading: true,
      isError: false,
      error: null,
      data: null,
    };
  }

  const event = state.events.find((event) => event.event_id === eventId);

  if (event === undefined) {
    return {
      isLoading: state.loading,
      isError: true,
      error: new Error(`Event with ID ${eventId} not found`),
      data: null,
    };
  }

  return {
    isLoading: state.loading,
    isError: false,
    error: null,
    data: event,
  };
};

export const useSelectedPanelId = () => {
  const state = useEphemeralWorkspaceState();
  return state.selectedPanelId;
};

export const useWorkspaceTimer = () => {
  const state = useEphemeralWorkspaceState();
  return state.timer;
};
