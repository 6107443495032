import { roboqlFields } from "@/shared/components/RoboqlEditor/roboqlFields";
import { QueryTarget } from "@/shared/domain/query";

export type SearchFieldType = "date" | "metadata" | "tags" | "other";

export interface SearchField {
  name: string;
  type: SearchFieldType;
}

export function comparatorForSearchField(
  searchField: SearchField,
  queryTarget: QueryTarget,
): string[] {
  const field = roboqlFields[queryTarget].find((field) => {
    return field.label === searchField.name;
  });

  if (!field || !field.comparators) {
    return [];
  }

  return field.comparators.sort((a, b) => a.localeCompare(b));
}
