import { useTheme } from "@mui/material";
import * as React from "react";

interface TagsGroupProps extends React.PropsWithChildren {
  style?: React.CSSProperties;
}

export function TagsGroup({ children, style }: TagsGroupProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(1.25),
        ...style,
      }}
    >
      {children}
    </div>
  );
}
