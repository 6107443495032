import { Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  EphemeralWorkspaceState,
  EphemeralWorkspaceStateContext,
} from "@/shared/components/visualization/WorkspaceCtx/EphemeralWorkspaceState";
import { VizConfig } from "@/shared/config";
import { useSharedWorkspace } from "@/shared/domain/workspaces";
import { SearchParams } from "@/shared/environment";
import { useResizable } from "@/shared/hooks";
import {
  actions,
  useFiles,
  useVizDispatch,
} from "@/shared/state/visualization";
import { nanoSecToSecStr, strSecToBigIntNanosec } from "@/shared/time";

import { DndContainer } from "../DndContainer";
import { Header } from "../Header";
import { PanelBoard } from "../PanelBoard";
import { Sidebar } from "../Sidebar";
import { Timeline } from "../Timeline";
import { Timer } from "../timer";
import { useWorkspaceContextForFiles } from "../WorkspaceCtx";

import { type Notification, Notifications } from "./Notifications";
import { useEnforceStorageQuota } from "./useEnforceStorageQuota";
import styles from "./Workspace.module.css";
import { WorkspaceComment } from "./WorkspaceComment";

interface WorkspaceProps {
  headerOverride?: React.ReactNode;
}

export function Workspace({ headerOverride }: WorkspaceProps) {
  const dispatch = useVizDispatch();

  useEnforceStorageQuota();

  const files = useFiles();
  const fileIds = React.useMemo(
    () => files.map((file) => file.fileId),
    [files],
  );

  const { workspaceId } = useParams();
  const sharedWorkspaceQuery = useSharedWorkspace(workspaceId);

  const [timeSpans, setTimeSpans] = React.useState<[bigint, bigint][]>([]);
  const workspaceContext = useWorkspaceContextForFiles(fileIds, timeSpans);

  React.useEffect(() => {
    const fileToTimeBounds = workspaceContext.topics.reduce(
      (accum, topic) => {
        if (topic.start_time === null || topic.end_time === null) {
          return accum;
        }

        if (!(topic.association.association_id in accum)) {
          accum[topic.association.association_id] = [
            topic.start_time,
            topic.end_time,
          ];
        }

        const currAccum = accum[topic.association.association_id];

        if (currAccum[0] > topic.start_time) {
          currAccum[0] = topic.start_time;
        }

        if (currAccum[1] < topic.end_time) {
          currAccum[1] = topic.end_time;
        }

        return accum;
      },
      {} as { [key: string]: [bigint, bigint] },
    );
    const spans: [bigint, bigint][] = Object.values(fileToTimeBounds);

    const mergedAndSorted = Timer.sortAndMergeSpans(spans);

    setTimeSpans(mergedAndSorted);
    workspaceContext.timer.updateTimeSpans(mergedAndSorted);
  }, [workspaceContext.topics, workspaceContext.timer]);

  useSyncTimerAndSearchParams(workspaceContext);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const resizeHandleRef = React.useRef<HTMLElement>(null);
  const { isDragging, separatorProps, layout1Size, layout2Size } = useResizable(
    {
      axis: "x",
      containerRef,
      resizeHandleRef,

      // Sidebar will initially take 20% of available space
      initialLayout1Size: 0.2,

      // Panelboard will initially take 80% of available space
      initialLayout2Size: 0.8,

      // Neither the sidebar nor panel board's width will
      // shrink lower than 5% or expand greater than 95%.
      minSize: 0.05,
      maxSize: 0.95,
    },
  );

  const [workspaceNotifications, setWorkspaceNotifications] = React.useState<
    Notification[]
  >([]);

  // Tell the root panels when we're changing the workspace layout
  React.useEffect(() => {
    dispatch(actions.setAllLayoutsResizing(isDragging));
  }, [isDragging, dispatch]);

  React.useEffect(() => {
    if (sharedWorkspaceQuery.isSuccess && sharedWorkspaceQuery.data) {
      const config = sharedWorkspaceQuery.data.config;
      const isValid = VizConfig.isValid(config);
      if (isValid) {
        const newVizConfig = VizConfig.from_obj(config);
        dispatch(actions.replaceState(newVizConfig.toObject()));
      } else {
        // The workspace record has an invalid config.
        // Likely the result of a non-backward compatible viz schema change.
        const notification: Notification = {
          id: "viz-config-invalid",
          message: [
            `The workspace provided via the URL ('${sharedWorkspaceQuery.data.workspace_id}') cannot be reconstituted.`,
            "This is likely due to an update to how we internally model these workspaces.",
            "We're working to support better backwards compatibility.",
          ].join(" "),
          severity: "error",
        };
        setWorkspaceNotifications((prevNotifications) => {
          if (prevNotifications.find((n) => n.id === notification.id)) {
            return prevNotifications;
          }
          return [...prevNotifications, notification];
        });
      }
    }
  }, [dispatch, sharedWorkspaceQuery.data, sharedWorkspaceQuery.isSuccess]);

  return (
    <EphemeralWorkspaceStateContext.Provider value={workspaceContext}>
      <div className={styles.workspaceContainer}>
        {/* Default to a pre-defined header if the workspace does not have one set. */}
        {headerOverride ?? <Header />}
        <Notifications
          onDismiss={(notification) => {
            setWorkspaceNotifications((prevNotifications) =>
              prevNotifications.filter((n) => n.id !== notification.id),
            );
          }}
          notifications={workspaceNotifications}
        />
        <DndContainer>
          <div className={styles.primaryContent} ref={containerRef}>
            <Sidebar
              style={{ flexBasis: `${layout1Size * 100}%` }}
              separatorProps={separatorProps}
              isDragging={isDragging}
              resizeHandleRef={resizeHandleRef}
            />

            {sharedWorkspaceQuery.isLoading ? (
              <Skeleton className={styles.loadingSkeleton} variant="rounded" />
            ) : (
              <PanelBoard
                className={classNames(styles.panelBoard, {
                  [styles.resizing]: isDragging,
                })}
                errorMsg={
                  sharedWorkspaceQuery.error?.message ||
                  workspaceContext.error?.message
                }
                style={{ flexBasis: `${layout2Size * 100}%` }}
              />
            )}
          </div>
        </DndContainer>
        <Timeline />
      </div>

      <WorkspaceComment
        fileIds={fileIds}
        files={files}
        workspaceContext={workspaceContext}
      />
    </EphemeralWorkspaceStateContext.Provider>
  );
}

/**
 * Synchronizes the given {@param timer} to the URL query string.
 *
 * This works like a database and a cache -- the query parameters are the source of truth
 * for what time to jump the workspace to, and the timer contains a cache where the next
 * state for the time value is stored.
 *
 * When the timer stops or seeks, it commits the working cache to the URL search params.
 *
 * If the value from the URL search params changes, it invalidates the working cache.
 */
function useSyncTimerAndSearchParams({ timer }: EphemeralWorkspaceState) {
  const expectedTimeSearchParam = React.useRef<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(
    function onSyncTimerAndSearchParams() {
      function syncTimerToParams() {
        // Sync the query params to the timer
        const timestamp = searchParams.get(SearchParams.TIMESTAMP) ?? undefined;
        // Only sync timer state after the timer has data.
        if (timer.duration <= 0) {
          return;
        }

        // Sync the timer to query params when and only when the
        // query params have been updated independently of the timer.
        if (
          timestamp !== undefined &&
          // Don't apply this update if it has already been applied.
          timestamp !== expectedTimeSearchParam.current
        ) {
          timer.seekTo(strSecToBigIntNanosec(timestamp));
          expectedTimeSearchParam.current = timestamp;
        }
      }

      function syncParamsToTimer() {
        // Sync the query params to the timer
        const timestamp = nanoSecToSecStr(timer.currentTime);
        // Only sync timer state after the timer has data.
        if (timer.duration <= 0) {
          return;
        }

        // Short-circuit the feedback loop from the timer to the query parameters.
        expectedTimeSearchParam.current = timestamp;
        // Avoid an unnecessary params update if possible.
        if (searchParams.get(SearchParams.TIMESTAMP) !== timestamp) {
          setSearchParams(
            (oldParams) => {
              const params = new URLSearchParams(oldParams);
              params.set(SearchParams.TIMESTAMP, timestamp);
              return params;
            },
            { preventScrollReset: true, replace: true },
          );
        }
      }
      // If the query params or timer changed, then sync them together now.
      syncTimerToParams();

      // Sync again when the timer's internal time span state changes.
      const abortController = new AbortController();
      const listenerOptions = { signal: abortController.signal };
      // timeSpanUpdate means that the timer's duration and start time may have both changed.
      timer.addListener("timeSpanUpdate", syncTimerToParams, listenerOptions);

      // When the timer stops on a specific time, sync the query params to the timer.
      timer.addListener("stop", syncParamsToTimer, listenerOptions);
      timer.addListener("seek", syncParamsToTimer, listenerOptions);
      return function disposeOnSyncTimer() {
        abortController.abort();
      };
    },
    [timer, setSearchParams, searchParams],
  );
}
