import { EventSource } from "@/shared/components/events/scope";
import { EventRecord } from "@/shared/domain/events";

import { CreateWorkspaceEventFormDefaults } from "../CreateWorkspaceEventForm/makeCreateEventRequest";

export enum SidebarViewType {
  CreateEvent = "CreateEvent",
  EventDetails = "EventDetails",
  PanelSettings = "PanelSettings",
  TabbedSidebar = "TabbedSidebar",
  None = "None",
}

export enum SidebarTab {
  Topics = "Topics",
  Events = "Events",
  AISummary = "AISummary",
}

export interface SidebarView<D = undefined> {
  type: SidebarViewType;
  data: D;
}

export interface CreateEventSidebarProps {
  defaultFields?: CreateWorkspaceEventFormDefaults;
  onSuccess: (event: EventRecord) => void;
  onCancel: () => void;
  sourceOptions: Array<EventSource>;
}

export interface EventDetailsSidebarProps {
  eventId: string;
}

export function isSidebarClosed(
  view: SidebarView<unknown>,
): view is SidebarView {
  return view.type === SidebarViewType.None;
}

export function isEventDetailsView(
  view: SidebarView<unknown>,
): view is SidebarView<EventDetailsSidebarProps> {
  return view.type === SidebarViewType.EventDetails;
}

export function isTabbedSidebarView(
  view: SidebarView<unknown>,
): view is SidebarView {
  return view.type === SidebarViewType.TabbedSidebar;
}

export function isPanelSettingsView(
  view: SidebarView<unknown>,
): view is SidebarView {
  return view.type === SidebarViewType.PanelSettings;
}

export function isCreateEventView(
  view: SidebarView<unknown> | null,
): view is SidebarView<CreateEventSidebarProps> {
  return view?.type === SidebarViewType.CreateEvent;
}
