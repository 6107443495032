import AutorenewIcon from "@mui/icons-material/Autorenew";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import * as React from "react";

import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoTooltip } from "../RobotoTooltip";
import { RobotoTypography } from "../RobotoTypography";
import { SystemUserOnly } from "../SystemUserOnly";

import { AISummaryFeedback } from "./AISummaryFeedback";
import styles from "./AISummaryMetadataBar.module.css";

interface AISummaryMetadataBarProps {
  created: string;
  onRegenerateClick: () => void;
  context: Record<string, unknown>;
  promptOverrideText?: string | null;
  onPromptOverrideChange?: (text: string | null) => void;
  isRegenerating?: boolean;
}

export const AISummaryMetadataBar: React.FC<AISummaryMetadataBarProps> = ({
  created,
  onRegenerateClick,
  context,
  promptOverrideText,
  onPromptOverrideChange,
  isRegenerating,
}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState(false);

  const defaultedPromptOverrideText = promptOverrideText || "";
  const defaultedOnPromptOverrideChange = onPromptOverrideChange || (() => {});

  const createdDate = DateTime.fromJSDate(new Date(created));
  const diffInSeconds = Math.abs(createdDate.diffNow("seconds").seconds);

  const relativeTime =
    diffInSeconds < 30 ? "a few moments ago" : createdDate.toRelative();

  return (
    <Box
      className={styles.metadataBar}
      sx={{ color: theme.palette.text.secondary }}
    >
      <RobotoTypography
        variant="body2"
        color="inherit"
        className={styles.createdText}
      >
        Generated {relativeTime}
      </RobotoTypography>

      <Box className={styles.actions}>
        <RobotoTooltip title="Regenerate">
          <span>
            <RobotoIconButton
              onClick={() => void onRegenerateClick()}
              eventName="RegenerateSummaryClicked"
              eventProperties={context}
              disabled={isRegenerating}
              className={isRegenerating ? styles.spinning : styles.iconButton}
            >
              <AutorenewIcon className={styles.renewIcon} />
            </RobotoIconButton>
          </span>
        </RobotoTooltip>
        <AISummaryFeedback context={context} />
        {onPromptOverrideChange && (
          <SystemUserOnly>
            <IconButton
              onClick={() => setModalOpen(true)}
              className={styles.iconButton}
            >
              <TuneIcon className={styles.tuneIcon} />
            </IconButton>
          </SystemUserOnly>
        )}
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          className={styles.modalContent}
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[24],
          }}
        >
          <TextField
            fullWidth
            multiline
            rows={20}
            label="System Prompt Override"
            value={defaultedPromptOverrideText || ""}
            onChange={(e) =>
              defaultedOnPromptOverrideChange(e.target.value || null)
            }
            placeholder="Enter custom system prompt or clear to use default"
          />
          <Box className={styles.modalButtons}>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
