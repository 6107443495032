import { clamp } from "@/shared/math";

import { Log } from "./log";

export interface IndexRange {
  start: number;
  end: number;
}

interface Opts {
  /**
   * Count of logs to fetch at once.
   *
   * Ideally, batchSize would be 1:1 with size of MCAP chunks. This would be the most efficient read strategy,
   * but we should not expect chunks to be consistently sized between or even within MCAP files.
   * Some MCAP chunks may contain 5 messages, others may contain 5000.
   * It depends on how they were written and the types of messages they contain.
   * Also, consider that data transfered between JS realms that is not a Transferable pays a structured clone cost,
   * so for a given average message data size, there's some upper bound on how many messages it makes sense
   * to attempt to copy between realms at once:
   *  > serialization blocks the sending realm, while deserialization blocks the receiving realm
   *  Ref: https://surma.dev/things/is-postmessage-slow/
   */
  batchSize: number;
  /** Max number of logs to cache */
  maxSize: number;
  /**
   * The time in milliseconds after which data is considered stale.
   * If set to Infinity, the data will never be considered stale.
   */
  staleTime: number;
}

export enum ScrollDirection {
  Forward = "forward", // scrolling forward in time
  Backward = "backward", // scrolling backward in time
}

export class LogCache {
  #batchSize: number;
  #logIndexToTimeAddedToCacheMap = new Map<number, DOMHighResTimeStamp>();
  #logs: (Log | undefined)[] = [];
  #maxCacheSize: number;
  #maxIndexWithData = Number.NEGATIVE_INFINITY;
  #minIndexWithData = Number.POSITIVE_INFINITY;
  #staleTime: number;
  #timestamps: BigUint64Array = new BigUint64Array(0);

  constructor(opts?: Partial<Opts>) {
    this.#batchSize = opts?.batchSize ?? 300;
    this.#maxCacheSize = opts?.maxSize ?? 2_000;
    this.#staleTime = opts?.staleTime ?? Number.POSITIVE_INFINITY;
  }

  public get logCount() {
    return this.#timestamps.length;
  }

  public get isTimestampIndexLoaded() {
    return this.#timestamps.length > 0;
  }

  public addLogsToCache(logs: Log[]) {
    const now = performance.now() + performance.timeOrigin;
    for (const log of logs) {
      const index = this.timestampToIndex(log.time);
      this.#logs[index] = log;
      this.#logIndexToTimeAddedToCacheMap.set(index, now);
      this.#minIndexWithData = Math.min(this.#minIndexWithData, index);
      this.#maxIndexWithData = Math.max(this.#maxIndexWithData, index);
    }
  }

  public dispose() {
    this.#logs.length = 0;
    this.#minIndexWithData = Number.POSITIVE_INFINITY;
    this.#maxIndexWithData = Number.NEGATIVE_INFINITY;
    this.#timestamps = new BigUint64Array(0);
    this.#logIndexToTimeAddedToCacheMap.clear();
  }

  /**
   * Maps a requested window of indices to a fixed-size bucket to ensure consistent data fetching.
   *
   * Each bucket has size #batchSize. Buckets are aligned to multiples of batchSize (e.g., 0-249, 250-499).
   * The method determines which bucket to return based on:
   * 1. The midpoint of the requested range
   * 2. The scroll direction
   * 3. The position relative to current bucket's midpoint
   *
   * When scrolling forward:
   * - Returns next bucket if midpoint >= current bucket's midpoint
   * - Otherwise returns current bucket
   *
   * When scrolling backward:
   * - Returns previous bucket if midpoint <= current bucket's midpoint
   * - Otherwise returns current bucket
   */
  public bucketize(
    range: IndexRange,
    scrollDirection = ScrollDirection.Forward,
    playbackRate = 1,
  ): IndexRange {
    const { start, end } = range;
    // Make batch size a function of the speed with which we need to render data.
    // The constants are ultimately arbitrary. But see docstring on `Opts.batchSize` for more details.
    const MIN_RATE = 1;
    const MAX_RATE = 2.5;
    const size = this.#batchSize * clamp(playbackRate, MIN_RATE, MAX_RATE);

    const midpoint = Math.floor((start + end) / 2);
    const currentBucketNumber = Math.floor(midpoint / size);

    // If we're in the first half of current bucket and scrolling backward,
    // or in the second half and scrolling forward, move to adjacent bucket
    const bucketMidpoint = currentBucketNumber * size + size / 2;
    const shouldMoveToAdjacentBucket =
      scrollDirection === ScrollDirection.Forward
        ? midpoint >= bucketMidpoint
        : midpoint <= bucketMidpoint;

    const targetBucketNumber = shouldMoveToAdjacentBucket
      ? currentBucketNumber +
        (scrollDirection === ScrollDirection.Forward ? 1 : -1)
      : currentBucketNumber;

    const bucketStart = targetBucketNumber * size;
    const bucketEnd = bucketStart + size - 1;

    // TODO(gabe): This is working better moving forward than backward.
    const minIndex = Math.max(0, Math.min(start, bucketStart));
    const maxIndex = Math.min(
      this.#timestamps.length - 1,
      Math.max(end, bucketEnd),
    );

    return { start: minIndex, end: maxIndex };
  }

  public getIndicesForMissingData(
    range: IndexRange,
    scrollDirection = ScrollDirection.Forward,
    playbackRate = 1,
  ): {
    missing: IndexRange[];
    isInputRangeInMissing: boolean;
  } {
    const { start, end } = this.bucketize(range, scrollDirection, playbackRate);

    const missing: IndexRange[] = [];
    let rangeStart: number | null = null;
    let isInputRangeInMissing = false;

    for (let i = start; i <= end; i++) {
      // if the log isn't yet cached or is stale (stale-while-revalidate)
      const isMissing =
        this.#logs[i] === undefined || this.#isCacheStaleAtIndex(i);

      // Check if this index falls within the original unbucketized range
      // This would mean the list virtualizer doesn't have the data it needs to render
      if (isMissing && i >= range.start && i <= range.end) {
        isInputRangeInMissing = true;
      }

      if (isMissing) {
        if (rangeStart === null) {
          rangeStart = i;
        }
      } else if (rangeStart !== null) {
        missing.push({ start: rangeStart, end: i - 1 });
        rangeStart = null;
      }
    }

    if (rangeStart !== null && rangeStart <= end) {
      missing.push({ start: rangeStart, end: end });
    }

    return {
      missing,
      isInputRangeInMissing,
    };
  }

  public indexToLog(index: number): Log | undefined {
    return this.#logs[index];
  }

  public indexToTimestamp(index: number): bigint {
    return this.#timestamps[index];
  }

  public markCacheStale() {
    this.#logIndexToTimeAddedToCacheMap.clear();
  }

  public setTimestampIndex(index: BigUint64Array) {
    if (index.length !== this.#maxCacheSize) {
      this.#logs.length = 0;
      this.#logs = Array.from({ length: this.#maxCacheSize });
    }
    this.#timestamps = index;
  }

  public timestampToIndex(time: bigint): number {
    let low = 0;
    let high = this.#timestamps.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const logTime = this.#timestamps[mid];
      if (logTime === time) {
        return mid;
      } else if (logTime < time) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    // Use the index of the closest log before the given time otherwise
    return Math.max(low - 1, 0);
  }

  public trimCache(currentWindow: IndexRange) {
    const { start, end } = currentWindow;
    const windowSize = end - start + 1;

    // If window size exceeds max cache size, only keep the current window
    if (windowSize >= this.#maxCacheSize) {
      for (let i = this.#minIndexWithData; i < start; i++) {
        this.#clearCacheAtIndex(i);
      }
      for (let i = this.#maxIndexWithData; i > end; i--) {
        this.#clearCacheAtIndex(i);
      }
      this.#minIndexWithData = start;
      this.#maxIndexWithData = end;
      return;
    }

    // Calculate how much padding we can add on each side
    const remainingSpace = this.#maxCacheSize - windowSize;
    const padding = Math.floor(remainingSpace / 2);

    const minIndex = Math.max(0, start - padding);
    const maxIndex = Math.min(this.#logs.length - 1, end + padding);

    // Clear data outside the padded window
    for (let i = this.#minIndexWithData; i < minIndex; i++) {
      this.#clearCacheAtIndex(i);
    }
    for (let i = this.#maxIndexWithData; i > maxIndex; i--) {
      this.#clearCacheAtIndex(i);
    }

    this.#minIndexWithData = minIndex;
    this.#maxIndexWithData = maxIndex;
  }

  #clearCacheAtIndex(index: number) {
    this.#logs[index] = undefined;
    this.#logIndexToTimeAddedToCacheMap.delete(index);
  }

  #isCacheStaleAtIndex(index: number): boolean {
    const cachedAt = this.#logIndexToTimeAddedToCacheMap.get(index) ?? -1;
    if (cachedAt === -1) {
      return true;
    }
    const now = performance.now() + performance.timeOrigin;
    return now - cachedAt > this.#staleTime;
  }
}
