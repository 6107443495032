import { useWorkspaceFiles } from "@/shared/components/visualization/WorkspaceCtx";

import { WorkspaceActionLink } from "./WorkspaceActionLink";

interface WorkspaceFileLinkProps {
  fileId: string;
  text: string;
  time?: bigint;
}

export const WorkspaceFileLink: React.FC<WorkspaceFileLinkProps> = ({
  fileId,
  text,
  time,
}) => {
  const files = useWorkspaceFiles();
  const hasFile = files.filter((file) => file.file_id === fileId).length === 1;

  if (!time || !hasFile) {
    return <span>{text}</span>;
  } else {
    return <WorkspaceActionLink text={text} time={time} />;
  }
};
