import { strSecToBigIntNanosec } from "@/shared/time";

// timestamp.ts
export type Timestamp = bigint & { __brand: "Timestamp" };

// Serialize: from Timestamp to string
export const serializeTimestamp = (ts: Timestamp): string => {
  return `timestamp:${ts.toString()}`;
};

// Deserialize: from string to Timestamp
export const deserializeTimestamp = (str: string): Timestamp => {
  if (!str.startsWith("timestamp:")) {
    throw new Error("Invalid timestamp format");
  }

  const raw = str.replace("timestamp:", "");

  if (raw.includes(".")) {
    const ts = strSecToBigIntNanosec(raw);
    return ts as Timestamp;
  }

  const ts = BigInt(raw);
  return ts as Timestamp;
};

export const createTimestamp = (n: bigint): Timestamp => {
  return n as Timestamp;
};

// Formats a timestamp as a string for display in the UI.
// Rendered by RobotoMarkdown and also used in the CommentsBox.
export const formatTimestampToMarkdownString = (
  timestamp: Timestamp,
  displayText?: string,
): string => {
  return `[${displayText ?? timestamp.toString()}](timestamp:${timestamp})`;
};
