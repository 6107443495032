import {
  Alert,
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useAuth, useNavigation } from "@/providers";
import {
  ActionParameter,
  ActionRecord,
  ComputeRequirements,
  ContainerParameters,
} from "@/shared/domain/actions";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { OrganizationTier, OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, actionEndpoint, triggerEndpoint } from "@/types";

import EditableActionParams from "../action/EditableActionParams";
import EditableComputeReqs from "../action/EditableComputeReqs";
import EditableContainerParams from "../action/EditableContainerParams";
import { EditableTimeout } from "../action/EditableTimeout";

import DeleteTriggerButton from "./DeleteTriggerButton";
import { EditableActionSelector } from "./EditableActionSelector";
import { EditableForEachSelector } from "./EditableForEachSelector";
import { EditableInputData } from "./EditableInputData";
import { EditableTriggerCauses } from "./EditableTriggerCauses";
import { EditableTriggerCondition } from "./EditableTriggerCondition/EditableTriggerCondition";
import { TriggerSwitch } from "./TriggerSwitch";

interface TriggerDetailsProps {
  org: OrgRecord;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
  trigger: TriggerRecord;
}

export const TriggerDetails: React.FC<TriggerDetailsProps> = ({
  onUpdate,
  org,
  trigger,
}) => {
  const [errorText, setErrorText] = React.useState<string | null>(null);
  const [parameters, setParameters] = React.useState<
    ActionParameter[] | undefined
  >(undefined);
  const { currentOrganization } = useAuth();
  const { goto } = useNavigation();

  const theme = useTheme();

  const { initiateRequest: getAction, updateCache } =
    useLazyAPICall<APIResponse<ActionRecord>>();

  React.useEffect(() => {
    async function fetchAction() {
      if (!trigger.action) {
        updateCache(null);
        return;
      }

      const queryParams = trigger.action.digest
        ? new URLSearchParams({ digest: trigger.action.digest })
        : undefined;

      const headers = trigger.action.owner
        ? { "X-Roboto-Resource-Owner-Id": trigger.action.owner }
        : undefined;

      const { data, error } = await getAction({
        endpoint: actionEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id,
        pathParams: { name: trigger.action.name },
        queryParams,
        headers,
      });

      if (!error) {
        setParameters(data?.data.parameters);
      }
    }

    void fetchAction();
  }, [trigger, currentOrganization?.org_id, getAction, updateCache]);

  return (
    <Box>
      {errorText && (
        <Box sx={{ mt: theme.spacing(1) }}>
          <Alert severity="error">{errorText}</Alert>
        </Box>
      )}
      <List sx={{ width: "48%" }}>
        <ListItem>
          <ListItemText primary={"Name"} secondary={trigger.name} />
        </ListItem>
        <EditableTriggerCauses
          title="Trigger On"
          value={trigger.causes}
          triggerName={trigger.name}
          currentOrg={org}
          setErrorText={setErrorText}
          onUpdate={onUpdate}
        />
        <EditableActionSelector
          title="Action"
          trigger={trigger}
          setErrorText={setErrorText}
          onUpdate={onUpdate}
        />
        <EditableInputData
          title="Required Inputs"
          field="required_inputs"
          value={trigger.required_inputs || []}
          triggerName={trigger.name}
          setErrorText={setErrorText}
          minRequired={1}
          onUpdate={onUpdate}
        />
        {org?.tier === OrganizationTier.Premium ? (
          <EditableTimeout<TriggerRecord>
            title="Timeout"
            field="timeout"
            value={trigger.timeout}
            endpoint={triggerEndpoint}
            name={trigger.name}
            currentOrg={org}
            setErrorText={setErrorText}
            renderMarkdown={false}
            onUpdate={onUpdate}
          />
        ) : (
          <ListItem>
            <ListItemText
              primary={"Timeout"}
              secondaryTypographyProps={{
                component: "span",
                sx: {
                  fontSize: "0.8rem",
                },
              }}
              secondary={
                trigger.timeout ? <pre>{trigger.timeout} minutes</pre> : "None"
              }
            />
          </ListItem>
        )}

        <EditableInputData
          title="Additional Inputs"
          field="additional_inputs"
          value={trigger.additional_inputs || []}
          triggerName={trigger.name}
          setErrorText={setErrorText}
          minRequired={0}
          onUpdate={onUpdate}
        />

        <EditableActionParams
          title="Parameters"
          value={parameters || []}
          paramValues={trigger.parameter_values}
          pathParams={{ name: trigger.name }}
          endpoint={triggerEndpoint}
          currentOrg={org}
          setErrorText={setErrorText}
          onUpdate={onUpdate}
        />

        <EditableTriggerCondition
          title={"Conditions"}
          value={trigger.condition}
          triggerName={trigger.name}
          setErrorText={setErrorText}
          onUpdate={onUpdate}
        />

        <EditableForEachSelector
          title={"Invoke For Each"}
          field={"for_each"}
          value={trigger.for_each}
          endpoint={triggerEndpoint}
          triggerName={trigger.name}
          currentOrg={org}
          setErrorText={setErrorText}
          onUpdate={onUpdate}
        />

        <EditableContainerParams
          title="Container Parameter Overrides"
          field="container_parameter_overrides"
          value={
            trigger.container_parameter_overrides || ({} as ContainerParameters)
          }
          pathParams={{ name: trigger.name }}
          endpoint={triggerEndpoint}
          currentOrg={org}
          setErrorText={setErrorText}
          overrides={true}
          onUpdate={onUpdate}
        />

        <EditableComputeReqs
          title="Compute Requirement Overrides"
          field="compute_requirement_overrides"
          value={
            trigger.compute_requirement_overrides || ({} as ComputeRequirements)
          }
          pathParams={{ name: trigger.name }}
          endpoint={triggerEndpoint}
          currentOrg={org}
          setErrorText={setErrorText}
          overrides={true}
          onUpdate={onUpdate}
        />

        <ListItem>
          <ListItemText
            primary={"Operations"}
            secondaryTypographyProps={{
              component: "span",
            }}
            secondary={
              <Box sx={{ mt: theme.spacing(1) }}>
                <Box sx={{ mb: theme.spacing(2), ml: theme.spacing(0.5) }}>
                  <FormControlLabel
                    control={
                      <TriggerSwitch
                        active={trigger.enabled}
                        triggerName={trigger.name}
                        setErrorText={setErrorText}
                        onUpdate={onUpdate}
                      />
                    }
                    label={
                      <Typography variant="body2">Enable Trigger</Typography>
                    }
                  />
                </Box>
                <DeleteTriggerButton
                  trigger={trigger}
                  onDelete={() => goto.actions({ tab: 1 })}
                  org={org}
                />
              </Box>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};
