import { useContext } from "react";

import type { HttpClient } from "@/shared/http";

import { HttpClientContext } from "./HttpClientContext";

export const useHttpClient = (): HttpClient => {
  // other fields on the client context are filtered out here because they're only used for setup.
  return useContext(HttpClientContext).httpClient;
};
