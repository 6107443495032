import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import { useCurrentOrgId } from "@/providers/auth/hooks";
import { useDomainServices } from "@/providers/DomainServices";
import {
  CreateEventForm,
  createEventFormSchema,
  EventNameInput,
} from "@/shared/components/events";
import { Unit, withValidation } from "@/shared/components/events/schema";
import { MetadataForm, MetadataView } from "@/shared/components/Metadata";
import { RobotoButton } from "@/shared/components/RobotoButton";
import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "@/shared/components/tags";
import { AssociationRecord } from "@/shared/domain/association";
import { EventRecord } from "@/shared/domain/events";
import { strSecToBigIntNanosec } from "@/shared/time";

interface CreateDatasetEventFormProps {
  onCancel: () => void;
  onSuccess: (event: EventRecord) => void;
  associationRecord: AssociationRecord;
}

const defaults = {
  description: "",
  metadata: {},
  tags: [],
  unit: Unit.Sec,
};

function convertTimeToNanoSec(time: string, unit: Unit): bigint {
  switch (unit) {
    case Unit.Sec:
      return strSecToBigIntNanosec(time);
    case Unit.NanoSec:
      return BigInt(time);
  }
}

export const CreateDatasetEventForm = ({
  associationRecord,
  onSuccess,
  onCancel,
}: CreateDatasetEventFormProps) => {
  const { events } = useDomainServices();
  const orgId = useCurrentOrgId();
  const form = useForm<CreateEventForm>({
    defaultValues: defaults,
    resolver: zodResolver(withValidation(createEventFormSchema)),
  });

  const onSubmit: SubmitHandler<CreateEventForm> = (data) => {
    const startTime = convertTimeToNanoSec(data.start_time, data.unit);
    const endTime = convertTimeToNanoSec(data.end_time, data.unit);
    return events
      .createEvent(orgId, {
        ...data,
        start_time: startTime,
        end_time: endTime,
        associations: [associationRecord],
      })
      .then(onSuccess);
  };

  const submissionErrors = form.formState.errors.root?.serverErrors;

  const unit = form.watch("unit");

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(event) => {
          form
            .handleSubmit(onSubmit)(event)
            .catch((error: Error) => {
              form.setError("root.serverErrors", {
                message: error.message,
              });
            });
        }}
        style={{ margin: "1rem 0" }}
      >
        <Grid container direction="column" justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <RobotoTypography
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Event name
            </RobotoTypography>
            <Controller
              control={form.control}
              name="name"
              render={({ field }) => {
                return (
                  <EventNameInput
                    currentName={field.value}
                    onNameChanged={field.onChange}
                    error={form.formState.errors.name !== undefined}
                    helperText={form.formState.errors.name?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              sx={{ fontWeight: 500, fontSize: "1rem", marginTop: "0.5rem" }}
            >
              Event duration
            </RobotoTypography>
            <RobotoTypography sx={{ fontSize: "0.9rem", margin: "0.5rem 0" }}>
              Enter the start and end time of the event in{" "}
              <b>{unit === Unit.Sec ? "seconds" : "nanoseconds"}</b> since
              epoch.
            </RobotoTypography>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <TextField
                {...form.register("start_time")}
                id="startTime"
                label="Start Time"
                error={form.formState.errors.start_time !== undefined}
                helperText={form.formState.errors.start_time?.message}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                }}
              />
              <TextField
                {...form.register("end_time")}
                id="endTime"
                error={form.formState.errors.end_time !== undefined}
                helperText={form.formState.errors.end_time?.message}
                label="End Time"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                }}
              />
              <Controller
                name="unit"
                control={form.control}
                render={({ field }) => {
                  return (
                    <ToggleButtonGroup
                      {...field}
                      aria-label="Select time unit"
                      color="primary"
                      exclusive
                      onChange={(_, value) => {
                        if (value !== null) {
                          field.onChange(value);
                        }
                      }}
                      size="small"
                      sx={{
                        /* This is a hack to make the toggle button group
                         * the same height as the text fields when they render
                         * an error message */
                        height: "40px",
                      }}
                    >
                      <ToggleButton
                        sx={{ textTransform: "none" }}
                        value={Unit.Sec}
                      >
                        sec
                      </ToggleButton>
                      <ToggleButton
                        sx={{ textTransform: "none" }}
                        value={Unit.NanoSec}
                      >
                        nsec
                      </ToggleButton>
                    </ToggleButtonGroup>
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Description (optional)
            </RobotoTypography>
            <TextField
              {...form.register("description")}
              placeholder="Description"
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
              error={form.formState.errors.description !== undefined}
              helperText={form.formState.errors.description?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0" }}
            >
              Tags (optional)
            </RobotoTypography>
            <Controller
              control={form.control}
              name="tags"
              render={({ field }) => {
                return (
                  <TagsGroup>
                    <Tags
                      tags={field.value}
                      onDeleteTag={(tag) => {
                        field.onChange(field.value.filter((t) => t !== tag));
                      }}
                    />
                    <TagInput
                      tags={field.value}
                      autocompleteType={AutocompleteType.EventTags}
                      onAddTag={(tag) => {
                        field.onChange([...field.value, tag]);
                      }}
                    />
                  </TagsGroup>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RobotoTypography
              sx={{ fontWeight: 500, fontSize: "1rem", margin: "0.5rem 0 0" }}
            >
              Metadata (optional)
            </RobotoTypography>
            <Controller
              control={form.control}
              name="metadata"
              render={({ field }) => {
                return (
                  <>
                    <MetadataView
                      metadata={field.value}
                      onChange={(metadata) => {
                        field.onChange(metadata);
                      }}
                    />
                    <MetadataForm
                      metadata={field.value}
                      autocompleteType={AutocompleteType.EventMetadataKeys}
                      onAddMetadata={(key, value) => {
                        field.onChange({
                          ...field.value,
                          [key]: value,
                        });
                      }}
                    />
                  </>
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: "1rem",
            }}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              <RobotoButton
                eventName={"CreateDatasetEventCanceled"}
                eventProperties={{
                  datasetId: associationRecord.association_id,
                }}
                type="button"
                variant="text"
                onClick={onCancel}
              >
                Cancel
              </RobotoButton>
              <RobotoButton
                eventName={"DatasetEventCreated"}
                eventProperties={{
                  datasetId: associationRecord.association_id,
                  eventName: form.getValues("name"),
                }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={form.formState.isSubmitting}
              >
                Create Event
              </RobotoButton>
            </div>
            {submissionErrors && (
              <Box component="p" sx={{ color: "red", margin: "0.5rem 0" }}>
                {submissionErrors.message}
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
