import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { Skeleton } from "@mui/material";

import {
  RobotoLinkHoverUnderline,
  RobotoTypography,
} from "@/shared/components";
import { useDataset } from "@/shared/domain/datasets/hooks/";

import styles from "./Scopes.module.css";

interface AssociatedDatasetProps {
  datasetId: string;
}

export function AssociatedDataset({ datasetId }: AssociatedDatasetProps) {
  const datasetQuery = useDataset(datasetId);
  if (datasetQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }
  if (datasetQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load dataset
        </RobotoTypography>
      </span>
    );
  }
  return (
    <span className={styles.container}>
      <TopicOutlinedIcon className={styles.icon} />
      <RobotoLinkHoverUnderline
        to={`/datasets/${datasetQuery.data.dataset_id}`}
        className={styles.text}
        title={datasetQuery.data.dataset_id}
      >
        {datasetQuery.data.dataset_id}
      </RobotoLinkHoverUnderline>
    </span>
  );
}
