import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";
import * as React from "react";

import { type MessagePathNode } from "@/shared/domain/topics";

import { Draggable, DragItem } from "../DnD";
import { Expandable } from "../Expandable";

import { ContextMenu } from "./ContextMenu";
import styles from "./MessagePath.module.css";
import { MessagePathLabel } from "./MessagePathLabel";

interface MessagePathProps {
  node: MessagePathNode;
}

/**
 * Render a MessagePath and its sub-MessagePaths (if any).
 */
export function MessagePath(props: MessagePathProps) {
  const { node } = props;
  const theme = useTheme();
  const [children, setChildren] = React.useState<JSX.Element | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleExpanded = () => {
    if (node.children.length === 0) {
      return;
    }

    if (children !== null) {
      setChildren(null);
      return;
    }
    return setChildren(
      <div className={styles.subPathsContainer}>
        {node.children.map((child) => {
          return <MessagePath key={child.label} node={child} />;
        })}
      </div>,
    );
  };

  const onRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const baseLabel = (
    <div
      role="button"
      aria-controls="context-menu"
      aria-label={node.data.message_path}
      title={node.data.message_path}
      onContextMenu={onRightClick}
      className={styles.truncate}
    >
      <MessagePathLabel
        dataType={node.data.data_type.split("/").pop() || ""}
        label={node.label}
      />
    </div>
  );

  const label =
    node.children.length === 0 ? (
      <>
        <Draggable<MessagePathNode>
          id={node.data.message_path_id}
          item={{ type: DragItem.MessagePath, data: node }}
          className={
            theme.palette.mode === "dark"
              ? styles.draggableDark
              : styles.draggableLight
          }
        >
          {baseLabel}
        </Draggable>
        <ContextMenu
          node={node}
          anchorElement={anchorEl}
          closeMenu={closeMenu}
        />
      </>
    ) : (
      <Expandable<MessagePathNode>
        data={node}
        EndIcon={children ? ExpandLessIcon : ExpandMoreIcon}
        onClick={toggleExpanded}
        className={styles.reducedWidth}
        iconClassName={styles.expandIcon}
      >
        {baseLabel}
      </Expandable>
    );

  return (
    <div>
      {label}
      {children}
    </div>
  );
}
