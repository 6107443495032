import { Alert, Box } from "@mui/material";
import React from "react";

import { AISummaryMetadataBar } from "@/shared/components/ai";
import { AISummaryMarkdownLink } from "@/shared/components/ai/AISummaryMarkdownLink.tsx";
import { PhraseProgressBar } from "@/shared/components/PhraseProgressBar";
import { RobotoMarkdown } from "@/shared/components/RobotoMarkdown";
import { useDatasetSummary } from "@/shared/domain/datasets/hooks/useDatasetSummary";
import { useRegenerateDatasetSummary } from "@/shared/domain/datasets/hooks/useRegenerateDatasetSummary.ts";

import styles from "./DatasetAISummary.module.css";

interface DatasetAISummaryProps {
  datasetId: string;
}

export function DatasetAISummary({ datasetId }: DatasetAISummaryProps) {
  const [promptOverrideText, setPromptOverrideText] = React.useState<
    string | null
  >(null);
  const { data, isLoading } = useDatasetSummary(datasetId);
  const regenerateMutation = useRegenerateDatasetSummary();

  return (
    <Box className={styles.container}>
      {data?.created && (
        <AISummaryMetadataBar
          created={data.created}
          isRegenerating={regenerateMutation.isPending}
          onRegenerateClick={() =>
            regenerateMutation.mutate({
              datasetId,
              options: promptOverrideText
                ? { systemPrompt: promptOverrideText }
                : undefined,
            })
          }
          context={{ datasetId, created: data.created }}
          promptOverrideText={promptOverrideText}
          onPromptOverrideChange={setPromptOverrideText}
        />
      )}

      {(isLoading || regenerateMutation.isPending) && (
        <Box className={styles.loadingContainer}>
          <PhraseProgressBar />
        </Box>
      )}

      {regenerateMutation.error && (
        <Alert severity="error">
          Failed to regenerate summary. Please try again later.
        </Alert>
      )}

      {data?.text && !regenerateMutation.isPending && (
        <RobotoMarkdown
          content={data?.text || ""}
          datasetLinkRenderer={({ datasetId: linkedDatasetId, text }) => {
            // Don't render a link to the dataset we're already on, that's just unnecessarily confusing
            if (linkedDatasetId === datasetId) {
              return <span>{text}</span>;
            } else {
              return (
                <AISummaryMarkdownLink datasetId={datasetId} text={text} />
              );
            }
          }}
          fileLinkRenderer={({ fileId, text, time }) => (
            <AISummaryMarkdownLink fileId={fileId} text={text} time={time} />
          )}
          topicLinkRenderer={({ topicId, text, time }) => (
            <AISummaryMarkdownLink topicId={topicId} text={text} time={time} />
          )}
          messagePathLinkRenderer={({ messagePathId, text, time }) => (
            <AISummaryMarkdownLink
              messagePathId={messagePathId}
              text={text}
              time={time}
            />
          )}
        />
      )}
    </Box>
  );
}
