import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import { Account } from "@/Account";
import { ActionsRoutes } from "@/Actions";
import { CreateTrigger } from "@/Actions/CreateTrigger";
import { Invocation } from "@/Actions/Invocation";
import { Trigger } from "@/Actions/Trigger";
import { Admin } from "@/Admin";
import { CollectionsRoutes } from "@/Collections";
import { Dashboard } from "@/Dashboard";
import { DatasetsRoutes } from "@/Datasets";
import { Device } from "@/Device";
import { FilesRoutes } from "@/Files";
import { Invite } from "@/Invite";
import { Org } from "@/Org";
import { Search } from "@/Search";
import { Settings } from "@/Settings";
import {
  VisualizationPageLayout,
  Workspace,
  WorkspaceErrorBoundary,
} from "@/shared/components/visualization";
import { WorkspaceSidebarProvider } from "@/shared/components/visualization/sidebarContext";
import { SignIn } from "@/SignIn";
import { SignUp } from "@/SignUp";
import { User } from "@/User";

import { AppProvidersLayout } from "./AppProvidersLayout";
import { AuthenticationLayout } from "./AuthenticationLayout";
import { ErrorPage } from "./ErrorPage";
import { PageLayout } from "./PageLayout";
import { ScrollRestorationLayout } from "./ScrollRestorationLayout";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppProvidersLayout />} errorElement={<ErrorPage />}>
      <Route element={<ScrollRestorationLayout />}>
        {/** Public routes */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />

        {/** User authenticated routes */}
        <Route element={<AuthenticationLayout isNotOrgProtected />}>
          <Route path="/invites/:inviteId" element={<Invite />} />
        </Route>

        {/** User and org authenticated routes */}
        <Route element={<AuthenticationLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/actions/*" element={<ActionsRoutes />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/collections/*" element={<CollectionsRoutes />} />
          <Route path="/datasets/*" element={<DatasetsRoutes />} />
          <Route path="/devices/:deviceId/*" element={<Device />} />
          <Route path="/files/*" element={<FilesRoutes />} />
          <Route path="/hub" element={<Navigate to="/actions/hub" replace />} />
          <Route path="/invocations/:invocationId/*" element={<Invocation />} />
          <Route path="/orgs/:orgId/*" element={<Org />} />
          <Route path="/search" element={<Search />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/topics"
            element={<Navigate to="/search?target=topics" replace />}
          />
          <Route path="/triggers/:triggerName/*" element={<Trigger />} />
          <Route path="/triggers/create" element={<CreateTrigger />} />
          <Route path="/users/:userId/*" element={<User />} />
          <Route element={<PageLayout title="Roboto - Visualization" />}>
            <Route path="/visualize" element={<VisualizationPageLayout />}>
              <Route
                path=":workspaceId?"
                element={
                  <WorkspaceSidebarProvider>
                    <Workspace />
                  </WorkspaceSidebarProvider>
                }
                errorElement={<WorkspaceErrorBoundary />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
);
