export {
  isApplyFilterCommand,
  isBuildTextSearchIndexCommand,
  isClearFilterCommand,
  isDisposeTextSearchIndexCommand,
  isGetLogsCommand,
  isInitCommand,
  isSetStateCommand,
  LogCommand,
  LogCommandResponse,
  type LogCommandPayloadMap,
  type LogCommandResponsePayloadMap,
} from "./commands";

export {
  type LogEventPayloadMap,
  isErrorEvent,
  isInitializedEvent,
  isLoadingStateChangeEvent,
  isLogTimeIndexStateChangeEvent,
  isSearchIndexStateChangeEvent,
  LogEvent,
} from "./events";

export const SOURCE = "roboto-log-panel";
