import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import { useTheme, Box } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { useAPICall } from "@/shared/services/apiHooks";
import {
  DeepHealthCheckResponse,
  RobotoAPICall,
  deepHealthCheckEndpoint,
} from "@/types";

import { RobotoTypography } from "../RobotoTypography";

export const HealthCheckText: React.FC = () => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const deepHealthCheckCall: RobotoAPICall = {
    endpoint: deepHealthCheckEndpoint,
    orgId: currentOrganization?.org_id,
    method: "GET",
  };

  const { data, error } =
    useAPICall<DeepHealthCheckResponse>(deepHealthCheckCall);

  let platformStatus: string;
  let platformIcon: React.JSX.Element;

  if (error) {
    platformStatus = "Error";
    platformIcon = <ErrorIcon sx={{ color: "#b60707", width: "16px" }} />;
  } else if (data) {
    let allHealthy = true;
    for (const key in data?.data) {
      allHealthy = !data.data[key].is_healthy ? false : allHealthy;
    }

    if (allHealthy) {
      platformStatus = "Healthy";
      platformIcon = (
        <CheckCircleIcon sx={{ color: "#2aa909", width: "16px" }} />
      );
    } else {
      platformStatus = "Degraded";
      platformIcon = <ErrorIcon sx={{ color: "#a98009", width: "16px" }} />;
    }
  } else {
    platformStatus = "Loading";
    platformIcon = <PendingIcon sx={{ color: "#BBB", width: "16px" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: theme.spacing(1),
      }}
    >
      {platformIcon}
      <RobotoTypography
        variant="caption"
        sx={{
          color: "#909399",
        }}
      >
        Platform {platformStatus}
      </RobotoTypography>
    </Box>
  );
};
