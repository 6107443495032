import { parseTimestampsAsBigInt } from "@/shared/json";

type DatasetLinkContext = {
  dataset: {
    dataset_id: string;
    t?: bigint;
  };
};

type FileLinkContext = {
  file: {
    file_id: string;
    t?: bigint;
  };
};

type TopicLinkContext = {
  topic: {
    topic_id: string;
    t?: bigint;
  };
};

type MessagePathLinkContext = {
  message_path: {
    message_path_id: string;
    t?: bigint;
  };
};

const isDatasetLinkContext = (json: unknown): json is DatasetLinkContext => {
  return (
    typeof json === "object" &&
    json !== null &&
    "dataset" in json &&
    typeof json.dataset === "object" &&
    json.dataset !== null &&
    "dataset_id" in json.dataset &&
    typeof json.dataset.dataset_id === "string" &&
    (!("t" in json.dataset) || typeof json.dataset.t === "bigint")
  );
};

const isFileLinkContext = (json: unknown): json is FileLinkContext => {
  return (
    typeof json === "object" &&
    json !== null &&
    "file" in json &&
    typeof json.file === "object" &&
    json.file !== null &&
    "file_id" in json.file &&
    typeof json.file.file_id === "string" &&
    (!("t" in json.file) || typeof json.file.t === "bigint")
  );
};

const isTopicLinkContext = (json: unknown): json is TopicLinkContext => {
  return (
    typeof json === "object" &&
    json !== null &&
    "topic" in json &&
    typeof json.topic === "object" &&
    json.topic !== null &&
    "topic_id" in json.topic &&
    typeof json.topic.topic_id === "string" &&
    (!("t" in json.topic) || typeof json.topic.t === "bigint")
  );
};

const isMessagePathLinkContext = (
  json: unknown,
): json is MessagePathLinkContext => {
  return (
    typeof json === "object" &&
    json !== null &&
    "message_path" in json &&
    typeof json.message_path === "object" &&
    json.message_path !== null &&
    "message_path_id" in json.message_path &&
    typeof json.message_path.message_path_id === "string" &&
    (!("t" in json.message_path) || typeof json.message_path.t === "bigint")
  );
};

export const isTimestampLink = (url: string) => {
  return url.startsWith("timestamp:");
};

function getLinkContext<T>(
  url: string,
  isLink: (json: unknown) => json is T,
): T | undefined {
  try {
    const decoded = decodeURIComponent(url);
    const json: unknown = JSON.parse(decoded, parseTimestampsAsBigInt);
    return isLink(json) ? json : undefined;
  } catch {
    return undefined;
  }
}

export const getTopicLinkContext = (
  url: string,
): TopicLinkContext | undefined => {
  return getLinkContext(url, isTopicLinkContext);
};

export const isTopicLink = (url: string) => {
  return getTopicLinkContext(url) !== undefined;
};

export const getMessagePathLinkContext = (
  url: string,
): MessagePathLinkContext | undefined => {
  return getLinkContext(url, isMessagePathLinkContext);
};

export const isMessagePathLink = (url: string) => {
  return getMessagePathLinkContext(url) !== undefined;
};

export const getDatasetLinkContext = (
  url: string,
): DatasetLinkContext | undefined => {
  return getLinkContext(url, isDatasetLinkContext);
};

export const isDatasetLink = (url: string) => {
  return getDatasetLinkContext(url) !== undefined;
};

export const getFileLinkContext = (
  url: string,
): FileLinkContext | undefined => {
  return getLinkContext(url, isFileLinkContext);
};

export const isFileLink = (url: string) => {
  return getFileLinkContext(url) !== undefined;
};
