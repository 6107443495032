import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { RobotoTypography } from "@/shared/components";
import { EventRecord } from "@/shared/domain/events";
import {
  getMessagePathNodeFromRecord,
  MessagePathRecord,
  TopicRecord,
} from "@/shared/domain/topics";
import { useMessagePathById } from "@/shared/domain/topics/hooks/useMessagePath";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";
import { SearchParams } from "@/shared/environment";
import { ErrorMonitoringService } from "@/shared/services";
import { useVizDispatch } from "@/shared/state/visualization";
import { nanoSecToSecStr } from "@/shared/time";

import {
  constructPanelForMessagePath,
  determineDefaultPanelType,
} from "../../DndContainer/dropHandlers";

import styles from "./Scopes.module.css";

interface AssociatedMessagePathProps {
  messagePathId: string;
  event: EventRecord;
}

export function AssociatedMessagePath({
  messagePathId,
  event,
}: AssociatedMessagePathProps) {
  const messagePathQuery = useMessagePathById(messagePathId);
  const topicQuery = useTopicById(messagePathQuery.data?.topic_id);
  const viewMessagePath = useViewMessagePath();

  if (messagePathQuery.isPending || topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (messagePathQuery.isError || topicQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load message path
        </RobotoTypography>
      </span>
    );
  }

  const msgPathName = `${topicQuery.data.topic_name}.${messagePathQuery.data.message_path}`;

  return (
    <span className={styles.container}>
      <TimelineIcon className={styles.icon} />
      <RobotoTypography
        component={"a"}
        className={classNames(styles.text, styles.link)}
        onClick={() => {
          try {
            viewMessagePath(messagePathQuery.data, topicQuery.data, event);
          } catch (e) {
            ErrorMonitoringService.captureError(e);
          }
        }}
        title={msgPathName}
      >
        {msgPathName}
      </RobotoTypography>
    </span>
  );
}

function useViewMessagePath() {
  const [_, setSearchParams] = useSearchParams();
  const vizDispatch = useVizDispatch();

  return React.useCallback(
    function viewMessagePath(
      messagePath: MessagePathRecord,
      topic: TopicRecord,
      event: EventRecord,
    ) {
      setSearchParams((oldParams) => {
        const params = new URLSearchParams(oldParams);
        params.set(SearchParams.TIMESTAMP, nanoSecToSecStr(event.start_time));
        return params;
      });

      const messagePathNode = getMessagePathNodeFromRecord(topic, messagePath);
      if (!messagePathNode) {
        throw new Error(
          `Failed to find message path node for ${messagePath.message_path} when constructing tree from topic ${topic.topic_name} to visualize.`,
        );
      }

      constructPanelForMessagePath(
        vizDispatch,
        messagePathNode,
        determineDefaultPanelType(messagePathNode),
      );
    },
    [setSearchParams, vizDispatch],
  );
}
