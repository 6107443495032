import { NS_PER_MS, milliSecToNanoSec } from "@/shared/time";

export class TimeSpan {
  public readonly startTime: bigint;
  public readonly endTime: bigint;

  // Duration offset in milliseconds.
  // Used to position this timespan within the absolute timeline of the timer.
  // E.g., the first timespan has an offset of 0, and if that timespan has a duration of 86, then the second timespan has an offset of 86
  #offset: number;

  constructor(startTime: bigint, endTime: bigint, offset: number) {
    this.startTime = startTime;
    this.endTime = endTime;

    this.#offset = offset;
  }

  get durationMs(): number {
    return Number(this.endTime - this.startTime) / NS_PER_MS;
  }

  /**
   * The beginning of this timespan in timeline offset milliseconds.
   */
  get offsetMs(): number {
    return this.#offset;
  }

  /**
   * The end of this timespan in timeline offset milliseconds.
   */
  get endMs(): number {
    return this.offsetMs + this.durationMs;
  }

  public contains(durationTimeMs: number): boolean {
    if (durationTimeMs < this.offsetMs) {
      return false;
    }
    const adjustedDurationMs = durationTimeMs - this.offsetMs;
    const adjustedDurationNs = milliSecToNanoSec(adjustedDurationMs);
    if (adjustedDurationNs + this.startTime > this.endTime) {
      return false;
    }

    return true;
  }

  public project(durationTimeMs: number): bigint {
    if (durationTimeMs < this.offsetMs) {
      return this.startTime;
    }
    const adjustedDurationMs = durationTimeMs - this.offsetMs;
    const adjustedDurationNs = milliSecToNanoSec(adjustedDurationMs);
    const projected = adjustedDurationNs + this.startTime;
    if (projected > this.endTime) {
      return this.endTime;
    }

    return projected;
  }
}
