import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { EventNameForm } from "@/shared/components/events/EventNameForm";
import { EventRecord } from "@/shared/domain/events";

import styles from "./EditableEventName.module.css";

interface EditableEventNameProps {
  event: EventRecord;
  onUpdateEvent: (event: EventRecord) => void;
  readonly: boolean;
}

export function EditableEventName({
  event,
  onUpdateEvent,
  readonly,
}: EditableEventNameProps) {
  const theme = useTheme();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  if (isEditing) {
    return (
      <EventNameForm
        id={event.event_id}
        name={event.name}
        onSubmit={(event: EventRecord) => {
          setIsEditing(false);
          if (event) {
            onUpdateEvent(event);
          }
        }}
        onCancel={() => setIsEditing(false)}
        inputClassName={styles.name}
      />
    );
  }

  return (
    <Box
      onClick={() => !readonly && setIsEditing(true)}
      sx={
        readonly
          ? {}
          : {
              ":hover": {
                backgroundColor: theme.palette.hoverBackground.main,
                borderRadius: "0.25rem",
                cursor: "pointer",
              },
            }
      }
    >
      {event.name}
    </Box>
  );
}
