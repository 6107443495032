import type { TimeBounds } from "@/shared/domain/topics/determineTimeBounds";
import type { PlotPanelState } from "@/shared/state/visualization";
import type { MessagePayload } from "@/shared/webworker";

import type { HoverDatum, Style } from "../PlotRenderer";
import { ViewportParams } from "../Viewport/viewport";

export enum PlotCommand {
  Dispose = "dispose",
  GetDataUnderCursor = "get-data-under-cursor",
  Init = "init",
  Pan = "pan",
  ApplyViewport = "apply-viewport",
  ResetView = "reset-view",
  Resize = "resize",
  SetState = "set-state",
  SetStyle = "set-style",
  Zoom = "zoom",
}

export interface TimeSpanAnnotation {
  // Used to de-dupe annotations at render time. Should re-use an existing meaningful ID like event_id if possible
  annotationId: string;

  // The start and end time of the annotation, in nanoseconds
  startTime: bigint;
  endTime: bigint;

  style?: {
    // Optional text to display on the annotation
    labelText?: string;

    // Optional color to use for the label and background
    labelColor?: string;
    backgroundColor?: string;
  };
}

export interface PlotInit {
  canvas: OffscreenCanvas;
  devicePixelRatio: number;
}

export interface ZoomPayload {
  zoomRatioX: number;
  zoomRatioY: number;
  center: { x: number; y: number };
  axis: "x" | "y" | "xy";
}

export interface PanPayload {
  deltaX: number;
  deltaY: number;
}

export interface PlotCommandPayloadMap {
  [PlotCommand.Dispose]: undefined;
  [PlotCommand.GetDataUnderCursor]: { x: number; y: number };
  [PlotCommand.Init]: PlotInit;
  [PlotCommand.Pan]: PanPayload;
  [PlotCommand.ApplyViewport]: ViewportParams;
  [PlotCommand.ResetView]: undefined;
  [PlotCommand.Resize]: { width: number; height: number };
  [PlotCommand.SetState]: {
    state: PlotPanelState["data"];
    annotations: TimeSpanAnnotation[];
    timeBounds: TimeBounds;
  };
  [PlotCommand.SetStyle]: Style;
  [PlotCommand.Zoom]: ZoomPayload;
}

export enum PlotCommandResponse {
  DataUnderCursor = "data-under-cursor",
}

export interface PlotCommandResponsePayloadMap {
  [PlotCommandResponse.DataUnderCursor]: HoverDatum[];
}

export function isDisposeCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.Dispose]>
> {
  return event.data.type === PlotCommand.Dispose;
}

export function isGetDataUnderCursorCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<
    PlotCommand,
    PlotCommandPayloadMap[PlotCommand.GetDataUnderCursor]
  >
> {
  return event.data.type === PlotCommand.GetDataUnderCursor;
}

export function isInitCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.Init]>
> {
  return event.data.type === PlotCommand.Init;
}

export function isPanCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.Pan]>
> {
  return event.data.type === PlotCommand.Pan;
}

export function isApplyViewportCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.ApplyViewport]>
> {
  return event.data.type === PlotCommand.ApplyViewport;
}

export function isResetViewCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.ResetView]>
> {
  return event.data.type === PlotCommand.ResetView;
}

export function isResizeCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.Resize]>
> {
  return event.data.type === PlotCommand.Resize;
}

export function isSetStateCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.SetState]>
> {
  return event.data.type === PlotCommand.SetState;
}

export function isSetStyleCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.SetStyle]>
> {
  return event.data.type === PlotCommand.SetStyle;
}

export function isZoomCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<PlotCommand, PlotCommandPayloadMap[PlotCommand.Zoom]>
> {
  return event.data.type === PlotCommand.Zoom;
}
