import classNames from "classnames";
import * as React from "react";

import CopyToClipboardButton from "@/shared/components/CopyToClipboardButton";

interface PanelTitleProps {
  className?: classNames.Argument;
  title: string | null;
}

/**
 * Display the title of a panel.
 */
export function PanelTitle({ className, title }: PanelTitleProps) {
  const [panelTitleHovering, setPanelTitleHovering] =
    React.useState<boolean>(false);
  return (
    <h3
      className={classNames(className)}
      onMouseEnter={() => setPanelTitleHovering(true)}
      onMouseLeave={() => setPanelTitleHovering(false)}
    >
      {title}
      {title && (
        <CopyToClipboardButton
          text={title}
          sx={{
            opacity: panelTitleHovering ? 1 : 0,
            transition: "opacity 0.2s",
            paddingLeft: "6px",
          }}
        />
      )}
    </h3>
  );
}
