import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";

import { Invocations } from "@/Actions/components";
import { useAuth } from "@/providers";
import {
  LoadingScreen,
  NavigationPage,
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
} from "@/shared/components";
import { TriggerEvaluationsForTrigger } from "@/shared/components/TriggerEvaluations";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, triggerEndpoint } from "@/types";

import { TriggerDetails } from "../components/trigger";

export function Trigger() {
  const { triggerName } = useParams<{ triggerName: string | undefined }>();

  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const {
    data: response,
    error,
    loading,
    initiateRequest,
    updateCache,
  } = useLazyAPICall<APIResponse<TriggerRecord>>();

  React.useEffect(() => {
    async function fetchTrigger() {
      if (!triggerName || !currentOrganization?.org_id) {
        return;
      }

      await initiateRequest({
        endpoint: triggerEndpoint,
        method: "GET",
        orgId: currentOrganization.org_id,
        pathParams: { name: triggerName },
      });
    }

    void fetchTrigger();
  }, [currentOrganization?.org_id, triggerName, initiateRequest]);

  const [searchParams, setSearchParams] = useSearchParams();

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  const triggerRecord = response?.data;

  if (loading || (!triggerRecord && !error)) {
    return <LoadingScreen />;
  }

  let errorDisplay = null;
  if (error) {
    errorDisplay = (
      <Box
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(1),
        }}
      >
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  } else if (!triggerRecord) {
    errorDisplay = (
      <Box
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(1),
        }}
      >
        <Alert severity="error">
          Failed to load trigger &apos;{triggerName}&apos;.
          <br />
          Try refreshing the page.
        </Alert>
      </Box>
    );
  }

  let pageContent = null;
  if (currentOrganization && triggerRecord) {
    pageContent = (
      <>
        <RobotoTabPanel index={0} value={tabValue}>
          <TriggerDetails
            org={currentOrganization}
            trigger={triggerRecord}
            onUpdate={updateCache}
          />
        </RobotoTabPanel>

        <RobotoTabPanel index={1} value={tabValue}>
          <Alert
            severity="info"
            sx={{
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          >
            Trigger evaluations are records of when a trigger was run for a
            given dataset. Trigger evaluations are initiated by platform events,
            such as when a file is uploaded to a dataset or when a
            dataset&apos;s metadata is updated.
          </Alert>
          <TriggerEvaluationsForTrigger triggerName={triggerName} />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={2}
          value={tabValue}
          sx={{
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Invocations
            triggerId={triggerRecord.trigger_id}
            currentOrg={currentOrganization}
          />
        </RobotoTabPanel>
      </>
    );
  } else if (!currentOrganization) {
    pageContent = (
      <Typography color={theme.palette.error.main} variant="h4">
        No organization selected
      </Typography>
    );
  }

  return (
    <NavigationPage title={"Roboto - Trigger"}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/actions?tab=1">Triggers</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
        >
          {triggerName}
        </Typography>
      </Breadcrumbs>

      {errorDisplay}

      {!error && (
        <>
          <Box
            sx={{
              paddingTop: theme.spacing(3),
            }}
          >
            <RobotoStyledTabs
              value={tabValue}
              onChange={(_event, newValue) => {
                setSearchParams({ tab: String(newValue) });
              }}
              aria-label="triggers tabs"
              variant="scrollable"
            >
              <RobotoStyledTab label="Details" />
              <RobotoStyledTab label="Evaluation History" />
              <RobotoStyledTab label="Invocations" />
            </RobotoStyledTabs>

            <Divider
              sx={{
                borderColor: theme.border.color,
              }}
            />
          </Box>
          <Box
            sx={{
              padding: theme.spacing(1, 0),
              paddingBottom: theme.spacing(1),
            }}
          >
            {pageContent}
          </Box>
        </>
      )}
    </NavigationPage>
  );
}
