import { TableCell, TableRow } from "@mui/material";

import { AssociationRecord } from "@/shared/domain/association";
import { type MessagePathNode } from "@/shared/domain/topics";

import { MessagePathLink } from "../links";
import { RobotoTypography } from "../RobotoTypography";

import { Cell } from "./Cell";
import styles from "./Cell.module.css";

function extractFieldFromMetadata<T>(
  key: string,
  metadata: Record<string, unknown>,
) {
  if (!(key in metadata)) {
    return "-";
  } else {
    return metadata[key] as T;
  }
}

interface MessagePathRowProps {
  messagePathNode: MessagePathNode;
  topicAssociation: AssociationRecord;
}

export function MessagePathRow({
  messagePathNode,
  topicAssociation,
}: MessagePathRowProps) {
  return (
    <TableRow key={messagePathNode.data.message_path_id}>
      <TableCell
        sx={{
          paddingLeft: 0,
        }}
      >
        <RobotoTypography variant="body2">
          <MessagePathLink
            messagePathNode={messagePathNode}
            topicAssociation={topicAssociation}
            className={styles.body}
          />
        </RobotoTypography>
      </TableCell>
      <Cell className={styles.body}>{messagePathNode.data.data_type}</Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("min", messagePathNode.data.metadata)}
      </Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("max", messagePathNode.data.metadata)}
      </Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("mean", messagePathNode.data.metadata)}
      </Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("median", messagePathNode.data.metadata)}
      </Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("true_count", messagePathNode.data.metadata)}
      </Cell>
      <Cell className={styles.body}>
        {extractFieldFromMetadata("false_count", messagePathNode.data.metadata)}
      </Cell>
    </TableRow>
  );
}
