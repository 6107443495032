import { SearchMode } from "./search";

export interface TextSearchFilter {
  query: string;
  mode: SearchMode;
}
export interface FrequencyFilter {
  playbackRate: number;
  targetFrequency: number;
}
export type LogFilter = TextSearchFilter | FrequencyFilter;

export const isTextSearchFilter = (
  filter: LogFilter,
): filter is TextSearchFilter => {
  return (filter as TextSearchFilter).query !== undefined;
};
