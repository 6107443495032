// type-only import used for a documentation comment only.
// eslint-disable-next-line no-unused-vars
import type { useSearchParams } from "react-router-dom";

/**
 * Param values intended for use with {@link useSearchParams}.
 */
export const SearchParams = {
  /**
   * Timestamp, intended to be encoded as fixed-point seconds with nanosecond precision.
   */
  TIMESTAMP: "t",
  FILTER_TERM: "filterTerm",
} as const;
