import { useTheme } from "@mui/material";
import React from "react";

import { serializeTimestamp, Timestamp as TimestampType } from "@/shared/text";
import { nanoSecToSecStr } from "@/shared/time";

import { useWorkspaceTimer } from "../visualization/WorkspaceCtx";

export const Timestamp: React.FC<{
  children: React.ReactNode;
  timestamp: TimestampType;
}> = ({ children, timestamp }) => {
  const theme = useTheme();
  const timer = useWorkspaceTimer();

  // Display a formatted big int if display text is a big int, otherwise display the display text
  let formattedText = nanoSecToSecStr(timestamp);
  if (typeof children === "string") {
    try {
      BigInt(children);
    } catch {
      formattedText = children;
    }
  }

  // Note: Timestamps must be rendered as links for proper markdown conversion on copy/paste
  return (
    <a
      href={serializeTimestamp(timestamp)}
      style={{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.text.secondary,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        timer.seekTo(timestamp);
      }}
    >
      {formattedText}
    </a>
  );
};
