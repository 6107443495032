/* eslint-disable no-console */
import { Env } from "@/shared/environment";

/**
 * Use the Singleton pattern and an environment variable to log console output only when in dev or beta
 */

export const LoggerService = (function () {
  // eslint-disable-next-line prefer-const
  let instance;

  if (instance) {
    return instance;
  }

  instance = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(message?: any, ...optionalParams: any[]) {
      if (
        Env.appEnv === "dev" ||
        Env.appEnv === "beta" ||
        Env.appEnv === "test"
      ) {
        // Suppress lint warnings as console.log itself uses any[] type
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.log(message, ...optionalParams);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error(message: any, errorObject?: any) {
      if (
        Env.appEnv === "dev" ||
        Env.appEnv === "beta" ||
        Env.appEnv === "test"
      ) {
        console.error(message, errorObject);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    warn(message?: any, ...optionalParams: any[]) {
      if (
        Env.appEnv === "dev" ||
        Env.appEnv === "beta" ||
        Env.appEnv === "test"
      ) {
        // Suppress lint warnings as console.log itself uses any[] type
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        console.warn(message, ...optionalParams);
      }
    },
  };

  return instance;
})();
