import { isDirectory, isFile } from "@/shared/domain/files/filesystem";

import { FileBrowserActionTypes, FileBrowserAction } from "./actions";
import { FileBrowserState } from "./state";

export const fileBrowserReducer = (
  state: FileBrowserState,
  action: FileBrowserAction,
): FileBrowserState => {
  switch (action.type) {
    case FileBrowserActionTypes.ClearAnnotationFile:
      return {
        ...state,
        annotationFile: undefined,
      };

    case FileBrowserActionTypes.DecrementTablePage:
      return {
        ...state,
        tableConfig: {
          page: action.payload.newPage,
          rowsPerPage: state.tableConfig.rowsPerPage,
          pageTokens: state.tableConfig.pageTokens,
        },
      };
    case FileBrowserActionTypes.DeleteFiles: {
      const newItems = state.selectedItems.filter((item) => {
        if (isDirectory(item)) {
          if (
            action.payload.some((row) => {
              if (!isDirectory(row)) {
                return false;
              }
              return row.directory.name === item.directory.name;
            })
          ) {
            return false;
          }
        } else if (isFile(item)) {
          if (
            action.payload.some((row) => {
              if (!isFile(row)) {
                return false;
              }
              return row.file.file_id === item.file.file_id;
            })
          ) {
            return false;
          }
        }

        return true;
      });

      return {
        ...state,
        selectedItems: newItems,
      };
    }
    case FileBrowserActionTypes.HandleViewToggle:
      return {
        ...state,
        displayMode: action.payload.display,
        fileTypes: action.payload.fileTypes,
        activeToggle: action.payload.activeToggle,
      };
    case FileBrowserActionTypes.IncrementTablePage: {
      const newTokens = {
        ...state.tableConfig.pageTokens,
        [action.payload.newPage]: action.payload.nextToken,
      };

      const newState = {
        ...state,
        tableConfig: {
          page: action.payload.newPage,
          rowsPerPage: state.tableConfig.rowsPerPage,
          pageTokens: newTokens,
        },
      };

      return newState;
    }
    case FileBrowserActionTypes.SetActiveToggle:
      return {
        ...state,
        activeToggle: action.payload,
      };
    case FileBrowserActionTypes.SetAlertState:
      return {
        ...state,
        alert: action.payload,
      };
    case FileBrowserActionTypes.SetAnnotationFile: {
      let file = undefined;

      if (action.payload === undefined) {
        return {
          ...state,
          annotationFile: file,
        };
      }

      if (isFile(action.payload)) {
        file = action.payload;
      }

      return {
        ...state,
        annotationFile: file,
      };
    }
    case FileBrowserActionTypes.SetAnnotationViewFilters:
      return {
        ...state,
        annotationViewFilters: action.payload,
      };
    case FileBrowserActionTypes.SetDisplayMode:
      return {
        ...state,
        displayMode: action.payload,
      };
    case FileBrowserActionTypes.SetError:
      return {
        ...state,
        error: action.payload,
      };
    case FileBrowserActionTypes.SetFileTypes: {
      const newState = {
        ...state,
        fileTypes: action.payload,
        tableConfig: {
          page: 0,
          rowsPerPage: state.tableConfig.rowsPerPage,
          pageTokens: { 0: undefined },
        },
      };

      return newState;
    }
    case FileBrowserActionTypes.SetGridItems:
      return {
        ...state,
        gridItems: action.payload,
      };
    case FileBrowserActionTypes.SetSearchTerm: {
      const newState = {
        ...state,
        searchTerm: action.payload,
        tableConfig: {
          page: 0,
          rowsPerPage: state.tableConfig.rowsPerPage,
          pageTokens: state.tableConfig.pageTokens,
        },
      };

      return newState;
    }
    case FileBrowserActionTypes.SetSelectedItems:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case FileBrowserActionTypes.SetShowHiddenFiles: {
      const newState = {
        ...state,
        showHiddenFiles: action.payload,
      };

      return newState;
    }
    case FileBrowserActionTypes.UpdateTableRowsPerPage: {
      const newState = {
        ...state,
        tableConfig: {
          page: 0,
          rowsPerPage: action.payload,
          pageTokens: { 0: undefined },
        },
      };

      return newState;
    }
    case FileBrowserActionTypes.SetTablePageToZero: {
      const newState = {
        ...state,
        tableConfig: {
          page: 0,
          rowsPerPage: state.tableConfig.rowsPerPage,
          pageTokens: { 0: undefined },
        },
      };

      return newState;
    }
  }
};
