import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  type UseInfiniteQueryResult,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { PaginatedResponse } from "@/shared/http";

import { DatasetRecord } from "../../datasets";

export type QueryResults<T> = {
  resultsQuery: UseInfiniteQueryResult<T[], Error>;
  countQuery: UseQueryResult<number>;
};

/**
 * This is how tanstack models infinite queries. To us it is an
 * intermediary type used to strongly type the value that is set in
 * tanstack's query cache. This is valuable because we later synchronously
 * update the cache when a search result (dataset) is updated.
 */
type InfiniteQueryResult<T> = InfiniteData<PaginatedResponse<T>>;

/**
 * Custom hook to retrieve query results with pagination.
 *
 * @template T - The type of the query results.
 * @param {string | undefined} queryId - The unique identifier for the query. If undefined, no query will be performed.
 * @param {number} [pageSize=250] - The number of results per page retrieved from the backend.
 *                                Default is set to 250, which is  higher than typical frontend pagination
 *                                defaults (e.g., 10, 25, or 50). This provides an upfront buffer capable
 *                                of handling multiple frontend pages worth of results, improving performance
 *                                and reducing the need for frequent backend requests.
 */
export function useQueryResults<T>(
  queryId: string | undefined,
  pageSize: number = 250,
): QueryResults<T> {
  const { queryService } = React.useContext(DomainServicesContext);

  const resultsQuery = useInfiniteQuery({
    queryKey: ["queryResults", queryId],
    queryFn: ({ pageParam, signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResults(queryId, {
        abortSignal: signal,
        nextPageToken: pageParam,
        pageSize: pageSize,
      });
    },
    select: (data: InfiniteQueryResult<T>) => {
      // Flatten backend pages of items into a single list of items.
      return data.pages.flatMap((value) => value.items);
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: PaginatedResponse<T>) => lastPage.next_token,
    enabled: queryId !== undefined,
    staleTime: Infinity,
  });

  const countQuery = useQuery({
    queryKey: ["queryResultsCount", queryId],
    queryFn: ({ signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResultCount(queryId, { abortSignal: signal });
    },
  });
  return { resultsQuery, countQuery };
}

/**
 * Updates the dataset in the search results query cache.
 * The intended use case is when updating a dataset while viewing dataset search results.
 *
 * @param queryClient The react-query query client.
 * @param newDataset The new dataset record.
 */
export function updateDatasetInSearchResultsCache(
  queryClient: QueryClient,
  newDataset: DatasetRecord,
): void {
  queryClient.setQueriesData<InfiniteQueryResult<DatasetRecord>>(
    {
      queryKey: ["queryResults"],
      exact: false,
    },
    (oldData) => {
      if (!oldData || !oldData.pages) {
        return oldData;
      }

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.map((oldDataset) => {
            if (oldDataset.dataset_id === newDataset.dataset_id) {
              return newDataset;
            }
            return oldDataset;
          }),
        })),
      };
    },
  );
}
