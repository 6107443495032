import * as React from "react";

import { Dimensions, usePopupState } from "@/shared/hooks";

import { usePlotPanelContext } from "../panelContext";
import { Extents } from "../PlotRenderer";
import { PlotTool } from "../plotTools";

interface SelectRegionProps {
  chartExtents: Extents | null;
  onDragChanged: (isDragging: boolean) => void;
  renderingSurface: HTMLDivElement | null;
}

interface SelectedDomain {
  firstPoint: number;
  secondPoint: number;
}

function normalizeRegionToChartSpace(
  selectedDomain: SelectedDomain,
  containerDims: Dimensions,
  chartExtents: Extents,
): SelectedDomain {
  return {
    firstPoint:
      (selectedDomain.firstPoint -
        containerDims.left -
        chartExtents.pixel.left) /
      chartExtents.pixel.width,
    secondPoint:
      (selectedDomain.secondPoint -
        containerDims.left -
        chartExtents.pixel.left) /
      chartExtents.pixel.width,
  };
}

/**
 *
 * @param selectedDomain selectedDomain should be a ratio relative to the chart
 */
function calculateRegionDimensions(
  selectedDomain: SelectedDomain,
  chartExtents: Extents,
) {
  const regionLeft =
    Math.min(selectedDomain.firstPoint, selectedDomain.secondPoint) *
    chartExtents.pixel.width;

  const regionWidth =
    Math.abs(selectedDomain.secondPoint - selectedDomain.firstPoint) *
    chartExtents.pixel.width;

  return {
    left: regionLeft,
    top: chartExtents.pixel.top,
    width: regionWidth,
    height: chartExtents.pixel.height,
  };
}

export function SelectRegion(props: SelectRegionProps) {
  const { chartExtents, onDragChanged, renderingSurface } = props;
  const { activeTool, selectedRegion, setSelectedRegion } =
    usePlotPanelContext();

  // The region that the user is actively drawing
  const [activeRegion, setActiveRegion] = React.useState<SelectedDomain | null>(
    null,
  );

  // Flag used to know when to broadcast the selected region
  const [hasFinishedSelectingRegion, setHasFinishedSelectingRegion] =
    React.useState(false);

  const contextMenu = usePopupState();
  const { open: openContextMenu } = contextMenu;

  React.useEffect(
    function addSelectRegionEventsToCanvas() {
      const shouldEnablePointerEvents =
        activeTool === PlotTool.SelectRegion &&
        renderingSurface !== null &&
        chartExtents !== null &&
        chartExtents.pixel.width !== 0;

      if (shouldEnablePointerEvents === false) {
        return;
      }

      const abortController = new AbortController();

      renderingSurface.addEventListener(
        "pointerdown",
        function startSelectingRegion(event: PointerEvent) {
          const eventAbortController = new AbortController();

          // The user is drawing a new region
          setHasFinishedSelectingRegion(false);

          const firstPoint = event.clientX;

          renderingSurface.addEventListener(
            "pointermove",
            function selectingRegion(event: PointerEvent) {
              const secondPoint = event.clientX;

              const region = normalizeRegionToChartSpace(
                { firstPoint, secondPoint },
                renderingSurface.getBoundingClientRect(),
                chartExtents,
              );

              setActiveRegion(region);
              onDragChanged(true);
            },
            { signal: eventAbortController.signal },
          );

          renderingSurface.addEventListener(
            "pointerup",
            function finishSelectingRegion() {
              setHasFinishedSelectingRegion(true);

              /* 
                Schedules setting isDragging to false on the next iteration of the event loop
                after all the immediate listeners have been called. This is to prevent
                subsequent click events from occurring at the same time as when the drag completes.
              */
              setTimeout(function resetDragging() {
                onDragChanged(false);
              }, 10);

              eventAbortController.abort();
            },
            { once: true },
          );

          const cancelSelectingRegion = () => {
            eventAbortController.abort();
            onDragChanged(false);
          };

          renderingSurface.addEventListener(
            "pointercancel",
            cancelSelectingRegion,
            {
              once: true,
            },
          );
          renderingSurface.addEventListener(
            "pointerleave",
            cancelSelectingRegion,
            {
              once: true,
            },
          );
        },
        { signal: abortController.signal },
      );
      return function dispose() {
        abortController.abort();
      };
    },
    [
      activeTool,
      onDragChanged,
      openContextMenu,
      renderingSurface,
      chartExtents,
    ],
  );

  React.useEffect(
    function resetSelectedRegion() {
      if (selectedRegion === null) {
        setActiveRegion(null);
        return;
      }
    },
    [activeTool, selectedRegion],
  );

  React.useEffect(
    function broadcastSelectedRegion() {
      if (
        hasFinishedSelectingRegion === false ||
        activeRegion === null ||
        chartExtents === null
      ) {
        return;
      }

      setSelectedRegion(calculateRegionDimensions(activeRegion, chartExtents));
    },
    [activeRegion, chartExtents, hasFinishedSelectingRegion, setSelectedRegion],
  );

  const shouldDisplaySelectedRegion =
    activeTool === PlotTool.SelectRegion &&
    chartExtents !== null &&
    activeRegion !== null &&
    chartExtents.pixel.width !== 0;

  if (shouldDisplaySelectedRegion === false) {
    return null;
  }

  const region = calculateRegionDimensions(activeRegion, chartExtents);

  // Need to convert the region's left relative to the rendering surface
  // because the selected region is rendered relative to the rendering surface
  const leftRelativeToRenderingSurface = region.left + chartExtents.pixel.left;

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(0,0,255,0.20)",
        pointerEvents: "none",
        left: `${leftRelativeToRenderingSurface}px`,
        height: `${region.height}px`,
        top: `${region.top}px`,
        width: `${region.width}px`,
        borderLeft: "1px solid blue",
        borderRight: "1px solid blue",
      }}
    />
  );
}
