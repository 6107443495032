import mime from "mime";

import { AISummary } from "@/shared/domain/ai_summary.ts";
import { Condition, SearchQueryBody } from "@/types/search";

import { HttpClient, robotoHeaders, PaginatedResponse } from "../../http";

import { type FileRecord, DirectoryRecord } from "./FileRecord";

interface Options {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

interface PutOptions extends Options {
  body?: BodyInit | null;
}

export interface DirectoryContentsPage {
  files: FileRecord[];
  directories: DirectoryRecord[];
  next_token: string | null;
}

interface GetFilesForDirectoryParams {
  path: string;
  fileNameSearchTerm: string;
  datasetId: string;
  pageSize: number;
  nextToken?: string;
  showHiddenFiles?: boolean;
  extensions?: string[];
  options?: Partial<Options>;
}

interface GetItemsForDirectoryParams {
  directoryPath: string;
  datasetId: string;
  pageSize: number;
  nextToken?: string;
  showHiddenFiles?: boolean;
  extensions?: string[];
  options?: Partial<Options>;
}

export interface IFileService {
  abortTransactions(
    transactionIds: string[],
    options?: Partial<Options>,
  ): Promise<void>;

  getFileRecord(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<FileRecord>;

  putFileRecord(
    fileId: string,
    options?: Partial<PutOptions>,
  ): Promise<FileRecord>;

  renameFile(
    fileId: string,
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<FileRecord>;

  renameDirectory(
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<DirectoryRecord>;

  getPresignedDownloadUrl(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<URL>;

  getJsonFileContents<T>(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<T>;

  getTagsForOrg(options?: Partial<Options>): Promise<string[]>;

  getMetadataKeysForOrg(options?: Partial<Options>): Promise<string[]>;

  getItemsForDirectory(
    params: GetItemsForDirectoryParams,
  ): Promise<DirectoryContentsPage>;

  getItemCountForDirectory(params: GetItemsForDirectoryParams): Promise<number>;

  getFilesForDirectory(
    params: GetFilesForDirectoryParams,
  ): Promise<PaginatedResponse<FileRecord>>;

  getFileCountForDirectory(params: GetFilesForDirectoryParams): Promise<number>;

  getFileCountForDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<number>;

  getExtensionsForDirectory(
    datasetId: string,
    directoryPath: string,
    options?: Partial<Options>,
  ): Promise<string[]>;

  deleteDirectories(
    datasetId: string,
    directoryPaths: string[],
    options?: Partial<Options>,
  ): Promise<void>;

  deleteFile(fileId: string, options?: Partial<Options>): Promise<void>;

  getSummary(
    fileId: string,
    options?: Partial<{ abortSignal: AbortSignal }>,
  ): Promise<AISummary>;

  generateSummary(
    fileId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      system_prompt?: string;
    }>,
  ): Promise<AISummary>;
}

class FileConditions {
  static inDataset(datasetId: string): Condition {
    return {
      field: "association_id",
      comparator: "EQUALS",
      value: datasetId,
    };
  }

  static withExtensions(extensions: string[]): Condition {
    const extensionConditions: Condition[] = extensions.map((extension) => ({
      field: "relative_path",
      comparator: "LIKE",
      value: `%${extension}`,
    }));

    return {
      operator: "OR",
      conditions: extensionConditions,
    };
  }

  static withSearchTerm(directoryPath: string, searchTerm: string): Condition {
    const searchPrefix = FileConditions.searchPrefixOf(directoryPath);

    const underDirectory: Condition = {
      field: "relative_path",
      comparator: "LIKE",
      value: `${searchPrefix}%${searchTerm}%`,
    };

    const notUnderSubdirectories: Condition = {
      field: "relative_path",
      comparator: "NOT_LIKE",
      value: `${searchPrefix}%/%`,
    };

    return {
      operator: "AND",
      conditions: [underDirectory, notUnderSubdirectories],
    };
  }

  static suppressHiddenFilesUnder(directoryPath: string): Condition {
    const searchPrefix = FileConditions.searchPrefixOf(directoryPath);

    return {
      field: "relative_path",
      comparator: "NOT_LIKE",
      value: `${searchPrefix}.%`,
    };
  }

  private static searchPrefixOf(directoryPath: string): string {
    if (directoryPath === "") {
      return "";
    } else {
      return `${directoryPath}/`;
    }
  }
}

export class FileService implements IFileService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async abortTransactions(
    transactionIds: string[],
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/transactions/abort`,
    );
    const body = {
      transaction_ids: transactionIds,
    };
    await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return;
  }

  public async getFileRecord(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<FileRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/record/${fileId}`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response.json<FileRecord>();
  }

  public async putFileRecord(
    fileId: string,
    options?: Partial<PutOptions>,
  ): Promise<FileRecord> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/record/${fileId}`,
    );
    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: options?.body,
    });
    return await response.json<FileRecord>();
  }

  public async renameFile(
    fileId: string,
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<FileRecord> {
    //strip leading and trailing slashes
    const cleanOldPath = currentPath.replace(/^\/+|\/+$/g, "");

    const newPath = `${cleanOldPath.split("/").slice(0, -1).join("/")}/${newName}`;

    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/rename`,
    );

    const body = {
      association_id: datasetId,
      new_path: newPath,
    };

    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: JSON.stringify(body),
    });

    return await response.json<FileRecord>();
  }

  public async renameDirectory(
    datasetId: string,
    currentPath: string,
    newName: string,
    options?: Partial<Options>,
  ): Promise<DirectoryRecord> {
    //strip leading and trailing slashes
    const cleanOldPath = currentPath.replace(/^\/+|\/+$/g, "");

    const slice = cleanOldPath.split("/").slice(0, -1).join("/");
    const cleanNewPath = `${slice}/${newName}`.replace(/^\/+|\/+$/g, "");

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/directory/rename`,
    );

    const body = {
      new_path: cleanNewPath,
      old_path: cleanOldPath,
    };

    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
      body: JSON.stringify(body),
    });

    return await response.json<DirectoryRecord>();
  }

  public async getPresignedDownloadUrl(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<URL> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/signed-url`,
      options?.searchParams,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    const { url: signedUrl } = await response.json<{ url: string }>();
    return new URL(signedUrl);
  }

  public async getJsonFileContents<T>(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<T> {
    const requestUrl = await this.getPresignedDownloadUrl(fileId, options);

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      excludeAuth: true,
    });

    return (await response.raw.json()) as T;
  }

  public async getTagsForOrg(options?: Partial<Options>): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error("getTagsForOrg requires an org ID, none was provided");
    }

    const requestUrl = this.#httpClient.constructUrl("v1/files/tags");
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  public async getMetadataKeysForOrg(
    options?: Partial<Options>,
  ): Promise<string[]> {
    if (!options?.resourceOwnerId) {
      throw Error(
        "getMetadataKeysForOrg requires an org ID, none was provided",
      );
    }

    const requestUrl = this.#httpClient.constructUrl("v1/files/metadata/keys");
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  /**
   * Fetches the files and folders immediately under a directory within a dataset.
   *
   * The directory path might be empty or "/", indicating the dataset root folder.
   *
   * @param params dataset ID, directory path and other optional parameters
   * @returns a `DirectoryContentsPage` listing files and folders under the given path
   */
  public async getItemsForDirectory(
    params: GetItemsForDirectoryParams,
  ): Promise<DirectoryContentsPage> {
    const {
      directoryPath,
      datasetId,
      pageSize,
      nextToken,
      showHiddenFiles,
      options,
      extensions,
    } = params;

    const urlParams = new URLSearchParams({
      directory_path: directoryPath,
      dataset_id: datasetId,
      page_size: pageSize.toString(),
    });

    if (nextToken) {
      urlParams.append("after", nextToken);
    }

    if (showHiddenFiles) {
      urlParams.append("show_hidden_files", "true");
    }

    if (extensions?.length) {
      urlParams.append("extensions", extensions.join(","));
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/directory-contents`,
      urlParams,
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<DirectoryContentsPage>();
  }

  /**
   * Counts the files and folders immediately under a directory within a dataset.
   *
   * The directory path might be empty or "/", indicating the dataset root.
   *
   * @param params dataset ID, directory path and other optional parameters
   * @returns the count of files and folders under the given path
   */
  public async getItemCountForDirectory(
    params: GetItemsForDirectoryParams,
  ): Promise<number> {
    const { directoryPath, datasetId, showHiddenFiles, extensions, options } =
      params;

    const urlParams = new URLSearchParams({
      directory_path: directoryPath,
      dataset_id: datasetId,
    });

    if (showHiddenFiles) {
      urlParams.append("show_hidden_files", "true");
    }

    if (extensions?.length) {
      urlParams.append("extensions", extensions.join(","));
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/directory-child-count`,
      urlParams,
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return Number.parseInt(await response.text());
  }

  /**
   * Searches for files by name immediately under a dataset directory.
   *
   * The directory path might be empty or "/", indicating the dataset root.
   *
   * @param params search term, directory path, dataset ID, and other optional parameters
   * @returns a paginated list of `FileRecord` for any matching files
   */
  public async getFilesForDirectory(
    params: GetFilesForDirectoryParams,
  ): Promise<PaginatedResponse<FileRecord>> {
    const {
      path,
      fileNameSearchTerm,
      datasetId,
      pageSize,
      nextToken,
      showHiddenFiles,
      options,
      extensions,
    } = params;

    const conditions: Condition[] = [FileConditions.inDataset(datasetId)];

    if (extensions?.length) {
      conditions.push(FileConditions.withExtensions(extensions));
    }

    const directoryPath = path.replace(/^\/+|\/+$/g, "");
    conditions.push(
      FileConditions.withSearchTerm(directoryPath, fileNameSearchTerm),
    );

    if (!showHiddenFiles) {
      conditions.push(FileConditions.suppressHiddenFilesUnder(directoryPath));
    }

    const query: SearchQueryBody = {
      limit: pageSize,
      condition: {
        operator: "AND",
        conditions: conditions,
      },
      sort_by: "relative_path",
      sort_direction: "ASC",
    };

    if (nextToken) {
      query["after"] = nextToken;
    }

    return await this.queryFiles(query, options);
  }

  /**
   * Counts files under a dataset directory whose names match a search string.
   *
   * @param params search term, directory path, dataset ID and other optional params
   * @returns the count of matching files
   */
  public async getFileCountForDirectory(
    params: GetFilesForDirectoryParams,
  ): Promise<number> {
    const {
      path,
      fileNameSearchTerm,
      datasetId,
      showHiddenFiles,
      extensions,
      options,
    } = params;

    const conditions: Condition[] = [FileConditions.inDataset(datasetId)];
    if (extensions?.length) {
      conditions.push(FileConditions.withExtensions(extensions));
    }

    const directoryPath = path.replace(/^\/+|\/+$/g, "");
    conditions.push(
      FileConditions.withSearchTerm(directoryPath, fileNameSearchTerm),
    );

    if (!showHiddenFiles) {
      conditions.push(FileConditions.suppressHiddenFilesUnder(directoryPath));
    }

    const query: SearchQueryBody = {
      condition: {
        operator: "AND",
        conditions: conditions,
      },
    };

    return await this.getFileQueryResultCount(query, options);
  }

  public async getFileCountForDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<number> {
    const query: SearchQueryBody = {
      condition: FileConditions.inDataset(datasetId),
    };

    return await this.getFileQueryResultCount(query, options);
  }

  public async getExtensionsForDirectory(
    datasetId: string,
    directoryPath: string,
    options?: Partial<Options>,
  ): Promise<string[]> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/directory-extensions`,
      new URLSearchParams({ directory_path: directoryPath }),
    );

    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<string[]>();
  }

  /**
   * Prepares fetching an AWS signed url with the correct query params.
   *
   * @param file - The file record
   * @param forDownload - Whether the signed URL is being generated to download a file
   * @returns
   */
  public getSignedUrlParams(file: FileRecord, forDownload?: boolean) {
    const queryParams = new URLSearchParams({ redirect: "false" });

    if (forDownload) {
      // If the signed URL is being generated to download a file
      // ensure the ContentDisposition is set to `attachment` to prompt
      // a browser download instead of serving the content inline
      queryParams.set("override_content_disposition", "attachment");
    } else {
      // AWS sets ContentType to `application/octet-stream` by default
      // on uploaded items, which prevents us from serving content.
      // Fortunately, the ContentType can be overridden in requests
      // so we can look up the correct MIME type and set it accordingly.
      // This ensures files like .html / .pdf can be served in browser.
      const fallbackType = "application/octet-stream";
      const mimeType = mime.getType(file.relative_path) || fallbackType;
      queryParams.set("override_content_type", mimeType);
    }

    return queryParams;
  }

  public async deleteDirectories(
    datasetId: string,
    directoryPaths: string[],
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/datasets/${datasetId}/files/delete-directories`,
    );

    const body = {
      directory_paths: directoryPaths,
    };

    await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }

  public async deleteFile(
    fileId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(`v1/files/${fileId}`);

    await this.#httpClient.delete(requestUrl, {
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return;
  }

  public async getSummary(
    fileId: string,
    options?: Partial<{ abortSignal: AbortSignal }>,
  ): Promise<AISummary> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/summary`,
    );
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({}),
    });
    return response.json<AISummary>();
  }

  public async generateSummary(
    fileId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      systemPrompt?: string;
    }>,
  ): Promise<AISummary> {
    const requestUrl = this.#httpClient.constructUrl(
      `v1/files/${fileId}/summary`,
    );

    const overrides = options?.systemPrompt
      ? { system_prompt: options.systemPrompt }
      : undefined;

    const response = await this.#httpClient.post(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({}),
      body: overrides && JSON.stringify(overrides),
    });
    return response.json<AISummary>();
  }

  private async queryFiles(
    query: SearchQueryBody,
    options?: Partial<Options>,
  ): Promise<PaginatedResponse<FileRecord>> {
    const requestUrl = this.#httpClient.constructUrl(`v1/files/query`);

    const response = await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(query),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<PaginatedResponse<FileRecord>>();
  }

  private async getFileQueryResultCount(
    query: SearchQueryBody,
    options?: Partial<Options>,
  ): Promise<number> {
    const requestUrl = this.#httpClient.constructUrl(
      "v1/files/query/result-count",
    );

    const response = await this.#httpClient.post(requestUrl, {
      body: JSON.stringify(query),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return Number.parseInt(await response.text());
  }
}
