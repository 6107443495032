export enum Accessibility {
  Organization = "organization",
  ActionHub = "action_hub",
}

export interface ComputeRequirements {
  vCPU: number;
  memory: number;
  storage: number;
  gpu: boolean;
}

export interface ContainerParameters {
  command: string[] | null;
  entry_point: string[] | null;
  env_vars: Record<string, string> | null;
  workdir: string | null;
}

export interface ActionReference {
  name: string;
  digest?: string;
  owner?: string;
}

export interface ActionParameter {
  name: string;
  required: boolean;
  description?: string;
  default?: unknown;
}

export interface ActionRecord {
  created: string;
  created_by: string;
  modified: string;
  modified_by: string;
  name: string;
  org_id: string;
  accessibility: Accessibility;
  compute_requirements?: ComputeRequirements;
  container_parameters: ContainerParameters;
  description?: string;
  digest?: string;
  inherits?: ActionReference;
  metadata: Record<string, string>;
  parameters?: ActionParameter[];
  published?: string;
  tags: string[];
  uri?: string;
  short_description?: string;
  timeout?: number;
}

export enum InvocationDataSourceType {
  Dataset = "Dataset",
}

export interface InvocationDataSource {
  data_source_type: InvocationDataSourceType;
  data_source_id: string;
}

export function isDataSourceSpecified(
  dataSource: InvocationDataSource,
): boolean {
  // Note: this must match the SDK constant defined in invocation_record.py
  return dataSource.data_source_id !== "__UNSPECIFIED_DATA_SOURCE__";
}

export enum InvocationStatus {
  Queued = 0,
  Scheduled = 1,
  Downloading = 2,
  Processing = 3,
  Uploading = 4,
  Completed = 5,
  Cancelled = 997,
  Failed = 998,
  Deadly = 999,
}

export const invocationStatusToString = (status?: InvocationStatus) => {
  switch (status) {
    case InvocationStatus.Queued:
      return "Pending";
    case InvocationStatus.Scheduled:
      return "Provisioning";
    case InvocationStatus.Downloading:
    case InvocationStatus.Processing:
    case InvocationStatus.Uploading: // Fall through
      return "Running";
    case InvocationStatus.Completed:
      return "Completed";
    case InvocationStatus.Cancelled:
      return "Cancelled";
    case InvocationStatus.Failed:
    case InvocationStatus.Deadly: // Fall through
      return "Failed";
  }
  return "Unknown";
};

export interface ActionProvenance {
  name: string;
  org_id: string;
  digest?: string;
}

export interface ExecutableProvenance {
  container_image_uri?: string;
  container_image_digest?: string;
}

export enum InvocationSource {
  Trigger = "Trigger",
  Manual = "Manual",
}

export interface SourceProvenance {
  source_type: InvocationSource;
  source_id: string;
}

export interface InvocationProvenance {
  action: ActionProvenance;
  executable: ExecutableProvenance;
  source: SourceProvenance;
}

export interface InvocationStatusRecord {
  status: InvocationStatus;
  detail?: string;
  timestamp: string;
}

export interface InvocationRecord {
  created: string;
  data_source: InvocationDataSource;
  input_data: string[];
  invocation_id: string;
  idempotency_id?: string;
  compute_requirements: ComputeRequirements;
  container_parameters: ContainerParameters;
  last_status: InvocationStatus;
  org_id: string;
  parameter_values: Record<string, string>;
  provenance: InvocationProvenance;
  status: InvocationStatusRecord[];
  duration: string;
  timeout: number;
}

export enum InvocationProcess {
  Setup = "setup",
  Action = "action",
  OutputHandler = "output_handler",
}

export interface InvocationLogRecord {
  partial_id?: string;
  log: string;
  process: InvocationProcess;
  timestamp: string;
}
