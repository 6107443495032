import { Box, Switch, useTheme } from "@mui/material";
import * as React from "react";

import { useSystemUser } from "@/providers/auth/hooks";

import { RobotoTypography } from "./RobotoTypography";

export const SystemUserViewToggle: React.FC = () => {
  const { isOverrideEnabled, toggleSystemUserOverride } = useSystemUser();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        width: "fit-content",
      }}
    >
      <Switch
        size="small"
        checked={!isOverrideEnabled}
        onChange={toggleSystemUserOverride}
      />
      <RobotoTypography variant="body2">
        {isOverrideEnabled
          ? "Admin Controls Disabled"
          : "Admin Controls Enabled"}
      </RobotoTypography>
    </Box>
  );
};
