import { FileRecord } from "@/shared/domain/files";

import { OPFSPath } from "./OPFSPath";
import { type Readable } from "./Readable";
import { StorageManager } from "./StorageManager";

export class LocalFile implements Readable {
  #abortSignal: AbortSignal | undefined;
  #fileRecord: FileRecord;
  // origin private file system path
  #opfsPath: OPFSPath | null = null;
  #storageManager: StorageManager;

  constructor(fileRecord: FileRecord, storageManager: StorageManager) {
    this.#fileRecord = fileRecord;
    this.#storageManager = storageManager;
  }

  public get id() {
    return this.#fileRecord.file_id;
  }

  public get path() {
    if (this.#opfsPath === null) {
      // Store file in OPFS as
      // <file_id>/<vN>/<filename>
      this.#opfsPath = new OPFSPath({
        name: this.#getFileRecordFileName(),
        parents: [this.#fileRecord.file_id, `v${this.#fileRecord.version}`],
      });
    }
    return this.#opfsPath;
  }

  public async isDownloaded(): Promise<boolean> {
    try {
      return (
        this.#fileRecord.size === (await this.path.getSize(this.#abortSignal))
      );
    } catch {
      return false;
    }
  }

  public async read(offset: bigint, size: bigint): Promise<Uint8Array> {
    const buffer = new Uint8Array(Number(size));
    const fileHandle = await this.path.getReadOnlySyncFileHandle(
      this.#abortSignal,
    );
    try {
      fileHandle.read(buffer, { at: Number(offset) });
      if (this.path.lastAccessTime === null) {
        await this.#storageManager.memorializeLastAccessTime(this.path);
      }
      return Promise.resolve(buffer);
    } finally {
      fileHandle.close();
    }
  }

  public setAbortSignal(abortSignal?: AbortSignal) {
    this.#abortSignal = abortSignal;
  }

  public async size(): Promise<bigint> {
    return BigInt(await this.path.getSize(this.#abortSignal));
  }

  #getFileRecordFileName() {
    const parts = this.#fileRecord.relative_path.split("/");
    return parts[parts.length - 1];
  }
}
