import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { default as PolyfilledSet } from "core-js/actual/set";
import * as React from "react";

import { RegexIcon } from "@/shared/components/icons/RegexIcon";
import { useDebouncedCallback } from "@/shared/hooks";
import { type LogPanelState } from "@/shared/state/visualization";

import { IndexState } from "../IndexState";

import { SearchMode, isQueryValid } from "./search";

interface LogSearchInputProps {
  hidden: boolean;
  onSearch: (query: string, mode: SearchMode, signal?: AbortSignal) => void;
  panelData: LogPanelState["data"];
  searchIndexState: IndexState;
}

function getPlaceholder(searchMode: SearchMode, searchIndexState: IndexState) {
  if (searchIndexState === IndexState.Empty) {
    return "Search index not yet built";
  }

  if (searchIndexState === IndexState.Building) {
    return "Building search index...";
  }

  return searchMode === SearchMode.Regex
    ? "Search by pattern"
    : "Search by keyword";
}

export function LogSearchInput(props: LogSearchInputProps) {
  const { onSearch, panelData, searchIndexState } = props;

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [searchMode, setSearchMode] = React.useState<SearchMode>(
    SearchMode.String,
  );
  const [isValidationError, setIsValidationError] =
    React.useState<boolean>(false);

  // Debounce time chosen through trial and error
  const debouncedSearch = useDebouncedCallback(onSearch, 300);

  const onQueryChange = React.useCallback(
    function onQueryChange(query: string, signal?: AbortSignal) {
      const trimmed = query.trim();
      if (isQueryValid(trimmed, searchMode)) {
        debouncedSearch(trimmed, searchMode, signal);
        setIsValidationError(false);
      } else {
        setIsValidationError(true);
      }

      setSearchTerm(query);
    },
    [debouncedSearch, searchMode],
  );

  /**
   * If visibile message paths are removed and the data is filtered, clear the search query and re-trigger search.
   *
   * This needs to happen because we don't know, for any given row,
   * whether that row matched the search query because of the now-removed message path.
   *
   * Once (if) we can select to which message path to apply a filter, this logic can be improved to be more targeted.
   */
  const prevState = React.useRef<LogPanelState["data"]>(panelData);
  React.useEffect(
    function clearQueryOnDataChange() {
      const query = searchTerm.trim();
      if (query.length === 0 || prevState.current === panelData) {
        return;
      }

      const prevMessagePaths = new PolyfilledSet(
        prevState.current.map((topicData) => topicData.messagePath.id),
      );
      const nextMessagePaths = new PolyfilledSet(
        panelData.map((topicData) => topicData.messagePath.id),
      );
      const messagePathsRemoved =
        prevMessagePaths.difference(nextMessagePaths).size > 0;

      const abortController = new AbortController();
      if (messagePathsRemoved) {
        onQueryChange("", abortController.signal);
      }

      prevState.current = panelData;

      return function abort() {
        abortController.abort();
      };
    },
    [onQueryChange, panelData, searchTerm],
  );

  return (
    <TextField
      value={searchTerm}
      disabled={searchIndexState !== IndexState.Built}
      fullWidth
      error={isValidationError}
      size="small"
      style={{
        display: props.hidden ? "none" : "block",
      }}
      placeholder={getPlaceholder(searchMode, searchIndexState)}
      onChange={(event) => onQueryChange(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon key="search" fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              title="Enable regular expressions"
              color={searchMode === SearchMode.Regex ? "primary" : "default"}
              aria-pressed={searchMode === SearchMode.Regex}
              aria-label="Toggle search by regular expression"
              onClick={() => {
                const nextSearchMode =
                  searchMode === SearchMode.Regex
                    ? SearchMode.String
                    : SearchMode.Regex;

                if (isQueryValid(searchTerm, nextSearchMode)) {
                  debouncedSearch(searchTerm, nextSearchMode);
                  setIsValidationError(false);
                } else {
                  setIsValidationError(true);
                }

                setSearchMode(nextSearchMode);
              }}
              size="small"
            >
              <RegexIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
