import * as React from "react";

import { type MessagePathNode } from "@/shared/domain/topics";

import { useTopicTreeForFile } from "../../WorkspaceCtx";

import styles from "./DataSourceSelector.module.css";
import { FileSelector } from "./FileSelector";
import { type PathSelectorProps, PathSelector } from "./PathSelector";

interface DataSourceSelectorProps {
  // Initial value used in the combo box for specifying file
  initialFile?: string;

  // Initial value used in the input box for specifying topic and message path
  initialPath?: string;

  // Callback when a valid, matched `MessagePathNode` has been selected.
  onChange: PathSelectorProps["onChange"];

  // Called for each candidate path match suggestion.
  // If it returns false, the path match suggestion is discarded.
  // Primary use case is to prune options that are inappropriate for a given panel type.
  // E.g., only show message path options that are bytes-like when selecting a message path
  // for the image panel.
  optionFilter?: (messagePath: MessagePathNode) => boolean;
}

export function DataSourceSelector({
  initialFile,
  initialPath,
  onChange,
  optionFilter,
}: DataSourceSelectorProps) {
  const [selectedFile, setSelectedFile] = React.useState<string | undefined>(
    initialFile,
  );
  const options = useTopicTreeForFile(selectedFile);

  const [shouldFocusPathSelector, setShouldFocusPathSelector] =
    React.useState(false);
  const pathSelectorRef = React.useRef<HTMLElement | null>(null);

  const onSelectFile = React.useCallback((fileId: string) => {
    setSelectedFile(fileId);
    setShouldFocusPathSelector(true);
  }, []);

  /**
   * Focus and open the path selector input when file selection changes.
   * Resets focus flag after execution.
   */
  React.useEffect(
    function focusPathSelectorOnFileChange() {
      const pathSelector = pathSelectorRef.current;
      if (shouldFocusPathSelector && pathSelector !== null) {
        const input = pathSelector.querySelector("input");
        if (input) {
          input.focus();
          input.click();
        }
        setShouldFocusPathSelector(false);
      }
    },
    [shouldFocusPathSelector],
  );

  return (
    <>
      <FileSelector
        className={styles.selector}
        onSelect={onSelectFile}
        selection={selectedFile}
      />
      <PathSelector
        className={styles.selector}
        initialInputValue={initialPath}
        onChange={onChange}
        options={options}
        optionFilter={optionFilter}
        ref={pathSelectorRef}
      />
    </>
  );
}
