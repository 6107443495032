import { LegendToggle } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { usePlotPanelContext } from "../panelContext";

export function LegendButton() {
  const { isLegendShown, setIsLegendShown } = usePlotPanelContext();
  return (
    <IconButton
      aria-label="legend-toggle"
      onClick={() => setIsLegendShown(!isLegendShown)}
      size="small"
      title="Toggle visibility of the legend"
    >
      <LegendToggle
        color={isLegendShown ? "primary" : "inherit"}
        fontSize="small"
      />
    </IconButton>
  );
}
