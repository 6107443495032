/**
 * WARNING: This function only supports adding (s) to the end of a word.
 * It does not support any other pluralization rules.
 *
 * @param word - The word to pluralize.
 * @param count - The number of items. If count is 1, the word is returned as is.
 * @returns The pluralized word.
 */
export function pluralize(word: string, count: number): string {
  if (count === 1) {
    return word;
  } else {
    return word + "s";
  }
}
