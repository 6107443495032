import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoLinkHoverUnderline } from "@/shared/components";
import CopyToClipboardButton from "@/shared/components/CopyToClipboardButton";
import { FileRecord } from "@/shared/domain/files";
import { dirname } from "@/shared/domain/files/filesystem";

const DATASET_ID_FONT_SIZE = "0.89rem";

interface FileHeaderDatasetLinkProps {
  datasetId?: string;
  fileRecord?: FileRecord;
}

export const FileHeaderDatasetLink: React.FC<FileHeaderDatasetLinkProps> = ({
  datasetId,
  fileRecord,
}) => {
  const theme = useTheme();

  const [datasetHovering, setDatasetHovering] = React.useState<boolean>(false);

  return (
    fileRecord && (
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(0.75),
          alignItems: "center",
        }}
        onMouseEnter={() => setDatasetHovering(true)}
        onMouseLeave={() => setDatasetHovering(false)}
      >
        <RobotoLinkHoverUnderline
          to={`/datasets/${datasetId}/${dirname(fileRecord.relative_path)}`}
          sx={{
            fontSize: DATASET_ID_FONT_SIZE,
            color: theme.palette.text.secondary,
          }}
        >
          {datasetId}
        </RobotoLinkHoverUnderline>
        {datasetId && (
          <CopyToClipboardButton
            text={datasetId}
            sx={{
              fontSize: DATASET_ID_FONT_SIZE,
              opacity: datasetHovering ? 1 : 0,
              transition: "opacity 0.2s",
            }}
          />
        )}
      </Box>
    )
  );
};
