import mapboxgl, { CirclePaint } from "mapbox-gl";

import { FileService } from "@/shared/domain/files";
import { Env } from "@/shared/environment";
import { RemoteFile } from "@/shared/fs";
import { McapReader, timeToSeconds } from "@/shared/mcap";
import { MapPanelState } from "@/shared/state/visualization";
import { MapPath } from "@/shared/state/visualization/schema/v1";
import { NS_PER_S, nanoSecToLocalTimestamp } from "@/shared/time";

import { ListenerType, Timer } from "../../timer/Timer";

import { defaultActiveMarker, defaultMarker } from "./colors";
import {
  getGeoMessagePaths,
  getNormalizedLatLon,
  PathData,
  TimestampedLocation,
} from "./geoTypes";
import { getNearestPoint } from "./getNearestPoint";
import { StyleToggleControl } from "./StyleToggleControl";

const activeLayerStyle: CirclePaint = {
  "circle-radius": 6,
  "circle-color": defaultActiveMarker,
};

export class MapService {
  #abortController: AbortController;
  #map: mapboxgl.Map | null = null;
  #fileService: FileService;
  #setIsLoading: (arg: boolean) => void;
  #timer?: Timer;
  #state?: MapPanelState["data"];
  #pathIdToDataMap: Map<string, PathData> = new Map();
  #representationIdToReaderMap: Map<string, McapReader> = new Map();
  #mapContainer: HTMLDivElement;
  #isMapCentered: boolean = false;
  #setSingleActiveLocation: (arg: TimestampedLocation | null) => void;
  #timerHold: symbol | null = null;
  #popup: mapboxgl.Popup | null = null;

  constructor({
    abortController,
    mapContainer,
    fileService,
    setIsLoading,
    timer,
    setSingleActiveLocation,
  }: {
    abortController: AbortController;
    mapContainer: HTMLDivElement;
    fileService: FileService;
    setIsLoading: (arg: boolean) => void;
    timer: Timer;
    setSingleActiveLocation: (arg: TimestampedLocation | null) => void;
  }) {
    this.#abortController = abortController;
    this.#mapContainer = mapContainer;
    this.#fileService = fileService;
    this.#setIsLoading = setIsLoading;
    this.#timer = timer;
    this.#setSingleActiveLocation = setSingleActiveLocation;
  }

  public initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      mapboxgl.accessToken = Env.robotoMapboxToken;

      this.#map = new mapboxgl.Map({
        container: this.#mapContainer,
        center: [0, 0], // Starting position [lon, lat]
        style: "mapbox://styles/mapbox/outdoors-v12", // https://docs.mapbox.com/api/maps/styles/
        zoom: 19, // Starting zoom level
      });

      this.#map.on("error", (evt) => {
        reject(evt.error);
      });

      this.#map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "top-right",
      );

      // Enables user to open map in fullscreen
      this.#map.addControl(new mapboxgl.FullscreenControl(), "top-right");

      // Enables user to toggle between map tiles (style)
      this.#map.addControl(
        new StyleToggleControl({
          onStyleLoad: () => this.draw(),
        }),
        "top-right",
      );

      this.#map.on("click", this.onMapClick.bind(this));

      const timerEvents: ListenerType[] = ["tick", "seek", "start", "stop"];
      timerEvents.forEach((event) =>
        this.#timer?.addListener(event, this.updateActiveLocation.bind(this), {
          signal: this.#abortController.signal,
        }),
      );
      this.#map.on("styledataloading", () => {
        if (this.#timerHold !== null || this.#timer === undefined) {
          return;
        }
        this.#timerHold = this.#timer?.hold();
      });
      this.#map.on("styledata", () => {
        if (this.#timerHold === null || this.#timer === undefined) {
          return;
        }
        this.#timer?.holdRelease(this.#timerHold);
        this.#timerHold = null;
      });

      this.#popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "300px",
        offset: 10,
        anchor: "top",
      });

      this.#map?.once("style.load", resolve);
    });
  }

  /**
   * Move the map to a given pathId
   */
  public centerOnPath(pathId: string): void {
    if (!this.#map) {
      return;
    }

    const pathData = this.#pathIdToDataMap.get(pathId);

    if (!pathData) {
      return;
    }

    const firstLatLon = getNormalizedLatLon(
      pathData.messages[0],
      pathData.latMessagePath,
      pathData.lonMessagePath,
    );

    if (firstLatLon) {
      this.#map?.setCenter({
        lon: firstLatLon[1],
        lat: firstLatLon[0],
      });
    }
  }

  /**
   * Delete the map and all associated layers/sources
   */
  public dispose() {
    // Abort any ongoing operations
    this.#abortController.abort();

    // Dispose of the map
    this.#map?.remove();

    // Dispose of the popup
    this.#popup?.remove();

    // Clear stored references
    this.#map = null;
    this.#popup = null;
    this.#timer = undefined;
    this.#state = undefined;
    this.#pathIdToDataMap.clear();
    this.#representationIdToReaderMap.clear();
  }

  /**
   * Render the map again in the available container
   */
  public resize(): void {
    if (!this.#map) {
      return;
    }

    this.#map.resize();
  }

  /**
   * Update the map state given changes to the state
   */
  public async setState(
    state: MapPath[],
    abortSignal?: AbortSignal,
  ): Promise<void> {
    if (!this.#map) {
      return;
    }

    // Remove old paths
    this.removeOldPaths(state);

    // Fetch data for newly added paths
    await this.addNewPaths(state, abortSignal);

    // Update internal state
    this.#state = state;

    this.draw();
  }

  private async addNewPaths(
    state: MapPath[],
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const pathsToAdd = state.filter(
      (path) => !this.#state?.some((existing) => existing.id === path.id),
    );
    if (pathsToAdd.length === 0) {
      return;
    }

    this.#setIsLoading(true);
    await Promise.all(
      pathsToAdd.map((path) => this.loadPathData(path, abortSignal)),
    ).finally(() => this.#setIsLoading(false));
  }

  /**
   * Safely add source and layer(s) to map once style has loaded
   */
  private addSourceAndLayers(
    sourceId: string,
    sourceData: mapboxgl.AnySourceData,
    layers: mapboxgl.AnyLayer[],
  ) {
    if (!this.#map) {
      return;
    }

    // Add the source
    if (!this.#map?.getSource(sourceId)) {
      this.#map?.addSource(sourceId, sourceData);
    }

    // Add the layers
    for (const layer of layers) {
      if (!this.#map?.getLayer(layer.id)) {
        this.#map?.addLayer(layer);
      }
    }
  }

  /**
   * Create a GeoJSON symbol layer for active markers.
   * This is used to highlight the nearest marker given the current time.
   */
  private createActiveLocationGeoJSON(
    location: TimestampedLocation,
    pathId: string,
  ): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            id: pathId,
            timestamp: location.timestamp,
          },
          geometry: {
            type: "Point",
            coordinates: [location.longitude, location.latitude],
          },
        },
      ],
    } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  }

  /**
   * Convert PathData to GeoJSON
   * This enables markers to be displayed in a performant symbol layer rendered by WebGL.
   */
  private createLocationsGeoJSON(
    pathData: PathData,
  ): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    const points = pathData.messages
      .map((message, idx) => {
        // Normalize the lat/lon input formats
        const latlon = getNormalizedLatLon(
          message,
          pathData.latMessagePath,
          pathData.lonMessagePath,
        );

        if (!latlon) {
          return null;
        }

        return {
          type: "Feature",
          properties: {
            id: idx,
            timestamp: timeToSeconds(message.logTime),
          },
          geometry: {
            type: "Point",
            coordinates: [latlon[1], latlon[0]],
          },
        };
      })
      .filter((feature) => feature !== null);

    const lineCoordinates = points.map((point) => point?.geometry.coordinates);

    return {
      type: "FeatureCollection",
      features: [
        ...points,
        {
          type: "Feature",
          properties: {
            id: "line",
          },
          geometry: {
            type: "LineString",
            coordinates: lineCoordinates,
          },
        },
      ],
    } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
  }

  /**
   * Add a specific path to the map and set up interaction handlers
   */
  private draw(abortSignal?: AbortSignal): void {
    if (
      this.#state === undefined ||
      abortSignal?.aborted ||
      this.#map === null
    ) {
      return;
    }

    // With the messages for each new path loaded, initialize them on the map
    for (const path of this.#state) {
      if (path.data.length < 2) {
        throw new Error("Missing complete lat/lon for path");
      }
      const pathData = this.#pathIdToDataMap.get(path.id);
      if (!pathData) {
        throw new Error("Failed to get path data");
      }

      //// If source/layer already exists, update styling
      if (this.#map.getSource(path.id)) {
        this.updatePathStyles(path);
        continue;
      }

      //// Else, create a new source/layers with appropriate styling applied
      // Create performant GeoJSON layer from path data
      const locationsGeoJSON = this.createLocationsGeoJSON(pathData);

      // If the map hasn't been centered yet, use the first point
      if (!this.#isMapCentered) {
        const firstLatLon = getNormalizedLatLon(
          pathData.messages[0],
          pathData.latMessagePath,
          pathData.lonMessagePath,
        );

        if (firstLatLon) {
          this.#map?.setCenter({
            lon: firstLatLon[1],
            lat: firstLatLon[0],
          });
          this.#isMapCentered = true;
        }
      }

      // Add the GeoJSON as a source to the map
      // Add a layer for markers
      // Add a layer for connecting line
      this.addSourceAndLayers(
        path.id,
        {
          type: "geojson",
          data: locationsGeoJSON,
        },
        [
          {
            id: this.getCircleLayerId(path.id),
            type: "circle",
            source: path.id,
            layout: {
              visibility: path.visible ? "visible" : "none",
            },
            paint: {
              "circle-radius": 3,
              "circle-color":
                (path.style["lineColor"] as string) || defaultMarker,
            },
          },
          {
            id: this.getLineLayerId(path.id),
            type: "line",
            source: path.id,
            layout: {
              "line-join": "round",
              "line-cap": "round",
              visibility: path.visible ? "visible" : "none",
            },
            paint: {
              "line-color":
                (path.style["lineColor"] as string) || defaultMarker,
              "line-width": 1,
            },
          },
        ],
      );

      // Setup hover interaction for the path
      this.setupHoverInteraction(this.getCircleLayerId(path.id));
    }

    this.updateActiveLocation();
  }

  /**
   * Get name for an active layer given pathId
   */
  private getActiveLayerId(pathId: string) {
    return `${pathId}-active`;
  }

  /**
   * Get name for a circle layer given pathId
   */
  private getCircleLayerId(pathId: string) {
    return `${pathId}-circle`;
  }

  /**
   * Get name for a line layer given pathId
   */
  private getLineLayerId(pathId: string) {
    return `${pathId}-line`;
  }

  /**
   * Load path data from file record sources
   */
  private async loadPathData(
    path: MapPath,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    // Determine the message paths that correspond to lat and lon data
    const { latMessagePath, lonMessagePath } = getGeoMessagePaths(path);

    // Load the data from the representation file
    // Note, this currently makes a simplifying assumption that the lat/lon data
    // comes from the same topic, and is located in the same representation file
    // This could be extended to get messages from 2 distinct lat/lon representations
    const representationId = path.data[0].representation.id;
    if (!this.#representationIdToReaderMap.has(representationId)) {
      const representationFileRecord = await this.#fileService.getFileRecord(
        path.data[0].representation.association.association_id,
        { abortSignal },
      );
      const file = new RemoteFile(representationFileRecord, this.#fileService);
      const reader = await McapReader.forFile(file, { abortSignal });
      this.#representationIdToReaderMap.set(representationId, reader);
    }
    const reader = this.#representationIdToReaderMap.get(representationId);
    if (!reader) {
      throw new Error("Failed to get reader for representation");
    }

    const messages = await reader.loadMessages({ abortSignal });
    // Memoize the loaded path data
    this.#pathIdToDataMap.set(path.id, {
      latMessagePath,
      lonMessagePath,
      messages,
    });
  }

  /**
   * Set global timer to the time associated with any clicked map points
   */
  private onMapClick(event: mapboxgl.MapLayerMouseEvent): void {
    if (!this.#map) {
      return;
    }

    // Query all features at the clicked point
    const features = this.#map.queryRenderedFeatures(event.point);

    if (features.length) {
      // Find the first Point feature with a timestamp property
      const clickedFeature = features.find(
        (feature) =>
          feature.geometry.type === "Point" &&
          feature.properties?.timestamp !== undefined,
      );

      if (clickedFeature && clickedFeature.properties) {
        const timestamp = clickedFeature.properties.timestamp as number;

        // Ensure the timestamp is valid
        if (!isNaN(timestamp)) {
          // Seek to the corresponding time in the timer
          this.#timer?.seekTo(BigInt(Math.round(timestamp * NS_PER_S)));
        }
      }
    }
  }

  /**
   * Remove old paths that are not present in the new state
   */
  private removeOldPaths(state: MapPath[]): void {
    const pathsToRemove = (this.#state ?? []).filter((existingPaths) => {
      const matching = state.find((path) => path.id === existingPaths.id);
      return matching === undefined;
    });

    for (const path of pathsToRemove) {
      const circleLayerId = this.getCircleLayerId(path.id);
      const lineLayerId = this.getLineLayerId(path.id);
      const activeLayerId = this.getActiveLayerId(path.id);
      this.#map?.removeLayer(circleLayerId);
      this.#map?.removeLayer(lineLayerId);
      this.#map?.removeSource(path.id);
      this.#map?.removeLayer(activeLayerId);
      this.#map?.removeSource(activeLayerId);
      this.#pathIdToDataMap.delete(path.id);

      const representationId = path.data[0].representation.id;
      const otherPathsShareRepresentation = this.#state?.some(
        (otherPath) => otherPath.data[0].representation.id === representationId,
      );
      if (!otherPathsShareRepresentation) {
        this.#representationIdToReaderMap.delete(representationId);
      }
    }
  }

  /**
   * Set up hover interaction for a specific path on the map
   */
  private setupHoverInteraction(pathId: string): void {
    // Show popup on hover
    this.#map?.on("mousemove", pathId, (e) => {
      if (!this.#map) {
        return;
      }

      this.#map.getCanvas().style.cursor = "pointer";

      if (e.features && e.features.length) {
        // Find the first Point feature with a valid timestamp property
        const hoveredFeature = e.features.find(
          (feature) =>
            feature.geometry.type === "Point" &&
            feature.properties?.timestamp !== undefined,
        );

        if (hoveredFeature && hoveredFeature.properties) {
          const coordinates = e.lngLat;
          const timestamp = hoveredFeature.properties.timestamp as number;

          // Ensure the timestamp is valid
          if (isNaN(timestamp)) {
            return;
          }

          // Ensure the popup is anchored to the point and displays correctly
          this.#popup
            ?.setLngLat(coordinates)
            .setHTML(
              `
            <div style="padding-top: 2px;color: #333333;">
              <div>${coordinates.lat.toFixed(6)}, ${coordinates.lng.toFixed(6)}</div>
              <div>${nanoSecToLocalTimestamp(BigInt(Math.round(timestamp * NS_PER_S)))}</div>
            </div>
          `,
            )
            .addTo(this.#map);
        }
      }
    });

    // Hide popup when not hovering
    this.#map?.on("mouseleave", pathId, () => {
      if (!this.#map) {
        return;
      }
      this.#map.getCanvas().style.cursor = "";
      this.#popup?.remove();
    });
  }

  /**
   * Show the closest point(s) to the current time on new layer(s)
   */
  private updateActiveLocation = (): void => {
    if (!this.#map || !this.#timer || this.#state === undefined) {
      return;
    }

    for (const path of this.#state) {
      const pathData = this.#pathIdToDataMap.get(path.id);
      if (!pathData) {
        throw new Error("Failed to get path data");
      }

      // Given the current time, find the nearest point idx in the path
      const newIndex = getNearestPoint(
        pathData.messages,
        this.#timer.currentTime,
      );

      // Get the lat/lon for the nearest point index
      const latlon = getNormalizedLatLon(
        pathData.messages[newIndex],
        pathData.latMessagePath,
        pathData.lonMessagePath,
      );

      if (!latlon) {
        return;
      }

      const timestampedLocation = {
        latitude: latlon[0],
        longitude: latlon[1],
        timestamp: timeToSeconds(pathData.messages[newIndex].logTime),
      };

      // If there's only 1 path, set the active location for use in textual indicator
      if (this.#pathIdToDataMap.size === 1) {
        this.#setSingleActiveLocation(timestampedLocation);
      }

      // Create GeoJSON with the single "active" point
      const activeLocationsGeoJSON = this.createActiveLocationGeoJSON(
        timestampedLocation,
        path.id,
      );

      // Either update an existing layer with the new point, or create
      // a new GeoJSON layer to show it on the map
      const activeSourceId = this.getActiveLayerId(path.id);
      const activeSource = this.#map?.getSource(activeSourceId);
      if (activeSource) {
        (activeSource as mapboxgl.GeoJSONSource).setData(
          activeLocationsGeoJSON,
        );
      } else {
        this.addSourceAndLayers(
          activeSourceId,
          {
            type: "geojson",
            data: activeLocationsGeoJSON,
          },
          [
            {
              id: activeSourceId,
              type: "circle",
              layout: {
                visibility: path.visible ? "visible" : "none",
              },
              source: activeSourceId,
              paint: activeLayerStyle,
            },
          ],
        );
      }
    }
  };

  /**
   * Update path styles given state changes
   */
  private updatePathStyles(path: MapPath): void {
    if (!this.#map) {
      return;
    }

    const circleLayerId = this.getCircleLayerId(path.id);
    const lineLayerId = this.getLineLayerId(path.id);
    const activeLayerId = this.getActiveLayerId(path.id);

    // Note circle layer and line layer both use `lineColor` property
    // This ensures the circular markers and connecting lines match

    // Update the circle color
    if (this.#map.getLayer(circleLayerId)) {
      if (path.style["lineColor"]) {
        this.#map.setPaintProperty(
          circleLayerId,
          "circle-color",
          path.style["lineColor"],
        );
      }
    }

    // Update the line color
    if (this.#map.getLayer(lineLayerId)) {
      this.#map.setPaintProperty(
        lineLayerId,
        "line-color",
        path.style["lineColor"],
      );
    }

    // Update visibility based on path.visible
    const visibility = path.visible ? "visible" : "none";
    if (this.#map.getLayer(circleLayerId)) {
      this.#map.setLayoutProperty(circleLayerId, "visibility", visibility);
    }
    if (this.#map.getLayer(lineLayerId)) {
      this.#map.setLayoutProperty(lineLayerId, "visibility", visibility);
    }
    if (this.#map.getLayer(activeLayerId)) {
      this.#map.setLayoutProperty(activeLayerId, "visibility", visibility);
    }
  }
}

// TODO:
// De-dupe issue Gabe mentioned on PR
// Determine strategy for handling active point in multi-series case
