import * as React from "react";

import { PlotTool } from "../plotTools/PlotTool";

export interface Region {
  width: number;
  height: number;
  top: number;
  left: number;
}

interface PlotPanelContext {
  activeTool: PlotTool;
  setActiveTool: (tool: PlotTool) => void;
  isViewportSynced: boolean;
  setViewportSynced: (next: boolean) => void;
  isLegendShown: boolean;
  setIsLegendShown: (next: boolean) => void;
  selectedRegion: Region | null;
  setSelectedRegion: (next: Region | null) => void;
}

export const PlotPanelContext = React.createContext<PlotPanelContext>({
  activeTool: PlotTool.Move,
  setActiveTool: () => {},

  isViewportSynced: true,
  setViewportSynced: () => {},

  isLegendShown: true,
  setIsLegendShown: () => {},

  selectedRegion: null,
  setSelectedRegion: () => {},
});
