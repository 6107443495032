import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

import { AlertDialog } from "@/shared/components/AlertDialog";
import { isAbortError } from "@/shared/errors";
import { ErrorMonitoringService } from "@/shared/services";

import { LogManager } from "../LogManager";
import { useLogPanelContext } from "../panelContext";

interface SearchToolProps {
  logManager: LogManager | null;
}

// 2025-03-17(GM): result of convo w/Benji
const A_LOT_OF_LOGS = 100_000;

export function SearchTool({ logManager }: SearchToolProps) {
  const { isSearchVisible, toggleSearchVisibility } = useLogPanelContext();
  const [confirmSearchIndexBuild, setConfirmSearchIndexBuild] =
    React.useState(false);
  const cancelIndexBuildRef = React.useRef<AbortController>(
    new AbortController(),
  );

  const manageSearchIndex = React.useCallback(
    function manageSearchIndex() {
      if (logManager === null) {
        return Promise.resolve();
      }

      cancelIndexBuildRef.current.abort();

      if (logManager.isSearchIndexEmpty) {
        cancelIndexBuildRef.current = new AbortController();
        logManager.buildSearchIndex(cancelIndexBuildRef.current.signal);
        return Promise.resolve();
      }
      return logManager.disposeSearchIndex();
    },
    [logManager],
  );

  const onClick = React.useCallback(
    function onSearchToolClicked() {
      if (logManager === null) {
        return;
      }

      if (
        !isSearchVisible &&
        logManager.isSearchIndexEmpty &&
        logManager.logCount > A_LOT_OF_LOGS
      ) {
        // render dialog prompting user to confirm an expensive operation
        // if attempting to open the search widget
        setConfirmSearchIndexBuild(true);
      } else {
        manageSearchIndex()
          .catch((err) => {
            if (isAbortError(err)) {
              return;
            }
            ErrorMonitoringService.captureError(err);
          })
          .finally(toggleSearchVisibility);
      }
    },
    [isSearchVisible, toggleSearchVisibility, logManager, manageSearchIndex],
  );

  const confirmationMessage = [
    "Searching within the Log Panel requires building a search index in the background.",
  ];
  if (logManager?.logCount !== undefined) {
    confirmationMessage.push(
      `This panel contains ${logManager.logCount.toLocaleString()} logs.`,
    );
  }
  Array.prototype.push.apply(confirmationMessage, [
    "Building the search index may take a while, and depending on the size of the data, may cause your browser to run out of memory.",
    "We're working on improving this feature to make it more performant for topics with many messages.",
  ]);

  return (
    <>
      <IconButton
        aria-label="search-logs"
        disableFocusRipple
        onClick={onClick}
        size="small"
        title="Search logs"
      >
        <SearchIcon
          color={isSearchVisible ? "primary" : "inherit"}
          fontSize="small"
        />
      </IconButton>
      <AlertDialog
        dialogOpen={confirmSearchIndexBuild}
        handleClose={() => {
          setConfirmSearchIndexBuild(false);
        }}
        dialogTitle="Build log search index"
        dialogText={confirmationMessage.join(" ")}
        dialogAction={async () => {
          await manageSearchIndex();
          toggleSearchVisibility();
        }}
      />
    </>
  );
}
