import * as React from "react";
import { useLocation } from "react-router-dom";

// The value used here is the last path segment in the URL
export enum DatasetTab {
  Files = "files",
  Topics = "topics",
  Events = "events",
  Actions = "actions",
  Details = "details",
  Permissions = "permissions",
  AISummary = "summary",
}

function deriveActiveTabFromUrl(path: string | undefined) {
  switch (path) {
    case DatasetTab.Topics:
      return DatasetTab.Topics;
    case DatasetTab.Events:
      return DatasetTab.Events;
    case DatasetTab.Actions:
      return DatasetTab.Actions;
    case DatasetTab.Details:
      return DatasetTab.Details;
    case DatasetTab.Permissions:
      return DatasetTab.Permissions;
    case DatasetTab.AISummary:
      return DatasetTab.AISummary;
    default:
      return DatasetTab.Files;
  }
}

export function useActiveDatasetTab() {
  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const [activeTab, setActiveTab] = React.useState(
    deriveActiveTabFromUrl(path),
  );

  React.useEffect(() => {
    setActiveTab(deriveActiveTabFromUrl(path));
  }, [path]);

  return [activeTab, setActiveTab] as const;
}
