import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";
import { AISummary } from "@/shared/domain/ai_summary.ts";

interface RegenerateSummaryOptions {
  systemPrompt?: string;
}

export function useRegenerateFileSummary() {
  const { files } = useDomainServices();
  const queryClient = useQueryClient();

  return useMutation<
    AISummary,
    Error,
    { fileId: string; options?: RegenerateSummaryOptions }
  >({
    mutationFn: ({ fileId, options }) => {
      return files.generateSummary(fileId, {
        systemPrompt: options?.systemPrompt,
      });
    },
    onSuccess: (newSummary, { fileId }) => {
      queryClient.setQueryData(["file-summary", fileId], newSummary);
    },
  });
}
