import { Goto } from "@/shared/navigation";

export const useURLService = () => {
  // these functions can be used to generate urls for <Link> components
  function generateSignUpURL(inviteId?: string) {
    if (inviteId) {
      return `${Goto.route.SignUp}?inviteId=${inviteId}`;
    }

    return Goto.route.SignUp;
  }

  function generateSignInURL(inviteId?: string) {
    if (inviteId) {
      return `${Goto.route.SignIn}?inviteId=${inviteId}`;
    }

    return Goto.route.SignIn;
  }

  return {
    generateSignUpURL,
    generateSignInURL,
  };
};
