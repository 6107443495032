import { RobotoButton } from "@/shared/components/RobotoButton";
import { Dimensions } from "@/shared/hooks";

import { PlotManager } from "../../PlotManager";
import { Extents } from "../../PlotRenderer";
import { Viewport } from "../../Viewport";
import { pixelRegionToViewport } from "../chartRegionToTimeRange";

interface ZoomFromSelectionButtonProps {
  chartExtents: Extents;
  onClose: () => void;
  plotManager: PlotManager | null;
  selectedRegion: Dimensions;
}

export function ZoomFromSelectionButton({
  chartExtents,
  onClose,
  plotManager,
  selectedRegion,
}: ZoomFromSelectionButtonProps) {
  const domain = pixelRegionToViewport(selectedRegion, chartExtents);

  return (
    <RobotoButton
      eventName={"ZoomFromSelectionClicked"}
      variant="text"
      onClick={function zoomToRegion() {
        plotManager?.applyViewport(
          Viewport.fromDomainAndRange({
            viewportDomain: domain,
            viewportRange: chartExtents.data.viewportRange,
            dataDomain: chartExtents.data.fullDomain,
            dataRange: chartExtents.data.fullRange,
          }),
        );
        // Delete selected region upon zooming
        onClose();
      }}
    >
      Zoom
    </RobotoButton>
  );
}
