import { styled } from "@mui/material";

export const HighlightedSpan = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  cursor: "pointer",
  display: "inline",
  padding: "2px 4px",
  borderRadius: "4px",
  whiteSpace: "normal",
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));
