import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { updateDatasetInSearchResultsCache } from "../../query/hooks/useQueryResults";
import { DatasetRecord, UpdateDatasetRequest } from "../DatasetRecord";

interface UpdateDataset {
  datasetId: string;
  resourceOwnerId: string;
  updates: UpdateDatasetRequest;
}

export function useUpdateDataset() {
  const { datasetService } = React.useContext(DomainServicesContext);

  const queryClient = useQueryClient();

  return useMutation<DatasetRecord, Error, UpdateDataset>({
    mutationFn: async ({ datasetId, resourceOwnerId, updates }) => {
      return await datasetService.updateDataset(datasetId, updates, {
        resourceOwnerId,
      });
    },

    onSuccess: (datasetRecord) => {
      updateDatasetInSearchResultsCache(queryClient, datasetRecord);

      queryClient.setQueryData(
        ["datasetRecord", datasetRecord.dataset_id],
        datasetRecord,
      );
    },
  });
}
