import { type Condition } from "@/types";

import {
  type ActionReference,
  type ComputeRequirements,
  type ContainerParameters,
  type InvocationDataSource,
} from "./ActionRecord";

export enum TriggerEvaluationCause {
  DatasetMetadataUpdate = "dataset_metadata_update",
  FileUpload = "file_upload",
  FileIngest = "file_ingest",
}

const causeToString: Record<TriggerEvaluationCause, string> = {
  [TriggerEvaluationCause.FileUpload]: "File Uploaded",
  [TriggerEvaluationCause.DatasetMetadataUpdate]: "Dataset Metadata Updated",
  [TriggerEvaluationCause.FileIngest]: "File Ingested",
};

export function causeToFormattedString(cause: TriggerEvaluationCause): string {
  return causeToString[cause];
}

export enum TriggerEvaluationOutcome {
  InvokedAction = "invoked_action",
  Skipped = "skipped",
}

export enum TriggerEvaluationOutcomeReason {
  AlreadyRun = "already_run",
  ConditionNotMet = "condition_not_met",
  NoMatchingFiles = "no_matching_files",
  TriggerDisabled = "trigger_disabled",
}

export enum TriggerEvaluationStatus {
  Pending = "pending",
  Evaluated = "evaluated",
  Failed = "failed",
}

export interface TriggerEvaluationRecord {
  trigger_evaluation_id: number;
  trigger_id: string;
  data_source: InvocationDataSource;
  evaluation_start: string;
  evaluation_end?: string;
  status: TriggerEvaluationStatus;
  status_detail?: string;
  outcome?: TriggerEvaluationOutcome;
  outcome_reason?: TriggerEvaluationOutcomeReason;
  cause?: TriggerEvaluationCause;
}

export interface TriggerEvaluationSummary {
  count_pending: number;
  last_evaluation_start: string | null;
}

export enum TriggerForEachPrimitive {
  Dataset = "dataset",
  DatasetFile = "dataset_file",
}

export interface TriggerRecord {
  action: ActionReference;
  additional_inputs?: string[];
  causes: TriggerEvaluationCause[];
  compute_requirement_overrides?: ComputeRequirements;
  condition?: Condition;
  container_parameter_overrides?: ContainerParameters;
  created: string;
  created_by: string;
  enabled: boolean;
  for_each: TriggerForEachPrimitive;
  modified: string;
  modified_by: string;
  name: string;
  org_id: string;
  parameter_values: Record<string, unknown>;
  required_inputs: string[];
  service_user_id: string;
  timeout?: number;
  trigger_id: string;
}

export type UpdateTriggerRequest = Partial<{
  action_name: string;
  action_owner_id: string;
  action_digest: string;
  additional_inputs: string[];
  causes: TriggerEvaluationCause[];
  compute_requirement_overrides: ComputeRequirements;
  container_parameter_overrides: ContainerParameters;
  condition: Condition;
  enabled: boolean;
  for_each: TriggerForEachPrimitive;
  parameter_values: Record<string, unknown>;
  required_inputs: string[];
  timeout: number;
}>;
