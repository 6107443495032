import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box } from "@mui/material";
import * as React from "react";

import { basename, FileNode } from "@/shared/domain/files/filesystem";

import { GridItem } from "../GridItem";

interface DatasetTextItemProps {
  item: FileNode;
  signedUrl: string;
  loading: boolean;
  setLoading: (arg: boolean) => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DatasetTextItem: React.FC<DatasetTextItemProps> = ({
  item,
  signedUrl,
  loading,
  setLoading,
  onClick,
}) => {
  React.useEffect(() => {
    setLoading(false);
  }, [setLoading, signedUrl]);

  return (
    <>
      <GridItem
        variant="outlined"
        sx={{
          display: loading ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: loading ? 0 : 1,
          transition: "opacity 0.5s",
          width: "100%",
          height: "100%",
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <Box>
            <InsertDriveFileOutlinedIcon fontSize="large" color="info" />
          </Box>
          <Box
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {basename(item.name)}
          </Box>
        </Box>
      </GridItem>
    </>
  );
};
