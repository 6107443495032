import { Autocomplete } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import {
  type MessagePathNode,
  type TopicNode,
  pathParser as parser,
} from "@/shared/domain/topics";

import { type PathMatch, pathMatcher as matcher } from "./PathMatcher";
import styles from "./PathSelector.module.css";
import { PathSelectorInput } from "./PathSelectorInput";

export interface PathSelectorProps {
  className?: classNames.Argument;
  initialInputValue?: string;
  onChange: (value: MessagePathNode) => void;
  options: TopicNode[];
  optionFilter?: (messagePath: MessagePathNode) => boolean;
}

export const PathSelector = React.forwardRef(function PathSelector(
  {
    className,
    initialInputValue,
    onChange,
    options,
    optionFilter,
  }: PathSelectorProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const [inputValue, setInputValue] = React.useState(initialInputValue ?? "");
  const [selection, setSelection] = React.useState<PathMatch | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const parsedInput = React.useMemo(
    () => parser.parse(inputValue),
    [inputValue],
  );
  const matches = React.useMemo<PathMatch[]>(
    () => matcher.findMatches(parsedInput, options, optionFilter),
    [parsedInput, options, optionFilter],
  );

  // Expect 0 or 1 complete matches.
  const firstCompleteMatch = React.useMemo(
    () => matches.find((match) => match.isComplete),
    [matches],
  );

  const onClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const onInput = React.useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setInputValue(value);
    },
    [],
  );

  const onOpen = React.useCallback(() => setIsOpen(true), []);

  const onSelect = React.useCallback(
    (_event: React.SyntheticEvent, value: string | PathMatch | null) => {
      if (value && typeof value !== "string") {
        setSelection(value);
        if (value.match !== undefined) {
          onChange(value.match);
        }
      }
    },
    [onChange],
  );

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      className={classNames(className)}
      filterOptions={(x) => x} // https://v5.mui.com/material-ui/react-autocomplete/#search-as-you-type
      freeSolo
      getOptionLabel={(option: string | PathMatch) => {
        if (typeof option === "string") {
          return option;
        }

        return option.parts.map((part) => part.substring).join("");
      }}
      inputValue={inputValue}
      isOptionEqualToValue={(option: PathMatch, value: PathMatch) => {
        return option.match.equals(value.match);
      }}
      onChange={onSelect}
      onClose={onClose}
      onInputChange={onInput}
      onOpen={onOpen}
      openOnFocus
      options={matches}
      ref={ref}
      renderInput={(params) => (
        <PathSelectorInput
          hasExactMatch={firstCompleteMatch !== undefined}
          inputValue={inputValue}
          inputParams={params}
          isValid={matches.length > 0}
          selectorIsOpen={isOpen}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li
            key={option.match.id}
            {...props}
            className={classNames(props.className, styles.option)}
          >
            {option.parts.map((part, idx) => {
              return (
                <span
                  key={`${idx}#${part.substring}`}
                  className={classNames({ [styles.highlight]: part.isMatch })}
                >
                  {part.substring}
                </span>
              );
            })}
          </li>
        );
      }}
      size="small"
      value={selection}
    />
  );
});
