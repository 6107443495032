import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

export function useDatasetFileCount(
  datasetId: string,
  orgId: string | undefined,
): UseQueryResult<number, Error> {
  const { files } = useDomainServices();

  return useQuery({
    queryKey: ["datasetFileCount", datasetId, orgId],
    queryFn: async ({ signal }) => {
      return await files.getFileCountForDataset(datasetId, {
        abortSignal: signal,
        resourceOwnerId: orgId,
      });
    },
    staleTime: 1000,
  });
}
