import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";

import { CommentRecord } from "@/shared/domain/comments";

import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoLink } from "../RobotoLink";
import { RobotoMarkdown } from "../RobotoMarkdown";
import { RobotoTooltip } from "../RobotoTooltip";

const ROBOTO_PLATFORM_USER_IDS = ["support@roboto.ai", "system@roboto.ai"];

function trimComment(text: string) {
  return text.length > 300 ? text.slice(0, 300) + "..." : text;
}

interface DisplayCommentProps {
  comment: CommentRecord;
  currentUserId: string;
  avatarUrl: string | null;
  onDeleteCommentPressed: (comment: CommentRecord) => void;
  onEditCommentPressed: (comment: CommentRecord) => void;
  summaryView?: boolean;
}

export const DisplayComment: React.FC<DisplayCommentProps> = ({
  comment,
  currentUserId,
  avatarUrl,
  onDeleteCommentPressed,
  onEditCommentPressed,
  summaryView,
}) => {
  const theme = useTheme();
  const time = new Date(comment.modified);
  const humanTime = time
    ? dayjs.duration(dayjs(time).diff(dayjs())).humanize(true)
    : "Never";
  const displayText = summaryView
    ? trimComment(comment.comment_text)
    : comment.comment_text;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(0.5),
        gap: theme.spacing(0.5),
        position: "relative",
        ":hover .hoverButtons": { opacity: 1 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {ROBOTO_PLATFORM_USER_IDS.includes(comment.created_by) ? (
          <Avatar
            imgProps={{ referrerPolicy: "no-referrer" }}
            sx={{ width: 38, height: 38 }}
          >
            <img
              src="/iconWhite.svg"
              style={{ width: "40px", height: "40px" }}
              alt="Roboto Logo"
            />
          </Avatar>
        ) : (
          <Avatar
            src={avatarUrl ?? ""}
            imgProps={{ referrerPolicy: "no-referrer" }}
            sx={{ width: 38, height: 38 }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: theme.spacing(2),
          flex: 1,
          a: {
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography variant="caption" component="span">
          <span style={{ fontWeight: 500 }}>
            {ROBOTO_PLATFORM_USER_IDS.includes(comment.created_by) ? (
              <>{comment.created_by}</>
            ) : (
              <RobotoLink
                to={`/users/${comment.created_by}`}
                sx={{ color: theme.palette.text.primary }}
              >
                {comment.created_by}
              </RobotoLink>
            )}
          </span>
          &nbsp;{" "}
          <span
            style={{ color: "#888" }}
            title={time.toLocaleString() + " (" + humanTime + ")"}
          >
            {time.toLocaleDateString(undefined, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}{" "}
            at{" "}
            {time.toLocaleTimeString(undefined, {
              hour: "numeric",
              minute: "numeric",
            })}
            {summaryView && (
              <>
                {" on "}
                <RobotoLink
                  to={`/${comment.entity_type}s/${comment.entity_id}`}
                  sx={{ color: "#888" }}
                >
                  {comment.entity_id}
                </RobotoLink>{" "}
              </>
            )}
            {comment.created !== comment.modified ? "(edited)" : ""}
          </span>
        </Typography>
        <Box
          sx={{
            fontSize: "0.9rem",
            a: { color: theme.palette.primary.main },
          }}
        >
          <RobotoMarkdown content={displayText} />
        </Box>
      </Box>
      {comment.created_by === currentUserId && !summaryView && (
        <Box
          className="hoverButtons"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            opacity: 0,
            transition: "opacity 0.2s ease",
          }}
        >
          <RobotoIconButton
            eventName={"EditCommentClicked"}
            eventProperties={{ commentId: comment.comment_id }}
            sx={{ marginRight: theme.spacing(1) }}
            onClick={() => onEditCommentPressed(comment)}
          >
            <RobotoTooltip title="Edit">
              <EditIcon fontSize="small" color="primary" />
            </RobotoTooltip>
          </RobotoIconButton>
          <RobotoIconButton
            eventName={"DeleteCommentClicked"}
            eventProperties={{ commentId: comment.comment_id }}
            onClick={() => onDeleteCommentPressed(comment)}
          >
            <RobotoTooltip title="Delete">
              <DeleteIcon fontSize="small" color="primary" />
            </RobotoTooltip>
          </RobotoIconButton>
        </Box>
      )}
    </Box>
  );
};
