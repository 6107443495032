import React from "react";

import { useNavigation } from "@/providers";
import { RobotoTooltip } from "@/shared/components/RobotoTooltip";
import {
  constructPanelForMessagePath,
  determineDefaultPanelType,
} from "@/shared/components/visualization/DndContainer/dropHandlers";
import { useDataset } from "@/shared/domain/datasets/hooks";
import { useFile } from "@/shared/domain/files/hooks";
import { getMessagePathNodeFromRecord } from "@/shared/domain/topics";
import { useMessagePathById } from "@/shared/domain/topics/hooks/useMessagePath.ts";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic.ts";
import { actions, useVizDispatch } from "@/shared/state/visualization";

import { HighlightedSpan } from "./HighlightedSpan";

interface AISummaryMarkdownLinkProps {
  datasetId?: string;
  fileId?: string;
  topicId?: string;
  messagePathId?: string;
  time?: bigint;
  text: string;
}

export const AISummaryMarkdownLink: React.FC<AISummaryMarkdownLinkProps> = ({
  datasetId,
  fileId,
  topicId,
  messagePathId,
  time,
  text,
}) => {
  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();
  const dataset = useDataset(datasetId);
  const file = useFile(fileId);
  const topic = useTopicById(topicId);
  const messagePath = useMessagePathById(messagePathId);
  const messagePathTopic = useTopicById(messagePath.data?.topic_id);

  let linkProps:
    | {
        tooltipText: string;
        onClick: () => void;
      }
    | undefined = undefined;

  if (messagePath.data && messagePathTopic.data) {
    const messagePathNode = getMessagePathNodeFromRecord(
      messagePathTopic.data,
      messagePath.data,
    );
    if (messagePathNode) {
      linkProps = {
        tooltipText: `Visualize: ${messagePathTopic.data.topic_name}.${messagePath.data.message_path}`,
        onClick: () => {
          goto.file({
            fileId: messagePathTopic.data.association.association_id,
            params: time ? { t: time } : undefined,
            beforeNavigation: () => {
              vizDispatch(actions.removeAllPanels());
              vizDispatch(
                actions.putFiles([
                  { fileId: messagePathTopic.data.association.association_id },
                ]),
              );
              constructPanelForMessagePath(
                vizDispatch,
                messagePathNode,
                determineDefaultPanelType(messagePathNode),
              );
            },
          });
        },
      };
    }
  } else if (topic.data) {
    linkProps = {
      tooltipText: `Visualize: ${topic.data.topic_name}`,
      onClick: () => {
        goto.file({
          fileId: topic.data.association.association_id,
          params: {
            filterTerm: topic.data.topic_name,
            ...(time ? { t: time } : {}),
          },
        });
      },
    };
  } else if (file.data) {
    linkProps = {
      tooltipText: `View: ${file.data.relative_path}`,
      onClick: () => {
        goto.file({
          fileId: file.data.file_id,
          params: time ? { t: time } : undefined,
        });
      },
    };
  } else if (dataset.data) {
    linkProps = {
      tooltipText: `View: '${dataset.data.dataset_id}'`,
      onClick: () => {
        goto.dataset({ datasetId: dataset.data.dataset_id });
      },
    };
  }

  // If the link doesn't resolve to anything valid, just render the text
  if (!linkProps) {
    return <span>{text}</span>;
  }

  return (
    <RobotoTooltip title={linkProps.tooltipText}>
      <HighlightedSpan onClick={linkProps.onClick}>{text}</HighlightedSpan>
    </RobotoTooltip>
  );
};
