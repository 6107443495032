import { useState } from "react";
import * as React from "react";

import { CommentRecord } from "@/shared/domain/comments";

import { AddEditComment } from "./AddEditComment";
import { DisplayComment } from "./DisplayComment";

interface CommentDisplayAndEditProps {
  comment: CommentRecord;
  avatarUrl: string | null;
  currentUserId: string;
  onDeleteCommentClicked: (comment: CommentRecord) => void;
  onCommentSaveClicked: (comment: CommentRecord) => void;
  summaryView?: boolean;
}

export const CommentDisplayAndEdit: React.FC<CommentDisplayAndEditProps> = ({
  comment,
  avatarUrl,
  currentUserId,
  onDeleteCommentClicked,
  onCommentSaveClicked,
  summaryView,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      {editMode ? (
        <AddEditComment
          entityId={comment.entity_id}
          entityType={comment.entity_type}
          onCommentSaved={(comment) => {
            onCommentSaveClicked(comment);
            setEditMode(false);
          }}
          onCancelClicked={() => setEditMode(false)}
          commentToEdit={comment}
        />
      ) : (
        <DisplayComment
          comment={comment}
          avatarUrl={avatarUrl}
          currentUserId={currentUserId}
          onDeleteCommentPressed={onDeleteCommentClicked}
          onEditCommentPressed={() => {
            setEditMode(true);
          }}
          summaryView={summaryView}
        />
      )}
    </>
  );
};
