import { useWorkspaceMessagePathById } from "@/shared/components/visualization/WorkspaceCtx/hooks.tsx";

import { WorkspaceActionLink } from "./WorkspaceActionLink";

interface WorkspaceMessagePathLinkProps {
  messagePathId: string;
  text: string;
  time?: bigint;
}

export const WorkspaceMessagePathLink: React.FC<
  WorkspaceMessagePathLinkProps
> = ({ messagePathId, text, time }) => {
  const messagePath = useWorkspaceMessagePathById(messagePathId);

  if (!messagePath) {
    if (time) {
      return <WorkspaceActionLink text={text} time={time} />;
    } else {
      return <span>{text}</span>;
    }
  } else {
    return (
      <WorkspaceActionLink
        messagePathId={messagePathId}
        text={text}
        time={time}
      />
    );
  }
};
