import { CSSProperties } from "react";

import { nanoSecToSecLossy } from "@/shared/time";

import { formatDuration } from "./formatDuration";
import { Unit } from "./schema";

interface EventDurationProps {
  startTime: string;
  endTime: string;
  unit: Unit;
  style?: CSSProperties;
  show?: boolean;
}

function convertTimeToSeconds(time: string, unit: Unit): number {
  switch (unit) {
    case Unit.Sec:
      return Number.parseFloat(time);
    case Unit.NanoSec:
      try {
        return nanoSecToSecLossy(BigInt(time));
      } catch {
        return NaN;
      }
  }
}

function calculateAndFormatDuration(
  startTime: string,
  endTime: string,
  unit: Unit,
): string {
  // Edge case: either start or end time is an empty string
  if (!startTime) {
    return "Invalid start time";
  }

  if (!endTime) {
    return "Invalid end time";
  }

  try {
    const startTimeSec = convertTimeToSeconds(startTime, unit);
    const endTimeSec = convertTimeToSeconds(endTime, unit);

    // Validate start and end times
    if (isNaN(startTimeSec)) {
      return "Invalid start time";
    }

    if (isNaN(endTimeSec)) {
      return "Invalid end time";
    }

    if (startTimeSec > endTimeSec) {
      return "Start time must be before end time";
    }

    const durationInSeconds = endTimeSec - startTimeSec;
    return formatDuration(durationInSeconds);
  } catch {
    return "Invalid duration";
  }
}

export function EventDuration({
  startTime,
  endTime,
  unit,
  style,
  show = true,
}: EventDurationProps) {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        height: "42px",
        borderLeftWidth: "2px",
        borderLeftStyle: "solid",
        borderRightWidth: "2px",
        borderRightStyle: "solid",
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.82rem",
        fontFamily: "monospace",
        ...style,
      }}
    >
      {calculateAndFormatDuration(startTime, endTime, unit)}
    </div>
  );
}
