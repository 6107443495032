import { type NavigateFunction } from "react-router-dom";

import { SearchParams } from "@/shared/environment";
import { LoggerService } from "@/shared/services/LoggerService";
import { actions, Dispatch } from "@/shared/state/visualization";
import { nanoSecToSecStr } from "@/shared/time";

import {
  ActionRouteParams,
  ActionsRouteParam,
  CollectionRouteParams,
  DatasetRouteParams,
  DeviceRouteParams,
  FileRouteParams,
  InviteRouteParams,
  InvocationRouteParams,
  InvokeActionRouteParams,
  SignInRouteParams,
  SignUpRouteParams,
  TriggerRouteParams,
  VisualizerRouteParams,
} from "./types";

export class Goto {
  static route = Object.freeze({
    Account: "/account",
    ActionHub: "/actions/hub",
    Actions: "/actions",
    Admin: "/admin",
    Collections: "/collections",
    CreateAction: "/actions/create",
    CreateDataset: "/datasets/create",
    CreateTrigger: "/triggers/create",
    Datasets: "/datasets",
    Devices: "/devices",
    Files: "/files",
    Home: "/",
    Invite: "/invites",
    Invocations: "/invocations",
    InvokeAction: "/actions/invoke",
    Notifications: "/notifications",
    Orgs: "/orgs",
    Search: "/search",
    Settings: "/settings",
    SignIn: "/signin",
    SignUp: "/signup",
    Triggers: "/triggers",
    Visualize: "/visualize",
  } as const);

  #navigate: NavigateFunction;
  #vizDispatch: Dispatch;

  constructor(navigator: NavigateFunction, vizDispatch: Dispatch) {
    this.#navigate = navigator;
    this.#vizDispatch = vizDispatch;
  }

  public home() {
    this.#navigate(Goto.route.Home);
  }

  public signIn(params: SignInRouteParams = {}) {
    const { inviteId, chooseOrg } = params;

    if (inviteId && chooseOrg) {
      LoggerService.warn(
        "Both inviteId and chooseOrg are set. Ignoring chooseOrg",
      );
    }

    if (inviteId) {
      this.#navigate(`${Goto.route.SignIn}?inviteId=${inviteId}`);
      return;
    }

    if (chooseOrg) {
      this.#navigate(`${Goto.route.SignIn}?chooseorg=true`);

      return;
    }

    this.#navigate(Goto.route.SignIn);
  }

  public signUp(params: SignUpRouteParams = {}) {
    const { interruptedVerificationCode } = params;

    if (interruptedVerificationCode) {
      this.#navigate(`${Goto.route.SignUp}?interruptedVerificationCode=true`);
    } else {
      this.#navigate(Goto.route.SignUp);
    }
  }

  public invite(params: InviteRouteParams = {}) {
    const { inviteId } = params;
    if (inviteId) {
      this.#navigate(`${Goto.route.Invite}/${inviteId}`);
    } else {
      this.#navigate(Goto.route.Invite);
    }
  }

  public search(urlParams?: URLSearchParams) {
    if (urlParams) {
      this.#navigate(`${Goto.route.Search}?${urlParams.toString()}`);
      return;
    }
    this.#navigate(Goto.route.Search);
  }

  public dataset({ datasetId, filePaths }: DatasetRouteParams) {
    const datasetUrl = `${Goto.route.Datasets}/${datasetId}`;
    if (filePaths) {
      this.#navigate(
        `${datasetUrl}/files/${filePaths.map(encodeURIComponent).join("/")}`,
      );
    }

    this.#navigate(datasetUrl);
  }

  public datasetTopics({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/topics`);
  }

  public datasetEvents({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/events`);
  }

  public datasetActions({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/actions`);
  }

  public datasetDetails({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/details`);
  }

  public datasetPermissions({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/permissions`);
  }

  public createDataset() {
    this.#navigate(`${Goto.route.CreateDataset}`);
  }

  public createDatasetEvent({ datasetId }: DatasetRouteParams) {
    this.#navigate(`${Goto.route.Datasets}/${datasetId}/events/create`);
  }

  public actions(params: ActionsRouteParam = {}) {
    const { tab } = params;
    if (tab) {
      this.#navigate(`${Goto.route.Actions}?tab=${tab}`);
    } else {
      this.#navigate(Goto.route.Actions);
    }
  }

  public action({ action }: ActionRouteParams) {
    const urlParts: string[] = [Goto.route.Actions];
    if (action.owner) {
      urlParts.push(action.owner);
    }
    if (action.digest) {
      urlParts.push(`${action.name}?digest=${action.digest}`);
    } else {
      urlParts.push(action.name);
    }
    this.#navigate(urlParts.join("/"));
  }

  public createAction() {
    this.#navigate(Goto.route.CreateAction);
  }

  public invocation({ invocationId, tab }: InvocationRouteParams) {
    if (tab) {
      this.#navigate(`${Goto.route.Invocations}/${invocationId}?tab=${tab}`);
    } else {
      this.#navigate(`${Goto.route.Invocations}/${invocationId}`);
    }
  }

  public invokeAction(params: InvokeActionRouteParams = {}) {
    const { action, datasetId, baseInvocation, inputFiles } = params;
    const urlParams = new URLSearchParams();

    if (action) {
      // Add "action_digest" to query params when we want to
      // enable invoking a specific version of an action
      urlParams.append("action_name", action.name);
      if (action.owner) {
        urlParams.append("action_owner", action.owner);
      }
    }
    if (datasetId) {
      urlParams.append("dataset_id", datasetId);
    }
    if (baseInvocation) {
      urlParams.append("base_invocation", baseInvocation);
    }
    if (inputFiles) {
      const encodedInputFiles = inputFiles
        .map((item) => encodeURIComponent(item))
        .join(",");
      urlParams.append("input_files", encodedInputFiles);
    }

    const queryString = urlParams.toString();
    const href = queryString
      ? `${Goto.route.InvokeAction}?${urlParams.toString()}`
      : `${Goto.route.InvokeAction}`;

    this.#navigate(href);
  }

  public trigger({ triggerId }: TriggerRouteParams) {
    this.#navigate(`${Goto.route.Triggers}/${triggerId}`);
  }

  public createTrigger() {
    this.#navigate(Goto.route.CreateTrigger);
  }

  public collections() {
    this.#navigate(Goto.route.Collections);
  }

  public collection({ collectionId }: CollectionRouteParams) {
    this.#navigate(`${Goto.route.Collections}/${collectionId}`);
  }

  public device({ deviceId }: DeviceRouteParams) {
    this.#navigate(`${Goto.route.Devices}/${deviceId}`);
  }

  public file({ fileId, params, beforeNavigation }: FileRouteParams) {
    if (beforeNavigation !== undefined) {
      beforeNavigation();
    }

    window.scrollTo(0, 0); // Ensure the page doesn't load scrolled down

    if (params) {
      const { t, ...rest } = params;
      const searchParams = new URLSearchParams(rest);

      if (t) {
        // The workspace expects the timestamp to be encoded as a fixed-point seconds value.
        searchParams.set(SearchParams.TIMESTAMP, nanoSecToSecStr(t));
      }

      const hasSearchParams = searchParams.size > 0;

      this.#navigate(
        `${Goto.route.Files}/${fileId}${hasSearchParams ? `?${searchParams}` : ""}`,
      );
    } else {
      this.#navigate(`${Goto.route.Files}/${fileId}`);
    }
  }

  public visualize({ files }: VisualizerRouteParams) {
    this.#vizDispatch(actions.putFiles(files));
    window.scrollTo(0, 0); // Ensure the page doesn't load scrolled down
    this.#navigate(Goto.route.Visualize);
  }
}

export type PageRoute = (typeof Goto.route)[keyof typeof Goto.route];

export type ExternalRoute = `https://${string}`;
