import { Box, Tab, Tabs, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { RobotoStyledTabProps, RobotoTabPanelProps } from "./types";

export const RobotoStyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiTabs-scrollButtons.Mui-disabled": {
    opacity: 0.3,
  },
  "&": {
    minHeight: "30px",
  },
  "& .MuiTabs-scroller": {
    flex: "0 1 auto",
  },
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(4),
  },
  "& .MuiButtonBase-root": {
    minWidth: "unset",
    minHeight: "unset",
    padding: 0,
  },
}));

export const RobotoStyledTab = styled((props: RobotoStyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

export const RobotoSmallStyledTab = styled((props: RobotoStyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

export const RobotoTabPanel = (props: RobotoTabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};
