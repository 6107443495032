import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { FileNode } from "@/shared/domain/files/filesystem";
import { PaginatedResponse } from "@/shared/http";

import { FileRecord } from "../FileRecord";

export function useAllDirectoryFiles(
  datasetId?: string,
  directoryPath?: string,
  resourceOwnerId?: string,
) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<FileNode[], Error>({
    queryKey: ["allDirectoryFiles", directoryPath, datasetId],
    queryFn: async ({ signal }) => {
      if (directoryPath === undefined || !datasetId) {
        return [];
      }

      let items: FileRecord[] = [];

      let nextToken: string | undefined = undefined;

      do {
        const data: PaginatedResponse<FileRecord> =
          await files.getFilesForDirectory({
            path: directoryPath,
            fileNameSearchTerm: "",
            datasetId,
            pageSize: 1000,
            nextToken,
            showHiddenFiles: true,
            options: {
              abortSignal: signal,
              resourceOwnerId,
            },
          });

        items = items.concat(data.items);
        nextToken = data.next_token;
      } while (nextToken);

      const data: FileNode[] = items.map((item) => {
        return {
          type: "file",
          name: item.relative_path.split("/").slice(-1)[0],
          file: item,
        };
      });

      return data;
    },
  });
}
