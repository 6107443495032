import {
  AuthError,
  signUp as amplifySignUp,
  confirmSignUp,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  resendSignUpCode,
  signInWithRedirect,
} from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";

import { OrgRecord } from "@/shared/domain/orgs";
import { UserRecord } from "@/shared/domain/users";
import { Env } from "@/shared/environment";
import { isAbortError } from "@/shared/errors";
import { RobotoDomainException } from "@/shared/http";
import { OrganizationRole } from "@/shared/services/ApiService";
import { ErrorMonitoringService } from "@/shared/services/ErrorMonitoringService";
import { UserIdToken } from "@/types";

import { HttpClientContext } from "../HttpClient";

import { AuthContext } from "./AuthContext";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthProviderState {
  availableOrganizations: OrgRecord[] | null;
  currentOrganization: OrgRecord | null;
  currentUser: UserRecord | null;
  initialAuthCheckComplete: boolean;
  isAuthenticated: boolean;
  orgRoles: OrganizationRole[] | null;
  pendingAuthOperationCount: number;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { httpClient, setResourceOwnerId } =
    React.useContext(HttpClientContext);
  const [authState, setAuthState] = useState<AuthProviderState>({
    availableOrganizations: [],
    currentUser: null,
    currentOrganization: null,
    initialAuthCheckComplete: false,
    isAuthenticated: false,
    orgRoles: [],
    // A race-condition safe count for pending auth operations
    // Increment before starting an operation that should cause the UI to show a loading indicator, decrement after it completes.
    pendingAuthOperationCount: 0,
  });

  React.useEffect(
    // Note(Pratik) We may want to opt for a PubSub pattern here so
    // that AuthProvider doesn't take a dependency on ErrorMonitoringService and
    // other dependencies that live outside react.
    function onUserChange() {
      if (authState.currentUser === null) {
        return;
      }

      ErrorMonitoringService.setUser(authState.currentUser);
    },
    [authState.currentUser],
  );

  React.useEffect(
    function onOrgChange() {
      setResourceOwnerId(authState.currentOrganization?.org_id);

      if (authState.currentOrganization === null) {
        return;
      }

      ErrorMonitoringService.setOrg(authState.currentOrganization);
    },
    [authState.currentOrganization, setResourceOwnerId],
  );

  const getCurrentOrganization = useCallback(() => {
    let storedOrg: OrgRecord | null = null;

    if (authState.currentOrganization) {
      return authState.currentOrganization;
    }

    const serializedOrg = localStorage.getItem("currentOrg");
    if (serializedOrg && serializedOrg !== "undefined") {
      storedOrg = JSON.parse(serializedOrg) as OrgRecord | null;
    }

    return storedOrg;
  }, [authState.currentOrganization]);

  const deleteCurrentOrganizationFromLocalStorage = useCallback(() => {
    localStorage.removeItem("currentOrg");
  }, []);

  const deleteCurrentOrganization = useCallback(() => {
    deleteCurrentOrganizationFromLocalStorage();

    setAuthState((prevState) => ({
      ...prevState,
      currentOrganization: null,
    }));
  }, [deleteCurrentOrganizationFromLocalStorage]);

  const setCurrentOrganization = (org: OrgRecord) => {
    localStorage.setItem("currentOrg", JSON.stringify(org));

    setAuthState((prevState) => ({
      ...prevState,
      currentOrganization: org,
    }));
  };

  const retrieveOrgRoles = useCallback(
    async (
      orgId: string,
      signal?: AbortSignal,
    ): Promise<OrganizationRole[]> => {
      const response = await httpClient.get(`v1/orgs/id/${orgId}/users`, {
        signal,
      });
      return response.json<OrganizationRole[]>();
    },
    [httpClient],
  );

  const retrieveOrgList = useCallback(
    async (signal?: AbortSignal): Promise<OrgRecord[]> => {
      const response = await httpClient.get("v1/users/orgs", { signal });
      return response.json<OrgRecord[]>();
    },
    [httpClient],
  );

  const retrieveRobotoUser = useCallback(
    async (signal?: AbortSignal): Promise<UserRecord> => {
      const response = await httpClient.get("v1/users", { signal });
      return response.json<UserRecord>();
    },
    [httpClient],
  );

  // Check auth status when app boots
  useEffect(() => {
    const abortController = new AbortController();

    const checkAuth = async () => {
      setAuthState((prevState) => ({
        ...prevState,
        pendingAuthOperationCount: prevState.pendingAuthOperationCount + 1,
      }));

      const [currentUser, availableOrganizations] = await Promise.all([
        retrieveRobotoUser(abortController.signal),
        retrieveOrgList(abortController.signal),
      ]);
      let storedOrg: OrgRecord | null = null;

      const serializedOrg = localStorage.getItem("currentOrg");
      if (serializedOrg && serializedOrg !== "undefined") {
        storedOrg = JSON.parse(serializedOrg) as OrgRecord | null;
      }

      let orgRoles = null;

      if (storedOrg) {
        orgRoles = await retrieveOrgRoles(
          storedOrg.org_id,
          abortController.signal,
        );
      }

      setAuthState((prevState) => ({
        ...prevState,
        availableOrganizations,
        currentOrganization: storedOrg,
        currentUser,
        isAuthenticated: currentUser !== null,
        orgRoles: orgRoles,
      }));
    };

    checkAuth()
      .catch((reason) => {
        if (
          isAbortError(reason) ||
          (reason instanceof RobotoDomainException && reason.isClientError())
        ) {
          // Swallow error if op was aborted
          // or if it's a client error (likely: unauthorized error), which is expected when not signed in yet.
          return;
        }

        ErrorMonitoringService.captureError(reason);
      })
      .finally(() => {
        setAuthState((prevState) => ({
          ...prevState,
          initialAuthCheckComplete: !abortController.signal.aborted,
          pendingAuthOperationCount: prevState.pendingAuthOperationCount - 1,
        }));
      });

    return function abortActiveRequests() {
      abortController.abort();
    };
  }, [retrieveRobotoUser, retrieveOrgList, retrieveOrgRoles]);

  const signUp = async (
    emailAddress: string,
    password: string,
  ): Promise<string | null> => {
    if (authState.currentUser) {
      return "You are already signed in. Please sign out before creating another account.";
    }

    try {
      await amplifySignUp({
        username: emailAddress,
        password: password,
        options: {
          userAttributes: {
            email: emailAddress,
          },
          autoSignIn: true,
        },
      });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        return error.message;
      }

      ErrorMonitoringService.captureError(error);
      return "There was an error signing up. Please try again.";
    }
  };

  const submitVerificationCode = async (
    username: string,
    code: string,
  ): Promise<string | null> => {
    try {
      await confirmSignUp({ username, confirmationCode: code });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        return error.message;
      }

      ErrorMonitoringService.captureError(error);
      return "There was an error confirming your account. Please try again.";
    }
  };

  const signInCommon = async (actuallySignInCallback: () => Promise<void>) => {
    try {
      setAuthState((prevState) => ({
        ...prevState,
        pendingAuthOperationCount: prevState.pendingAuthOperationCount + 1,
      }));

      await actuallySignInCallback();

      const [currentUser, availableOrganizations] = await Promise.all([
        retrieveRobotoUser(),
        retrieveOrgList(),
      ]);

      const currentOrg = getCurrentOrganization();

      let orgRoles = null;
      if (currentOrg) {
        orgRoles = await retrieveOrgRoles(currentOrg.org_id);
      }

      setAuthState((prevState) => ({
        ...prevState,
        availableOrganizations,
        currentOrganization: currentOrg,
        currentUser,
        isAuthenticated: currentUser !== null,
        orgRoles: orgRoles,
      }));

      return null;
    } catch (err: unknown) {
      if (isAbortError(err)) {
        return null;
      }

      setAuthState((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        currentUser: null,
        currentOrganization: getCurrentOrganization(),
        availableOrganizations: null,
        orgRoles: null,
      }));

      if (err instanceof AuthError) {
        return err.message;
      }

      ErrorMonitoringService.captureError(err);
      return "There was an error signing in. Please try again.";
    } finally {
      setAuthState((prevState) => ({
        ...prevState,
        pendingAuthOperationCount: prevState.pendingAuthOperationCount - 1,
      }));
    }
  };

  const signIn = async (
    emailAddress: string,
    password: string,
  ): Promise<string | null> => {
    const actuallySignInCallback = async () => {
      await amplifySignIn({ username: emailAddress, password });
    };

    return signInCommon(actuallySignInCallback);
  };

  const ssoSignIn = async (inviteId: string | null): Promise<string | null> => {
    const actuallySignInCallback = async () => {
      if (inviteId) {
        await signInWithRedirect({
          provider: { custom: Env.ssoSignInConfig?.cognitoProviderId || "" },
          customState: JSON.stringify({ inviteId: inviteId }),
        });
      } else {
        await signInWithRedirect({
          provider: { custom: Env.ssoSignInConfig?.cognitoProviderId || "" },
        });
      }
    };

    return signInCommon(actuallySignInCallback);
  };

  const signOut = async (): Promise<string | null> => {
    try {
      await amplifySignOut();

      deleteCurrentOrganizationFromLocalStorage();
      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        return error.message;
      }

      ErrorMonitoringService.captureError(error);
      return "There was an error signing out. Please try again.";
    } finally {
      setAuthState((prevState) => ({
        ...prevState,
        availableOrganizations: null,
        currentOrganization: null,
        currentUser: null,
        isAuthenticated: false,
        orgRoles: null,
      }));
    }
  };

  const getCurrentUser = async (): Promise<UserRecord> => {
    if (authState.currentUser) {
      return authState.currentUser;
    }

    return retrieveRobotoUser();
  };

  const forgotPasswordInitiate = async (
    emailAddress: string,
  ): Promise<string | null> => {
    try {
      await resetPassword({ username: emailAddress });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }

      return "There was an error initiating the password reset process. Please try again.";
    }
  };

  const forgotPasswordComplete = async (
    emailAddress: string,
    code: string,
    newPassword: string,
  ): Promise<string | null> => {
    try {
      await confirmResetPassword({
        username: emailAddress,
        newPassword,
        confirmationCode: code,
      });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        return error.message;
      }

      return "There was an error completing the password reset process. Please try again.";
    }
  };

  const parseJwt = (token: string): UserIdToken | null => {
    const base64Url = token.split(".")[1];
    if (base64Url === undefined) {
      return null;
    }
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload) as UserIdToken;
  };

  const getUserIdToken = async (): Promise<UserIdToken | null> => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString();
      if (idToken === undefined) {
        return null;
      }
      return parseJwt(idToken);
    } catch (err: unknown) {
      ErrorMonitoringService.captureError(err);
      return null;
    }
  };

  const refreshOrgList = async () => {
    const orgs = await retrieveOrgList();

    setAuthState((prevState) => ({
      ...prevState,
      availableOrganizations: orgs,
    }));
  };

  const resendVerificationCode = async (emailAddress: string) => {
    try {
      await resendSignUpCode({ username: emailAddress });
      return null;
    } catch (e) {
      ErrorMonitoringService.captureError(e);
      return "Error sending code. Please try again.";
    }
  };

  return (
    <AuthContext.Provider
      value={{
        initialAuthCheckComplete: authState.initialAuthCheckComplete,
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.pendingAuthOperationCount > 0,
        currentUser: authState.currentUser,
        currentOrganization: authState.currentOrganization,
        availableOrganizations: authState.availableOrganizations,
        orgRoles: authState.orgRoles,
        signUp,
        submitVerificationCode,
        signIn,
        signOut,
        ssoSignIn,
        forgotPasswordInitiate,
        forgotPasswordComplete,
        getCurrentUser,
        getCurrentOrganization,
        setCurrentOrganization,
        deleteCurrentOrganization,
        getUserIdToken,
        refreshOrgList,
        resendVerificationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
