import { useContext, useCallback, useState, useEffect } from "react";

import { Auth, AuthContext } from "./AuthContext";

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

const SYSTEM_USER_OVERRIDE_KEY = "roboto_override_system_user_view";

export const useSystemUser = () => {
  const { currentUser } = useAuth();
  const [isOverrideEnabled, setIsOverrideEnabled] = useState(
    () => localStorage.getItem(SYSTEM_USER_OVERRIDE_KEY) === "true",
  );

  const isActualSystemUser = currentUser?.is_system_user ?? false;

  // Listen for storage changes from other components
  useEffect(() => {
    const handleStorageChange = () => {
      setIsOverrideEnabled(
        localStorage.getItem(SYSTEM_USER_OVERRIDE_KEY) === "true",
      );
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const toggleSystemUserOverride = useCallback(() => {
    if (!isActualSystemUser) {
      return;
    }

    const newOverrideState = !isOverrideEnabled;
    if (newOverrideState) {
      localStorage.setItem(SYSTEM_USER_OVERRIDE_KEY, "true");
    } else {
      localStorage.removeItem(SYSTEM_USER_OVERRIDE_KEY);
    }

    setIsOverrideEnabled(newOverrideState);
    // Keep the storage event for other tabs/windows
    window.dispatchEvent(new Event("storage"));
  }, [isActualSystemUser, isOverrideEnabled]);

  return {
    isSystemUser: isActualSystemUser && !isOverrideEnabled,
    isActualSystemUser,
    isOverrideEnabled,
    toggleSystemUserOverride,
  };
};

export const useCurrentOrgId = (
  // This is optional so components which use this hook can fire an optional prop into this without doing any
  // further validation, and verify that it'll resolve to something meaningful.
  overrideOrgId?: string,
): string => {
  const auth = useAuth();

  if (overrideOrgId) {
    return overrideOrgId;
  }

  if (auth.currentOrganization?.org_id) {
    return auth.currentOrganization.org_id;
  }

  throw new Error(
    "Could not resolve an org_id from useAuth organization or from overrideOrgId",
  );
};
