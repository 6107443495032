import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { FileRecord } from "@/shared/domain/files";

export function useGetFileContents() {
  const { files: fileService } = React.useContext(DomainServicesContext);

  return React.useCallback(
    function getFileContentsAsUrl(
      file: FileRecord,
      options?: Partial<{
        forDownload: boolean;
        abortSignal: AbortSignal;
      }>,
    ) {
      const { abortSignal, forDownload = false } = options || {};
      return fileService.getPresignedDownloadUrl(file.file_id, {
        // Add extra search params to support downloads as attachments
        searchParams: fileService.getSignedUrlParams(file, forDownload),
        abortSignal,
      });
    },
    [fileService],
  );
}
