import { LogPanelState } from "@/shared/state/visualization";
import { type MessagePayload, WorkerManager } from "@/shared/webworker";

import { IndexState } from "./IndexState";
import { Log } from "./log";
import { type IndexRange, LogCache, ScrollDirection } from "./LogCache";
import { type LogFilter, isTextSearchFilter } from "./LogFilter";
import {
  isErrorEvent,
  isInitializedEvent,
  isLoadingStateChangeEvent,
  isLogTimeIndexStateChangeEvent,
  isSearchIndexStateChangeEvent,
  LogCommand,
  LogCommandPayloadMap,
  LogCommandResponse,
  LogCommandResponsePayloadMap,
  LogEvent,
  LogEventPayloadMap,
  SOURCE,
} from "./messaging";

export type OnInitializedCallback = (
  error: LogEventPayloadMap[LogEvent.Initialized],
) => void;

export type OnErrorCallback = (
  error: LogEventPayloadMap[LogEvent.Error],
) => void;

export type OnLogsBufferedCallback = (
  data: LogEventPayloadMap[LogEvent.LogsBuffered],
) => void;

export type OnLoadingStateChangedCallback = (
  data: LogEventPayloadMap[LogEvent.LoadingStateChange],
) => void;

export type OnNumLogsChangedCallback = (
  data: LogEventPayloadMap[LogEvent.NumLogsChanged],
) => void;

export type OnSearchIndexStateChangedCallback = (
  data: LogEventPayloadMap[LogEvent.SearchIndexStateChange],
) => void;

const noop = () => {};

export class LogManager {
  #abortController: AbortController;

  #logCache: LogCache = new LogCache();

  // Callback for when the worker is initialized
  #onInitialized: OnInitializedCallback = noop;

  // Callback for when an error has occurred
  #onError: OnErrorCallback = noop;

  // Callback for when we're loading logs
  #onLoadingStateChanged: OnLoadingStateChangedCallback = noop;

  #onNumLogsChanged: OnNumLogsChangedCallback = noop;

  #onSearchIndexStateChanged: OnSearchIndexStateChangedCallback = noop;

  #searchIndexState = IndexState.Empty;

  #workerManager: WorkerManager;

  constructor() {
    this.#abortController = new AbortController();

    const worker = new Worker(new URL("./LogService.worker", import.meta.url), {
      type: "module",
    });

    this.#workerManager = new WorkerManager({
      worker,
      onError: this.#onError,
      onMessage: this.#onMessage,
      signal: this.#abortController.signal,
    });

    this.#workerManager.sendCommand({
      data: undefined,
      source: SOURCE,
      type: LogCommand.Init,
    });
  }

  public get logCount() {
    return this.#logCache.logCount;
  }

  public get isSearchIndexEmpty() {
    return this.#searchIndexState === IndexState.Empty;
  }

  public buildSearchIndex(signal?: AbortSignal) {
    this.#workerManager.sendCommand({
      data: undefined,
      source: SOURCE,
      type: LogCommand.BuildTextSearchIndex,
      signal,
    });
  }

  public async clearFilter(signal?: AbortSignal) {
    const timestampsBuffer = await this.#workerManager.sendCommandAwaitResponse<
      LogCommand,
      LogCommandPayloadMap[LogCommand.ClearFilter],
      LogCommandResponsePayloadMap[LogCommandResponse.TimestampsFiltered]
    >({
      type: LogCommand.ClearFilter,
      data: undefined,
      signal,
      source: SOURCE,
    });

    const timestamps = new BigUint64Array(timestampsBuffer);
    this.#logCache.setTimestampIndex(timestamps);
    this.#onNumLogsChanged({ numLogs: timestamps.length });
  }

  public dispose(): void {
    this.#abortController.abort();
    this.#workerManager.dispose();
    this.#logCache.dispose();
  }

  public async disposeSearchIndex(signal?: AbortSignal) {
    await this.clearFilter(signal);
    this.#workerManager.sendCommand({
      data: undefined,
      signal,
      source: SOURCE,
      type: LogCommand.DisposeTextSearchIndex,
    });
  }

  public async filterLogs(
    filter: LogFilter,
    signal?: AbortSignal,
  ): Promise<void> {
    const isFilterEmpty =
      isTextSearchFilter(filter) && filter.query.trim().length === 0;
    if (isFilterEmpty) {
      return await this.clearFilter(signal);
    }

    const timestampsBuffer = await this.#workerManager.sendCommandAwaitResponse<
      LogCommand,
      LogCommandPayloadMap[LogCommand.ApplyFilter],
      LogCommandResponsePayloadMap[LogCommandResponse.TimestampsFiltered]
    >({
      type: LogCommand.ApplyFilter,
      data: filter,
      signal,
      source: SOURCE,
    });

    const timestamps = new BigUint64Array(timestampsBuffer);
    this.#logCache.setTimestampIndex(timestamps);
    this.#onNumLogsChanged({ numLogs: timestamps.length });
  }

  public findLogIndexForTime(time: bigint): number {
    return this.#logCache.timestampToIndex(time);
  }

  public getLogByIndex(index: number): Log | undefined {
    return this.#logCache.indexToLog(index);
  }

  public getTimestampByIndex(index: number): bigint | undefined {
    return this.#logCache.indexToTimestamp(index);
  }

  public async loadLogs(
    currentWindow: IndexRange,
    scrollDirection = ScrollDirection.Forward,
    playbackRate = 1,
    signal?: AbortSignal,
  ): Promise<boolean> {
    const result = this.#logCache.getIndicesForMissingData(
      currentWindow,
      scrollDirection,
      playbackRate,
    );

    // if the current window is within missing, set loading indicator
    if (result.isInputRangeInMissing) {
      this.#onLoadingStateChanged({ isLoading: true });
    }

    if (result.missing.length === 0) {
      // Nothing to load
      return false;
    }

    try {
      const logBatch = await Promise.all(
        result.missing.map((range) => {
          return this.#workerManager.sendCommandAwaitResponse<
            LogCommand,
            LogCommandPayloadMap[LogCommand.GetLogs],
            LogCommandResponsePayloadMap[LogCommandResponse.LogsLoaded]
          >({
            type: LogCommand.GetLogs,
            data: range,
            signal,
            source: SOURCE,
          });
        }),
      );
      logBatch.forEach((logs) => this.#logCache.addLogsToCache(logs));
      this.#logCache.trimCache(currentWindow);
      return true;
    } finally {
      if (result.isInputRangeInMissing) {
        this.#onLoadingStateChanged({ isLoading: false });
      }
    }
  }

  public setEventListener<K extends keyof LogEventPayloadMap>(
    type: K,
    listener: (arg0: LogEventPayloadMap[K]) => void,
  ): void {
    if (type === LogEvent.Initialized) {
      this.#onInitialized = listener as OnInitializedCallback;
      return;
    }

    if (type === LogEvent.Error) {
      this.#onError = listener as OnErrorCallback;
      return;
    }

    if (type === LogEvent.LoadingStateChange) {
      this.#onLoadingStateChanged = listener as OnLoadingStateChangedCallback;
      return;
    }

    if (type === LogEvent.NumLogsChanged) {
      this.#onNumLogsChanged = listener as OnNumLogsChangedCallback;
      return;
    }

    if (type === LogEvent.SearchIndexStateChange) {
      this.#onSearchIndexStateChanged =
        listener as OnSearchIndexStateChangedCallback;
      return;
    }
  }

  public setState(
    state: LogPanelState["data"],
    abortSignal: AbortSignal,
  ): void {
    this.#workerManager.sendCommand({
      data: state,
      source: SOURCE,
      signal: abortSignal,
      type: LogCommand.SetState,
    });
    this.#logCache.markCacheStale();
  }

  #onMessage = (event: MessageEvent<MessagePayload>) => {
    if (isErrorEvent(event) && event.data.error !== undefined) {
      this.#onError(event.data.error);
      return;
    }

    if (isInitializedEvent(event)) {
      this.#onInitialized(event.data.data);
      return;
    }

    if (isLoadingStateChangeEvent(event)) {
      this.#onLoadingStateChanged(event.data.data);
      return;
    }

    if (isLogTimeIndexStateChangeEvent(event)) {
      const payload = event.data.data;
      switch (payload.state) {
        case IndexState.Building: {
          // No-op
          break;
        }
        case IndexState.Built: {
          const index = payload.index;
          if (index === undefined) {
            // This would represent a programming error
            throw new Error(
              "InvalidState: Cannot set LogTimeIndexState to 'built' without also providing the index.",
            );
          }
          const timestamps = new BigUint64Array(index);
          this.#logCache.setTimestampIndex(timestamps);
          this.#onNumLogsChanged({ numLogs: timestamps.length });
          break;
        }
        case IndexState.Empty: {
          this.#logCache.dispose();
          this.#onNumLogsChanged({ numLogs: 0 });
          break;
        }
      }

      return;
    }

    if (isSearchIndexStateChangeEvent(event)) {
      const payload = event.data.data;
      this.#searchIndexState = payload.state;
      this.#onSearchIndexStateChanged(payload);
      return;
    }
  };
}
