import { LogPanelState } from "@/shared/state/visualization";
import { MessagePayload } from "@/shared/webworker";

import { Log } from "../log";
import { IndexRange } from "../LogCache";
import { type LogFilter } from "../LogFilter";

export enum LogCommand {
  ApplyFilter = "apply-filter",
  BuildTextSearchIndex = "build-text-search-index",
  ClearFilter = "clear-filter",
  DisposeTextSearchIndex = "dispose-text-search-index",
  Init = "init",
  GetLogs = "get-logs",
  SetState = "set-state",
}

export enum LogCommandResponse {
  LogsLoaded = "logs-loaded",
  TimestampsFiltered = "timestamps-filtered",
}

export interface LogCommandPayloadMap {
  [LogCommand.ApplyFilter]: LogFilter;
  [LogCommand.BuildTextSearchIndex]: undefined;
  [LogCommand.ClearFilter]: undefined;
  [LogCommand.DisposeTextSearchIndex]: undefined;
  [LogCommand.Init]: undefined;
  [LogCommand.SetState]: LogPanelState["data"];
  [LogCommand.GetLogs]: IndexRange;
}

export interface LogCommandResponsePayloadMap {
  [LogCommandResponse.LogsLoaded]: Log[];
  [LogCommandResponse.TimestampsFiltered]: ArrayBuffer;
}

export function isInitCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.Init]>
> {
  return event.data.type === LogCommand.Init;
}

export function isSetStateCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.SetState]>
> {
  return event.data.type === LogCommand.SetState;
}

export function isApplyFilterCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.ApplyFilter]>
> {
  return event.data.type === LogCommand.ApplyFilter;
}

export function isBuildTextSearchIndexCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<
    LogCommand,
    LogCommandPayloadMap[LogCommand.BuildTextSearchIndex]
  >
> {
  return event.data.type === LogCommand.BuildTextSearchIndex;
}

export function isClearFilterCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.ClearFilter]>
> {
  return event.data.type === LogCommand.ClearFilter;
}

export function isDisposeTextSearchIndexCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<
    LogCommand,
    LogCommandPayloadMap[LogCommand.DisposeTextSearchIndex]
  >
> {
  return event.data.type === LogCommand.DisposeTextSearchIndex;
}

export function isGetLogsCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogCommand, LogCommandPayloadMap[LogCommand.GetLogs]>
> {
  return event.data.type === LogCommand.GetLogs;
}
