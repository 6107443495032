import { LegendButton } from "./LegendButton";
import { MoveTool } from "./MoveTool";
import { SelectRegionTool } from "./SelectRegionTool";
import { SyncViewportButton } from "./SyncViewportButton";

export function Toolbar() {
  return (
    <>
      <SyncViewportButton />
      <MoveTool />
      <SelectRegionTool />
      <LegendButton />
    </>
  );
}
