import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/shared/components";
import { FileVizLink } from "@/shared/components/links";
import { TopicSequence } from "@/shared/domain/topics/TopicSequence";
import { timeToSeconds } from "@/shared/mcap";
import { nanoSecToDate, nanoSecToSecLossy } from "@/shared/time";
interface TopicSequenceExpanderDetailsProps {
  topicSequence: TopicSequence;
}

const listIconStyles = {
  minWidth: "35px",
};

export const TopicSequenceDetails: React.FC<
  TopicSequenceExpanderDetailsProps
> = ({ topicSequence }) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary={"Start Time"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {topicSequence.start_time !== null ? (
                      <>
                        {nanoSecToDate(topicSequence.start_time).toLocaleString(
                          undefined,
                          {
                            timeZoneName: "short",
                          },
                        )}
                        <br />
                        &rArr; {timeToSeconds(topicSequence.start_time)} seconds
                        since epoch
                      </>
                    ) : (
                      "N/A"
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"End Time"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {topicSequence.end_time !== null ? (
                      <>
                        {nanoSecToDate(topicSequence.end_time).toLocaleString(
                          undefined,
                          {
                            timeZoneName: "short",
                          },
                        )}
                        <br />
                        &rArr; {timeToSeconds(topicSequence.end_time)} seconds
                        since epoch
                      </>
                    ) : (
                      "N/A"
                    )}
                  </>
                }
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary="Associated Files"
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <List dense>
                    {topicSequence.topics.map((topic) => (
                      <ListItem key={topic.topic_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FileVizLink
                              fileId={topic.association.association_id}
                              topicName={topicSequence.topic_name}
                              subtitle={
                                topic.start_time !== null &&
                                topic.end_time !== null && (
                                  <RobotoTypography
                                    variant="body2"
                                    sx={{ color: theme.palette.text.secondary }}
                                  >
                                    {nanoSecToSecLossy(
                                      topic.end_time - topic.start_time,
                                    ).toFixed(3)}
                                    s from{" "}
                                    {nanoSecToDate(
                                      topic.start_time,
                                    ).toLocaleString(undefined, {
                                      timeZoneName: "short",
                                    })}
                                  </RobotoTypography>
                                )
                              }
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
