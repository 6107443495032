import { HttpClient, robotoHeaders, type PaginatedResponse } from "../../http";

import {
  UpdateTriggerRequest,
  type TriggerEvaluationRecord,
  type TriggerEvaluationSummary,
  type TriggerRecord,
} from "./TriggerRecord";

type Options = {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
};

export class TriggerService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async getTriggerById(
    triggerId: string,
    options?: Partial<Options>,
  ): Promise<TriggerRecord> {
    const url = this.#httpClient.constructUrl(`v1/triggers/id/${triggerId}`);
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<TriggerRecord>();
  }

  public async getTriggerEvaluationsForDataset(
    datasetId: string,
    options?: Partial<
      Options & {
        limit: number;
      }
    >,
  ): Promise<TriggerEvaluationRecord[]> {
    const baseUrl = `v1/triggers/dataset/id/${datasetId}/evaluations`;
    const paginatedResponse = await this.getPagedTriggerEvaluations(
      baseUrl,
      options,
    );
    const evaluations = [...paginatedResponse.items];

    let nextPageToken = paginatedResponse.next_token;
    while (nextPageToken) {
      const nextPage = await this.getPagedTriggerEvaluations(baseUrl, {
        ...options,
        pageToken: nextPageToken,
      });
      evaluations.push(...nextPage.items);
      nextPageToken = nextPage.next_token;
    }

    return evaluations;
  }

  public async getTriggerEvaluationsForTrigger(
    triggerName: string,
    options?: Partial<
      Options & {
        limit: number;
      }
    >,
  ): Promise<TriggerEvaluationRecord[]> {
    const baseUrl = `v1/triggers/${triggerName}/evaluations`;
    const paginatedResponse = await this.getPagedTriggerEvaluations(
      baseUrl,
      options,
    );
    const evaluations = [...paginatedResponse.items];

    let nextPageToken = paginatedResponse.next_token;
    while (nextPageToken) {
      const nextPage = await this.getPagedTriggerEvaluations(baseUrl, {
        ...options,
        pageToken: nextPageToken,
      });
      evaluations.push(...nextPage.items);
      nextPageToken = nextPage.next_token;
    }

    return evaluations;
  }

  public async getTriggerEvaluationsSummaryForDataset(
    datasetId: string,
    options?: Partial<Options>,
  ): Promise<TriggerEvaluationSummary> {
    const url = this.#httpClient.constructUrl(
      `v1/triggers/dataset/id/${datasetId}/evaluations/summary`,
    );
    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return response.json<TriggerEvaluationSummary>();
  }

  public async updateTrigger(
    name: string,
    updates: UpdateTriggerRequest,
    options?: Partial<Options>,
  ): Promise<TriggerRecord> {
    if (!options?.resourceOwnerId) {
      throw Error(
        "updateTrigger requires a resource owner ID, none was provided",
      );
    }

    const requestUrl = this.#httpClient.constructUrl(`v1/triggers/${name}`);

    const response = await this.#httpClient.put(requestUrl, {
      idempotent: true,
      headers: robotoHeaders({ resourceOwnerId: options.resourceOwnerId }),
      body: JSON.stringify(updates),
    });

    return response.json<TriggerRecord>();
  }

  private async getPagedTriggerEvaluations(
    baseUrl: string,
    options?: Partial<
      Options & {
        limit: number;
        pageToken: string;
      }
    >,
  ): Promise<PaginatedResponse<TriggerEvaluationRecord>> {
    const { abortSignal, limit, pageToken, resourceOwnerId } = options ?? {};
    const searchParams = new URLSearchParams();
    if (limit !== undefined) {
      searchParams.append("limit", limit.toString());
    }
    if (pageToken) {
      searchParams.append("page_token", pageToken);
    }
    const url = this.#httpClient.constructUrl(baseUrl, searchParams);
    const response = await this.#httpClient.get(url, {
      signal: abortSignal,
      headers: robotoHeaders({ resourceOwnerId }),
    });
    return response.json<PaginatedResponse<TriggerEvaluationRecord>>();
  }
}
