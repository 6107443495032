export function basename(path: string, ext = "") {
  let base = new String(path).substring(path.lastIndexOf("/") + 1);
  if (base.lastIndexOf("/") === base.length - 1) {
    return "";
  }
  if (base.lastIndexOf(".") > 0 && ext.length > 0 && base.endsWith(ext)) {
    base = base.substring(0, base.length - ext.length);
  }
  return base;
}

export function extname(path: string) {
  const lastDotIndex = path.lastIndexOf(".");
  const lastSlashIndex = path.lastIndexOf("/");
  if (lastDotIndex > lastSlashIndex && lastDotIndex !== path.length - 1) {
    return path.substring(lastDotIndex);
  }
  return "";
}

export function dirname(path: string) {
  if (typeof path !== "string") {
    return "";
  }
  const cleanPath = path.replace(/\/+$/, "");
  const lastSlashIndex = cleanPath.lastIndexOf("/");
  if (lastSlashIndex === -1) {
    return ".";
  }
  if (lastSlashIndex === 0) {
    return "/";
  }
  return cleanPath.substring(0, lastSlashIndex);
}
