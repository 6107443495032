import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import * as React from "react";

import {
  AutocompleteType,
  TagAndMetadataAutocomplete,
} from "../TagAndMetadataAutocomplete";

import { Metadata } from "./types";

interface Props {
  metadata: Metadata;
  autocompleteType: AutocompleteType;
  onAddMetadata: (key: string, value: string) => void;
  placeholderKeyName?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export const MetadataForm: React.FC<Props> = ({
  metadata,
  autocompleteType,
  onAddMetadata,
  placeholderKeyName = "Key",
  disabled = false,
  isLoading = false,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: theme.spacing(1) }}>
      <TagAndMetadataAutocomplete
        autocompleteType={autocompleteType}
        onMetadataCreated={onAddMetadata}
        keyPlaceholderText={placeholderKeyName}
        existingKeys={Object.keys(metadata)}
        disabled={disabled}
        isLoading={isLoading}
      />
    </Box>
  );
};
