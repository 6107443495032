import { Alert, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import { AISummaryMetadataBar } from "@/shared/components/ai";
import { RobotoMarkdown } from "@/shared/components/RobotoMarkdown";
import { WorkspaceFileLink } from "@/shared/components/visualization/WorkspaceActionLink/WorkspaceFileLink.tsx";
import { WorkspaceTopicLink } from "@/shared/components/visualization/WorkspaceActionLink/WorkspaceTopicLink.tsx";
import { useFileSummary } from "@/shared/domain/files/hooks";
import { useRegenerateFileSummary } from "@/shared/domain/files/hooks/useRegenerateFileSummary";

import { WorkspaceMessagePathLink } from "../../WorkspaceActionLink/WorkspaceMessagePathLink.tsx";

import styles from "./AISummaryFileContent.module.css";

interface AISummaryFileContentProps {
  fileId: string;
}

export const AISummaryFileContent: React.FC<AISummaryFileContentProps> = ({
  fileId,
}: AISummaryFileContentProps) => {
  const [promptOverride, setPromptOverride] = React.useState<string | null>(
    null,
  );
  const { data, isLoading, error } = useFileSummary(fileId);
  const regenerateMutation = useRegenerateFileSummary();

  return (
    <Box className={styles.container}>
      {data?.created && (
        <AISummaryMetadataBar
          created={data.created}
          onRegenerateClick={() =>
            regenerateMutation.mutate({
              fileId,
              options: promptOverride
                ? { systemPrompt: promptOverride }
                : undefined,
            })
          }
          context={{ fileId }}
          promptOverrideText={promptOverride}
          onPromptOverrideChange={setPromptOverride}
          isRegenerating={regenerateMutation.isPending}
        />
      )}

      {(isLoading || regenerateMutation.isPending) && (
        <Box className={styles.loadingContainer}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      )}

      {(error || regenerateMutation.error) && (
        <Alert severity="error">
          Failed to load file summary. Please try again later.
        </Alert>
      )}

      {data?.text && !regenerateMutation.isPending && (
        <RobotoMarkdown
          content={data?.text || ""}
          fileLinkRenderer={({ fileId: linkedFileId, text, time }) => {
            return (
              <WorkspaceFileLink
                fileId={linkedFileId}
                text={text}
                time={time}
              />
            );
          }}
          topicLinkRenderer={({ topicId, text, time }) => {
            return (
              <WorkspaceTopicLink topicId={topicId} text={text} time={time} />
            );
          }}
          messagePathLinkRenderer={({ messagePathId, text, time }) => {
            return (
              <WorkspaceMessagePathLink
                messagePathId={messagePathId}
                text={text}
                time={time}
              />
            );
          }}
        />
      )}
    </Box>
  );
};
