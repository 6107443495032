import {
  type MessagePathPart,
  MessagePathPartType,
} from "@/shared/domain/topics";

function constructErrorMessage(path: MessagePathPart[]): string {
  const formattedPath = path
    .map((part) => {
      if (part.type === MessagePathPartType.Attr) {
        return part.attribute;
      }
      return `[${part.start}:${part.end ?? Infinity}]`;
    })
    .join(".");
  return `Unable to extract data from path: ${formattedPath}`;
}

export class DataExtractionError extends Error {
  static fromMessagePath(messagePath: MessagePathPart[]): DataExtractionError {
    const message = constructErrorMessage(messagePath);
    return new DataExtractionError(message);
  }

  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "DataAccessError";
  }
}
