import {
  type RobotoServiceErrorBody,
  RobotoDomainException,
  RobotoServiceException,
} from "./exceptions";

interface RobotoServiceSuccessResponse<T> {
  data: T;
}

function isSuccessResponse(
  response: unknown,
): response is RobotoServiceSuccessResponse<unknown> {
  return (
    typeof response === "object" && response !== null && "data" in response
  );
}

interface RobotoServiceErrorResponse<T> {
  error: T;
}

function isErrorResponse(
  response: unknown,
): response is RobotoServiceErrorResponse<unknown> {
  return (
    typeof response === "object" && response !== null && "error" in response
  );
}

function isJsonObject(response: unknown): response is JsonObject {
  return typeof response === "object" && response !== null;
}

interface JsonReviver {
  (key: string, value: unknown, context?: { source: string }): unknown;
}

export class HttpResponse {
  #response: Response;

  constructor(response: Response) {
    this.#response = response;
  }

  public get raw(): Response {
    return this.#response;
  }

  public get ok(): boolean {
    return this.#response.ok;
  }

  public get headers(): Headers {
    return this.#response.headers;
  }

  public get status(): number {
    return this.#response.status;
  }

  public arrayBuffer(): Promise<ArrayBuffer> {
    return this.#response.arrayBuffer();
  }

  public async json<ResponseBodyType>(
    reviver?: JsonReviver,
  ): Promise<ResponseBodyType> {
    let parseError: unknown = undefined;
    let responseBody:
      | RobotoServiceSuccessResponse<ResponseBodyType>
      | RobotoServiceErrorResponse<RobotoServiceErrorBody>
      | JsonValue;
    try {
      if (reviver === undefined) {
        responseBody = (await this.#response.json()) as
          | RobotoServiceSuccessResponse<ResponseBodyType>
          | RobotoServiceErrorResponse<RobotoServiceErrorBody>
          | JsonValue;
      } else {
        const responseText = await this.#response.text();
        responseBody = JSON.parse(responseText, reviver) as
          | RobotoServiceSuccessResponse<ResponseBodyType>
          | RobotoServiceErrorResponse<RobotoServiceErrorBody>
          | JsonValue;
      }
    } catch (e) {
      // Swallow JSON parsing error
      // This may happen if the Response has no body.
      responseBody = null;
      parseError = e;
    }

    if (isSuccessResponse(responseBody)) {
      return responseBody.data;
    }

    if (!this.#response.ok) {
      if (isErrorResponse(responseBody)) {
        // A well-formatted RobotoServiceErrorResponse
        throw RobotoDomainException.fromHttpResponse(responseBody.error);
      } else if (isJsonObject(responseBody) && "message" in responseBody) {
        // An error response that is not wrapped in { error: ... }
        // May be returned by the Roboto Authorizer
        const errorResponse = {
          error_code: String(this.#response.status),
          message: String(responseBody.message),
        };
        throw RobotoDomainException.fromHttpResponse(errorResponse);
      }
      throw RobotoDomainException.fromStatusCode(this.#response.status);
    }

    throw new Error("Unhandled HTTP response", {
      cause: { body: responseBody, parseError },
    });
  }

  public text(): Promise<string> {
    return this.#response.text();
  }

  public async throwIfError(): Promise<void> {
    if (this.#response.ok === false) {
      await this.json<RobotoServiceException>();
    }
  }
}
