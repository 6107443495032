// Uses the syslog severity levels
export enum Severity {
  Emergency = "EMERGENCY",
  Alert = "ALERT",
  Critical = "CRITICAL",
  Error = "ERROR",
  Warning = "WARNING",
  Notice = "NOTICE",
  Info = "INFO",
  Debug = "DEBUG",
}

export interface Log {
  time: bigint;
  severity?: Severity;
  sequence: number;
  data: Record<string, unknown>;
}
