import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { useAuth } from "@/providers";
import { useDomainServices } from "@/providers/DomainServices";
import { RobotoTableCell, RobotoTableRow } from "@/shared/components";
import { OrganizationTier, OrgRecord } from "@/shared/domain/orgs";

const useAllOrgs = () => {
  const { orgService } = useDomainServices();

  return useQuery({
    queryKey: ["allOrgs"],
    queryFn: ({ signal }) =>
      orgService.getAllOrgs({
        abortSignal: signal,
      }),
    // Optional: set how long until data is considered stale
    staleTime: 30000,
  });
};

export function AllOrgsViewer() {
  const { setCurrentOrganization } = useAuth();
  const { data } = useAllOrgs();

  const assumeOrgRole = (org: OrgRecord) => {
    return () => {
      setCurrentOrganization(org);
    };
  };

  const rows = data
    ?.sort((orgA, orgB) => {
      if (orgA.tier !== orgB.tier) {
        // Premium orgs come first
        return orgA.tier === OrganizationTier.Premium ? -1 : 1;
      }
      // Within same tier, sort by creation date (newest first)
      return orgB.created.localeCompare(orgA.created);
    })
    .map((org, idx) => {
      return (
        <RobotoTableRow key={`token-row-${idx}`}>
          <RobotoTableCell>
            {org.name} (
            {
              <Link
                to={`/orgs/${org.org_id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                {org.org_id}
              </Link>
            }
            )
          </RobotoTableCell>
          <RobotoTableCell sx={{ whiteSpace: "nowrap" }}>
            {org.created.substring(0, 10)}
          </RobotoTableCell>
          <RobotoTableCell>{org.tier}</RobotoTableCell>
          <RobotoTableCell sx={{ whiteSpace: "nowrap" }}>
            <Button
              disableElevation
              onClick={assumeOrgRole(org)}
              style={{
                textTransform: "none",
              }}
              startIcon={<VisibilityIcon fontSize="small" />}
            >
              View As
            </Button>
          </RobotoTableCell>
        </RobotoTableRow>
      );
    });

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        maxWidth: "900px",
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <RobotoTableCell>Name</RobotoTableCell>
            <RobotoTableCell>Created</RobotoTableCell>
            <RobotoTableCell>Tier</RobotoTableCell>
            <RobotoTableCell>Actions</RobotoTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
}
