import { MessagePathPartType } from "./MessagePathRecord";

/**
 * Index location of a parsed segment within the raw input string.
 * `start` is an inclusive bound while `end` is an exclusive bound,
 * such that `parsedPart.value === input.slice(parsedPart.loc.start, parsedPart.loc.end)`
 */
interface Location {
  start: number; // inclusive
  end: number; // exclusive
}

interface ParsedMessagePathPartBase {
  isFinalSegment: boolean; // is this the last segment of the parsed message path
  loc: Location;
  value: string; // parsed value of this segment
}

export interface ParsedMessagePathAttr extends ParsedMessagePathPartBase {
  type: MessagePathPartType.Attr;
}

export interface ParsedMessagePathSlice extends ParsedMessagePathPartBase {
  isClosed?: boolean; // does this segment have an opening an closing square bracket?
  isRange?: boolean; // is this segment a range slice: e.g., "[1:3]", "[4:]", "[:2]", "[:]"
  isValid: boolean; // conforms to expectations: e.g., "[3]", "[1:3]", "[4:]", "[:2]", "[:]"
  start?: number;
  type: MessagePathPartType.Slice;
  end?: number;
}

export type ParsedMessagePathPart =
  | ParsedMessagePathAttr
  | ParsedMessagePathSlice;

export interface PathParseResult {
  topic: { name: string; loc: Location };
  messagePath: ParsedMessagePathPart[];
}

export class PathParser {
  public static MESSAGE_PATH_SEPARATOR = "."; // separator used to delineate message path segments
  public static MESSAGE_PATH_PART_PARSER =
    /^(?<attribute>[^[\]]*)(?<slice>\[.*\]?)?$/;
  public static SLICE_VALIDATION_PATTERN =
    /^\s*(?<open_bracket>\[)\s*((?<index>\d*)|\s*(?<start>\d*)\s*(?<range_delimiter>:)\s*(?<end>\d*)?\s*)?\s*(?<close_bracket>\])?\s*$/;
  public static TOPIC_SEPARATOR = "."; // separator used between the topic name and message path

  public static splitMessagePath(path: string): string[] {
    return path.split(PathParser.MESSAGE_PATH_SEPARATOR);
  }

  public parse(input: string): PathParseResult {
    const { topic, remainder } = this.parseTopicName(input);

    return {
      topic,
      messagePath: this.parseMessagePath(remainder, topic.loc.end),
    };
  }

  private parseMessagePath(
    input: string,
    topicOffset: number,
  ): ParsedMessagePathPart[] {
    return PathParser.splitMessagePath(input)
      .reduce((accum, part, idx, parts) => {
        const match = PathParser.MESSAGE_PATH_PART_PARSER.exec(part);
        if (match === null) {
          // E.g., empty string
          return accum;
        }

        const isFinalSegment = idx === parts.length - 1;
        const groups = match.groups || {};
        const attribute = "attribute" in groups && groups.attribute;
        const slice = "slice" in groups && groups.slice;

        const precedingPart = accum[accum.length - 1];

        if (attribute) {
          const start = input.indexOf(attribute, precedingPart?.loc?.end ?? 0);
          accum.push({
            isFinalSegment: isFinalSegment && !slice,
            loc: {
              start,
              end: start + attribute.length,
            },
            type: MessagePathPartType.Attr,
            value: attribute.trim(),
          });
        }
        if (slice) {
          const start = input.indexOf(slice, precedingPart?.loc?.end ?? 0);
          accum.push(
            this.parseMessagePathSlicePart({
              isFinalSegment,
              loc: {
                start,
                end: start + slice.length,
              },
              value: slice,
            }),
          );
        }

        return accum;
      }, [] as ParsedMessagePathPart[])
      .map((part) => ({
        ...part,
        loc: {
          start: part.loc.start + topicOffset,
          end: part.loc.end + topicOffset,
        },
      }));
  }

  private parseMessagePathSlicePart(
    part: ParsedMessagePathPartBase,
  ): ParsedMessagePathSlice {
    const match = PathParser.SLICE_VALIDATION_PATTERN.exec(part.value);
    if (match === null) {
      return {
        ...part,
        isValid: false,
        type: MessagePathPartType.Slice,
      };
    }

    const groups = match.groups ?? {};
    const isOpened =
      "open_bracket" in groups && groups.open_bracket !== undefined;
    const isClosed =
      "close_bracket" in groups && groups.close_bracket !== undefined;
    const isRange =
      "range_delimiter" in groups && groups.range_delimiter !== undefined;
    const index = "index" in groups && groups.index;
    const start = "start" in groups && groups.start;
    const end = "end" in groups && groups.end;

    const result: ParsedMessagePathSlice = {
      ...part,
      isClosed,
      isRange,
      isValid: true,
      type: MessagePathPartType.Slice,
    };

    if (!isOpened) {
      // e.g., ""
      result.isValid = false;
      return result;
    }

    if (isOpened && isClosed && !index && !isRange) {
      // e.g., "[]"
      result.isValid = false;
      return result;
    }

    if (isOpened && !index && !isRange) {
      // e.g., "["
      return result;
    }

    if (index) {
      const start = Number.parseInt(index);
      if (Number.isNaN(start)) {
        // E.g., "[a"
        result.isValid = false;
        return result;
      }

      result.start = start;
      return result;
    }

    if (isRange) {
      if (!start) {
        // e.g., "[:", "[:3]"
        result.start = 0;
      } else {
        const parsedStart = Number.parseInt(groups.start);
        if (Number.isNaN(parsedStart)) {
          // e.g., "[a:"
          result.isValid = false;
        } else {
          // e.g., "[3:", "[1:3"
          result.start = parsedStart;
        }
      }

      if (!end && isClosed) {
        // e.g., "[1:]"
        result.end = Infinity;
      } else if (end) {
        const parsedEnd = Number.parseInt(end);
        if (Number.isNaN(parsedEnd)) {
          // e.g., "[0:a"
          result.isValid = false;
        } else {
          // e.g., "[1:4"
          result.end = parsedEnd;
        }
      }
    }

    return result;
  }

  private parseTopicName(input: string): {
    topic: PathParseResult["topic"];
    remainder: string;
  } {
    const [topic] = input.split(PathParser.TOPIC_SEPARATOR, 1);
    return {
      topic: {
        name: topic.trim(),
        loc: {
          start: input.indexOf(topic),
          end: topic.length,
        },
      },
      remainder: input.slice(topic.length),
    };
  }
}

export const pathParser = new PathParser();
