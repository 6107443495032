import { AnnotationViewFilters } from "@/shared/components/Annotations";
import { FileSystemNode } from "@/shared/domain/files/filesystem";

export enum FileBrowserActionTypes {
  ClearAnnotationFile = "ClearAnnotationFile",
  DecrementTablePage = "DecrementTablePage",
  DeleteFiles = "DeleteFiles",
  HandleViewToggle = "HandleViewToggle",
  IncrementTablePage = "IncrementTablePage",
  SetActiveToggle = "SetActiveToggle",
  SetAlertState = "SetAlertState",
  SetAnnotationFile = "SetAnnotationFile",
  SetAnnotationViewFilters = "SetAnnotationViewFilters",
  SetError = "SetError",
  SetDisplayMode = "SetDisplayMode",
  SetFileTypes = "SetFileTypes",
  SetGridItems = "SetGridItems",
  SetSearchTerm = "SetSearchTerm",
  SetSelectedItems = "SetSelectedItems",
  SetShowHiddenFiles = "SetShowHiddenFiles",
  SetTablePageToZero = "SetTablePageToZero",
  UpdateTableRowsPerPage = "UpdateTableRowsPerPage",
  UpdateTablePage = "UpdateTablePage",
}

interface ClearAnnotationFileAction {
  type: FileBrowserActionTypes.ClearAnnotationFile;
  payload: null;
}

interface DecrementTablePage {
  type: FileBrowserActionTypes.DecrementTablePage;
  payload: { newPage: number };
}

interface DeleteFilesAction {
  type: FileBrowserActionTypes.DeleteFiles;
  payload: FileSystemNode[];
}

interface HandleViewToggleAction {
  type: FileBrowserActionTypes.HandleViewToggle;
  payload: { display: string; fileTypes: string[]; activeToggle: string };
}

interface IncrementTablePage {
  type: FileBrowserActionTypes.IncrementTablePage;
  payload: { newPage: number; nextToken: string | undefined };
}

interface SetActiveToggleAction {
  type: FileBrowserActionTypes.SetActiveToggle;
  payload: string;
}

interface SetAlertStateAction {
  type: FileBrowserActionTypes.SetAlertState;
  payload: {
    isOpen: boolean;
    title: string;
    text: string;
    action: () => Promise<void>;
  };
}

interface SetAnnotationFileAction {
  type: FileBrowserActionTypes.SetAnnotationFile;
  payload: FileSystemNode | undefined;
}

interface SetAnnotationViewFiltersAction {
  type: FileBrowserActionTypes.SetAnnotationViewFilters;
  payload: AnnotationViewFilters;
}

interface SetDisplayModeAction {
  type: FileBrowserActionTypes.SetDisplayMode;
  payload: string;
}

interface SetErrorAction {
  type: FileBrowserActionTypes.SetError;
  payload: Error | null;
}

interface SetFileTypesAction {
  type: FileBrowserActionTypes.SetFileTypes;
  payload: string[];
}

interface SetGridItemsAction {
  type: FileBrowserActionTypes.SetGridItems;
  payload: number;
}

interface SetSearchTermAction {
  type: FileBrowserActionTypes.SetSearchTerm;
  payload: string;
}

interface SetSelectedItemsAction {
  type: FileBrowserActionTypes.SetSelectedItems;
  payload: FileSystemNode[];
}

interface SetShowHiddenFilesAction {
  type: FileBrowserActionTypes.SetShowHiddenFiles;
  payload: boolean;
}

interface SetTablePageToZeroAction {
  type: FileBrowserActionTypes.SetTablePageToZero;
  payload: null;
}

interface UpdateTableRowsPerPageAction {
  type: FileBrowserActionTypes.UpdateTableRowsPerPage;
  payload: 10 | 25 | 50;
}

export type FileBrowserAction =
  | ClearAnnotationFileAction
  | DecrementTablePage
  | DeleteFilesAction
  | HandleViewToggleAction
  | IncrementTablePage
  | SetActiveToggleAction
  | SetAlertStateAction
  | SetAnnotationFileAction
  | SetAnnotationViewFiltersAction
  | SetDisplayModeAction
  | SetErrorAction
  | SetFileTypesAction
  | SetGridItemsAction
  | SetSearchTermAction
  | SetSelectedItemsAction
  | SetShowHiddenFilesAction
  | SetTablePageToZeroAction
  | UpdateTableRowsPerPageAction;
