import * as React from "react";

import {
  causeToFormattedString,
  TriggerEvaluationRecord,
} from "@/shared/domain/actions";

import { DatasetLink } from "../links";
import { RobotoTableCell, RobotoTableRow } from "../RobotoStyledTable";

import {
  type EvaluationColumn,
  DEFAULT_EVALUATION_COLUMNS,
  outcomeReasonToFormattedString,
  outcomeToFormattedString,
} from "./tableColumns";
import { TriggerEvaluationStatusChip } from "./TriggerEvaluationStatusChip";
import { TriggerEvaluationStatusDetail } from "./TriggerEvaluationStatusDetail";
import { TriggerLink } from "./TriggerLink";

interface TriggerEvaluationsRowProps {
  evaluation: TriggerEvaluationRecord;
  columns?: EvaluationColumn[];
}

export function TriggerEvaluationsRow({
  columns = DEFAULT_EVALUATION_COLUMNS,
  evaluation,
}: TriggerEvaluationsRowProps) {
  const cells = columns.map((column) => {
    if (column === "cause") {
      const cause = evaluation.cause;
      return (
        <RobotoTableCell key={column}>
          {cause === undefined ? "-" : causeToFormattedString(cause)}
        </RobotoTableCell>
      );
    }
    if (column === "dataset_id") {
      return (
        <RobotoTableCell key={column}>
          <DatasetLink datasetId={evaluation.data_source.data_source_id} />
        </RobotoTableCell>
      );
    }
    if (column === "data_source") {
      return (
        <React.Fragment key={column}>
          <RobotoTableCell>
            {evaluation.data_source.data_source_id}
          </RobotoTableCell>
          <RobotoTableCell>
            {evaluation.data_source.data_source_type}
          </RobotoTableCell>
        </React.Fragment>
      );
    }
    if (
      (column === "evaluation_start" || column === "evaluation_end") &&
      evaluation[column]
    ) {
      return (
        <RobotoTableCell key={column}>
          {new Date(evaluation[column]).toLocaleString()}
        </RobotoTableCell>
      );
    }
    if (column === "outcome") {
      return (
        <RobotoTableCell key={column}>
          {outcomeToFormattedString(evaluation.outcome)}
        </RobotoTableCell>
      );
    }
    if (column === "outcome_reason") {
      return (
        <RobotoTableCell key={column}>
          {outcomeReasonToFormattedString(evaluation.outcome_reason)}
        </RobotoTableCell>
      );
    }
    if (column === "status") {
      return (
        <RobotoTableCell key={column}>
          <TriggerEvaluationStatusChip status={evaluation.status} />
        </RobotoTableCell>
      );
    }
    if (column === "status_detail") {
      return (
        <RobotoTableCell key={column}>
          <TriggerEvaluationStatusDetail
            statusDetail={evaluation.status_detail}
          />
        </RobotoTableCell>
      );
    }
    if (column === "trigger_id") {
      return (
        <RobotoTableCell key={column}>
          <TriggerLink triggerId={evaluation.trigger_id} />
        </RobotoTableCell>
      );
    }
    const value =
      evaluation[
        column as Exclude<
          keyof TriggerEvaluationRecord,
          | "cause"
          | "data_source"
          | "evaluation_start"
          | "evaluation_end"
          | "outcome"
          | "outcome_reason"
          | "status"
          | "status_detail"
          | "trigger_id"
        >
      ];

    return (
      <RobotoTableCell
        key={column}
        sx={{
          maxWidth: "250px",
          wordBreak: "break-all",
        }}
      >
        {value ?? "-"}
      </RobotoTableCell>
    );
  });

  return <RobotoTableRow>{cells}</RobotoTableRow>;
}
