import { type AutocompleteRenderInputParams, TextField } from "@mui/material";

import { PathParser } from "@/shared/domain/topics";

import { MessagePathHelp } from "./MessagePathHelp";

interface PathSelectorInputProps {
  hasExactMatch: boolean;
  inputValue: string;
  inputParams: AutocompleteRenderInputParams;
  isValid: boolean;
  selectorIsOpen: boolean;
}

function getInputColorHint({
  isValid,
  hasExactMatch,
}: {
  isValid: boolean;
  hasExactMatch: boolean;
}) {
  if (!isValid) {
    return "error";
  }

  if (hasExactMatch) {
    return "success";
  }

  return undefined;
}

export function PathSelectorInput({
  hasExactMatch,
  inputValue,
  inputParams,
  isValid,
  selectorIsOpen,
}: PathSelectorInputProps) {
  const placeholderExample = [
    "esc_status",
    PathParser.TOPIC_SEPARATOR,
    "esc[2:7]",
    PathParser.MESSAGE_PATH_SEPARATOR,
    "esc_power",
  ].join("");

  return (
    <TextField
      {...inputParams}
      color={getInputColorHint({ isValid, hasExactMatch })}
      error={!isValid}
      fullWidth
      InputProps={{
        ...inputParams.InputProps,
        endAdornment: (
          <>
            {inputParams.InputProps.endAdornment}
            <MessagePathHelp show={inputValue.length > 0 && selectorIsOpen} />
          </>
        ),
      }}
      label="Topic and Message Path"
      placeholder={`e.g., ${placeholderExample}`}
    />
  );
}
