import { useVirtualizer } from "@tanstack/react-virtual";
import * as React from "react";

import { MessagePathNode } from "@/shared/domain/topics";
import { isAbortError } from "@/shared/errors";
import {
  useMeasure,
  type OnDimensionsChange,
  useDebouncedCallback,
} from "@/shared/hooks";
import { ErrorMonitoringService } from "@/shared/services";
import { LayoutItem, LogPanelState } from "@/shared/state/visualization";

import { useWorkspaceTimer } from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelHeader } from "../PanelHeader";
import { PanelLayout } from "../PanelLayout";
import { RenderingError } from "../RenderingError";

import { IndexState } from "./IndexState";
import { LogManager } from "./LogManager";
import styles from "./LogPanel.module.css";
import { Toolbar } from "./logTools/Toolbar";
import { LogEvent } from "./messaging";
import { useLogPanelContext } from "./panelContext";
import { LogSearchInput } from "./search/LogSearchInput";
import {
  ColumnDefinition,
  DEFAULT_LOG_LINE_HEIGHT,
  Pagination,
  Row,
  TableHeader,
} from "./table";
import { useLogs } from "./useLogs";

function getDynamicColumns(data: LogPanelState["data"]): ColumnDefinition[] {
  return data.map((topic) => ({
    messagePathId: topic.messagePath.id,
    name: MessagePathNode.partsToString(topic.messagePath.parts),
  }));
}

interface LogPanelProps {
  layout: LayoutItem;
  state: LogPanelState;
}

/**
 * Chosen such that MAX_LOGS_PER_PAGE * DEFAULT_LOG_LINE_HEIGHT is less than
 * the maximum height of a scroll element (which varies browser by browser, but tops out aroun 17M pixels tall).
 */
const MAX_LOGS_PER_PAGE = 250_000;

/**
 * Project virtualized index in pagination-space to actual index in data-space.
 */
function virtualToDataIndex(virtualIndex: number, currentPage: number) {
  return currentPage * MAX_LOGS_PER_PAGE + virtualIndex;
}

const VIRTUAL_GRID_INLINE_STYLES = Object.freeze({
  "--row-height": `${DEFAULT_LOG_LINE_HEIGHT}px`,
}) as React.CSSProperties;

export function LogPanel({ layout, state }: LogPanelProps) {
  const timer = useWorkspaceTimer();
  const [logManager, setLogManager] = React.useState<LogManager | null>(null);

  const [error, setError] = React.useState<Error | null>(null);

  // Message::logTime is used as the unique identifier for tracking expanded and selected states
  const [expanded, setExpanded] = React.useState<Set<bigint>>(new Set());
  const [selected, setSelected] = React.useState<bigint | undefined>();

  // We will initially show 20 logs loading but we will use the actual number of logs
  // once they have loaded.
  const [numLogs, setNumLogs] = React.useState(20);
  const [currentPage, setCurrentPage] = React.useState(0); // 0-indexed

  const [searchIndexState, setSearchIndexState] = React.useState(
    IndexState.Empty,
  );

  const { isSearchVisible } = useLogPanelContext();

  React.useEffect(
    function init() {
      if (error !== null) {
        return;
      }

      const abortController = new AbortController();
      const manager = new LogManager();

      manager.setEventListener(LogEvent.Initialized, () => {
        if (abortController.signal.aborted) {
          return;
        }

        setLogManager(manager);
      });

      manager.setEventListener(LogEvent.Error, (err) => {
        if (abortController.signal.aborted || isAbortError(err)) {
          return;
        }

        const error = new Error(err.message, { cause: err });
        ErrorMonitoringService.captureError(error);
        setError(error);
        setLogManager(null);
      });

      manager.setEventListener(LogEvent.NumLogsChanged, ({ numLogs }) => {
        if (abortController.signal.aborted) {
          return;
        }

        setNumLogs(numLogs);
      });

      return function cleanUp() {
        abortController.abort();
        manager.dispose();
      };
    },
    [error],
  );

  const dataLoadingTimerHoldRef = React.useRef<symbol | null>(null);
  React.useEffect(
    function holdTimerWhileDataLoading() {
      if (logManager === null) {
        return;
      }

      const abortController = new AbortController();

      logManager.setEventListener(
        LogEvent.LoadingStateChange,
        ({ isLoading }) => {
          if (abortController.signal.aborted) {
            return;
          }

          if (isLoading && dataLoadingTimerHoldRef.current === null) {
            dataLoadingTimerHoldRef.current = timer.hold();
          }
          if (!isLoading && dataLoadingTimerHoldRef.current !== null) {
            timer.holdRelease(dataLoadingTimerHoldRef.current);
            dataLoadingTimerHoldRef.current = null;
          }
        },
      );

      return function cleanUp() {
        abortController.abort();
        if (dataLoadingTimerHoldRef.current !== null) {
          timer.holdRelease(dataLoadingTimerHoldRef.current);
          dataLoadingTimerHoldRef.current = null;
        }
      };
    },
    [logManager, timer],
  );

  /**
   * Hold the timer while the search index is building.
   * Building the search index is resource intensive, we don't want playback held up by it.
   */
  const searchIndexBuildingTimerHoldRef = React.useRef<symbol | null>(null);
  React.useEffect(
    function holdTimerWhileSearchIndexBuilding() {
      if (logManager === null) {
        return;
      }

      const abortController = new AbortController();

      logManager.setEventListener(
        LogEvent.SearchIndexStateChange,
        ({ state }) => {
          if (abortController.signal.aborted) {
            return;
          }

          switch (state) {
            case IndexState.Building: {
              if (searchIndexBuildingTimerHoldRef.current === null) {
                searchIndexBuildingTimerHoldRef.current = timer.hold();
              }
              break;
            }
            case IndexState.Built: // FALL THROUGH
            case IndexState.Empty: {
              if (searchIndexBuildingTimerHoldRef.current !== null) {
                timer.holdRelease(searchIndexBuildingTimerHoldRef.current);
                searchIndexBuildingTimerHoldRef.current = null;
              }
              break;
            }
          }

          setSearchIndexState(state);
        },
      );

      return function cleanUp() {
        abortController.abort();
        if (searchIndexBuildingTimerHoldRef.current !== null) {
          timer.holdRelease(searchIndexBuildingTimerHoldRef.current);
          searchIndexBuildingTimerHoldRef.current = null;
        }
      };
    },
    [logManager, timer],
  );

  React.useEffect(
    function setState() {
      if (logManager === null) {
        return;
      }

      const abortController = new AbortController();

      logManager.setState(state.data, abortController.signal);

      return function cleanUp() {
        abortController.abort();
      };
    },
    [state.data, logManager],
  );

  const tableRef = React.useRef<HTMLDivElement>(null);
  const hasInitiatedTimerUpdateRef = React.useRef<boolean>(false);

  const staticColumns = ["time"];
  const dynamicColumns = getDynamicColumns(state.data);
  const numColumns = dynamicColumns.length + staticColumns.length;

  const getItemKey = React.useCallback(
    function getItemKey(index: number) {
      const dataIndex = virtualToDataIndex(index, currentPage);
      return logManager?.getLogByIndex(dataIndex)?.time ?? dataIndex;
    },
    [logManager, currentPage],
  );

  const getLogCountForCurrentPage = React.useCallback(() => {
    const startIndex = currentPage * MAX_LOGS_PER_PAGE;
    const remainingLogs = Math.max(0, numLogs - startIndex);
    return Math.min(remainingLogs, MAX_LOGS_PER_PAGE);
  }, [currentPage, numLogs]);

  const virtualizer = useVirtualizer({
    count: getLogCountForCurrentPage(),
    // Value chosen to match the loading row height
    estimateSize: () => DEFAULT_LOG_LINE_HEIGHT,
    getItemKey,
    getScrollElement: () => tableRef.current,
    // Value chosen through trial and error
    overscan: 5,
    paddingStart: DEFAULT_LOG_LINE_HEIGHT, // account for header
    useAnimationFrameWithResizeObserver: true,
  });
  const items = virtualizer.getVirtualItems();

  useLogs(logManager, virtualizer, timer, currentPage, MAX_LOGS_PER_PAGE);

  const scrollTable = React.useCallback(
    function scrollTable() {
      if (logManager === null) {
        return;
      }

      // Don't scroll when this panel has initiated a timer update
      if (hasInitiatedTimerUpdateRef.current) {
        hasInitiatedTimerUpdateRef.current = false;
        return;
      }

      // Closest index as of (equal to or before) currentTime
      const dataIndexAsOfTime = logManager.findLogIndexForTime(
        timer.currentTime,
      );
      const logTimeAsOfTime = logManager.getTimestampByIndex(dataIndexAsOfTime);

      // Calculate which page this index falls within
      const targetPage = Math.floor(dataIndexAsOfTime / MAX_LOGS_PER_PAGE);
      if (targetPage !== currentPage) {
        setCurrentPage(targetPage);
      }

      // Map to virtual index
      const indexWithinPage = dataIndexAsOfTime % MAX_LOGS_PER_PAGE;

      // 2025-04-02 (GM)
      // While the virtualizer exposes a "scrollToIndex" method,
      // under certain conditions that happen to match ours,
      // it will continuously, recursively call itself in an attempt to adjust for dynamic sizing.
      // https://github.com/TanStack/virtual/blob/main/packages/virtual-core/src/index.ts#L997-L1016
      //
      // Combing through relevant GH issues didn't provide a better resolution:
      //  - https://github.com/TanStack/virtual/issues/925
      //  - https://github.com/TanStack/virtual/issues/467
      const offsetAndAlign = virtualizer.getOffsetForIndex(
        indexWithinPage,
        "center",
      );
      if (offsetAndAlign === undefined) {
        return;
      }
      const [offset] = offsetAndAlign;
      virtualizer.scrollToOffset(offset);

      if (logTimeAsOfTime !== undefined) {
        setSelected(logTimeAsOfTime);
      }
    },
    [logManager, timer, virtualizer, currentPage],
  );

  /**
   * When log indices have changed, reset scroll state.
   */
  React.useEffect(scrollTable, [numLogs, scrollTable]);

  React.useEffect(
    function setTimerCallbacks() {
      const abortController = new AbortController();

      timer.addListener("tick", scrollTable, {
        signal: abortController.signal,
      });
      timer.addListener("seek", scrollTable, {
        signal: abortController.signal,
      });

      return function abort() {
        abortController.abort();
      };
    },
    [timer, scrollTable],
  );

  const onRenderingSurfaceResize = React.useCallback<OnDimensionsChange>(() => {
    if (logManager && state.data.length > 0) {
      scrollTable();
    }
  }, [logManager, state.data.length, scrollTable]);

  const onRenderingSurfaceResizeDebounced =
    useDebouncedCallback<OnDimensionsChange>(onRenderingSurfaceResize, 150);

  const [measured] = useMeasure<HTMLDivElement>({
    onDimensionsChange: onRenderingSurfaceResizeDebounced,
  });

  const onClearError = React.useCallback(() => setError(null), []);

  const onRowClick = React.useCallback(
    (logTime: bigint) => {
      hasInitiatedTimerUpdateRef.current = true;
      setSelected(logTime);
      timer.seekTo(logTime);
      setExpanded((prev) => {
        if (prev.has(logTime)) {
          const next = new Set(prev);
          next.delete(logTime);
          return next;
        }
        return new Set(prev).add(logTime);
      });
    },
    [timer],
  );

  const handlePageChange = React.useCallback(
    (page: number) => {
      if (logManager === null) {
        return;
      }
      setCurrentPage(page);

      // Get the first log of the new page
      const firstLogIndex = page * MAX_LOGS_PER_PAGE;
      const timestamp = logManager.getTimestampByIndex(firstLogIndex);

      if (timestamp !== undefined) {
        // Set flag to prevent scrollTable from handling this timer update
        hasInitiatedTimerUpdateRef.current = true;
        timer.seekTo(timestamp);
      }
    },
    [logManager, timer],
  );

  const totalVirtualHeight = virtualizer.getTotalSize();
  const scrollListInlineStyles = React.useMemo(() => {
    return {
      "--grid-template-columns": `12.5rem repeat(${numColumns - 1}, 1fr)`,
      height: `${totalVirtualHeight}px`,
    } as React.CSSProperties;
  }, [numColumns, totalVirtualHeight]);

  return (
    <PanelLayout
      state={state}
      layout={layout}
      header={
        <PanelHeader
          additionalTools={<Toolbar logManager={logManager} />}
          className={styles.panelHeader}
          state={state}
        />
      }
    >
      <NoDataMessage panelData={state.data} />
      <div className={styles.container} ref={measured}>
        <LogSearchInput
          hidden={!isSearchVisible}
          onSearch={(query, mode, signal) => {
            if (logManager === null) {
              return;
            }
            logManager.filterLogs({ query, mode }, signal).catch((err) => {
              if (isAbortError(err)) {
                return;
              }
              ErrorMonitoringService.captureError(err);
            });
          }}
          panelData={state.data}
          searchIndexState={searchIndexState}
        />

        <div
          className={styles.virtualizedGrid}
          ref={tableRef}
          style={VIRTUAL_GRID_INLINE_STYLES}
        >
          <div className={styles.scrollList} style={scrollListInlineStyles}>
            <TableHeader
              columnDefinitions={dynamicColumns}
              panelId={state.id}
            />
            <div
              className={styles.window}
              style={{
                transform: `translateY(${items[0]?.start ?? 0}px)`,
              }}
            >
              {items.map((virtualRow) => {
                const dataIndex = virtualToDataIndex(
                  virtualRow.index,
                  currentPage,
                );
                const log = logManager?.getLogByIndex(dataIndex);
                const columns = dynamicColumns.map((column) => ({
                  ...column,
                  isLoading:
                    // We're loading if the column doesn't exist in log and we're
                    // displaying the previous query while the new one is fetching.
                    column.messagePathId in (log?.data || {}) === false,
                }));
                const logTime = log?.time;

                return (
                  <Row
                    columns={columns}
                    expanded={logTime !== undefined && expanded.has(logTime)}
                    key={virtualRow.key}
                    log={log}
                    onClick={onRowClick}
                    ref={virtualizer.measureElement}
                    selected={logTime !== undefined && logTime === selected}
                    virtualIndex={virtualRow.index}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageCount={Math.ceil(numLogs / MAX_LOGS_PER_PAGE)}
        />
      </div>
      <RenderingError error={error} onClearError={onClearError} />
    </PanelLayout>
  );
}
