import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { Box, Divider, Tooltip, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";

import { useWorkspaceTimer } from "@/shared/components/visualization/WorkspaceCtx";
import { UserSuggestion, CommentRecord } from "@/shared/domain/comments";
import {
  createTimestamp,
  deserializeTimestamp,
  formatTimestampToMarkdownString,
  serializeTimestamp,
} from "@/shared/text";
import { nanoSecToSecStr } from "@/shared/time";

import { RobotoButton } from "../RobotoButton";
import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoTypography } from "../RobotoTypography";

import { fixTimestampLinks } from "./helpers";

interface CommentInputProps {
  enableTimestamps: boolean;
  userSuggestionList: UserSuggestion[];
  handleSaveComment: (commentText: string) => void;
  onCancelClicked?: () => void;
  commentToEdit?: CommentRecord;
}

export const CommentInput: FC<CommentInputProps> = ({
  enableTimestamps,
  userSuggestionList,
  handleSaveComment,
  onCancelClicked,
  commentToEdit,
}) => {
  const theme = useTheme();

  const [commentState, setCommentState] = useState<string>("");

  const [isActive, setIsActive] = useState<boolean>(false);

  const timer = useWorkspaceTimer();

  useEffect(() => {
    if (commentToEdit) {
      setCommentState(commentToEdit.comment_text);
      setIsActive(true);
    }
  }, [commentToEdit]);

  const handleCancel = () => {
    onCancelClicked?.();
    setIsActive(false);
    setCommentState("");
  };

  /**
   A mention takes the form @[user.name](user.id) in the underlying string. On the UI 
   it will look the same as a mention in slack. 
   */
  const handleChange = (event: { target: { value: string } }) => {
    setCommentState(event.target.value);
  };

  if (!isActive) {
    return (
      <>
        <RobotoTypography
          variant={"body2"}
          sx={{ color: theme.palette.text.disabled }}
          onClick={() => setIsActive(true)}
        >
          Add a comment...
        </RobotoTypography>
        <Divider sx={{ width: "100%" }} />
      </>
    );
  }

  return (
    <>
      <MentionsInput
        autoFocus
        style={{
          control: {
            minHeight: 63,
            lineHeight: "inherit",
          },
          highlighter: {
            padding: theme.spacing(1),
            border: "1px solid transparent",
            fontSize: "0.9rem",
          },
          input: {
            padding: theme.spacing(1),
            color: theme.palette.text.primary,
            border: theme.border.thin,
            lineHeight: "inherit",
            fontSize: "0.9rem",
          },
          suggestions: {
            list: {
              border: theme.border.thin,
              fontSize: 14,
              backgroundColor: theme.palette.paper.main,
            },
            item: {
              padding: theme.spacing(1),
              "&focused": {
                backgroundColor: theme.palette.primary.light,
              },
            },
          },
        }}
        value={commentState}
        onChange={handleChange}
        placeholder={
          "Markdown is supported. You can tag people with @. Emojis like :smile: can be used."
        }
      >
        <Mention
          style={{ backgroundColor: theme.palette.primary.light }}
          displayTransform={(_id, display) => `@${display}`}
          trigger="@"
          data={userSuggestionList}
          renderSuggestion={(suggestion: SuggestionDataItem) => {
            return <>{suggestion.display}</>;
          }}
        />
        <Mention
          style={{ backgroundColor: theme.palette.primary.light }}
          displayTransform={(id, display) => {
            try {
              BigInt(display);
              return nanoSecToSecStr(deserializeTimestamp(id));
            } catch {
              return `${display}`;
            }
          }}
          markup="[__display__](__id__)"
          trigger="timestamp"
          data={[
            {
              id: serializeTimestamp(createTimestamp(timer.currentTime)),
              display: nanoSecToSecStr(timer.currentTime),
            },
          ]}
          renderSuggestion={(suggestion: SuggestionDataItem) => {
            return <>timestamp: {suggestion.display}</>;
          }}
        />
      </MentionsInput>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: theme.spacing(1),
          gap: theme.spacing(1),
        }}
      >
        <RobotoButton
          eventName={"SaveCommentClicked"}
          eventProperties={{
            commentId: commentToEdit?.comment_id ?? "",
          }}
          variant={"contained"}
          onClick={() => {
            const fixedComment = fixTimestampLinks(commentState);
            setCommentState(fixedComment);
            handleSaveComment(fixedComment);
          }}
          size="small"
        >
          {commentToEdit ? "Save" : "Comment"}
        </RobotoButton>
        <RobotoButton
          eventName={"CancelCommentClicked"}
          eventProperties={{
            commentId: commentToEdit?.comment_id ?? "",
          }}
          onClick={handleCancel}
          size="small"
          variant="outlined"
        >
          Cancel
        </RobotoButton>
        {enableTimestamps && (
          <RobotoIconButton
            eventName={"AddTimestampToCommentClicked"}
            onClick={() => {
              setCommentState((prevState) => {
                return (
                  prevState +
                  formatTimestampToMarkdownString(
                    createTimestamp(timer.currentTime),
                  )
                );
              });
            }}
            size="small"
          >
            <Tooltip title="Add timestamp" color="primary">
              <MoreTimeIcon color="primary" />
            </Tooltip>
          </RobotoIconButton>
        )}
      </Box>
    </>
  );
};
