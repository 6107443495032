export const NS_PER_MS = 1e6;
export const NS_PER_MS_BIGINT = BigInt(NS_PER_MS);
export const MS_PER_S = 1000;
export const NS_PER_S = NS_PER_MS * MS_PER_S;
export const NS_PER_S_BIGINT = BigInt(NS_PER_S);

export function milliSecToNanoSec(value: number): bigint {
  const ns = value * NS_PER_MS;
  return BigInt(ns.toFixed(0));
}

/**
 * Lossily converts a bigint nanosecond value into a floating-point milliseconds value.
 *
 * The conversion will remain accurate to ~microsecond precision.
 *
 * For more information, see {@link nanoSecToSecLossy}
 */
export function nanoSecToMilliSecLossy(value: bigint): number {
  const sec = Number(value / NS_PER_S_BIGINT);
  const nsec = Number(value % NS_PER_S_BIGINT);
  return sec * MS_PER_S + nsec / NS_PER_MS;
}

/**
 * Lossily converts a bigint nanosecond value into a floating-point seconds value.
 *
 * The conversion will remain accurate to ~microsecond precision.
 * The lossy conversion begins at the point where the bigint value passes
 * the limit of `Number.isSafeInteger`. You can experiment with that and `Date.now()*1000`
 * values to see this in action.
 *
 * For example:
 * `1629806438_269938658n` becomes
 * `1629806438.2699387`
 * ```
 *
 * For lossless conversion to a string-based fixed-point representation, see {@link nanoSecToSecStr}.
 */
export function nanoSecToSecLossy(value: bigint): number {
  const sec = Number(value / NS_PER_S_BIGINT);
  const nsec = Number(value % NS_PER_S_BIGINT);
  return sec + nsec / NS_PER_S;
}

/**
 * Losslessly converts a bigint nanosecond value into a slightly-more-legible fixed-point
 * seconds value.
 */
export function nanoSecToSecStr(value: bigint): string {
  const abs = value < 0 ? -1n * value : value;
  if (abs <= NS_PER_S_BIGINT) {
    // We can safely represent values under 1 billion as a number to get the decimal representation.
    return (Number(value) / NS_PER_S).toFixed(9);
  }
  // Otherwise, we will insert a decimal point at the appropriate spot in the string.
  const str = String(value);
  return `${str.slice(0, -9)}.${str.slice(-9)}`;
}

export function nanoSecToDate(ns: bigint) {
  // Date is built around millisecond precision.
  // For higher precision, we would need to look into an alternate date lib like Temporal.
  const ms = nanoSecToMilliSecLossy(ns);
  return new Date(ms);
}

export const nanoSecToLocalTimestamp = (ns: bigint) => {
  const date = nanoSecToDate(ns);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  // Format the time
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: true,
    timeZoneName: "short",
  };

  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

  return `${formattedDate} ${formattedTime}`;
};

/**
 * Converts a string representing a number of seconds to a BigInt in nanoseconds.
 * Handles fractional seconds and ensures that nanoseconds are properly rounded and normalized.
 *
 * @param value - The string representing seconds, possibly with fractional seconds.
 * @returns The equivalent BigInt in nanoseconds.
 */
export const strSecToBigIntNanosec = (value: string): bigint => {
  const [secondsPart, nanosecondsPart = "0"] = value.split(".");

  const sign = Number(value) < 0 ? -1n : 1n;
  let sec = BigInt(Math.abs(parseInt(secondsPart, 10)));

  // Handle fractional seconds and rounding
  const digitsShort = 9 - nanosecondsPart.length;
  let nsec = BigInt(
    Math.round(parseInt(nanosecondsPart, 10) * 10 ** digitsShort),
  );

  // Normalize if the nsec is >= 1e9
  if (nsec >= 1_000_000_000n) {
    sec += nsec / 1_000_000_000n; // Add full seconds from nsec overflow
    nsec = nsec % 1_000_000_000n; // Keep only the remainder as nanoseconds
  }

  return sign * (sec * 1_000_000_000n + nsec);
};
