import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import classNames from "classnames";

import { RobotoTypography } from "@/shared/components";
import { compareOptionalBigints } from "@/shared/components/visualization/compareOptionalBigints";
import { useWorkspaceFilesForTopics } from "@/shared/components/visualization/WorkspaceCtx/hooks";
import {
  actions,
  useVizDispatch,
  ImagePanelClip,
} from "@/shared/state/visualization";
import { nanoSecToLocalTimestamp } from "@/shared/time";

import styles from "./ManageTopics.module.css";

interface ManageTopicsProps {
  panelId: string;
  clips: ImagePanelClip[];
}

export function ManageTopics({ panelId, clips }: ManageTopicsProps) {
  const theme = useTheme();
  const dispatch = useVizDispatch();
  const files = useWorkspaceFilesForTopics(
    clips.map((clip) => clip.data.topic.id),
  );

  const onRemoveClip = (clip: ImagePanelClip) => {
    dispatch(actions.removeClipFromImagePanel(panelId, clip.id));
  };

  const sortedClips = clips.sort((a, b) => {
    return compareOptionalBigints(
      a.data.topic.startTime ? BigInt(a.data.topic.startTime) : null,
      b.data.topic.startTime ? BigInt(b.data.topic.startTime) : null,
    );
  });

  return (
    <List className={styles.topicList}>
      {sortedClips.map((clip) => {
        // These _should_ always be defined
        const startTime = clip.data.topic.startTime
          ? nanoSecToLocalTimestamp(BigInt(clip.data.topic.startTime))
          : "Undefined";
        const endTime = clip.data.topic.endTime
          ? nanoSecToLocalTimestamp(BigInt(clip.data.topic.endTime))
          : "Undefined";

        // Topics only have references to a file's ID, which isn't particularly useful info to display to an end user.
        //
        // The workspace ephemeral state should have loaded all relevant records before anything rendered, so we can
        // look up the file record and get the relative path and dataset ID. Note, this assumes files are only
        // associated with datasets
        let fileName = "Undefined";
        let datasetId = "Undefined";
        const fileRecord = files.find(
          (file) => file.file_id === clip.data.topic.association.association_id,
        );
        if (fileRecord) {
          fileName = fileRecord.relative_path;
          datasetId = fileRecord.association_id;
        }

        return (
          <ListItem
            key={clip.id}
            className={classNames(styles.topicItem, {
              [styles.topicItemDark]: theme.palette.mode === "dark",
              [styles.topicItemLight]: theme.palette.mode === "light",
            })}
          >
            <ListItemText
              primary={
                <Box className={styles.topicHeader}>
                  <RobotoTypography
                    variant="subtitle1"
                    className={styles.topicName}
                  >
                    {clip.data.topic.name}
                  </RobotoTypography>
                  <IconButton
                    aria-label="remove"
                    title="Remove Clip"
                    onClick={() => onRemoveClip(clip)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              }
              secondary={
                <TableContainer>
                  <Table size="small" className={styles.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className={classNames(
                            styles.tableCell,
                            styles.tableLabel,
                          )}
                        >
                          Dataset
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          <span title={datasetId}>{datasetId}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classNames(
                            styles.tableCell,
                            styles.tableLabel,
                          )}
                        >
                          File
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          <span title={fileName}>{fileName}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classNames(
                            styles.tableCell,
                            styles.tableLabel,
                          )}
                        >
                          Start
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          <span title={startTime}>{startTime}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classNames(
                            styles.tableCell,
                            styles.tableLabel,
                          )}
                        >
                          End
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          <span title={endTime}>{endTime}</span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classNames(
                            styles.tableCell,
                            styles.tableLabel,
                          )}
                        >
                          Duration
                        </TableCell>
                        <TableCell className={styles.tableCell}>
                          {endTime !== "Undefined" && startTime !== "Undefined"
                            ? (new Date(endTime).getTime() -
                                new Date(startTime).getTime()) /
                              1000
                            : "Undefined"}
                          s
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
