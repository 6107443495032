import type { McapTypes } from "@mcap/core";

import { AsyncEvent } from "@/shared/synchronization";

import type { Message } from "./Message";

enum MessageChunkStatus {
  Unloaded,
  Loading,
  Loaded,
  Error,
}

export class MessageChunk<T = unknown> {
  #abortController?: AbortController;
  #idx: number;
  #chunkEntry: McapTypes.ChunkIndex;
  #status: MessageChunkStatus;
  #loadedEvent: AsyncEvent;
  // Messages must be stored in ascending logTime order
  #messages: Message<T>[];

  constructor(chunkEntry: McapTypes.ChunkIndex, idx: number) {
    this.#chunkEntry = chunkEntry;
    this.#idx = idx;
    this.#loadedEvent = new AsyncEvent();
    this.#messages = [];
    this.#status = MessageChunkStatus.Unloaded;
  }

  public get aborted(): boolean {
    return this.#abortController?.signal.aborted ?? false;
  }

  public get index(): number {
    return this.#idx;
  }

  public get messages(): readonly Message<T>[] {
    return this.#messages;
  }

  public get status(): string {
    return MessageChunkStatus[this.#status];
  }

  public get start(): bigint {
    return this.#chunkEntry.messageStartTime;
  }

  public get end(): bigint {
    return this.#chunkEntry.messageEndTime;
  }

  public abort(): void {
    this.#abortController?.abort();
    this.unload();
  }

  public beginLoading(abortController?: AbortController): void {
    this.#abortController = abortController ?? new AbortController();
    this.#status = MessageChunkStatus.Loading;
    this.#loadedEvent.clear();
  }

  public containsTime(timeNs: bigint): boolean {
    const greaterThanStart = timeNs >= this.start;
    const lessThanEnd = timeNs <= this.end;
    return greaterThanStart && lessThanEnd;
  }

  /**
   * Return the message at the given timepoint,
   * or the message logged closest to the given timepoint.
   */
  public getMessageAsOfTime(searchTimeNs: bigint): Message<T> | undefined {
    if (this.#messages.length === 0) {
      return;
    }

    let low = 0;
    let high = this.#messages.length - 1;
    let result: Message<T> | undefined = undefined;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const message = this.#messages[mid];
      if (message.logTime <= searchTimeNs) {
        // Current message is a result candidate
        result = message;
        // Check if there are any messages after this one that are closer to the timepoint
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    return result;
  }

  public getMessagesInRange({
    start: startTime,
    end: endTime,
  }: {
    start?: bigint;
    end?: bigint;
  }): Message<T>[] {
    return this.#messages.filter((message) => {
      const start = startTime ?? message.logTime;
      const end = endTime ?? message.logTime;
      return message.logTime >= start && message.logTime <= end;
    });
  }

  public isLoaded(): boolean {
    return this.#status === MessageChunkStatus.Loaded;
  }

  public isLoading(): boolean {
    return this.#status === MessageChunkStatus.Loading;
  }

  public loadingDidError(): void {
    this.#status = MessageChunkStatus.Error;
    this.#loadedEvent.set();
  }

  public messagesLoaded(messages: Message<T>[]): void {
    this.#messages = messages;
    this.#status = MessageChunkStatus.Loaded;
    this.#loadedEvent.set();
  }

  public requiresLoad(): boolean {
    return (
      this.#status !== MessageChunkStatus.Loaded &&
      this.#status !== MessageChunkStatus.Loading
    );
  }

  public toDebugPayload(): Record<string, unknown> {
    return {
      index: this.index,
      start: this.start,
      end: this.end,
      messageCount: this.messages.length,
      status: this.status,
    };
  }

  public unload(): void {
    this.#status = MessageChunkStatus.Unloaded;
    this.#messages.length = 0;
    this.#loadedEvent.clear();
  }

  public waitUntilLoaded(signal?: AbortSignal): Promise<boolean> {
    if (signal?.aborted || this.#abortController?.signal.aborted) {
      return Promise.resolve(this.#loadedEvent.isSet);
    }

    // If a signal is provided in addition to the chunk's own abort controller,
    // cascade the abort signal to the chunk's abort controller.
    const cascadeAbort = () => {
      this.abort();
    };
    signal?.addEventListener("abort", cascadeAbort, { once: true });

    return this.#loadedEvent
      .wait(this.#abortController?.signal)
      .then((loaded) => {
        signal?.removeEventListener("abort", cascadeAbort);
        return loaded;
      });
  }
}
