import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { FileNode } from "@/shared/domain/files/filesystem";

export function useAnnotationFiles(
  datasetId: string | undefined,
  directoryPath: string | undefined,
) {
  const { files } = React.useContext(DomainServicesContext);

  return useQuery<FileNode[], Error>({
    queryKey: ["annotationFiles", datasetId, directoryPath],
    queryFn: async ({ signal }) => {
      if (datasetId === undefined || directoryPath === undefined) {
        return Promise.reject(new Error("Invalid datasetId or directoryPath"));
      }

      const jsonFilesResponse = await files.getFilesForDirectory({
        path: directoryPath,
        fileNameSearchTerm: ".json",
        datasetId,
        pageSize: 1000,
        showHiddenFiles: true,
        options: {
          abortSignal: signal,
        },
      });

      const jsonFileNodes: FileNode[] = jsonFilesResponse.items.map(
        (record) => {
          return {
            type: "file",
            name: record.relative_path.split("/").slice(-1)[0],
            file: record,
          };
        },
      );

      return jsonFileNodes;
    },
    enabled: datasetId !== undefined && directoryPath !== undefined,
  });
}
