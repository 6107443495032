import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";

import { HealthCheckResponse } from "../HealthRecord";

export function useHealthCheck() {
  const { healthService } = useDomainServices();

  return useQuery<HealthCheckResponse, Error>({
    queryKey: ["health-check"],
    queryFn: ({ signal }) => {
      return healthService.deepCheck({
        abortSignal: signal,
      });
    },
    // Health check should be relatively fresh
    staleTime: 30000,
  });
}
