import {
  Alert,
  AlertTitle,
  Box,
  Skeleton,
  TablePagination,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import { CommentRecord, CommentEntityType } from "@/shared/domain/comments";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { RobotoAPICall, listCommentsEndpoint } from "@/types";

import { AddEditComment } from "./AddEditComment";
import { CommentsList } from "./CommentsList";

interface CommentsBoxProps {
  entityType?: CommentEntityType;
  entityId?: string;
  userId?: string;
  initialCount?: number;
  summaryView?: boolean;
  enableTimestamps?: boolean;
}

export const CommentsBox: React.FC<CommentsBoxProps> = ({
  entityType,
  entityId,
  userId,
  initialCount,
  summaryView,
  enableTimestamps = false,
}) => {
  const [commentTableConfig, setCommentTableConfig] = useState<{
    page: number;
    rowsPerPage: 10 | 25 | 50;
  }>({ page: 0, rowsPerPage: 10 });

  const { orgRoles } = useAuth();

  const theme = useTheme();

  const [avatarMap, setAvatarMap] = useState<{ [key: string]: string }>({});
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    prependCache,
    removeItemFromCache,
    pageData,
    isNextPageAvailable,
    cacheLength,
    updateItemsInCache,
    loading,
  } = usePaginatedAPICall<CommentRecord>();

  const handleCommentAdded = (newComment: CommentRecord) => {
    prependCache(0, commentTableConfig.rowsPerPage, [newComment]);
  };

  const handleCommentUpdated = (comment: CommentRecord) => {
    const items: { [key: string]: CommentRecord } = {};
    items[comment.comment_id] = comment;

    updateItemsInCache(items, "comment_id");
  };

  const handleCommentDeleted = (comment: CommentRecord) => {
    removeItemFromCache(comment.comment_id, "comment_id");
  };

  useEffect(() => {
    if (orgRoles === null) {
      return;
    }

    const avatarMap: { [key: string]: string } = {};

    for (const role of orgRoles) {
      if (role.user.picture_url) {
        avatarMap[role.user.user_id] = role.user.picture_url;
      }
    }

    setAvatarMap(avatarMap);
  }, [orgRoles]);

  const { currentOrganization } = useAuth();

  useEffect(() => {
    let commentEndpoint = listCommentsEndpoint;

    // If no user or entity are provided, fetch all recent comments
    if (summaryView && !userId && !entityType && !entityId) {
      commentEndpoint = () => "/comments/recent";
    }

    const pathParams = {} as {
      entityType?: CommentEntityType;
      entityId?: string;
      userId?: string;
    };

    if (userId) {
      pathParams.userId = encodeURIComponent(userId);
    }
    if (entityType) {
      pathParams.entityType = entityType;
    }
    if (entityId) {
      pathParams.entityId = entityId;
    }

    const apiCall: RobotoAPICall = {
      endpoint: commentEndpoint,
      method: "GET",
      pathParams: pathParams,
      orgId: currentOrganization?.org_id,
    };

    void getFirstPage(apiCall, initialCount || 10);
  }, [
    currentOrganization?.org_id,
    userId,
    entityId,
    entityType,
    getFirstPage,
    initialCount,
    summaryView,
  ]);

  useEffect(() => {
    if (!loading) {
      setFirstLoading(false);
    }
  }, [loading]);

  return (
    <Box>
      {!firstLoading ? (
        <>
          {!summaryView && entityType && entityId && (
            <>
              <Box>
                <AddEditComment
                  entityType={entityType}
                  entityId={entityId}
                  onCommentSaved={handleCommentAdded}
                  enableTimestamps={enableTimestamps}
                />
              </Box>
            </>
          )}

          <Box
            sx={{
              mb: theme.spacing(2),
            }}
          >
            {summaryView && pageData.length === 0 && !loading && (
              <Alert severity="warning">
                <AlertTitle>Comments</AlertTitle>
                No comments have been left yet.
              </Alert>
            )}

            <Box>
              <CommentsList
                avatarMap={avatarMap}
                comments={pageData}
                onCommentDeleted={handleCommentDeleted}
                onCommentUpdated={handleCommentUpdated}
                summaryView={summaryView}
              />
            </Box>
          </Box>

          {!summaryView && (
            <TablePagination
              component={Box}
              count={cacheLength}
              rowsPerPageOptions={[10, 25, 50]}
              page={commentTableConfig.page}
              nextIconButtonProps={{
                disabled: !isNextPageAvailable,
              }}
              onPageChange={(_e, newPage) => {
                if (newPage > commentTableConfig.page) {
                  void fetchNextPage(
                    newPage,
                    commentTableConfig.rowsPerPage,
                    "url",
                  );
                } else {
                  fetchPreviousPage(newPage, commentTableConfig.rowsPerPage);
                }

                setCommentTableConfig((prev) => {
                  return {
                    ...prev,
                    page: newPage,
                  };
                });
              }}
              rowsPerPage={commentTableConfig.rowsPerPage}
              onRowsPerPageChange={(e) => {
                const newRows = parseInt(e?.target?.value) as 10 | 25 | 50;

                void onRowsPerPageChange(newRows);

                setCommentTableConfig((prev) => {
                  return {
                    ...prev,
                    rowsPerPage: newRows ?? 10,
                  };
                });
              }}
              labelRowsPerPage={"Comments per page:"}
              sx={{
                borderTop: `1px solid ${theme.border.color}`,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: theme.border.radius,
                borderBottomRightRadius: theme.border.radius,
                display: cacheLength > 0 ? "block" : "none",
              }}
            />
          )}
        </>
      ) : (
        <Box
          sx={{
            mb: theme.spacing(2),
          }}
        >
          <Skeleton animation="wave" variant="rounded" height={70} />
        </Box>
      )}
    </Box>
  );
};
