import { ErrorDetail, MessagePayload } from "@/shared/webworker";

import { IndexState } from "../IndexState";

export enum LogEvent {
  Error = "error",
  Initialized = "initialized",
  LoadingStateChange = "loading-state-change",
  LogsBuffered = "logs-buffered",
  LogTimeIndexStateChange = "logtime-index-state-change",
  NumLogsChanged = "num-logs-changed",
  SearchIndexStateChange = "search-index-state-change",
}

export interface LogEventPayloadMap {
  [LogEvent.Error]: ErrorDetail | Error;
  [LogEvent.Initialized]: undefined;
  [LogEvent.LoadingStateChange]: { isLoading: boolean };
  [LogEvent.LogsBuffered]: { count: number };
  [LogEvent.LogTimeIndexStateChange]: {
    state: IndexState;
    index?: ArrayBuffer;
  };
  [LogEvent.NumLogsChanged]: { numLogs: number };
  [LogEvent.SearchIndexStateChange]: { state: IndexState };
}

export function isErrorEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.Error]>
> {
  return event.data.type === LogEvent.Error;
}

export function isInitializedEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.Initialized]>
> {
  return event.data.type === LogEvent.Initialized;
}

export function isLoadingStateChangeEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.LoadingStateChange]>
> {
  return event.data.type === LogEvent.LoadingStateChange;
}

export function isLogTimeIndexStateChangeEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.LogTimeIndexStateChange]>
> {
  return event.data.type === LogEvent.LogTimeIndexStateChange;
}

export function isSearchIndexStateChangeEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<LogEvent, LogEventPayloadMap[LogEvent.SearchIndexStateChange]>
> {
  return event.data.type === LogEvent.SearchIndexStateChange;
}
