interface TruncateOptions {
  characterLimit: number;
}

/**
 * Truncates a string to `characterLimit` characters and adds an ellipsis.
 *
 * @param {string} string - The string to truncate
 * @param {number} options.characterLimit - The number of characters to truncate to
 * @returns {string} The truncated string
 */
export function truncate(string: string, options: TruncateOptions): string {
  if (string.length < options.characterLimit) {
    return string;
  } else {
    return string.slice(0, options.characterLimit) + "...";
  }
}
