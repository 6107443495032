import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";

import { AutocompleteType } from "../TagAndMetadataAutocomplete";
import { useElementsForAutocompleteType } from "../TagAndMetadataAutocomplete/hook";

interface EventNameInputProps {
  /**
   * Current event name (if we're updating an existing event)
   */
  currentName?: string;

  /**
   * Callback to let the parent form component know of event name changes.
   */
  onNameChanged: (newName: string | null) => void;

  /**
   * Text size for the event name.
   */
  size?: "small";

  /**
   * If `true`, the event name input is disabled.
   */
  disabled?: boolean;

  /**
   * Whether to auto-focus on the event name input field.
   */
  autoFocus?: boolean;

  /**
   * If `true`, the event name label is displayed in an error state.
   */
  error?: boolean;

  /**
   * The helper text content.
   */
  helperText?: string;

  /**
   * Styles for the TextField's input element
   */
  inputClassName?: string;
}

/**
 * Provide automatic event name suggestions when creating or editing events.
 *
 * @param props properties for the event name input field
 * @returns a MUI Autocomplete component for event names
 */
export const EventNameInput: React.FC<EventNameInputProps> = (
  props: EventNameInputProps,
) => {
  const { elements: eventNames } = useElementsForAutocompleteType(
    AutocompleteType.EventNames,
  );

  return (
    <Autocomplete
      data-cy="eventNameAutocomplete"
      value={props.currentName}
      onInputChange={(_, newInputValue: string) => {
        props.onNameChanged(newInputValue);
      }}
      freeSolo
      openOnFocus
      options={eventNames}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          id="name"
          placeholder="Name"
          autoFocus={props.autoFocus}
          fullWidth
          disabled={props.disabled}
          error={props.error}
          helperText={props.helperText}
          size={props.size}
          InputProps={{
            ...params.InputProps,
            className: props.inputClassName,
          }}
        />
      )}
      componentsProps={{
        paper: {
          sx: {
            fontSize: "0.875rem",
          },
        },
      }}
    />
  );
};
