import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  styled,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { type TopicNode } from "@/shared/domain/topics";
import { timeToSeconds } from "@/shared/mcap";

import { MessagePathsDetail } from "../MessagePathsDetail";
import { RobotoTypography } from "../RobotoTypography";

export const MetadataTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const tableFontSize = "0.8rem";
const missingDataTerm = "Unknown";

interface TopicsTableRowProps {
  topicNode: TopicNode;
}

export const TopicsTableRow: React.FC<TopicsTableRowProps> = ({
  topicNode,
}) => {
  const theme = useTheme();

  const [expandedMsgPaths, setExpandedMsgPaths] =
    React.useState<boolean>(false);

  return (
    <>
      <MetadataTableRow key={topicNode.data.topic_id}>
        <TableCell>
          <Box display={"flex"} alignItems={"center"} gap={theme.spacing(2)}>
            <IconButton
              onClick={() => setExpandedMsgPaths((prevState) => !prevState)}
              sx={{
                padding: theme.spacing(0.25),
                transform: expandedMsgPaths ? "none" : "rotate(270deg)",
              }}
            >
              <ExpandCircleDownOutlinedIcon
                color={"primary"}
                fontSize="small"
              />
            </IconButton>
            <RobotoTypography variant="body2" fontSize={tableFontSize}>
              {topicNode.data.topic_name}
            </RobotoTypography>
          </Box>
        </TableCell>
        <TableCell>
          <RobotoTypography variant="body2" fontSize={tableFontSize}>
            {topicNode.data.message_count || missingDataTerm}
          </RobotoTypography>
        </TableCell>
        <TableCell>
          <RobotoTypography variant="body2" fontSize={tableFontSize}>
            {topicNode.data.schema_name || missingDataTerm}
          </RobotoTypography>
        </TableCell>
        <TableCell>
          <RobotoTypography variant="body2" fontSize={tableFontSize}>
            {topicNode.data.start_time !== null
              ? timeToSeconds(topicNode.data.start_time)
              : missingDataTerm}
          </RobotoTypography>
        </TableCell>
        <TableCell>
          <RobotoTypography variant="body2" fontSize={tableFontSize}>
            {topicNode.data.end_time !== null
              ? timeToSeconds(topicNode.data.end_time)
              : missingDataTerm}
          </RobotoTypography>
        </TableCell>
      </MetadataTableRow>

      {expandedMsgPaths && (
        <TableRow>
          <TableCell colSpan={6}>
            <Box
              display={"flex"}
              margin={theme.spacing(1.5)}
              padding={theme.spacing(1, 2, 1, 2)}
              border={"1px solid"}
              borderColor={theme.border.color}
            >
              <MessagePathsDetail
                topicId={topicNode.data.topic_id}
                topicAssociation={topicNode.data.association}
                showTitle={false}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
