import { Divider, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSystemUser } from "@/providers/auth/hooks.ts";
import { RobotoStyledTab, RobotoStyledTabs } from "@/shared/components";
import { AIBadge } from "@/shared/components/ai";

import { DatasetActionStatus } from "./DatasetActionStatus";
import { useActiveDatasetTab, DatasetTab } from "./useActiveDatasetTabFromUrl";

interface DatasetTabsProps {
  datasetId: string;
}

export function DatasetTabs({ datasetId }: DatasetTabsProps) {
  const theme = useTheme();
  const { isSystemUser } = useSystemUser();
  const [activeTab, setActiveTab] = useActiveDatasetTab();
  const [pathCache, setPathCache] = useState("");
  const location = useLocation();

  const handleTabSwitch = useCallback(
    (currentTab: DatasetTab, nextTab: DatasetTab) => {
      if (currentTab === DatasetTab.Files) {
        setPathCache(location.pathname + location.search);
      }

      setActiveTab(nextTab);
    },
    [location, setActiveTab, setPathCache],
  );

  return (
    <div
      style={{
        marginTop: theme.spacing(1),
      }}
    >
      <RobotoStyledTabs
        value={activeTab}
        onChange={(_event, nextTab: DatasetTab) => {
          handleTabSwitch(activeTab, nextTab);
        }}
      >
        <RobotoStyledTab
          value={DatasetTab.Files}
          label="Files"
          component={Link}
          to={pathCache}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Topics}
          label="Topics"
          component={Link}
          to={"topics"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Events}
          label="Events"
          component={Link}
          to={"events"}
          replace
        />
        <RobotoStyledTab
          icon={<DatasetActionStatus datasetId={datasetId} />}
          iconPosition="end"
          value={DatasetTab.Actions}
          label="Actions"
          component={Link}
          to={"actions"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Details}
          label="Details"
          component={Link}
          to={"details"}
          replace
        />
        <RobotoStyledTab
          value={DatasetTab.Permissions}
          label="Permissions"
          component={Link}
          to={"permissions"}
          replace
        />
        {isSystemUser && (
          <RobotoStyledTab
            value={DatasetTab.AISummary}
            icon={<AIBadge />}
            iconPosition="start"
            label="&nbsp;&nbsp;AI"
            component={Link}
            to={"summary"}
            replace
          />
        )}
      </RobotoStyledTabs>
      <Divider
        sx={{
          borderColor: theme.border.color,
        }}
      />
    </div>
  );
}
