import {
  Scene,
  Vector3,
  Engine,
  Color4,
  ArcRotateCamera,
  GaussianSplattingMesh,
} from "@babylonjs/core";
import { useTheme } from "@mui/material";
import * as React from "react";

import { LayoutItem, ThreeDPanelState } from "@/shared/state/visualization";

import { PanelHeader } from "../PanelHeader";
import { PanelLayout } from "../PanelLayout";

import styles from "./ThreeDPanel.module.css";

interface ThreeDPanelProps {
  layout: LayoutItem;
  state: ThreeDPanelState;
}

export function ThreeDPanel({ layout, state }: ThreeDPanelProps) {
  const theme = useBabylonTheme();
  const [index] = React.useState(0);
  const canvasRef = React.useRef<HTMLCanvasElement>();
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(
    /**
     * This is lifted from the babylonjs gaussian splat demo: https://playground.babylonjs.com/#45KYTJ#61
     */
    function initGaussianSplatDemo() {
      if (canvasRef.current === undefined) {
        return;
      }
      const canvas = canvasRef.current;

      // Absorb canvas mouse scrolls.
      function absorbMouseWheelEvents(event: MouseEvent) {
        event.preventDefault();
        return false;
      }
      canvas.addEventListener("wheel", absorbMouseWheelEvents, false);

      // Start Babylon's engine,
      const engine = new Engine(canvas);

      // This creates a basic Babylon Scene object (non-mesh)
      const scene = new Scene(engine);
      // TODO(#1179): Use proper state management
      // for the babylon constructs -- we don't want to have to re-build and re-render an entire 3d model
      // just to react to the color changes.
      scene.clearColor = theme.backgroundColor;

      // This creates and positions a free camera (non-mesh)
      const camera = new ArcRotateCamera(
        "camera1",
        -0.8,
        1.2,
        10,
        new Vector3(0, 0, 0),
        scene,
      );
      camera.wheelPrecision = 100;
      camera.inertia = 0.97;

      // This attaches the camera to the canvas
      camera.attachControl(canvas, true);

      let mesh: GaussianSplattingMesh | null = null;
      function loadMesh() {
        const splatUrls = [
          "https://assets.babylonjs.com/splats/gs_Sqwakers_trimed.splat",
          "https://assets.babylonjs.com/splats/gs_Skull.splat",
          "https://assets.babylonjs.com/splats/gs_Plants.splat",
          "https://assets.babylonjs.com/splats/gs_Fire_Pit.splat",
        ];
        mesh?.dispose();
        // Create a new mesh, attach it to the scene, and tell it to load itself from one of the given URLs.
        mesh = new GaussianSplattingMesh("gs", splatUrls[index], scene);

        const cameraRadii = [5, 5, 7, 9];
        const cameraAlphas = [-0.8, 2.9, -0.9, 0.8];
        camera.radius = cameraRadii[index];
        camera.alpha = cameraAlphas[index];
      }

      // Display loading screen while loading assets
      setIsLoading(true);

      loadMesh();

      // The Babylon scene will invoke this callback when any loading resources it contains
      // finish loading and the scene is ready to render.
      scene.executeWhenReady(function () {
        setIsLoading(false);
      });

      // Put the camera in a reasonable area to view the demo's model.
      scene.onBeforeRenderObservable.add(() => {
        camera.beta = Math.min(camera.beta, 1.45);
        camera.radius = Math.max(camera.radius, 3);
        camera.radius = Math.min(camera.radius, 6);
      });

      engine.runRenderLoop(function render() {
        scene.render();
      });

      // Track screen resizes
      const observer = new ResizeObserver(function onBabylonCanvasResized() {
        engine.resize();
        scene.render();
      });
      observer.observe(canvas);

      return function disposeScene() {
        canvas.removeEventListener("wheel", absorbMouseWheelEvents);
        // Failing to call `dispose` on a Babylon resource will lead to memory leaks.
        // We gotta be careful at the boundary between React and Babylon to clean up
        // *all* Babylon stuff when the component hosting Babylon unmounts.
        scene.dispose();
        mesh?.dispose();
        engine.dispose();
        observer.disconnect();
      };
    },
    [canvasRef, setIsLoading, theme.backgroundColor, index],
  );

  return (
    <PanelLayout
      header={<PanelHeader className={styles.panelHeader} state={state} />}
      isLoading={isLoading}
      layout={layout}
      state={state}
    >
      <canvas
        className={styles.canvas}
        ref={(ref) => (canvasRef.current = ref ?? undefined)}
      />
      <div className={styles.comingSoon}>Coming Soon!</div>
    </PanelLayout>
  );
}

interface BabylonTheme {
  backgroundColor: Color4;
}
function useBabylonTheme(): BabylonTheme {
  const theme = useTheme();
  const babylonTheme = React.useMemo(
    () => ({
      backgroundColor: Color4.FromHexString(theme.palette.paper.main),
    }),
    [theme],
  );
  return babylonTheme;
}
