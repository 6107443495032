import { ExponentialBackoff, handleWhen, retry } from "cockatiel";

function isNoModificationAllowedError(err: unknown) {
  return (
    err instanceof DOMException && err.name === "NoModificationAllowedError"
  );
}

export class OPFSPath {
  public lastAccessTime: Date | null = null;
  public readonly name: string;
  public readonly parents: string[];

  #size: number | null = null;

  constructor({ name, parents = [] }: { parents?: string[]; name: string }) {
    this.name = name;
    this.parents = parents;
  }

  public async delete() {
    let container = await navigator.storage.getDirectory();
    for (const dir of this.parents) {
      const child = await container.getDirectoryHandle(dir, { create: false });
      container = child;
    }
    await container.removeEntry(this.name);
  }

  /**
   * Will throw a NotFoundError (DOMException) if file is not found.
   * https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryHandle/getFileHandle#exceptions
   *
   * The returned FileSystemSyncAccessHandle MUST be closed once finished with it.
   */
  public getReadOnlySyncFileHandle(
    abortSignal?: AbortSignal,
  ): Promise<FileSystemSyncAccessHandle> {
    return this.#getSyncAccessHandle(false, abortSignal);
  }

  public async getSize(abortSignal?: AbortSignal): Promise<number> {
    if (this.#size === null) {
      const fileHandle = await this.getReadOnlySyncFileHandle(abortSignal);
      try {
        this.#size = fileHandle.getSize();
      } finally {
        fileHandle.close();
      }
    }
    return this.#size;
  }

  /**
   * The returned FileSystemSyncAccessHandle MUST be closed once finished with it.
   */
  public getWriteableSyncFileHandle(
    abortSignal?: AbortSignal,
  ): Promise<FileSystemSyncAccessHandle> {
    // Assume that if asking for a writeable handle, the file size will change
    // So clear cached value if present
    this.#size = null;
    return this.#getSyncAccessHandle(true, abortSignal);
  }

  public toString(): string {
    if (this.parents.length === 0) {
      return this.name;
    }
    // This str repr is not used for determining path on disk,
    // so won't be sensitive to platform differences with path separators
    return `${this.parents.join("/")}/${this.name}`;
  }

  /**
   * There can only be one FileSystemSyncAccessHandle per path. From the docs:
   *  > Creating a FileSystemSyncAccessHandle takes an exclusive lock on the file associated with the file handle.
   *  > This prevents the creation of further FileSystemSyncAccessHandles or FileSystemWritableFileStreams
   *  > for the file until the existing access handle is closed.
   *  https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/createSyncAccessHandle
   *
   * Once the "mode" option of createSyncAccessHandle has better browser support,
   * we should set "read-only" mode when opening a read-only handle.
   */
  async #getSyncAccessHandle(writeable: boolean, abortSignal?: AbortSignal) {
    const retryGetSyncAccessHandlePolicy = retry(
      handleWhen(isNoModificationAllowedError),
      {
        backoff: new ExponentialBackoff({
          initialDelay: 1, // ms
        }),
        maxAttempts: 20,
      },
    );

    try {
      return await retryGetSyncAccessHandlePolicy.execute(
        async (retryContext) => {
          if (retryContext.signal.aborted) {
            throw new DOMException("Aborted", "AbortError");
          }
          const opfsRoot = await navigator.storage.getDirectory();
          let parentDirHandle = opfsRoot;
          const parents = [...this.parents];
          while (parents.length > 0) {
            const parent = parents.shift();
            if (parent === undefined) {
              break;
            }
            parentDirHandle = await parentDirHandle.getDirectoryHandle(parent, {
              create: writeable,
            });
          }
          const fileHandle = await parentDirHandle.getFileHandle(this.name, {
            create: writeable,
          });
          return await fileHandle.createSyncAccessHandle();
        },
        abortSignal,
      );
    } catch (err) {
      // on give up retrying to get a file system sync access handle
      // remap the exception to something more user facing
      if (isNoModificationAllowedError(err)) {
        throw new Error(
          "Encountered a temporary problem accessing data. Please reload this panel to retry.",
          { cause: err },
        );
      }
      // something else happened, rethrow
      throw err;
    }
  }
}
