import {
  type MessagePathAttr,
  type MessagePathPart,
  type MessagePathSlice,
  type MessagePathRecord,
  CanonicalDataType,
  MessagePathPartType,
} from "../MessagePathRecord";
import { PathParser } from "../PathParser";
import {
  type RepresentationRecord,
  RepresentationStorageFormat,
} from "../RepresentationRecord";
import { type TopicRecord } from "../TopicRecord";

abstract class TreeNode<T> {
  #children: Map<string, MessagePathNode>;
  #data: T;
  #depth: number = 0;
  #label: string;

  constructor(props: { data: T; label: string }) {
    this.#children = new Map();
    this.#data = props.data;
    this.#label = props.label;
  }

  public get children(): MessagePathNode[] {
    return Array.from(this.#children.values()).sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { numeric: true }),
    );
  }

  public get data(): T {
    return this.#data;
  }

  public get depth(): number {
    return this.#depth;
  }

  public abstract get id(): string;

  public get label(): string {
    return this.#label;
  }

  abstract findRepresentation(
    format: RepresentationStorageFormat,
  ): RepresentationRecord | null;

  *[Symbol.iterator](): Iterator<MessagePathNode> {
    for (const child of this.children) {
      yield child; // Yield the immediate child
      yield* child; // Delegate to the child’s iterator to yield its children
    }
  }

  public addChild = (
    messagePathRecord: MessagePathRecord,
    label: string,
  ): MessagePathNode => {
    if (this.#wouldCreateCycle(messagePathRecord.message_path_id)) {
      throw new Error(
        `Cycle detected attempting to attach node ${messagePathRecord.message_path_id} to ${this.id}`,
      );
    }

    if (!(this instanceof MessagePathNode) && !(this instanceof TopicNode)) {
      throw new Error("Unable to add a child to an unknown TreeNode type");
    }

    const node = new MessagePathNode({
      data: messagePathRecord,
      label,
      parent: this,
    });
    node.#depth = this.depth + 1;
    this.#children.set(node.id, node);

    return node;
  };

  public findChildByLabel = (label: string): MessagePathNode | undefined => {
    for (const child of this.#children.values()) {
      if (child.label === label) {
        return child;
      }
    }
  };

  public hasChild = (child: MessagePathNode) => {
    return this.#children.has(child.id);
  };

  public isLeafNode = (): boolean => {
    return this.children.length === 0;
  };

  public removeChild = (child: MessagePathNode) => {
    this.#children.delete(child.id);
  };

  public toJson = () => {
    return this.data;
  };

  #wouldCreateCycle = (id: string): boolean => {
    // Start from this node as the potential parent and walk up
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let current: TreeNode<unknown> | null = this;

    while (current !== null) {
      // If we hit this node, we found a cycle
      if (current.id === id) {
        return true;
      }
      // Walk up the parent chain
      current = current instanceof MessagePathNode ? current.parent : null;
    }

    return false;
  };
}

export class TopicNode extends TreeNode<TopicRecord> {
  public get id() {
    return this.data.topic_id;
  }

  public get name() {
    return this.data.topic_name;
  }

  public findRepresentation = (
    format: RepresentationStorageFormat = RepresentationStorageFormat.MCAP,
  ): RepresentationRecord | null => {
    if (
      this.data.default_representation &&
      this.data.default_representation.storage_format === format
    ) {
      return this.data.default_representation;
    }
    return null;
  };

  public toString = () => {
    return this.name;
  };
}

/**
 * Do not manually construct a MessagePathNode, use `TreeNode::addChild` instead.
 */
export class MessagePathNode extends TreeNode<MessagePathRecord> {
  #parent: MessagePathNode | TopicNode;
  #parts: MessagePathPart[] | null = null;

  public static partsToString(parts: MessagePathPart[]): string {
    function messagePathSliceToString(slice: MessagePathSlice): string {
      const end = slice.end ?? Infinity;
      if (end - slice.start === 1) {
        return `${slice.start}`;
      }
      const formattedEnd = end === Infinity ? "" : end;
      const range = `${slice.start}:${formattedEnd}`;
      if (range === "0:") {
        return ":";
      }
      return range;
    }

    return parts.reduce((accum, part) => {
      if (part.type === MessagePathPartType.Attr) {
        return `${accum}${accum.length > 0 ? "." : ""}${part.attribute}`;
      }
      return `${accum}[${messagePathSliceToString(part)}]`;
    }, "");
  }

  constructor(props: {
    data: MessagePathRecord;
    label: string;
    parent: MessagePathNode | TopicNode;
    parts?: MessagePathPart[];
  }) {
    super(props);

    this.#parent = props.parent;

    if (props.parts !== undefined) {
      this.#parts = props.parts;
    }
  }

  get id() {
    return this.data.message_path_id;
  }

  get parent(): MessagePathNode | TopicNode {
    return this.#parent;
  }

  get topic(): TopicNode {
    let parent = this.#parent;
    while (parent instanceof MessagePathNode) {
      parent = parent.parent;
    }
    return parent;
  }

  public equals = (other: unknown): boolean => {
    if (!(other instanceof MessagePathNode)) {
      return false;
    }

    if (this.id !== other.id) {
      return false;
    }

    const selfParts = this.toParts();
    const otherParts = other.toParts();
    if (selfParts.length !== otherParts.length) {
      return false;
    }

    return selfParts.every((part, idx) => {
      const correspondingPart = otherParts[idx];
      for (const key of Object.keys(part)) {
        const coercedKey = key as keyof MessagePathPart;
        if (part[coercedKey] !== correspondingPart[coercedKey]) {
          return false;
        }
      }
      return true;
    });
  };

  /**
   * Find a pointer to the data for the given topic message path.
   *
   * Walks up the message path tree, from the current node up to the topic record,
   * and returns the first appropriate representation it finds.
   *
   * If no appropriate representation is found, return `null`.
   */
  public findRepresentation = (
    format: RepresentationStorageFormat = RepresentationStorageFormat.MCAP,
  ): RepresentationRecord | null => {
    const representation = this.data.representations.find((representation) => {
      return representation.storage_format === format;
    });

    if (representation !== undefined) {
      return representation;
    }

    return this.parent.findRepresentation(format);
  };

  /**
   * Is data represented by the current MessagePathNode contained in the same
   * MCAP file as another topic message path that contains bytes?
   *
   * Used to prevent loading data in a Raw Message panel if the records contain large binary payloads.
   * As of July 2024, doing that crashes the Raw Message panel.
   * If the data fetching approach of the Raw Message panel is updated to account for/avoid this,
   * this check can be safely removed.
   */
  public sharesRepresentationWithBinaryField = (): boolean => {
    // If the given message path is itself a binary field, short-circuit further evaluation.
    if (
      this.data.canonical_data_type === CanonicalDataType.Image ||
      this.data.canonical_data_type === CanonicalDataType.Byte
    ) {
      return true;
    }

    const representation = this.findRepresentation(
      RepresentationStorageFormat.MCAP,
    );
    if (representation === null) {
      // If we don't have a reference to where the bytes of this path of topic data are stored,
      // then the rest of this doesn't matter, because we can't visualize this path of topic data anyway.
      return false;
    }

    // ...then BFS to find if any parents/siblings/cousins/aunts to the current message path represent bytes-like values.
    const queue: MessagePathNode[] = [...this.topic.children];
    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) {
        continue;
      }
      if (
        // The node being evaluated is bytes-like...
        (current.data.canonical_data_type === CanonicalDataType.Image ||
          current.data.canonical_data_type === CanonicalDataType.Byte) &&
        // ...and those bytes are in the same MCAP file as the target message path's
        current.findRepresentation(RepresentationStorageFormat.MCAP)
          ?.representation_id === representation.representation_id
      ) {
        return true;
      }
      queue.push(...current.children);
    }

    return false;
  };

  public toParts = (): MessagePathPart[] => {
    if (this.#parts === null) {
      const parentParts =
        this.#parent instanceof MessagePathNode ? this.#parent.toParts() : [];
      const lastSegment = PathParser.splitMessagePath(
        this.data.message_path,
      ).pop();
      this.#parts = [...parentParts];

      const attr: MessagePathAttr = {
        type: MessagePathPartType.Attr,
        attribute: lastSegment ?? this.data.message_path,
        dataType: this.data.canonical_data_type,
      };
      this.#parts.push(attr);

      if (this.data.canonical_data_type === CanonicalDataType.Array) {
        const slice: MessagePathSlice = {
          type: MessagePathPartType.Slice,
          start: 0,
          end: Infinity,
        };
        this.#parts.push(slice);
      }
    }
    return this.#parts;
  };

  public toString = () => {
    return [
      this.topic.name,
      PathParser.TOPIC_SEPARATOR,
      MessagePathNode.partsToString(this.toParts()),
    ].join("");
  };

  public toTopicData = () => {
    const topic = this.topic;
    const representation = this.findRepresentation(
      RepresentationStorageFormat.MCAP,
    );
    if (!representation) {
      throw new Error(
        `Cannot determine visualization-specific representation for ${topic.data.topic_name}`,
      );
    }

    return {
      topic: {
        id: topic.data.topic_id,
        name: topic.data.topic_name,
        association: topic.data.association,
        startTime: topic.data.start_time?.toString(),
        endTime: topic.data.end_time?.toString(),
        messageCount: topic.data.message_count ?? undefined,
      },
      messagePath: {
        id: this.data.message_path_id,
        dotPath: this.data.message_path,
        metadata: this.data.metadata,
        parts: this.toParts(),
      },
      representation: {
        association: representation.association,
        id: representation.representation_id,
        format: representation.storage_format,
      },
    };
  };

  /**
   * Create copy of node with modified slice bounds.
   * This node is unattached to the rest of the tree.
   * Used for selecting specific array indices when matching user-specified paths (e.g., in DataSourceSelector).
   */
  public withModifiedSlicePart = (
    partIndex: number,
    { start, end }: { start?: number; end?: number },
  ): MessagePathNode => {
    const parts = [...this.toParts()];
    const slicePart = parts[partIndex];

    if (
      slicePart === undefined ||
      slicePart.type !== MessagePathPartType.Slice
    ) {
      throw new Error("Cannot update message path part");
    }

    const updatedSlicePart = {
      ...slicePart,
      start: start ?? slicePart.start,
      end: end ?? slicePart.end,
    };
    if (updatedSlicePart.end === undefined) {
      updatedSlicePart.end = updatedSlicePart.start += 1;
    }
    parts.splice(partIndex, 1, updatedSlicePart);

    const updated = new MessagePathNode({
      data: this.data,
      label: this.label,
      parent: this.parent,
      parts,
    });

    return updated;
  };
}
