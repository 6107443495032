import HelpIcon from "@mui/icons-material/Help";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";

import styles from "./MessagePathHelp.module.css";

const HELP_TEXT = (
  <>
    <p>
      To edit segments of a message path that specify an array, specify either a
      0-based index, e.g.:
    </p>
    <ul>
      <li className={styles.example}>
        <code>[0]</code>
        <span>The first element in the array</span>
      </li>
      <li className={styles.example}>
        <code>[4]</code>
        <span>The fifth element in the array</span>
      </li>
    </ul>
    <p>or a range in slice notation (exclusive of end), e.g.:</p>
    <ul>
      <li className={styles.example}>
        <code>[2:5]</code>
        <span>The third and fourth elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>[2:&nbsp;]</code>
        <span>The third through last elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>[&nbsp;:3]</code>
        <span>The first through third elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>[&nbsp;:&nbsp;]</code>
        <span>All elements in the array</span>
      </li>
    </ul>
  </>
);

interface MessagePathHelpProps {
  show: boolean;
}

/**
 * Help text rendered in a tooltip for the MessagePathEditor.
 */
export function MessagePathHelp({ show }: MessagePathHelpProps) {
  if (!show) {
    return null;
  }

  return (
    <InputAdornment position="end">
      <Tooltip title={HELP_TEXT}>
        <IconButton aria-label="help" size="small">
          <HelpIcon className={styles.icon} />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
}
