import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { EventDescriptionForm } from "@/shared/components/events";
import { EventRecord } from "@/shared/domain/events";

import styles from "./EditableEventDescription.module.css";
import { EventDescription } from "./EventDescription";

interface EditableEventDescriptionProps {
  event: EventRecord;
  onUpdateEvent: (event: EventRecord) => void;
  readonly: boolean;
}

export function EditableEventDescription({
  event,
  onUpdateEvent,
  readonly = false,
}: EditableEventDescriptionProps) {
  const theme = useTheme();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  if (isEditing) {
    return (
      <EventDescriptionForm
        id={event.event_id}
        description={event.description}
        onSubmit={(event: EventRecord) => {
          setIsEditing(false);
          if (event) {
            onUpdateEvent(event);
          }
        }}
        onCancel={() => setIsEditing(false)}
        inputClassName={styles.description}
      />
    );
  }

  return (
    <Box
      onClick={() => !readonly && setIsEditing(true)}
      sx={
        readonly
          ? {}
          : {
              ":hover": {
                backgroundColor: theme.palette.hoverBackground.main,
                borderRadius: "0.25rem",
                cursor: "pointer",
              },
            }
      }
    >
      <EventDescription description={event.description} readonly={readonly} />
    </Box>
  );
}
