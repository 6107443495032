import OpenWithIcon from "@mui/icons-material/OpenWith";
import IconButton from "@mui/material/IconButton";

import { usePlotPanelContext } from "../panelContext";

import { PlotTool } from "./PlotTool";

export function MoveTool() {
  const { activeTool, setActiveTool } = usePlotPanelContext();
  return (
    <IconButton
      aria-label="pan-chart"
      onClick={() => setActiveTool(PlotTool.Move)}
      size="small"
      title="Move around the chart"
    >
      <OpenWithIcon
        color={activeTool === PlotTool.Move ? "primary" : "inherit"}
        fontSize="small"
      />
    </IconButton>
  );
}
