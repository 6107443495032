import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";
import * as React from "react";

import { useAuth } from "@/providers";
import { RobotoLinkHoverUnderline } from "@/shared/components";
import { FileRecord } from "@/shared/domain/files";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";

import { datasetFilesQueryEndpoint } from "../../model";

interface ActionFilesPreviewProps {
  inputData: string[] | undefined;
  datasetId: string | null;
}

export const ActionFilesPreview: React.FC<ActionFilesPreviewProps> = ({
  inputData,
  datasetId,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const [filesPreviewPage, setFilesPreviewPage] = React.useState<number>(0);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    isNextPageAvailable,
    pageData: fileData,
    loading: fileDataLoading,
    error: fileDataError,
  } = usePaginatedAPICall<FileRecord>();

  const fetchInputFiles = React.useCallback(async () => {
    if (!datasetId || !inputData) {
      return;
    }
    setFilesPreviewPage(0);
    await getFirstPage(
      {
        endpoint: datasetFilesQueryEndpoint,
        method: "POST",
        orgId: currentOrganization?.org_id,
        pathParams: { datasetId: datasetId },
        requestBody: JSON.stringify({
          include_patterns: inputData,
          exclude_patterns: [".*/**/*"],
        }),
      },
      10,
    );
  }, [getFirstPage, currentOrganization?.org_id, datasetId, inputData]);

  // Memoize the debounced function
  const debouncedFetchInputFiles = React.useMemo(
    () => debounce(fetchInputFiles, 500),
    [fetchInputFiles],
  );

  React.useEffect(() => {
    // Call the debounced function instead of fetchInputFiles directly
    void debouncedFetchInputFiles();
    // Cleanup function to cancel any pending debounced calls
    return () => debouncedFetchInputFiles.cancel();
  }, [debouncedFetchInputFiles]);

  let fileDataContent;

  if (fileDataError || fileData.length === 0) {
    fileDataContent = (
      <ListItem>
        <ListItemText
          primary="No matching files found"
          primaryTypographyProps={{
            variant: "body2",
          }}
        />
      </ListItem>
    );
  } else if (fileDataLoading) {
    fileDataContent = (
      <Skeleton animation="wave" variant="rounded" height={150} />
    );
  } else {
    fileDataContent = (
      <>
        {fileData.map((item, idx) => {
          return (
            <ListItem
              key={idx}
              sx={{
                pl: theme.spacing(1),
                pr: theme.spacing(1),
              }}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <InsertDriveFileOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={item.relative_path}
                primaryTypographyProps={{
                  variant: "body2",
                  sx: {
                    fontSize: "0.8rem",
                  },
                }}
                sx={{
                  wordBreak: "break-all",
                }}
              />
            </ListItem>
          );
        })}
      </>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        component={Paper}
        variant="outlined"
        sx={{
          p: theme.spacing(2),
          opacity:
            inputData &&
            datasetId &&
            inputData.length > 0 &&
            inputData[0] !== ""
              ? 1
              : 0,
          transition: "opacity 0.2s",
          height: "fit-content",
          display: {
            xs: "none",
            lg: "block",
          },
          backgroundColor: theme.palette.paper.main,
          minWidth: "450px",
          mt: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">
            Input Data Preview —{" "}
            <RobotoLinkHoverUnderline
              to={`/datasets/${datasetId}`}
              target="_blank"
            >
              {datasetId}
            </RobotoLinkHoverUnderline>
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              justifyContent: "center",
            }}
          >
            <IconButton
              disabled={filesPreviewPage === 0}
              onClick={() => {
                const previousPage = filesPreviewPage - 1;
                setFilesPreviewPage(previousPage);
                fetchPreviousPage(previousPage, 10);
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              disabled={!isNextPageAvailable}
              onClick={() => {
                const nextPage = filesPreviewPage + 1;
                setFilesPreviewPage(nextPage);
                void fetchNextPage(nextPage, 10);
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        </Box>
        <List dense>{fileDataContent}</List>
      </Box>
    </Box>
  );
};
