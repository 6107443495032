import classNames from "classnames";
import * as mapboxgl from "mapbox-gl";

import styles from "./StyleToggleControl.module.css";

interface StyleToggleControlParams {
  onStyleLoad?: () => void;
}

export class StyleToggleControl implements mapboxgl.IControl {
  private map?: mapboxgl.Map;
  private isSatellite = false;
  private onStyleLoadCallback?: StyleToggleControlParams["onStyleLoad"];

  public constructor(params: StyleToggleControlParams) {
    this.onStyleLoadCallback = params.onStyleLoad;
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;

    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    div.innerHTML = `
      <button title="Toggle map tiles" class="${classNames(styles.controlButton)}">
        <span id="icon-container">${this.#getIcon()}</span>
      </button>
    `;

    const button = div.querySelector("button");
    if (button) {
      button.onclick = () => {
        this.map?.once("style.load", this.#onStyleLoad);

        if (!this.isSatellite) {
          this.map?.setStyle("mapbox://styles/mapbox/satellite-streets-v12");
          this.isSatellite = true;
        } else {
          this.map?.setStyle("mapbox://styles/mapbox/outdoors-v12");
          this.isSatellite = false;
        }

        this.#updateIcon(div);
      };
    }

    return div;
  }

  onRemove(): void {
    this.map = undefined;
  }

  #getIcon(): string {
    return !this.isSatellite
      ? `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
           <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M5 4.99h3C8 6.65 6.66 8 5 8zM5 12v-2c2.76 0 5-2.25 5-5.01h2C12 8.86 8.87 12 5 12m0 6 3.5-4.5 2.5 3.01L14.5 12l4.5 6z"/>
         </svg>`
      : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
           <path d="m20.5 3-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5M15 19l-6-2.11V5l6 2.11zz"/>
         </svg>`;
  }

  #onStyleLoad = () => {
    this.onStyleLoadCallback?.();
  };

  #updateIcon(container: HTMLElement) {
    const iconContainer = container.querySelector("#icon-container");
    if (iconContainer) {
      iconContainer.innerHTML = this.#getIcon();
    }
  }
}
