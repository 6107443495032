/**
 * Roboto exception hierarchy. Shadows `packages.roboto.exceptions`.
 */

export interface RobotoServiceErrorBody {
  error_code: string;
  message: string;
  stack_trace?: string[];
  resource_name?: string; // Only present for RobotoLimitExceededException
  limit_quantity?: number; // Only present for RobotoLimitExceededException
  current_quantity?: number; // Only present for RobotoLimitExceededException
}

/**
 * Base class for Roboto domain exceptions.
 *
 * All subclasses must define a public static `httpStatusCode` property
 * that maps to its corresponding HTTP status code.
 * This mapping is used to construct the appropriate exception type when handling HTTP responses.
 *
 * The base class uses 999 as its status code to prevent accidental matches with valid HTTP status codes.
 */
export class RobotoDomainException extends Error {
  public static httpStatusCode: number = 999;
  public static subclasses: (typeof RobotoDomainException)[] = [];

  protected errorBody: RobotoServiceErrorBody;

  public static fromHttpResponse(
    errorResponse: RobotoServiceErrorBody,
  ): RobotoDomainException {
    for (const subclass of RobotoDomainException.subclasses) {
      if (subclass.name === errorResponse.error_code) {
        return new subclass(errorResponse);
      }
    }

    return new RobotoDomainException(errorResponse);
  }

  public static fromStatusCode(statusCode: number): RobotoDomainException {
    const errorBodyBase = {
      error_code: String(statusCode),
    };
    for (const subclass of RobotoDomainException.subclasses) {
      if (subclass.httpStatusCode === statusCode) {
        return new subclass({
          ...errorBodyBase,
          message: subclass.name,
        });
      }
    }

    return new RobotoDomainException({
      ...errorBodyBase,
      message: "Error",
    });
  }

  constructor(errorBody: RobotoServiceErrorBody) {
    super(errorBody.message);
    this.name = "RobotoDomainException";
    this.errorBody = errorBody;
  }

  public get message(): string {
    return this.errorBody.message;
  }

  public get stackTrace(): string[] | undefined {
    return this.errorBody.stack_trace;
  }

  public isClientError(): boolean {
    const errorCode = Number(this.errorBody.error_code);
    return errorCode > 399 && errorCode < 500;
  }
}

export class RobotoUnauthorizedException extends RobotoDomainException {
  public static httpStatusCode = 401;
  public static name = "RobotoUnauthorizedException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoUnauthorizedException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoUnauthorizedException);

export class RobotoNotFoundException extends RobotoDomainException {
  public static httpStatusCode = 404;
  public static name = "RobotoNotFoundException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoNotFoundException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoNotFoundException);

export class RobotoIllegalArgumentException extends RobotoDomainException {
  public static httpStatusCode = 400;
  public static name = "RobotoIllegalArgumentException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoIllegalArgumentException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoIllegalArgumentException);

export class RobotoInvalidRequestException extends RobotoDomainException {
  public static httpStatusCode = 400;
  public static name = "RobotoInvalidRequestException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoInvalidRequestException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoInvalidRequestException);

export class RobotoInvalidStateTransitionException extends RobotoDomainException {
  public static httpStatusCode = 400;
  public static name = "RobotoInvalidStateTransitionException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoInvalidStateTransitionException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoInvalidStateTransitionException);

export class RobotoNoOrgProvidedException extends RobotoDomainException {
  public static httpStatusCode = 400;
  public static name = "RobotoNoOrgProvidedException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoNoOrgProvidedException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoNoOrgProvidedException);

export class RobotoConditionException extends RobotoDomainException {
  public static httpStatusCode = 409;
  public static name = "RobotoConditionException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoConditionException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoConditionException);

export class RobotoConflictException extends RobotoDomainException {
  public static httpStatusCode = 409;
  public static name = "RobotoConflictException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoConflictException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoConflictException);

export class RobotoServiceException extends RobotoDomainException {
  public static httpStatusCode = 500;
  public static name = "RobotoServiceException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoServiceException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoServiceException);

export class RobotoUnknownOperationException extends RobotoDomainException {
  public static httpStatusCode = 404;
  public static name = "RobotoUnknownOperationException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoUnknownOperationException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoUnknownOperationException);

export class RobotoLimitExceededException extends RobotoDomainException {
  public static httpStatusCode = 403;
  public static name = "RobotoLimitExceededException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoLimitExceededException.name;
  }

  public get resourceName(): string {
    if (!this.errorBody.resource_name) {
      throw new Error("Resource name is not present");
    }
    return this.errorBody.resource_name;
  }

  public get limitQuantity(): number {
    if (this.errorBody.limit_quantity === undefined) {
      throw new Error("Limit quantity is not present");
    }
    return this.errorBody.limit_quantity;
  }

  public get currentQuantity(): number {
    if (this.errorBody.current_quantity === undefined) {
      throw new Error("Current quantity is not present");
    }
    return this.errorBody.current_quantity;
  }
}
RobotoDomainException.subclasses.push(RobotoLimitExceededException);

export class RobotoInternalException extends RobotoDomainException {
  public static httpStatusCode = 500;
  public static name = "RobotoInternalException";

  constructor(...args: ConstructorParameters<typeof RobotoDomainException>) {
    super(...args);
    this.name = RobotoInternalException.name;
  }
}
RobotoDomainException.subclasses.push(RobotoInternalException);
