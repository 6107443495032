import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

interface MutateFileMetadataProps {
  fileId: string;
  putFields?: Record<string, string>;
  removeFields?: string[];
}

interface MetadataChangeSet {
  metadata_changeset: {
    put_fields?: Record<string, string>;
    remove_fields?: string[];
  };
}

interface MutateMetadataResponse {
  fileId: string;
  metadata: Record<string, string>;
}

export function useMutateFileMetadata() {
  const { files } = React.useContext(DomainServicesContext);
  const queryClient = useQueryClient();

  return useMutation<MutateMetadataResponse, Error, MutateFileMetadataProps>({
    mutationFn: async ({ fileId, putFields, removeFields }) => {
      const body: MetadataChangeSet = {
        metadata_changeset: {},
      };

      if (putFields !== undefined) {
        body.metadata_changeset.put_fields = putFields;
      }

      if (removeFields !== undefined) {
        body.metadata_changeset.remove_fields = removeFields;
      }

      const record = await files.putFileRecord(fileId, {
        body: JSON.stringify(body),
      });

      return {
        fileId: record.file_id,
        metadata: record.metadata,
      };
    },
    onSuccess: (response) => {
      // Update the cache with the updated file metadata from putFileRecord
      queryClient.setQueryData(
        ["fileRecordMetadata", response.fileId],
        response.metadata,
      );
    },
  });
}
