import { Box, Popover } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "../RobotoTypography";

interface AIBadgeProps {
  sx?: React.CSSProperties;
}

// This was made using AI LMAO
export const AIBadge: React.FC<AIBadgeProps> = ({ sx }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        component="span"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          ...sx,
        }}
      >
        <span style={{ fontSize: "1.2rem" }}>🔮</span>
      </Box>
      <Popover
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, pb: 0, maxWidth: 300 }}>
          <RobotoTypography variant="subtitle2" gutterBottom>
            AI-Generated Content
          </RobotoTypography>
          <RobotoTypography variant="body2" paragraph>
            This content is generated using AI. While we strive for accuracy, AI
            outputs may occasionally be incorrect or inconsistent.
          </RobotoTypography>
        </Box>
      </Popover>
    </>
  );
};
