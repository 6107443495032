/**
 * Shadow of types and enumerations from `roboto.domain.files`
 */

// 50 MB is the maximum file size that we attempt
// to render a preview for in a text-based component
export const MAX_FILE_SIZE_PREVIEW = 50000000;

export enum FileStatus {
  Available = "Available",
  Deleted = "Deleted",
}

export enum IngestionStatus {
  NotIngested = "not_ingested",
  PartlyIngested = "partly_ingested",
  Ingested = "ingested",
}

export interface FileRecord {
  association_id: string;
  created: string; // timestamp
  created_by: string;
  file_id: string;
  modified: string; // timestamp
  modified_by: string;
  relative_path: string;
  size: number;
  org_id: string;
  uri: string;
  status: FileStatus;
  upload_id: string;
  origination: string;
  device_id: string;
  tags: string[];
  metadata: Record<string, string>;
  description?: string;
  ingestion_status: IngestionStatus;
  version: number;
}

export interface DirectoryRecord {
  association_id: string;
  name: string;
  relative_path: string;
}
