import { LogManager } from "../LogManager";

import { SearchTool } from "./SearchTool";

interface ToolbarProps {
  logManager: LogManager | null;
}

export function Toolbar({ logManager }: ToolbarProps) {
  return (
    <div style={{ display: "flex", gap: "0.25rem", margin: "0 0.25rem" }}>
      <SearchTool logManager={logManager} />
    </div>
  );
}
