import { Box } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/shared/components";

import styles from "./index.module.css";

export interface PhraseProgressBarProps {
  phrases?: string[];
  show?: boolean;
  fadeInRateMs?: number;
  fadeOutRateMs?: number;
  holdPhraseMs?: number;
  shuffle?: boolean;
}

const DEFAULT_PHRASES = [
  "Actualizing the Actuators",
  "Calculating Optimal Path (To Happiness)",
  "Calibrating Cameras",
  "Counting Electric Sheep",
  "Doing an NP-Hard Day's Work",
  "Lending a Shoulder to Rosout On",
  "Massaging Servos",
  "Polishing Pixels",
  "Recharging Batteries",
  "Releasing Phlogiston",
  "Resetting Mode to Wumbo", // https://www.youtube.com/watch?v=--hsVknT1c0
  "Reticulating Splines",
  "Returning Magic Smoke to Transistors",
  "Solving the Halting Problem",
  "Sorting Quaternions",
  "Swapping End Effectors",
  "Tuning Tensor Cores",
];

interface PhaseReducerState {
  phraseIdx: number;
  charIdx: number;
  mode: "add" | "hold" | "remove";
}

export const PhraseProgressBar: React.FC<PhraseProgressBarProps> = ({
  phrases = DEFAULT_PHRASES,
  show = true,
  holdPhraseMs = 1200,
  shuffle = true,
  fadeInRateMs = 50,
  fadeOutRateMs = 20,
}) => {
  if (!phrases || phrases.length === 0) {
    throw new Error("Need phrases to be a non-empty list");
  }

  const shuffledPhrases = React.useMemo(() => {
    const workingPhrases = [...phrases];

    if (shuffle) {
      // Fisher-Yates Shuffle
      for (let i = workingPhrases.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [workingPhrases[i], workingPhrases[j]] = [
          workingPhrases[j],
          workingPhrases[i],
        ];
      }
    }

    return workingPhrases;
  }, [phrases, shuffle]);

  const currentPhraseReducer = (
    state: PhaseReducerState,
  ): PhaseReducerState => {
    switch (state.mode) {
      case "add": {
        const nextCharIdx = state.charIdx + 1;
        return {
          ...state,
          charIdx: nextCharIdx,
          mode:
            nextCharIdx >= shuffledPhrases[state.phraseIdx].length
              ? "hold"
              : state.mode,
        };
      }
      case "hold": {
        return {
          ...state,
          charIdx: state.charIdx - 1,
          mode: "remove",
        };
      }
      case "remove": {
        const nextCharIdx = state.charIdx - 1;
        return {
          charIdx: nextCharIdx,
          phraseIdx:
            nextCharIdx < 0
              ? (state.phraseIdx + 1) % shuffledPhrases.length
              : state.phraseIdx,
          mode: nextCharIdx < 0 ? "add" : state.mode,
        };
      }
      default:
        throw new Error("Invalid mode");
    }
  };

  const [currentPhrase, currentPhraseDispatch] = React.useReducer(
    currentPhraseReducer,
    {
      phraseIdx: 0,
      charIdx: 0,
      mode: "add",
    },
  );

  const displayPhrase = shuffledPhrases[currentPhrase.phraseIdx].substring(
    0,
    currentPhrase.charIdx,
  );

  React.useEffect(() => {
    let timeoutLength = 0;
    if (currentPhrase.mode === "add") {
      timeoutLength = fadeInRateMs;
    } else if (currentPhrase.mode === "hold") {
      timeoutLength = holdPhraseMs;
    } else if (currentPhrase.mode === "remove") {
      timeoutLength = fadeOutRateMs;
    }

    const timeoutId = setTimeout(currentPhraseDispatch, timeoutLength);
    return () => clearTimeout(timeoutId);
  }, [
    shuffledPhrases,
    fadeInRateMs,
    holdPhraseMs,
    fadeOutRateMs,
    currentPhrase,
  ]);

  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <RobotoTypography variant={"h5"}>{displayPhrase}</RobotoTypography>
      <div className={styles.animTypewrite}>
        <RobotoTypography variant={"h5"}>|</RobotoTypography>
      </div>
    </Box>
  );
};
