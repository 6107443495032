import {
  type MessagePathNode,
  type ParsedMessagePathAttr,
  type ParsedMessagePathSlice,
  type PathParseResult,
  type TopicNode,
  MessagePathPartType,
  PathParser,
  pathParser,
} from "@/shared/domain/topics";

interface PathMatchPart {
  isMatch: boolean;
  substring: string;
}

export interface PathMatch {
  // A match is complete if the topic and each of the message path parts are exact matches
  // and the path under consideration has the same number of message path parts as the option.
  isComplete: boolean;
  match: MessagePathNode;
  parts: PathMatchPart[];
}

export class PathMatcher {
  public findMatches(
    input: PathParseResult,
    matchCandidates: TopicNode[],
    matchFilter?: (messagePath: MessagePathNode) => boolean,
  ): PathMatch[] {
    return matchCandidates.reduce<PathMatch[]>((matches, topic) => {
      const topicMatches = this.#evaluateMatch(input, topic, matchFilter);
      return [...matches, ...topicMatches];
    }, []);
  }

  #evaluateMatch = (
    input: PathParseResult,
    topic: TopicNode,
    matchFilter?: (messagePath: MessagePathNode) => boolean,
  ): PathMatch[] => {
    const isExactTopicMatch = input.topic.name === topic.name;
    const inputHasMessagePathSegments = input.messagePath.length > 0;
    if (!isExactTopicMatch && inputHasMessagePathSegments) {
      // E.g., an input of "topic.message.path" should not match the option "topic100.message.path"
      return [];
    }

    const topicPartMatches = this.#matchText(input.topic.name, topic.name);
    const topicDoesMatch = topicPartMatches.some((match) => match.isMatch);
    const performSubstringMatch = !inputHasMessagePathSegments;

    if (!topicDoesMatch && inputHasMessagePathSegments) {
      return [];
    }

    const topicSeparator = {
      isMatch: inputHasMessagePathSegments,
      substring: PathParser.TOPIC_SEPARATOR,
    };
    topicPartMatches.push(topicSeparator);

    const matches = [];
    for (const messagePath of topic) {
      // Compare the parsed input against known MessagePathNode,
      // normalized by parsing its string representation using the same logic.
      // This ensures that if a message path attribute is named with a "reserved character"
      // (e.g., square brackets, as in "ev_hpos[0]") it can be properly matched against user input.
      const parsedMessagePath = pathParser.parse(messagePath.toString());
      if (input.messagePath.length > parsedMessagePath.messagePath.length) {
        continue;
      }

      if (matchFilter !== undefined && !matchFilter(messagePath)) {
        continue;
      }

      const pathMatch = performSubstringMatch
        ? this.#evaluateSubstringMatch(input.topic.name, messagePath)
        : this.#evaluatePositionalMatch(input, parsedMessagePath, messagePath);

      if (pathMatch === null) {
        continue;
      }

      // Insert topic name PathMatchParts at head of the match's parts breakdown
      pathMatch.parts.unshift(...topicPartMatches);

      const anyPartsMatch = pathMatch.parts.some((match) => match.isMatch);
      if (anyPartsMatch) {
        pathMatch.isComplete = pathMatch.parts.every((part) => part.isMatch);
        matches.push(pathMatch);
      }
    }

    return matches;
  };

  /**
   * Validates if input path segments match exactly at the same positions in candidate path.
   * For non-final segments, requires exact matches (e.g. "foo.bar.baz" matching "foo.barre.qux").
   * For final segment, allows partial matches (e.g. "foo.ba" matching "foo.bar").
   */
  #evaluatePositionalMatch = (
    input: PathParseResult,
    known: PathParseResult,
    node: MessagePathNode,
  ): PathMatch | null => {
    const pathMatch: PathMatch = {
      isComplete: false,
      match: node,
      parts: [],
    };

    for (const [idx, knownPart] of known.messagePath.entries()) {
      const inputPart = input.messagePath[idx];

      if (knownPart.type === MessagePathPartType.Attr) {
        // Syntactically, an attribute must have been preceded by another match part
        const hasPriorMatchPart = pathMatch.parts.length > 0;
        if (hasPriorMatchPart) {
          pathMatch.parts.push({
            isMatch: true,
            substring: PathParser.MESSAGE_PATH_SEPARATOR,
          });
        }
      }

      if (inputPart === undefined) {
        if (knownPart.type === MessagePathPartType.Attr) {
          pathMatch.parts.push({
            isMatch: false,
            substring: knownPart.value,
          });
        } else {
          if (knownPart.isRange) {
            const end = Number.isFinite(knownPart.end) ? knownPart.end : "";
            const range = `[${knownPart.start}:${end}]`;
            const substring = range === "[0:]" ? "[:]" : range;
            pathMatch.parts.push({
              isMatch: false,
              substring,
            });
          } else {
            pathMatch.parts.push({
              isMatch: false,
              substring: `[${knownPart.start}]`,
            });
          }
        }
        continue;
      }

      if (inputPart.type !== knownPart.type) {
        // type mismatch, early return
        return null;
      }

      if (
        inputPart.type === MessagePathPartType.Slice &&
        knownPart.type === MessagePathPartType.Slice
      ) {
        const slicePartMatches = this.#matchSlice(
          inputPart,
          knownPart,
          pathMatch.match,
          idx,
        );
        if (slicePartMatches.matches.length > 0) {
          pathMatch.parts.push(...slicePartMatches.matches);
          pathMatch.match = slicePartMatches.node;
        } else {
          return null;
        }
      }

      if (
        inputPart.type === MessagePathPartType.Attr &&
        knownPart.type === MessagePathPartType.Attr
      ) {
        const attrPartMatches = this.#matchAttr(inputPart, knownPart);
        if (attrPartMatches.length > 0) {
          pathMatch.parts.push(...attrPartMatches);
        } else {
          return null;
        }
      }
    }

    return pathMatch;
  };

  /**
   * Search for `substring` anywhere within any "Attribute"-type MessagePathParts.
   */
  #evaluateSubstringMatch = (
    substring: string,
    messagePath: MessagePathNode,
  ): PathMatch => {
    const pathMatch: PathMatch = {
      isComplete: false,
      match: messagePath,
      parts: [],
    };

    for (const part of messagePath.toParts()) {
      if (part.type === MessagePathPartType.Attr) {
        if (pathMatch.parts.length > 0) {
          pathMatch.parts.push({
            isMatch: false,
            substring: PathParser.MESSAGE_PATH_SEPARATOR,
          });
        }
        pathMatch.parts.push(...this.#matchText(substring, part.attribute));
      } else {
        pathMatch.parts.push({
          isMatch: false,
          substring: "[:]",
        });
      }
    }
    return pathMatch;
  };

  #matchAttr(
    input: ParsedMessagePathAttr,
    known: ParsedMessagePathAttr,
  ): PathMatchPart[] {
    const matches: PathMatchPart[] = [];

    const isExact = input.value === known.value;
    if (!input.isFinalSegment && !isExact) {
      // If this is an intermediate segment of the input, it must exactly match this attribute.
      // E.g., given the input "foo.bar.baz", if evaluating the "bar" message path part,
      // this should not match the message path "foo.barre.baz"
      return [];
    }

    const attrMatches = this.#matchText(input.value, known.value);
    const anyMatch = attrMatches.some((match) => match.isMatch);
    if (!anyMatch) {
      return [];
    } else {
      matches.push(...attrMatches);
    }

    return matches;
  }

  #matchSlice(
    input: ParsedMessagePathSlice,
    known: ParsedMessagePathSlice,
    node: MessagePathNode,
    position: number, // index within node.toParts
  ): { matches: PathMatchPart[]; node: MessagePathNode } {
    const ret: { matches: PathMatchPart[]; node: MessagePathNode } = {
      matches: [],
      node,
    };

    if (!input.isValid && !known.isValid) {
      // E.g., a message path is named using the square bracket "reserved characters": "column[abc]"
      const isExact = input.value === known.value;
      if (!isExact && input.isClosed) {
        return ret;
      }

      const stringMatches = this.#matchText(input.value, known.value);
      if (stringMatches.some((match) => match.isMatch)) {
        return {
          matches: stringMatches,
          node,
        };
      }
      return ret;
    }

    if (!input.isValid) {
      // Expecting a number, given something that couldn't be coerced to a number
      return ret;
    }

    if (!known.isRange) {
      if (input.isRange) {
        // E.g., known message path is named "ev_hpos[0]" but input is "ev_hpos[:]"
        return ret;
      }

      if (input.start !== undefined && known.start !== input.start) {
        // E.g., known message path is named "ev_hpos[0]" but input is "ev_hpos[2]"
        return ret;
      }
    }

    ret.matches.push({
      isMatch: true,
      substring: input?.value,
    });

    if (!input.isClosed) {
      if (input.start === undefined) {
        const isFullRange =
          known.isRange && known.start === 0 && !Number.isFinite(known.end);
        const start = isFullRange ? ":" : known.start;
        ret.matches.push({
          isMatch: false,
          substring: `${start}]`,
        });
      } else {
        ret.matches.push({
          isMatch: false,
          substring: "]",
        });
      }
    }

    if (node.toParts()[position]?.type === MessagePathPartType.Slice) {
      // There may be a disconnect between the message path node part and the ParseResult
      // obtained by parsing a message path node's string representation if a message path
      // includes square brackets, which are "reserved characters" parsed by the PathParser.
      let end = Number.isFinite(known.end) ? known.end : input.end;
      if (
        !Number.isFinite(end) &&
        input.start !== undefined &&
        !input.isRange
      ) {
        end = input.start + 1;
      }

      ret.node = node.withModifiedSlicePart(position, {
        start: input.start,
        end,
      });
    }

    return ret;
  }

  #matchText(input: string, known: string): PathMatchPart[] {
    const matchStartPosition = known.indexOf(input);
    const matches: PathMatchPart[] = [];

    if (matchStartPosition === -1) {
      matches.push({
        isMatch: false,
        substring: known,
      });
      return matches;
    }

    // Pre-match segment if not at start
    if (matchStartPosition > 0) {
      matches.push({
        isMatch: false,
        substring: known.slice(0, matchStartPosition),
      });
    }

    // Matching segment
    if (input.length > 0) {
      matches.push({
        isMatch: true,
        substring: input,
      });
    }

    // Post-match segment if any remains
    const remainderStart = matchStartPosition + input.length;
    if (remainderStart < known.length) {
      matches.push({
        isMatch: false,
        substring: known.slice(remainderStart),
      });
    }

    return matches;
  }
}

export const pathMatcher = new PathMatcher();
