import { Skeleton, Theme, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { nanoSecToSecStr } from "@/shared/time";

import { Log, Severity } from "../log";
import styles from "../LogPanel.module.css";

import { ColumnDefinition } from "./columnDefinition";

function toDisplayable(value: unknown): string {
  switch (typeof value) {
    case "undefined":
      return "Not set";
    case "string":
      return value;
    case "number":
      return value.toString();
    case "boolean":
      return value.toString();
    case "object":
      if (value === null) {
        return "null";
      }
      return JSON.stringify(value);
    default:
      return "Unknown";
  }
}

function severityToColor(
  theme: Theme,
  selected: boolean,
  severity?: Severity,
): string {
  if (selected) {
    return theme.palette.tableRowBackground.selected.main;
  }
  switch (severity) {
    case Severity.Emergency:
    case Severity.Alert:
    case Severity.Critical:
    case Severity.Error:
      return theme.palette.tableRowBackground.error.main;
    case Severity.Warning:
      return theme.palette.tableRowBackground.warning.main;
    default:
      return "inherit";
  }
}

interface Column extends ColumnDefinition {
  isLoading: boolean;
}

interface RowProps {
  columns: Column[];
  expanded: boolean;
  log: Log | undefined;
  onClick: (logTime: bigint) => void;
  selected: boolean;
  virtualIndex: number;
}

export const DEFAULT_LOG_LINE_HEIGHT = 35; // px

const LogRow = React.memo(function LogRow({
  columns,
  expanded,
  log,
  onClick,
  selected,
}: RowProps) {
  const theme = useTheme();

  const inlineStyles = React.useMemo(() => {
    const base = {
      "--hover-background-color": theme.palette.hoverBackground.main,
      borderBottom: theme.border.thin,
    } as React.CSSProperties & Record<string, string>;

    if (expanded) {
      base["--row-height"] = "100%";
    }

    if (log !== undefined) {
      base["--background-color"] = severityToColor(
        theme,
        selected,
        log?.severity,
      );
    }

    return base;
  }, [expanded, log, selected, theme]);

  const onClickCb = React.useCallback(
    function onClickCb() {
      if (log === undefined) {
        return;
      }
      onClick(log.time);
    },
    [log, onClick],
  );

  return (
    <div className={styles.row} onClick={onClickCb} style={inlineStyles}>
      <p
        className={classNames(styles.cell, styles.fixedWidth, {
          [styles.expanded]: expanded,
        })}
      >
        {log !== undefined ? (
          nanoSecToSecStr(log.time)
        ) : (
          <Skeleton animation={false} height={20} />
        )}
      </p>
      {columns.map((column) => {
        const content =
          column.isLoading || log === undefined ? (
            <Skeleton animation={false} height={20} />
          ) : (
            toDisplayable(log.data[column.messagePathId])
          );
        return (
          <p
            key={column.messagePathId}
            className={classNames(styles.cell, styles.flexibleWidth, {
              [styles.expanded]: expanded,
            })}
          >
            {content}
          </p>
        );
      })}
    </div>
  );
});

export const Row = React.forwardRef<HTMLDivElement, RowProps>(function Row(
  props: RowProps,
  ref,
) {
  return (
    <div
      className={styles.rowContainer}
      // 2025-03-23 (GM)
      // While not well documented,
      // it appears (from reading source) the data-index attribute must match the virtualizer's index.
      // This may not match the *actual* index of the message in the topic (e.g., Message::sequence).
      data-index={props.virtualIndex}
      ref={ref}
    >
      <LogRow {...props} />
    </div>
  );
});
