// Check for regex named group support
// This doesn't exist in Safari <= 16.2 and can crash pages
export const browserSupportsNamedGroups = (): boolean => {
  try {
    const testString = "abcdef";
    testString.match(/(?<=\/)/g);
    return true;
  } catch {
    return false;
  }
};
