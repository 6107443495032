import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Divider, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth, useNavigation } from "@/providers";
import { UpdateDatasetTags } from "@/shared/components/datasets";
import { useSidebarControls } from "@/shared/components/MainLayoutWithSidebar";
import { RobotoButton } from "@/shared/components/RobotoButton";
import { RobotoIconButton } from "@/shared/components/RobotoIconButton";
import { RobotoTypography } from "@/shared/components/RobotoTypography";
import {
  InvocationRecord,
  invocationStatusToString,
  invocationDatasetCondition,
} from "@/shared/domain/actions";
import { DatasetRecord } from "@/shared/domain/datasets";
import { useDatasetFileCount } from "@/shared/domain/datasets/hooks";
import { fetchAllItems } from "@/shared/services/apiHooks";
import { ErrorMonitoringService } from "@/shared/services/ErrorMonitoringService";
import { Dataset, searchInvocationsEndpoint } from "@/types";

import { KeyValuePair } from "./KeyValuePair";
import { TitleRow } from "./TitleRow";

export interface DatasetDetailSidebarContentProps {
  dataset?: Dataset;
  onLinkCopied?: () => void;
  onDatasetUpdated?: (record: DatasetRecord) => void;
  onTagUpdateError?: (error: Error) => void;
}

export const DatasetDetailSidebarContent: React.FC<
  DatasetDetailSidebarContentProps
> = ({ dataset, onLinkCopied, onDatasetUpdated, onTagUpdateError }) => {
  const theme = useTheme();
  const { goto } = useNavigation();
  const sidebarControls = useSidebarControls();

  const handleClose = () => {
    if (sidebarControls.isOpen) {
      sidebarControls.toggleOpen();
    }
  };

  const onClickLink = async () => {
    const link = `${window.location.href}/${dataset?.dataset_id}`;

    try {
      await navigator.clipboard.writeText(link);

      if (onLinkCopied) {
        onLinkCopied();
      }
    } catch (error) {
      ErrorMonitoringService.captureError(error);
    }
  };

  const createdDateString = new Date(
    dataset?.created || "",
  ).toLocaleDateString();

  const modifiedDateString = new Date(dataset?.modified || "").toLocaleString();

  const [invocationCount, setInvocationCount] = React.useState<{
    isLoading: boolean;
    runningCount: number;
    finishedCount: number;
  }>({ isLoading: true, runningCount: 0, finishedCount: 0 });

  const { currentOrganization } = useAuth();

  const fileCountQuery = useDatasetFileCount(
    dataset?.dataset_id ?? "",
    currentOrganization?.org_id,
  );

  React.useEffect(() => {
    if (dataset && dataset.dataset_id) {
      const fetchInvocationCount = async () => {
        setInvocationCount({
          isLoading: true,
          runningCount: 0,
          finishedCount: 0,
        });

        const { items, error } = await fetchAllItems<InvocationRecord>({
          method: "POST",
          endpoint: searchInvocationsEndpoint,
          orgId: currentOrganization?.org_id ?? "",
          requestBody: JSON.stringify({
            condition: invocationDatasetCondition(dataset.dataset_id),
          }),
        });

        if (error) {
          ErrorMonitoringService.captureError(error);
        }

        let runningCount = 0;
        let finishedCount = 0;

        for (let i = 0; i < items.length; i++) {
          const status = invocationStatusToString(
            items[i].status[items[i].status.length - 1].status,
          );

          if (status === "Running" || status === "Pending") {
            runningCount++;
          } else {
            finishedCount++;
          }
        }

        setInvocationCount({
          isLoading: false,
          runningCount,
          finishedCount,
        });
      };

      void fetchInvocationCount();
    }
  }, [dataset, currentOrganization?.org_id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <FolderOpenIcon
            sx={{
              marginRight: theme.spacing(1.25),
              color: theme.palette.text.primary,
            }}
          />
          <RobotoTypography
            variant="body1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              marginRight: theme.spacing(1),
            }}
          >
            {dataset?.dataset_id}
          </RobotoTypography>
        </Box>
        <RobotoIconButton
          eventName={"DatasetSidebarClosed"}
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <KeyboardTabIcon />
        </RobotoIconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: theme.spacing(2),
          gap: theme.spacing(1.5),
        }}
      >
        <RobotoButton
          variant={"contained"}
          onClick={() => {
            goto.dataset({ datasetId: dataset?.dataset_id ?? "" });
          }}
          eventName={"DatasetOpened"}
          eventProperties={{
            datasetId: dataset?.dataset_id ?? "",
          }}
        >
          Open
        </RobotoButton>

        <RobotoButton
          variant={"outlined"}
          startIcon={<ShareIcon />}
          onClick={() => {
            void onClickLink();
          }}
          eventName={"ShareDatasetClicked"}
          eventProperties={{
            datasetId: dataset?.dataset_id ?? "",
          }}
        >
          Share
        </RobotoButton>
      </Box>

      <Divider sx={{ marginBottom: theme.spacing(2) }} />

      <Box
        sx={{
          marginBottom: theme.spacing(2),
        }}
      >
        <TitleRow title={"Details"} IconComponent={InfoOutlinedIcon} />
        <Box>
          <KeyValuePair label="Name" value={dataset?.name || "N/A"} />
          <KeyValuePair label="Created" value={createdDateString} />
          <KeyValuePair label="Modified" value={modifiedDateString} />
          <KeyValuePair label="Created By" value={dataset?.created_by || ""} />
          <KeyValuePair
            label="Files"
            value={`${fileCountQuery.data ?? ""}`}
            isLoading={fileCountQuery.data === undefined}
          />
        </Box>
      </Box>

      <Divider sx={{ marginBottom: theme.spacing(2) }} />

      <Box
        sx={{
          marginBottom: theme.spacing(2),
        }}
      >
        <TitleRow title={"Tags"} IconComponent={LabelOutlinedIcon} />

        <Box>
          {dataset?.dataset_id && (
            <UpdateDatasetTags
              initialTags={dataset?.tags || []}
              datasetId={dataset?.dataset_id}
              currentOrg={currentOrganization}
              onError={onTagUpdateError}
              onTagsUpdated={onDatasetUpdated}
              size="small"
            />
          )}
        </Box>
      </Box>

      {dataset?.metadata && Object.keys(dataset?.metadata).length > 0 && (
        <>
          <Divider sx={{ marginBottom: theme.spacing(2) }} />

          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <TitleRow title={"Metadata"} IconComponent={DataObjectIcon} />
            <Box>
              {Object.keys(dataset?.metadata ?? {}).map((key) => {
                return (
                  <KeyValuePair
                    key={key}
                    label={key}
                    value={dataset.metadata?.[key] || ""}
                  />
                );
              })}
            </Box>
          </Box>
        </>
      )}

      <Divider sx={{ marginBottom: theme.spacing(2) }} />

      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <TitleRow title={"Actions"} IconComponent={AutoAwesomeOutlinedIcon} />
        <KeyValuePair
          label="Running"
          value={`${
            invocationCount.isLoading ? "" : invocationCount.runningCount
          }`}
          isLoading={invocationCount.isLoading}
        />
        <KeyValuePair
          label="Completed"
          value={`${
            invocationCount.isLoading ? "" : invocationCount.finishedCount
          }`}
          isLoading={invocationCount.isLoading}
        />
      </Box>
    </Box>
  );
};
