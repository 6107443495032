import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  FileVizLink,
  TopicVizLink,
  DatasetLink,
  MessagePathLinkFromId,
} from "@/shared/components/links";
import { MetadataForm, MetadataView } from "@/shared/components/Metadata";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "@/shared/components/tags";
import { AssociationType } from "@/shared/domain/association";
import { EventRecord, eventByAssociationType } from "@/shared/domain/events";
import { useUpdateEvent } from "@/shared/domain/events/hooks";
import { timeToSeconds } from "@/shared/mcap";
import { LoggerService } from "@/shared/services";
import { nanoSecToDate } from "@/shared/time";

import { EditableEventDescription } from "./EditableEventDescription";
import { EditableEventName } from "./EditableEventName";

const listIconStyles = {
  minWidth: "35px",
};

interface EventExpanderDetailsBaseProps {
  event: EventRecord;
  readonly?: boolean;
}

interface ReadonlyProps extends EventExpanderDetailsBaseProps {
  readonly: true;
}

interface EditableProps extends EventExpanderDetailsBaseProps {
  readonly?: false;
  onUpdateEvent: (event: EventRecord) => void;
}

type EventExpanderDetailsProps = ReadonlyProps | EditableProps;

export const EventDetails: React.FC<EventExpanderDetailsProps> = (props) => {
  const { event, readonly = false } = props;
  const onUpdateEvent =
    readonly === true ? () => {} : (props as EditableProps).onUpdateEvent;

  const theme = useTheme();
  const associationByType = eventByAssociationType(event);
  const associatedDatasets = associationByType[AssociationType.Dataset];
  const associatedFiles = associationByType[AssociationType.File];
  const associatedTopics = associationByType[AssociationType.Topic];
  const associatedMessagePaths = associationByType[AssociationType.MessagePath];

  const updateEventMutation = useUpdateEvent();

  const onAddTag = (tag: string) => {
    updateEventMutation
      .mutateAsync({
        id: event.event_id,
        requestBody: {
          metadata_changeset: {
            put_tags: [tag],
          },
        },
      })
      .then((newEvent: EventRecord) => {
        onUpdateEvent(newEvent);
      })
      .catch((err) => LoggerService.error(err));
  };

  const onDeleteTag = (tag: string) => {
    updateEventMutation
      .mutateAsync({
        id: event.event_id,
        requestBody: {
          metadata_changeset: {
            remove_tags: [tag],
          },
        },
      })
      .then((newEvent: EventRecord) => {
        onUpdateEvent(newEvent);
      })
      .catch((err) => LoggerService.error(err));
  };

  const addMetadataToEvent = (key: string, value: string) => {
    updateEventMutation
      .mutateAsync({
        id: event.event_id,
        requestBody: {
          metadata_changeset: {
            put_fields: { [key]: value },
          },
        },
      })
      .then((newEvent: EventRecord) => {
        onUpdateEvent(newEvent);
      })
      .catch((err) => LoggerService.error(err));
  };

  const removeMetadataFromEvent = (key: string) => {
    updateEventMutation
      .mutateAsync({
        id: event.event_id,
        requestBody: {
          metadata_changeset: {
            remove_fields: [key],
          },
        },
      })
      .then((newEvent: EventRecord) => {
        onUpdateEvent(newEvent);
      })
      .catch((err) => LoggerService.error(err));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary={"Name"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <EditableEventName
                    event={event}
                    onUpdateEvent={onUpdateEvent}
                    readonly={readonly}
                  />
                }
                secondaryTypographyProps={{
                  component: Box,
                }}
                sx={{
                  maxWidth: "600px",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Description"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <EditableEventDescription
                    event={event}
                    onUpdateEvent={onUpdateEvent}
                    readonly={readonly}
                  />
                }
                secondaryTypographyProps={{
                  component: Box,
                }}
                sx={{
                  maxWidth: "800px",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Created"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {new Date(event.created).toLocaleString(undefined, {
                      timeZoneName: "short",
                    })}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Tags"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {readonly && event.tags.length === 0 && <>None</>}
                    <TagsGroup style={{ marginTop: theme.spacing(1) }}>
                      <Tags
                        tags={event.tags}
                        truncateText={false}
                        onDeleteTag={readonly ? undefined : onDeleteTag}
                      />
                      {readonly === false && (
                        <TagInput
                          autocompleteType={AutocompleteType.EventTags}
                          onAddTag={onAddTag}
                          tags={event.tags}
                          disabled={readonly}
                        />
                      )}
                    </TagsGroup>
                  </>
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Metadata"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {readonly && Object.keys(event.metadata).length === 0 && (
                      <>None</>
                    )}
                    <MetadataView
                      metadata={event.metadata}
                      onDeleteMetadata={removeMetadataFromEvent}
                      disabled={readonly}
                    />
                    {readonly === false && (
                      <MetadataForm
                        metadata={event.metadata}
                        onAddMetadata={addMetadataToEvent}
                        autocompleteType={AutocompleteType.EventMetadataKeys}
                      />
                    )}
                  </>
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <ListItemText
                primary={"Start Time"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {nanoSecToDate(event.start_time).toLocaleString(undefined, {
                      timeZoneName: "short",
                    })}
                    <br />
                    &rArr; {timeToSeconds(event.start_time)} seconds since epoch
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"End Time"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <>
                    {nanoSecToDate(event.end_time).toLocaleString(undefined, {
                      timeZoneName: "short",
                    })}
                    <br />
                    &rArr; {timeToSeconds(event.end_time)} seconds since epoch
                  </>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Scope"
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondary={
                  <List dense>
                    {associatedDatasets.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <TopicOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <DatasetLink
                              datasetId={association.association_id}
                              key={association.association_id}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedFiles.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <InsertDriveFileOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <FileVizLink
                              fileId={association.association_id}
                              key={association.association_id}
                              startTime={event.start_time}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedTopics.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <AccountTreeOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <TopicVizLink
                              topicId={association.association_id}
                              key={association.association_id}
                              startTime={event.start_time}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                    {associatedMessagePaths.map((association) => (
                      <ListItem key={association.association_id} dense>
                        <ListItemIcon style={listIconStyles}>
                          <TimelineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <MessagePathLinkFromId
                              messagePathId={association.association_id}
                              key={association.association_id}
                              startTime={event.start_time}
                            />
                          }
                          primaryTypographyProps={{
                            component: "span",
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
