import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { AssociationType } from "@/shared/domain/association.ts";
import { TopicRecord } from "@/shared/domain/topics";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";
import { LoggerService } from "@/shared/services";
import { nanoSecToSecStr } from "@/shared/time";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

const linkErrorComponent = (
  <Typography variant="body2" color="error">
    Failed to load link
  </Typography>
);

function defaultLinkName(topicRecord: TopicRecord) {
  return topicRecord.topic_name;
}

function buildTopicVizUrl(
  fileId: string,
  params: {
    filterTerm?: string;
    t?: string;
  },
) {
  const searchParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== undefined),
    ),
  );
  const query = searchParams.toString();
  return `/files/${fileId}${query ? `?${query}` : ""}`;
}

interface TopicVizLinkProps {
  topicId: string;
  getLinkName?: (topicRecord: TopicRecord) => string;

  /**
   * If provided, the file will be opened at the given time.
   * Otherwise, the file will be opened at the start of the file.
   *
   * `startTime` is in nanoseconds since the UNIX epoch.
   **/
  startTime?: bigint;
}

export const TopicVizLink: React.FC<TopicVizLinkProps> = ({
  topicId,
  getLinkName = defaultLinkName,
  startTime,
}) => {
  const topicQuery = useTopicById(topicId);

  if (topicQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (topicQuery.isError) {
    LoggerService.error(topicQuery.error?.message, topicQuery.error);
    return linkErrorComponent;
  }

  if (topicQuery.data?.association.association_type !== AssociationType.File) {
    LoggerService.error("Can only link to topics associated with files.");
    return linkErrorComponent;
  }

  const fileId = topicQuery.data.association.association_id;

  const route = buildTopicVizUrl(fileId, {
    filterTerm: topicQuery.data.topic_name,
    t: startTime ? nanoSecToSecStr(startTime) : undefined,
  });

  return (
    <RobotoLinkHoverUnderline to={route} variant="body2">
      {getLinkName(topicQuery.data)}
    </RobotoLinkHoverUnderline>
  );
};
