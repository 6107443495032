import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  ThemeProvider,
  SimplePaletteColorOptions,
  Theme,
} from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ColorMode, ColorModeContext } from "./color";
import { lightTheme, darkTheme } from "./themes";
import { ZLayer } from "./types";

declare module "@mui/material/styles" {
  // In order to augment the existing Palette interface in MUI,
  // the changes need to be made to both Palette and PaletteOptions
  // https://mui.com/material-ui/customization/palette/#typescript

  export interface Palette {
    foreground: SimplePaletteColorOptions;
    navMenu: SimplePaletteColorOptions;
    toastHeader: SimplePaletteColorOptions;
    buttonHeader: SimplePaletteColorOptions;
    paper: SimplePaletteColorOptions;
    code: SimplePaletteColorOptions;
    tableHover: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    panel: SimplePaletteColorOptions;
    tableRowBackground: {
      error: SimplePaletteColorOptions;
      selected: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
    };
  }

  export interface PaletteOptions {
    foreground: SimplePaletteColorOptions;
    navMenu: SimplePaletteColorOptions;
    toastHeader: SimplePaletteColorOptions;
    buttonHeader: SimplePaletteColorOptions;
    paper: SimplePaletteColorOptions;
    code: SimplePaletteColorOptions;
    tableHover: SimplePaletteColorOptions;
    hoverBackground: SimplePaletteColorOptions;
    panel: SimplePaletteColorOptions;
    tableRowBackground: {
      error: SimplePaletteColorOptions;
      selected: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
    };
  }

  export interface Theme {
    border: {
      thin: string;
      thick: string;
      radius: string;
      color: string;
    };
    navTopBarHeight: string;
    navSideBarWidth: string;
    actionButtonHeight: string;
    zLayer: (layer: ZLayer) => number;
  }

  export interface ThemeOptions {
    border: Partial<Theme["border"]>;
    navTopBarHeight: Theme["navTopBarHeight"];
    navSideBarWidth: Theme["navSideBarWidth"];
    actionButtonHeight: Theme["actionButtonHeight"];
    zLayer: Theme["zLayer"];
  }
}

const THEME_LOCAL_STORAGE_KEY = "theme";
const DARK_SCHEME_MEDIA_QUERY = "(prefers-color-scheme: dark)";

const useColorModeState = () => {
  const [colorMode, setColorMode] = useState<ColorMode>(() => {
    return (
      (localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as ColorMode) ?? "auto"
    );
  });

  const [systemDarkPreference, setSystemDarkPreference] = useState(
    () => window.matchMedia(DARK_SCHEME_MEDIA_QUERY).matches,
  );

  useEffect(() => {
    const matchDark = window.matchMedia(DARK_SCHEME_MEDIA_QUERY);
    const handleChange = (event: MediaQueryListEvent) =>
      setSystemDarkPreference(event.matches);

    matchDark.addEventListener("change", handleChange);
    return () => matchDark.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    if (colorMode === "auto") {
      localStorage.removeItem(THEME_LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(THEME_LOCAL_STORAGE_KEY, colorMode);
    }
  }, [colorMode]);

  const toggleColorMode = useCallback(() => {
    setColorMode((prev) => {
      if (prev === "auto") {
        return "dark";
      }
      if (prev === "dark") {
        return "light";
      }
      return "auto";
    });
  }, []);

  return { colorMode, systemDarkPreference, toggleColorMode };
};

interface MUIThemeProviderProps {
  children: React.ReactNode;
}

/**
 * Responsibilities:
 *  1. StyledEngineProvider: instruct MUI to inject its styles at the top of the head tag,
 *     allowing for overrides without the use of !important.
 *     Reference: https://mui.com/material-ui/integrations/interoperability/#css-injection-order-3
 *  2. CssBaseline: apply base styles
 *  3. ColorModeContext: provide React context for the color mode toggle
 *  4. ThemeProvider: provide the theme to the app
 */

export const MUIThemeProvider: React.FC<MUIThemeProviderProps> = ({
  children,
}) => {
  const { colorMode, systemDarkPreference, toggleColorMode } =
    useColorModeState();

  const effectiveTheme = useMemo(() => {
    const themeMap: Record<ColorMode, Theme> = {
      auto: systemDarkPreference ? darkTheme : lightTheme,
      dark: darkTheme,
      light: lightTheme,
    };
    return themeMap[colorMode];
  }, [colorMode, systemDarkPreference]);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
        <ThemeProvider theme={effectiveTheme}>{children}</ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};
