import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import {
  TriggerRecord,
  TriggerEvaluationCause,
  causeToFormattedString,
} from "@/shared/domain/actions";
import { useUpdateTrigger } from "@/shared/domain/actions/hooks/useUpdateTrigger";
import { OrgRecord } from "@/shared/domain/orgs";
import { APIResponse } from "@/types";

import { TriggerCauseSelector } from "./TriggerCauseSelector";

interface EditableTriggerCausesProps {
  title: string;
  value: TriggerEvaluationCause[];
  triggerName: string;
  currentOrg: OrgRecord;
  setErrorText: (error: string) => void;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
}

export const EditableTriggerCauses: React.FC<EditableTriggerCausesProps> = ({
  title,
  value,
  triggerName,
  currentOrg,
  setErrorText,
  onUpdate,
}) => {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [causes, setCauses] = React.useState(value);
  const updateTriggerMutation = useUpdateTrigger();
  const readableCauses = causes.map(causeToFormattedString);

  const updateRecord = (newCauses: TriggerEvaluationCause[]) => {
    setErrorText("");
    updateTriggerMutation.mutate(
      {
        name: triggerName,
        resourceOwnerId: currentOrg?.org_id || "",
        updates: {
          causes: newCauses,
        },
      },
      {
        onSuccess: (trigger) => {
          setCauses(trigger.causes);
          onUpdate({ data: trigger });
          setEditable(false);
        },
        onError: (error: Error) => {
          setErrorText(error.message);
        },
      },
    );
  };

  const onClick = () => {
    if (editable) {
      updateRecord(causes);
    } else {
      setEditable(true);
    }
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>

            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />

            <Box>
              {editable === false ? (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {readableCauses.length > 0
                    ? `${readableCauses.join(", ")}`
                    : "Never"}
                </Typography>
              ) : (
                <Box
                  sx={{
                    mt: theme.spacing(1),
                  }}
                >
                  <TriggerCauseSelector causes={causes} setCauses={setCauses} />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
