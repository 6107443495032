import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { unified } from "unified";

export const preProcessContent = (content: string): string => {
  // Pre-process the mentions, place the @ symbol inside the link for correct formatting
  // and make the link navigate to '/users/{userId}'
  return content
    .replace(/@\[([^\]]+)\]\(([^)]+)\)/gi, "[@$1](/users/$2)")
    .replace(/(?:\n\n)(?![*-])\n/gi, "&nbsp;\n ");
};

const postProcessContent = (content: string): string => {
  // post process the mentions, move the @ symbol outside of the display text
  // and make the link just the user id
  return content.replace(/\[@(.*?)\]\(\/users\/(.*?)\)/g, "@[$1]($2)");
};

export const convertToMarkdownWriteToClipboard = (html: string): void => {
  let markdown = unified()
    .use(rehypeParse, { fragment: true }) // Parse HTML
    .use(rehypeRemark) // Convert to Markdown AST
    .use(remarkStringify) // Convert AST to Markdown string
    .processSync(html)
    .toString();

  markdown = postProcessContent(markdown).trim();

  void navigator.clipboard.writeText(markdown);
};
