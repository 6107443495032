import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Outlet } from "react-router-dom";

import Providers from "@/providers";

import "../styles/globals.css";

export function AppProvidersLayout() {
  return (
    <Providers>
      <Outlet />
    </Providers>
  );
}
