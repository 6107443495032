import * as React from "react";

import { useDomainServices } from "@/providers/DomainServices";
import { AssociationType } from "@/shared/domain/association.ts";
import { EventRecord } from "@/shared/domain/events/EventRecord.ts";

import { Timer } from "../timer";

import { EphemeralWorkspaceState } from "./EphemeralWorkspaceState";

export enum EVENT_ACTIONS {
  ADD_EVENT = "ADD_EVENT",
  REMOVE_EVENT = "REMOVE_EVENT",
  SET_EVENTS = "SET_EVENTS",
  UPDATE_EVENT = "UPDATE_EVENT",
}

interface AddEventAction {
  type: EVENT_ACTIONS.ADD_EVENT;
  payload: EventRecord;
}

interface RemoveEventAction {
  type: EVENT_ACTIONS.REMOVE_EVENT;
  payload: EventRecord["event_id"];
}

interface SetEventsAction {
  type: EVENT_ACTIONS.SET_EVENTS;
  payload: EventRecord[];
}

interface UpdateEventAction {
  type: EVENT_ACTIONS.UPDATE_EVENT;
  payload: EventRecord;
}

type EventAction =
  | AddEventAction
  | RemoveEventAction
  | SetEventsAction
  | UpdateEventAction;

const eventsReducer = (state: EventRecord[], action: EventAction) => {
  switch (action.type) {
    case EVENT_ACTIONS.ADD_EVENT:
      return [...state, action.payload];
    case EVENT_ACTIONS.REMOVE_EVENT:
      return state.filter((event) => event.event_id !== action.payload);
    case EVENT_ACTIONS.SET_EVENTS:
      return action.payload;
    case EVENT_ACTIONS.UPDATE_EVENT:
      return state.map((event) => {
        if (event.event_id === action.payload.event_id) {
          return action.payload;
        }
        return event;
      });
    default:
      return state;
  }
};

interface WorkspaceDomainContext {
  files: EphemeralWorkspaceState["files"];
  topics: EphemeralWorkspaceState["topics"];
  messagePaths: EphemeralWorkspaceState["messagePaths"];
}

export const useWorkspaceContextForFiles = (
  fileIds: string[],
  timeSpans: [bigint, bigint][] = [],
): EphemeralWorkspaceState => {
  const domainServices = useDomainServices();

  const [workspaceContext, setWorkspaceContext] =
    React.useState<WorkspaceDomainContext>({
      files: [],
      topics: [],
      messagePaths: [],
    });

  const [events, dispatch] = React.useReducer(eventsReducer, []);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);

  const [selectedPanelId, setSelectedPanelId] = React.useState<string | null>(
    null,
  );

  const timerRef = React.useRef<Timer>(new Timer({ spans: timeSpans }));

  React.useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();

    const initializeWorkspaceManager = async () => {
      const newWorkspaceState: WorkspaceDomainContext = {
        files: [],
        topics: [],
        messagePaths: [],
      };

      const datasetIds: string[] = [];

      for (const fileId of fileIds) {
        // We need to get the file record to look up its dataset via association
        const fileRecord = await domainServices.files.getFileRecord(fileId, {
          abortSignal: abortController.signal,
        });
        newWorkspaceState.files.push(fileRecord);
        datasetIds.push(fileRecord.association_id);

        // We also need to dynamically load the topics associated with each file
        const fileTopics = await domainServices.topics.getTopicsByAssociation(
          {
            association_id: fileId,
            association_type: AssociationType.File,
          },
          {
            abortSignal: abortController.signal,
          },
        );
        newWorkspaceState.topics.push(...fileTopics);
      }

      for (const topic of newWorkspaceState.topics) {
        newWorkspaceState.messagePaths.push(...topic.message_paths);
      }

      const events = await domainServices.events.getEventsForAssociations(
        {
          datasetIds: datasetIds,
          fileIds: Array.from(
            newWorkspaceState.files.map((file) => file.file_id),
          ),
          topicIds: Array.from(
            newWorkspaceState.topics.map((topic) => topic.topic_id),
          ),
          messagePathIds: Array.from(
            newWorkspaceState.messagePaths.map(
              (messagePath) => messagePath.message_path_id,
            ),
          ),
        },
        {
          abortSignal: abortController.signal,
        },
      );
      dispatch({ type: EVENT_ACTIONS.SET_EVENTS, payload: events });
      setWorkspaceContext(newWorkspaceState);
      setError(null);
    };

    initializeWorkspaceManager()
      .catch((err: Error) => {
        if (abortController.signal.aborted || err.name === "AbortError") {
          return;
        }
        setError(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) {
          return;
        }
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [domainServices, fileIds]);

  const workspace = React.useMemo(
    function createContext() {
      return {
        // Server state cache
        ...workspaceContext,
        events,
        loading,
        error,

        // Events CRUD
        addEventToWorkspace: (event: EventRecord) => {
          dispatch({ type: EVENT_ACTIONS.ADD_EVENT, payload: event });
        },
        removeEventFromWorkspace: (eventId: EventRecord["event_id"]) => {
          dispatch({ type: EVENT_ACTIONS.REMOVE_EVENT, payload: eventId });
        },
        updateEventInWorkspace: (event: EventRecord) => {
          dispatch({ type: EVENT_ACTIONS.UPDATE_EVENT, payload: event });
        },

        // Panel selection
        selectedPanelId,
        selectPanel: (panelId: string) => setSelectedPanelId(panelId),
        clearPanelSelection: () => setSelectedPanelId(null),
        clearPanelSelectionIfMatches: (panelId: string) => {
          setSelectedPanelId((selected: string | null) => {
            if (selected === panelId) {
              return null;
            }

            return selected;
          });
        },

        // Timer
        timer: timerRef.current,
      };
    },
    [workspaceContext, events, loading, error, selectedPanelId],
  );

  return workspace;
};
