import type { McapTypes } from "@mcap/core";

import { MessageChunk } from "./MessageChunk";

export class MessageChunks<T> {
  #chunks: MessageChunk<T>[];
  #minTime: bigint | null = null;
  #maxTime: bigint | null = null;

  constructor(chunkIndices: readonly McapTypes.ChunkIndex[]) {
    // Downstream logic assumes chunks are in ascending order.
    const sortedChunkIndices = chunkIndices.slice().sort((a, b) => {
      if (a.messageStartTime === b.messageStartTime) {
        // break ties based on where chunk appears in the file
        return a.chunkStartOffset < b.chunkStartOffset ? -1 : 1;
      }
      return a.messageStartTime < b.messageStartTime ? -1 : 1;
    });

    const chunks = sortedChunkIndices.map(
      (chunkIndexRecord, idx) => new MessageChunk<T>(chunkIndexRecord, idx),
    );

    this.#chunks = chunks;
  }

  public get maxTime(): bigint {
    if (this.#maxTime === null) {
      this.#maxTime = this.#chunks[0].end;
      for (const chunk of this.#chunks) {
        if (chunk.end > this.#maxTime) {
          this.#maxTime = chunk.end;
        }
      }
    }
    return this.#maxTime;
  }

  public get minTime(): bigint {
    if (this.#minTime === null) {
      this.#minTime = this.#chunks[0].start;
      for (const chunk of this.#chunks) {
        if (chunk.start < this.#minTime) {
          this.#minTime = chunk.start;
        }
      }
    }
    return this.#minTime;
  }

  public dispose(): void {
    for (const chunk of this.#chunks) {
      chunk.unload();
    }
  }

  /**
   * Returns the chunk that contains the given timepoint,
   * or the chunk that ends closest to the given timepoint.
   */
  public getChunkAsOfTime(searchTimeNs: bigint): MessageChunk<T> | undefined {
    if (this.#chunks.length === 0) {
      return;
    }

    let low = 0;
    let high = this.#chunks.length - 1;
    let result: MessageChunk<T> | undefined = undefined;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const chunk = this.#chunks[mid];
      if (chunk.containsTime(searchTimeNs)) {
        // A chunk contains a message published around the given timepoint.
        // Short-circuit the search.
        return chunk;
      } else if (chunk.end < searchTimeNs) {
        // The search timepoint is after the end of this chunk
        // Set chunk as our best candidate.
        result = chunk;
        low = mid + 1;
      } else {
        // The search timepoint must be before the start of this chunk
        high = mid - 1;
      }
    }
    return result;
  }

  public getChunksContainingTimestamps(
    timestamps: readonly bigint[],
  ): MessageChunk<T>[] {
    const chunks = new Set<MessageChunk<T>>();

    // store the last found chunk
    // and check if the next timestamp falls within it before doing another search for matching chunk
    // this optimizes for the expected case in which timestamps are ordered
    // and the common case in which messages logged close in time are located in the same chunk
    let lastChunk: MessageChunk<T> | undefined;
    for (const timestamp of timestamps) {
      if (lastChunk?.containsTime(timestamp)) {
        continue;
      }
      lastChunk = this.getChunkAsOfTime(timestamp);
      if (lastChunk !== undefined) {
        chunks.add(lastChunk);
      }
    }
    return Array.from(chunks).sort((a, b) => a.index - b.index);
  }

  public getChunksInRange({
    start,
    end,
  }: {
    start?: bigint | ((chunkStart: bigint, chunkEnd: bigint) => boolean);
    end?: bigint | ((chunkStart: bigint, chunkEnd: bigint) => boolean);
  }): MessageChunk<T>[] {
    return this.#chunks.filter((chunk) => {
      const startCondition = start ?? chunk.start;
      const endCondition = end ?? chunk.end;

      const startConditionTest =
        typeof startCondition === "function"
          ? startCondition(chunk.start, chunk.end)
          : startCondition <= chunk.end;
      const endConditionTest =
        typeof endCondition === "function"
          ? endCondition(chunk.start, chunk.end)
          : endCondition >= chunk.start;
      return startConditionTest && endConditionTest;
    });
  }

  public getLoadingChunks(): MessageChunk<T>[] {
    return this.#chunks.filter((chunk) => chunk.isLoading());
  }
}
