import postHog from "posthog-js";

import { OrgRecord } from "@/shared/domain/orgs";
import { UserRecord } from "@/shared/domain/users";
import { Env, isDevOrTestStage, isProdStage } from "@/shared/environment";

import { LoggerService } from "../LoggerService";

import { EventName, EventProps } from "./analytics";

class PostHogService {
  constructor() {
    postHog.init(Env.PostHogPublicToken, {
      api_host: "https://us.i.posthog.com",
      capture_pageview: false,
      loaded: (ph) => {
        if (isProdStage() === false) {
          LoggerService.log("Disabling PostHog session recording in non-prod");
          ph.set_config({
            disable_session_recording: true,
          });
        }
        if (isDevOrTestStage()) {
          LoggerService.log("Disabling PostHog in dev/test");
          ph.set_config({
            opt_out_capturing_by_default: true,
          });
        }
      },
      session_recording: {
        maskInputOptions: {
          password: true,
          email: true,
        },
      },
    });
  }
  identifyUser(userRecord: UserRecord) {
    LoggerService.log("Analytics Service: Identifying user");
    postHog.identify(userRecord.user_id, {
      email: userRecord.user_id, // UserId is email
      name: userRecord.name,
    });
  }
  resetUser() {
    postHog.reset();
  }
  setOrg(orgRecord: OrgRecord) {
    LoggerService.log("Analytics Service: Setting org");
    postHog.group("organization", orgRecord.org_id, {
      name: orgRecord.name,
    });
  }
  trackEvent<Name extends EventName>(
    name: Name,
    props: EventProps<Name>,
  ): void {
    LoggerService.log(`Tracking event: ${name}`, props || "");
    postHog.capture(name, props);
  }
}

const instance = new PostHogService();
export { instance as PostHogService };
