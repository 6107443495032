import { MessagePathNode, PathParser } from "@/shared/domain/topics";
import { useDebouncedCallback } from "@/shared/hooks";
import {
  type PlotSeries,
  actions,
  useVizDispatch,
} from "@/shared/state/visualization";

import { colorForSeries } from "../../../colorScale";
import { DataSourceSelector } from "../../../controls";
import { isViewableAsPlot } from "../../../Panel/predicates";
import { default as sectionStyles } from "../../SettingsSection.module.css";

import { ConfigItem } from "./ConfigItem";
import { PlotSeriesHeader } from "./PlotSeriesHeader";

interface PlotSeriesConfigProps {
  order: number;
  panelId: string;
  plotSeries: PlotSeries;
}

export function PlotSeriesConfig({
  order,
  panelId,
  plotSeries,
}: PlotSeriesConfigProps) {
  const vizStateDispatch = useVizDispatch();

  const changeSeriesColor = useDebouncedCallback(function setSeriesColor(
    lineColor: string,
  ) {
    vizStateDispatch(
      actions.setSeriesConfig(panelId, plotSeries.id, {
        lineColor,
      }),
    );
  }, 15);

  const lineColor = colorForSeries(plotSeries);

  const fileId = plotSeries.data.topic.association.association_id;

  const formattedMessagePath = MessagePathNode.partsToString(
    plotSeries.data.messagePath.parts,
  );
  const path = [plotSeries.data.topic.name, formattedMessagePath].join(
    PathParser.TOPIC_SEPARATOR,
  );

  return (
    <div className={sectionStyles.setting}>
      <PlotSeriesHeader
        order={order}
        panelId={panelId}
        plotSeries={plotSeries}
      />
      <ConfigItem label="Data Source">
        <DataSourceSelector
          initialFile={fileId}
          initialPath={path}
          onChange={(messagePathNode) => {
            vizStateDispatch(
              actions.setDataSource({
                topicData: messagePathNode.toTopicData(),
                panelId,
                segmentId: plotSeries.id,
              }),
            );
          }}
          optionFilter={isViewableAsPlot}
        />
      </ConfigItem>
      <ConfigItem label="Line Color">
        <input
          aria-label={`Change line color for ${path}`}
          type="color"
          value={lineColor}
          name={plotSeries.id}
          onChange={(event) => {
            changeSeriesColor(event.target.value);
          }}
        />
      </ConfigItem>
    </div>
  );
}
