import {
  type MessagePathNode,
  CanonicalDataType,
} from "@/shared/domain/topics";
import { ErrorMonitoringService } from "@/shared/services";
import {
  type Dispatch,
  type PanelState,
  type TopicData,
  actions,
  ImagePanelState,
  isImagePanelState,
  isLogPanelState,
  isMapPanelState,
  Layout,
  LayoutItem,
  LayoutOrientation,
  MapPanelState,
  PanelType,
  Placement,
} from "@/shared/state/visualization";
import { nanoSecToSecStr } from "@/shared/time";

import { DroppableData, DropZone } from "../DnD";
import {
  getCorrespondingGeoType,
  isLatitudeType,
  isLongitudeType,
} from "../Panel/MapPanel";
import { panelAcceptsMessagePath } from "../Panel/predicates";

interface LayoutData {
  layout: Layout | LayoutItem;
  orientation: LayoutOrientation;
}

function canAddToLogPanel(
  panel: PanelState,
  messagePath: MessagePathNode,
): {
  isValid: boolean;
  errors: string[];
} {
  if (!isLogPanelState(panel)) {
    return {
      isValid: false,
      errors: ["Cannot add data to non-log panel"],
    };
  }

  // Can always add if the panel is empty
  if (panel.data.length === 0) {
    return { isValid: true, errors: [] };
  }

  const errors: string[] = [];

  const topic = messagePath.topic;

  // Cannot add if the message path is for a different topic
  if (panel.data[0].topic.id !== topic.data.topic_id) {
    errors.push("Cannot add data from a different topic to a log panel");
  }

  // Cannot add if the message path is already in the panel
  if (
    panel.data.some(
      (data) => data.messagePath.id === messagePath.data.message_path_id,
    )
  ) {
    errors.push(
      `Message path "${messagePath.data.message_path}" is already in the log panel`,
    );
  }

  return { isValid: errors.length === 0, errors };
}

function canAddToPanel(panel: PanelState, messagePath: MessagePathNode) {
  const panelType = panel.type;
  switch (panelType) {
    case PanelType.Log:
      return canAddToLogPanel(panel, messagePath);
    default:
      return { isValid: true, errors: [] };
  }
}

/**
 * Make a default decision for how to visualize a data type.
 * E.g., a numeric type should be visualized as a plot,
 * a string should be visualized as a "raw message," etc.
 */
export function determineDefaultPanelType(
  messagePath: MessagePathNode,
): PanelType {
  switch (messagePath.data.canonical_data_type) {
    case CanonicalDataType.Number:
    case CanonicalDataType.NumberArray:
    case CanonicalDataType.Boolean: // Fall through
      return PanelType.Plot;
    case CanonicalDataType.Image:
      return PanelType.Image;
    case CanonicalDataType.String:
      return PanelType.Log;
    case CanonicalDataType.LatDegFloat:
    case CanonicalDataType.LonDegFloat:
    case CanonicalDataType.LatDegInt:
    case CanonicalDataType.LonDegInt:
      return PanelType.Map;
    default:
      return PanelType.RawMessage;
  }
}

function getCorrespondingMessagePath(messagePath: MessagePathNode) {
  const canonicalType = messagePath.data.canonical_data_type;

  if (isLatitudeType(canonicalType) || isLongitudeType(canonicalType)) {
    const correspondingType = getCorrespondingGeoType(canonicalType);
    const siblingMessagePath = messagePath.parent?.children?.find(
      (siblingNode) =>
        siblingNode.data.canonical_data_type === correspondingType,
    );

    return siblingMessagePath;
  }

  return undefined;
}

/**
 * On drop of a message path into the panel board,
 * dispatch an action to create a new panel appropriate for that message path.
 */
export function constructPanelForMessagePath(
  dispatch: Dispatch,
  messagePath: MessagePathNode,
  panelType: PanelType,
  placement?: Placement,
) {
  let data = [messagePath.toTopicData()];

  if (panelType === PanelType.Map) {
    const correspondingMessagePath = getCorrespondingMessagePath(messagePath);

    if (correspondingMessagePath) {
      const correspondingData = correspondingMessagePath.toTopicData();
      data = [...data, correspondingData];
    }
  }

  dispatch(actions.createPanel(data, panelType, placement));
}

function addDataToImagePanel(
  dispatch: Dispatch,
  panelState: ImagePanelState,
  onError: (errorMsg: string) => void,
  data: TopicData,
) {
  if (panelState.data) {
    if (
      panelState.data.some(
        (clip) => clip.data.messagePath.id === data.messagePath.id,
      )
    ) {
      onError("This clip is already used by this panel.");
      return;
    }

    for (const clip of panelState.data) {
      const clipStart = clip.data.topic.startTime;
      const clipEnd = clip.data.topic.endTime;
      const dataStart = data.topic.startTime;
      const dataEnd = data.topic.endTime;

      if (
        clipStart === undefined ||
        clipEnd === undefined ||
        dataStart === undefined ||
        dataEnd === undefined
      ) {
        continue;
      }

      if (
        (clipStart <= dataStart && dataStart <= clipEnd) ||
        (clipStart <= dataEnd && dataEnd <= clipEnd)
      ) {
        onError(
          "Overlapping clips are not supported at this time. Added clip " +
            `t=[${nanoSecToSecStr(BigInt(clipStart))}, ${nanoSecToSecStr(BigInt(clipEnd))}]` +
            " overlaps with existing clip " +
            `t=[${nanoSecToSecStr(BigInt(dataStart))}, ${nanoSecToSecStr(BigInt(dataEnd))}].`,
        );
        return;
      }
    }
  }

  dispatch(actions.putImagePanelData(panelState.id, data));
}

function addDataToMapPanel(
  dispatch: Dispatch,
  panelState: MapPanelState,
  messagePath: MessagePathNode,
  onError: (errorMsg: string) => void,
  data: TopicData,
) {
  // Only add the message path to the map if it's not already in use
  // This prevents duplicate paths from being added
  if (!messagePathInMapState(panelState, messagePath)) {
    const correspondingMessagePath = getCorrespondingMessagePath(messagePath);
    const dataArr = correspondingMessagePath
      ? [data, correspondingMessagePath.toTopicData()]
      : [data];

    dispatch(actions.addPathToMapPanel(panelState.id, dataArr));
  } else {
    onError(
      `Message path '${messagePath.data.message_path}' is already in use on the panel.`,
    );
  }
  return;
}

export function addDataToPanel(
  dispatch: Dispatch,
  panelState: PanelState,
  messagePath: MessagePathNode,
  onError: (errorMsg: string) => void,
) {
  const data = messagePath.toTopicData();

  if (panelState.type === PanelType.Plot) {
    dispatch(actions.addSeriesToPlotPanel(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.RawMessage) {
    dispatch(actions.putRawMessagePanelData(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.Image && isImagePanelState(panelState)) {
    addDataToImagePanel(dispatch, panelState, onError, data);
    return;
  }

  if (panelState.type === PanelType.Log) {
    dispatch(actions.addDataToLogPanel(panelState.id, data));
    return;
  }

  if (panelState.type === PanelType.Map && isMapPanelState(panelState)) {
    addDataToMapPanel(dispatch, panelState, messagePath, onError, data);
    return;
  }
}

function messagePathInMapState(
  panelState: MapPanelState,
  messagePath: MessagePathNode,
): boolean {
  // Get a list of topic message path ids that are currently in use
  const activeMessagePathIds = panelState.data.flatMap((path) =>
    path.data.map((geoItem) => geoItem.messagePath.id),
  );

  // Check if the provided message path id is already in the list
  if (activeMessagePathIds.includes(messagePath.data.message_path_id)) {
    return true;
  }

  // Message path is not currently in map state
  return false;
}

export function dropMessagePath(
  dispatch: Dispatch,
  draggedMessagePath: MessagePathNode,
  droppable: DroppableData<unknown>,
  onError: (errorMsg: string) => void,
) {
  const { dropZone } = droppable;

  if (dropZone === DropZone.PanelBoard) {
    const panelType = determineDefaultPanelType(draggedMessagePath);
    constructPanelForMessagePath(dispatch, draggedMessagePath, panelType);
  }
  if (dropZone === DropZone.Layout) {
    const { data } = droppable as DroppableData<LayoutData>;
    const panelType = determineDefaultPanelType(draggedMessagePath);
    if (data === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropMessagePath's droppable data is undefined instead of being of type LayoutData",
        ),
      );
      onError("An error occurred dropping data onto the board.");
      return;
    }

    constructPanelForMessagePath(dispatch, draggedMessagePath, panelType, {
      siblingLayout: data.layout,
      orientation: data.orientation,
    });
  }
  if (dropZone === DropZone.Panel) {
    const { data: currentPanel } = droppable as DroppableData<PanelState>;
    if (currentPanel === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropMessagePath's droppable data is undefined instead of being of type PanelState",
        ),
      );
      onError("An error occurred dropping data on the panel.");
      return;
    }

    if (!panelAcceptsMessagePath(currentPanel, draggedMessagePath)) {
      onError(
        `Cannot drop data of type "${draggedMessagePath.data.data_type}" onto a panel of type "${currentPanel.type}"`,
      );
      return;
    }

    const addCommand = canAddToPanel(currentPanel, draggedMessagePath);
    if (!addCommand.isValid) {
      onError(addCommand.errors.join("\n"));
      return;
    }

    addDataToPanel(dispatch, currentPanel, draggedMessagePath, onError);
  }
}

export function dropPanel(
  dispatch: Dispatch,
  draggedPanel: LayoutItem,
  droppable: DroppableData<unknown>,
  onError: (errorMsg: string) => void,
) {
  const dropZone = droppable.dropZone;

  if (dropZone === DropZone.Layout) {
    const { data } = droppable as DroppableData<LayoutData>;

    if (data === undefined) {
      // This would be a programming error.
      ErrorMonitoringService.captureError(
        new Error(
          "DropPanel's droppable data is undefined instead of being of type LayoutData",
        ),
      );
      onError("An error occurred dropping this panel.");
      return;
    }

    dispatch(
      actions.movePanel(draggedPanel, {
        siblingLayout: data.layout,
        orientation: data.orientation,
      }),
    );
  }
}
